import React from 'react'
import { Button, Form, Input, Modal, Switch, Typography, message } from 'antd'
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import InputMask from 'react-input-mask'
import { passwordPattern, ruNamePattern } from 'shared/utils/patterns'
import { userApi } from 'api/user'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import { normalizePhone } from 'shared/utils/normalizePhone'
import { superAdminRoutes } from 'shared/constants/routes'
import { showServerError } from 'shared/utils/showServerError'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import styles from './UserForm.module.scss'

interface IUserFormEditProps {
  mode: 'edit'
  userId: string
}

interface IUserFormCreateProps {
  mode: 'create'
}

type IUserFormProps = IUserFormEditProps | IUserFormCreateProps

interface IDataForm {
  firstName: string
  lastName: string
  middleName?: string
  noMiddleName: boolean
  email: string
  password: string
  phone: string
}

export const UserForm: React.FC<IUserFormProps> = (props) => {
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = React.useState(
    props.mode === 'create' ? false : true
  )
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const middleName: string | undefined = Form.useWatch('middleName', { form })
  const noMiddleName: boolean | undefined = Form.useWatch('noMiddleName', {
    form
  })
  const isMiddleNameLength: boolean = Boolean(middleName?.length ?? false)

  React.useEffect(() => {
    if (noMiddleName) {
      form.setFields([
        {
          name: 'middleName',
          errors: [],
          value: undefined
        }
      ])
    }
  }, [noMiddleName])

  React.useEffect(() => {
    if (isMiddleNameLength) {
      form.setFieldValue('noMiddleName', false)
      form.setFields([
        {
          name: 'middleName',
          errors: []
        }
      ])
    }
  }, [isMiddleNameLength])

  React.useEffect(() => {
    if (props.mode === 'edit') {
      const getUser = async () => {
        message.loading('Загрузка данных о пользователе')
        try {
          const { data } = await userApi.getUser(props.userId)
          form.setFields([
            { name: 'number', value: data.number },
            { name: 'firstName', value: data.name },
            { name: 'lastName', value: data.surname },
            { name: 'middleName', value: data.patronymic },
            { name: 'noMiddleName', value: !data.patronymic },
            { name: 'email', value: data.email },
            { name: 'phone', value: data.tel ? phoneMaskStatic(data.tel) : undefined }
          ])
        } catch (e) {
          const error = e as AxiosError
          if (error.response?.status === 500 || error.response?.status === 400 || error.response?.status === 404) {
            return navigate(superAdminRoutes.USERS)
          }
          showServerError(e)
          setIsError(true)
        } finally {
          message.destroy()
          setIsLoading(false)
        }
      }
      getUser()
    }
  }, [])

  const setProductsAction = async () => {
    try {
      await userApi.setActions({
        toDelete: props.mode === 'edit' ? props.userId : ''
      })
      message.success('Пользователь успешно удален')
      navigate(superAdminRoutes.USERS)
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const onSubmit = async (data: IDataForm) => {
    try {
      setIsSubmitting(true)
      if (props.mode === 'create') {
        await userApi.addUser({
          name: data.firstName.trim(),
          surname: data.lastName.trim(),
          patronymic: noMiddleName ? undefined : data.middleName?.trim(),
          email: data.email.trim(),
          password: data.password.trim(),
          tel: normalizePhone(data.phone)
        })
        message.success('Пользователь успешно добавлен')
      } else {
        await userApi.updateUser({
          _id: props.userId,
          name: data.firstName.trim(),
          surname: data.lastName.trim(),
          patronymic: noMiddleName ? undefined : data.middleName?.trim(),
          email: data.email.trim(),
          tel: normalizePhone(data.phone)
        })
        message.info('Пользователь успешно обновлен')
      }
      navigate(superAdminRoutes.USERS)
    } catch (e) {
      showServerError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const showDeleteConfirm = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Вы действительно хотите удалить пользователя?',
      content: 'Отменить данное действие будет невозможно.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',
      cancelText: 'Закрыть',
      onOk() {
        return new Promise((resolve) => {
          setProductsAction().then(resolve)
        })
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  return (
    <>
      {isLoading || isError ? null : (
        <div style={{ paddingBottom: 80 }}>
          <MainTitle
            isGoBack
            text={
              props.mode === 'create'
                ? 'Добавить пользователя'
                : `Пользователь ID ${form.getFieldValue('number')}`
            }
          />
          <Typography.Title level={2} className={styles.title}>
            Информация о пользователе
          </Typography.Title>
          <Form
            className={styles.form}
            form={form}
            name='users'
            size='large'
            onFinish={onSubmit}
            autoComplete='off'
          >
            <Form.Item
              name='firstName'
              label='Имя'
              rules={[
                {
                  required: true,
                  message: 'Заполните имя'
                },
                {
                  whitespace: true,
                  message: 'Заполните имя'
                },
                ruNamePattern
              ]}
              hasFeedback
            >
              <Input placeholder='Введите имя' />
            </Form.Item>
            <Form.Item
              name='lastName'
              label='Фамилия'
              rules={[
                {
                  required: true,
                  message: 'Заполните фамилию'
                },
                {
                  whitespace: true,
                  message: 'Заполните фамилию'
                },
                ruNamePattern
              ]}
              hasFeedback
            >
              <Input placeholder='Введите фамилию' />
            </Form.Item>
            <div className={styles.middleName}>
              <Form.Item
                name='middleName'
                label='Отчество'
                rules={
                  noMiddleName
                    ? []
                    : [
                      {
                        required: true,
                        message: 'Заполните отчество'
                      },
                      {
                        whitespace: true,
                        message: 'Заполните отчество'
                      },
                      ruNamePattern
                    ]
                }
                style={{ flexGrow: 1 }}
                hasFeedback
              >
                <Input placeholder='Введите отчество' />
              </Form.Item>
              <Form.Item
                name='noMiddleName'
                label='Нет отчества'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
            </div>
            <Form.Item
              name='email'
              label='Электронный адрес'
              rules={[
                {
                  type: 'email',
                  message: 'Некорректный электронный адрес'
                },
                {
                  required: true,
                  message: 'Заполните электронный адрес'
                }
              ]}
              hasFeedback
            >
              <Input type='email' placeholder='Введите электронный адрес' />
            </Form.Item>
            <Form.Item
              name='phone'
              label='Номер телефона'
              rules={
                [
                  {
                    required: true,
                    message: 'Заполните номер телефона'
                  },
                  {
                    min: 18,
                    message: 'Заполните номер телефона'
                  }
                ]
              }
              hasFeedback
            >
              <InputMask mask='+7 (999) 999-99-99' maskChar={null}>
                {/* @ts-ignore */}
                {() => <Input placeholder='Введите номер телефона' />}
              </InputMask>
            </Form.Item>
            {props.mode === 'create' && (
              <Form.Item
                name='password'
                label='Пароль'
                rules={[
                  {
                    required: true,
                    message: 'Заполните пароль'
                  },
                  ...passwordPattern
                ]}
                hasFeedback
              >
                <Input.Password placeholder='Введите пароль' />
              </Form.Item>
            )}
            <div className={styles.footer}>
              <Button
                htmlType='submit'
                type='primary'
                size='large'
                icon={<AppstoreAddOutlined />}
                loading={isSubmitting}
              >
                Сохранить
              </Button>
              {props.mode === 'edit' && (
                <Button
                  danger
                  type='primary'
                  icon={<DeleteOutlined />}
                  onClick={showDeleteConfirm}
                >
                  Удалить
                </Button>
              )}
            </div>
          </Form>
        </div>
      )}
    </>
  )
}
