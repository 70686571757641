export interface IAdminOrdersQueryRequest extends Components.Schemas.IAdminOrdersQueryRequest { }
export interface IOrdersQueryFilters extends Omit<IAdminOrdersQueryRequest, 'skip' | 'limit'> { }
export interface IAdminOrdersQueryResponse extends Components.Schemas.IAdminOrdersQueryResponse { }
export interface ITableOrder extends Components.Schemas.IPopulatedOrder { }
export interface IOrderItem extends Components.Schemas.IPopulatedOrderItem { }
export interface IAdminChangeOrderStatus extends Components.Schemas.IAdminChangeOrderStatus { }
export interface IAdminGetOrderResponse extends Components.Schemas.IAdminGetOrderResponse { }
export interface IAdminDenyItem extends Components.Schemas.IAdminDenyItem { }
export interface IAdminRemoveOrderItemRequest extends Components.Schemas.IAdminRemoveOrderItemRequest { }
export interface IAdminUpdateOrderRequest extends Components.Schemas.IAdminUpdateOrderRequest { }
export interface IAdminMultiOrderResponse extends Components.Schemas.IAdminMultiOrderResponse { }

export enum EOrderStatus {
  NOT_PAID = 'NOT_PAID',
  ON_CONFIRMATION = 'ON_CONFIRMATION',
  CONFIRMED_BY_SELLER = 'CONFIRMED_BY_SELLER',
  IN_DELIVERY = 'IN_DELIVERY',
  WAITING_IN_POINT = 'WAITING_IN_POINT',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  ARCHIVED = 'ARCHIVED',
}

export enum ERecieveMethod {
  PICKUP_POINT = 'PICKUP_POINT',
  EXPRESS = 'EXPRESS',
  COURIER = 'COURIER',
  PVZ = 'PVZ',
}

export enum EOrderItemsStatus {
  AGREED = 'AGREED',
  DENIED = 'DENIED',
}

export interface IUpdateAdminOrderFormData {
  deliveryPrice?: string
  trackNumber?: string
  invoice: any
  deliveryType: ERecieveMethod
  prepareDate: any
  shopId?: string
  deliveryAddress?: string
  note: string
}