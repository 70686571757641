import React from 'react'
import styles from './ModerationIcon.module.scss'

interface IModerationIconProps {
  width: number
  height: number
}

export const ModerationIcon: React.FC<IModerationIconProps> = React.memo(({ width, height }) => {
  return (
    <div className={styles.wrapper} style={{ width, height }}>
      <span className={styles.text}>M</span>
    </div>
  )
})
