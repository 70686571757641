import React from 'react'
import {
  Button,
  Cascader,
  Form,
  Input,
  MenuProps,
  Modal,
  Select,
  Typography,
  message
} from 'antd'
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SaveOutlined
} from '@ant-design/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { AxiosError } from 'axios'
import { InfoBlock, LargeText } from 'components/ui'
import { productApi } from 'api/product'
import {
  EActionsProductRequest,
  EProductStatus,
  EUnit,
  IFrontVariation,
  IParsedCategory,
  IPreviewData,
  IProductFormData,
  IUpdateProduct
} from 'models/product'
import { ERoles } from 'models/auth'
import { sellerApi } from 'api/seller'
import { ISeller, IShopDetails } from 'models/seller'
import { appSelectors } from 'store/app'
import { AnchorsWrapper } from 'components/layout/AnchorsWrapper/AnchorsWrapper'
import { getSellerEditPath } from 'routing/utils/getSellerEditPath'
import { showServerError } from 'shared/utils/showServerError'
import { floatNumbersPattern, numbersPattern } from 'shared/utils/patterns'
import { showMessage } from 'shared/utils/showMessage'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { IMAGES_URL } from 'shared/constants/urls'
import { Uploader } from 'components/ui/Uploader/Uploader'
import { TVariantMode } from 'models/app'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { ProductPreview } from '../ProductPreview/ProductPreview'
import styles from './ProductForm.module.scss'
import { getPreviewData } from './utils/getPreviewData'
import { getCreateSubmitData, getEditSubmitData } from './utils/getSubmitData'
import { CreateCategory } from './ui/CreateCategory'
import { ProductStatus } from './ui/ProductStatus'
import { Characteristics } from './ui/Characteristics'
import { validateVariations } from './utils/variations'

interface IProductFormProps {
  mode: TVariantMode
}

const anchors: MenuProps['items'] = [
  { label: 'Информация о товаре', key: '#info' },
  { label: 'Габариты', key: '#dimensions' },
  { label: 'Наполнение', key: '#filling' }
]

export const ProductForm: React.FC<IProductFormProps> = ({ mode }) => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const sellerId = useAppSelector(appSelectors.selectSellerId)
  const role = useAppSelector(appSelectors.selectRole)
  const isAdmin = role === ERoles.ADMIN

  const isModeCreate = mode === 'create'

  const [form] = Form.useForm()

  const [preview, setPreview] = React.useState({
    open: false,
    data: null as IPreviewData | null
  })

  const [sellerInfo, setSellerInfo] = React.useState<ISeller | null>(null)

  const [categories, setCategories] = React.useState<IParsedCategory[]>([])
  const [selectedCategoryName, setSelectedCategoryName] =
    React.useState<string>('')
  const [selectedSubCategoryName, setSelectedSubCategoryName] =
    React.useState<string>('')

  const [isLoading, setIsLoading] = React.useState(isModeCreate ? false : true)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [isCreateCategoryOpen, setIsCreateCategoryOpen] = React.useState(false)

  const [deletedPhotos, setDeletedPhotos] = React.useState<string[]>([])
  const [deletedVariations, setDeletedVariations] = React.useState<string[]>([])

  const productId: string | undefined = Form.useWatch('id', {
    form,
    preserve: true
  })
  const number: string | undefined = Form.useWatch('number', {
    form,
    preserve: true
  })
  const productStatus: EProductStatus | undefined = Form.useWatch('status', {
    form,
    preserve: true
  })
  const seller: { _id: string; shop_details: IShopDetails } | undefined =
    Form.useWatch('seller', { form, preserve: true })

  const variations: IFrontVariation[] =
    Form.useWatch('variations', {
      form,
      preserve: true
    }) ?? []

  const firstVariationName = React.useMemo(() => {
    return variations.find((el) => el.firstCharacteristicName)
      ?.firstCharacteristicName
  }, [variations])

  const secondVariationName = React.useMemo(() => {
    return variations.find((el) => el.secondCharacteristicName)
      ?.secondCharacteristicName
  }, [variations])

  const getCategories = async () => {
    try {
      const response = await productApi.getCategories({
        parsed: 'true'
      })
      setCategories(response.data as IParsedCategory[])
    } catch (e) {
      showServerError(e)
    }
  }

  const onDeleteProduct = async () => {
    try {
      await productApi.setActions({ toDelete: productId })
      message.success('Товар успешно удален')
      navigate(-1)
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const changeStatus = async (
    status:
      | EProductStatus.PUBLISHED
      | EProductStatus.DRAFT
      | EProductStatus.REJECTED
  ) => {
    try {
      const action =
        status === EProductStatus.PUBLISHED
          ? EActionsProductRequest.PUBLISH
          : status === EProductStatus.DRAFT
            ? EActionsProductRequest.DRAFT
            : EProductStatus.REJECTED
      message.loading('Обновление статуса')
      await productApi.setActions({ [action]: productId })
      form.setFieldValue('status', status)
      message.destroy()
      message.success('Статус успешно сменен')
    } catch (e) {
      message.destroy()
      showServerError(e)
    }
  }

  React.useEffect(() => {
    getCategories()
  }, [])

  React.useEffect(() => {
    if (!isAdmin) {
      const getSeller = async () => {
        try {
          const response = await sellerApi.getSeller(sellerId as string)
          setSellerInfo(response.data)
        } catch (e) {
          showServerError(e)
        }
      }
      getSeller()
    }
  }, [])

  const onAddToDeletedVariations = React.useCallback(
    (productId: string) => {
      setDeletedVariations((prev) => [...prev, productId])
    },
    [deletedVariations]
  )

  React.useEffect(() => {
    if (mode === 'edit') {
      const getProduct = async () => {
        try {
          message.loading('Загрузка данных о товаре')
          const { data } = await productApi.getProduct(id as string)
          form.setFields([
            { name: 'id', value: data._id },
            { name: 'number', value: data.number },
            { name: 'status', value: data.status },
            { name: 'seller', value: data.seller_id },
            { name: 'name', value: data.name },
            {
              name: 'categories_id',
              value:
                data?.category_id?._id && data?.subCategory_id?._id
                  ? [data.category_id._id, data.subCategory_id._id]
                  : undefined
            },
            { name: 'vendor_code', value: data.vendor_code },
            { name: 'price', value: data.price },
            { name: 'tax', value: data.tax },
            { name: 'width', value: data?.dimension?.width },
            { name: 'length', value: data?.dimension?.length },
            { name: 'height', value: data?.dimension?.height },
            { name: 'weight', value: data?.dimension?.weight },
            { name: 'unit', value: data?.dimension?.unit },
            { name: 'in_stock', value: data?.dimension?.in_stock },
            {
              name: 'photo',
              value: data?.photo?.map((el) => ({
                uid: el,
                thumbUrl: `${IMAGES_URL}${el}`
              }))
            },
            { name: 'desc', value: data.desc },
            { name: 'characteristics', value: data?.characteristic }
          ])
          if (data.variations?.length) {
            const variationsForForm =
              data.variations.map(
                (item) =>
                  ({
                    product: { id: item._id, name: item.name },
                    firstCharacteristicName: item.characteristic[0].name,
                    firstCharacteristicValue: item.characteristic[0].value,
                    secondCharacteristicName: item.characteristic?.[1]?.name,
                    secondCharacteristicValue: item.characteristic?.[1]?.value
                  } as IFrontVariation)
              ) ?? []
            form.setFieldValue('variations', [
              ...variationsForForm,
              data.variations[0].characteristic.length > 1
                ? ({
                  firstCharacteristicName:
                    data.variations[0].characteristic[0].name,
                  firstCharacteristicValue: '',
                  secondCharacteristicName:
                    data.variations[0].characteristic[1].name,
                  secondCharacteristicValue: ''
                } as IFrontVariation)
                : ({
                  firstCharacteristicName:
                    data.variations[0].characteristic[0].name,
                  firstCharacteristicValue: ''
                } as IFrontVariation)
            ])
          }
          setSelectedCategoryName(data?.category_id?.name ?? '')
          setSelectedSubCategoryName(data?.subCategory_id?.name ?? '')
        } catch (e) {
          const error = e as AxiosError
          if (
            error.response?.status === 400 ||
            error.response?.status === 404
          ) {
            return navigate('/')
          }
          showServerError(e)
          setIsError(true)
        } finally {
          setIsLoading(false)
          message.destroy()
        }
      }
      getProduct()
    }
  }, [])

  const closePreview = React.useCallback(() => {
    setPreview({ open: false, data: null })
    bodyScrollLock.disable()
  }, [])

  const closeCreateCategory = React.useCallback(() => {
    setIsCreateCategoryOpen(false)
    bodyScrollLock.disable()
  }, [])

  const deleteProduct = React.useCallback(
    (onSuccess?: () => void) => {
      bodyScrollLock.enable()
      Modal.confirm({
        centered: true,
        title: 'Вы действительно хотите удалить  товар?',
        content: 'Отменить данное действие будет невозможно.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Удалить',
        cancelText: 'Закрыть',
        onOk() {
          if (onSuccess) {
            return onSuccess()
          }
          return new Promise((resolve) => {
            onDeleteProduct().then(resolve)
          })
        },
        onCancel() {
          bodyScrollLock.disable()
        }
      })
    },
    [productId]
  )

  const saveProduct = async (
    status:
      | EProductStatus.DRAFT
      | EProductStatus.PUBLISHED
      | EProductStatus.REJECTED
      | undefined
  ) => {
    try {
      bodyScrollLock.disable()
      closePreview()
      setIsSubmitting(true)
      const data: IProductFormData = form.getFieldsValue()
      const normalizedData = isModeCreate
        ? getCreateSubmitData(data, status, firstVariationName, secondVariationName)
        : getEditSubmitData(
          data,
          status,
          productId ?? '',
          deletedPhotos,
          deletedVariations,
          firstVariationName,
          secondVariationName
        )
      isModeCreate
        ? await productApi.addProduct(normalizedData)
        : await productApi.updateProduct(normalizedData as IUpdateProduct)
      navigate(-1)
      if (status === EProductStatus.DRAFT) {
        return message.info('Товар сохранен как черновик')
      }
      if (status === EProductStatus.PUBLISHED) {
        return message.success('Товар успешно опубликован')
      }
      message.success('Товар успешно сохранен')
    } catch (e) {
      showServerError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onFinish = async (
    status:
      | EProductStatus.DRAFT
      | EProductStatus.PUBLISHED
      | EProductStatus.REJECTED
      | undefined
  ) => {
    if (
      status &&
      productStatus === EProductStatus.MODERATION &&
      role === ERoles.USER
    ) {
      if (status === EProductStatus.PUBLISHED) {
        return showMessage(
          'error',
          'Этот товар находится на модерации, вы не можете его опубликовать',
          ''
        )
      }
      return showMessage(
        'error',
        'Этот товар находится на модерации, вы не можете сохранить его в черновик',
        ''
      )
    }
    try {
      const data = await form.validateFields()
      if (data.variations) {
        const { isError } = validateVariations(data.variations)
        if (isError) return
      }
    } catch (error) {
      return showMessage('error', 'Заполните все поля корректно', '')
    }
    const data: IProductFormData = form.getFieldsValue()
    if (status === EProductStatus.PUBLISHED) {
      bodyScrollLock.enable()
      return setPreview({
        open: true,
        data: getPreviewData(
          data,
          selectedCategoryName,
          selectedSubCategoryName
        )
      })
    }
    saveProduct(status)
  }

  return (
    <AnchorsWrapper
      title={
        isModeCreate
          ? 'Добавить товар'
          : isAdmin
            ? `Редактировать товар ID ${number ?? ''}`
            : 'Редактировать товар'
      }
      anchors={anchors}
      titleId='info'
    >
      <div>
        {isLoading || isError ? null : (
          <Form form={form} name='product' size='large'>
            <section className={styles.section}>
              <div
                className={styles.sectionHeader}
                style={{ marginBottom: isAdmin ? 18 : 30 }}
              >
                <Typography.Title
                  level={2}
                  className={styles.sectionTitle}
                  style={{ marginBottom: 0 }}
                >
                  Информация о товаре
                </Typography.Title>
                <ProductStatus
                  activeStatus={productStatus}
                  onStatusClick={changeStatus}
                />
              </div>
              {isAdmin && (
                <LargeText className={styles.seller}>
                  Продавец:{' '}
                  <Link to={getSellerEditPath(seller?._id ?? '')}>
                    <span style={{ color: 'rgba(36, 127, 255, 1)' }}>
                      {seller?.shop_details?.shop_name}
                    </span>
                  </Link>
                </LargeText>
              )}
              <Form.Item
                name='name'
                label='Название товара'
                rules={[
                  {
                    required: true,
                    message: 'Заполните название товара'
                  },
                  {
                    whitespace: true,
                    message: 'Заполните название товара'
                  }
                ]}
                hasFeedback
              >
                <Input placeholder='Ваше название' />
              </Form.Item>
              <div className={styles.flexSmall}>
                <Form.Item
                  name='categories_id'
                  label='Категория'
                  rules={[
                    {
                      required: true,
                      message: 'Выберите категорию'
                    }
                  ]}
                  className={styles.inputFlex}
                  hasFeedback
                >
                  <Cascader
                    showSearch
                    placeholder='Выбор категории'
                    rootClassName='myCascader'
                    popupClassName={styles.categorySelect}
                    options={categories}
                    fieldNames={{ label: 'title' }}
                    expandTrigger='click'
                    onChange={(value, options) => {
                      form.setFieldValue('categories_id', value)
                      setSelectedCategoryName(options?.[0]?.title ?? '')
                      setSelectedSubCategoryName(options?.[1]?.title ?? '')
                    }}
                  />
                </Form.Item>
                <Button
                  htmlType='button'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    bodyScrollLock.enable()
                    setIsCreateCategoryOpen(true)
                  }}
                >
                  {isAdmin ? 'Добавить категорию' : 'Добавить подкатегорию'}
                </Button>
              </div>
              <Form.Item
                name='vendor_code'
                label='Артикул'
                rules={[
                  {
                    required: true,
                    message: 'Заполните артикул товара'
                  },
                  {
                    whitespace: true,
                    message: 'Заполните артикул товара'
                  }
                ]}
                hasFeedback
              >
                <Input placeholder='Ваш внутренний артикул' />
              </Form.Item>
              <div
                className={styles.flexBig}
                style={{ marginBottom: isAdmin ? 24 : 15 }}
              >
                <Form.Item
                  name='price'
                  label='Стоимость'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните стоимость товара'
                    },
                    {
                      pattern: floatNumbersPattern,
                      message: 'Заполните стоимость товара корректно'
                    }
                  ]}
                  className={styles.inputFlex}
                  hasFeedback
                >
                  <Input placeholder='Цена в рублях для покупателя' />
                </Form.Item>
                <Form.Item
                  name='tax'
                  rules={[
                    {
                      required: true,
                      message: 'Выберите размер налога'
                    }
                  ]}
                  style={{ width: 238, marginBottom: 0 }}
                  hasFeedback
                >
                  <Select
                    placeholder='Размер налога'
                    options={[
                      { value: 10, label: '10%' },
                      { value: 20, label: '20%' },
                      { value: 0, label: 'Без налога' }
                    ]}
                  />
                </Form.Item>
              </div>
              {!isAdmin && (
                <InfoBlock
                  text={
                    <>
                      <span>
                        При указании стоимости учитывайте комиссию сервиса:
                      </span>{' '}
                      <Typography.Text className={styles.commissionInfoPercent}>
                        {sellerInfo?.commission}%
                      </Typography.Text>
                    </>
                  }
                />
              )}
            </section>
            <section id='dimensions' className={styles.section}>
              <Typography.Title
                level={2}
                className={classNames(
                  styles.sectionTitle,
                  styles.dimensionsTitle
                )}
              >
                Габариты (см)
              </Typography.Title>
              <Form.Item
                name='width'
                label='Ширина'
                rules={[
                  {
                    required: true,
                    message: 'Заполните ширину упакованного товара'
                  },
                  {
                    pattern: numbersPattern,
                    message: 'Заполните ширину упакованного товара корректно'
                  }
                ]}
                hasFeedback
              >
                <Input placeholder='Ширина упакованного товара в сантиметрах' />
              </Form.Item>
              <Form.Item
                name='length'
                label='Длина'
                rules={[
                  {
                    required: true,
                    message: 'Заполните длину упакованного товара'
                  },
                  {
                    pattern: numbersPattern,
                    message: 'Заполните длину упакованного товара корректно'
                  }
                ]}
                hasFeedback
              >
                <Input placeholder='Длина упакованного товара в сантиметрах' />
              </Form.Item>
              <Form.Item
                name='height'
                label='Высота'
                rules={[
                  {
                    required: true,
                    message: 'Заполните высоту упакованного товара'
                  },
                  {
                    pattern: numbersPattern,
                    message: 'Заполните высоту упакованного товара корректно'
                  }
                ]}
                hasFeedback
              >
                <Input placeholder='Высота упакованного товара в сантиметрах' />
              </Form.Item>
              <div className={styles.flexBig}>
                <Form.Item
                  name='weight'
                  label='Вес'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните вес товара'
                    },
                    {
                      pattern: floatNumbersPattern,
                      message: 'Заполните вес товара корректно'
                    }
                  ]}
                  className={styles.inputFlex}
                  hasFeedback
                >
                  <Input placeholder='Общий вес товара' />
                </Form.Item>
                <Form.Item
                  name='unit'
                  rules={[
                    {
                      required: true,
                      message: 'Выберите единицы измерения'
                    }
                  ]}
                  style={{ width: 238, marginBottom: 0 }}
                  hasFeedback
                >
                  <Select
                    placeholder='Единицы измерения'
                    options={[
                      { value: EUnit.GR, label: 'Граммы' },
                      { value: EUnit.KG, label: 'Килограммы' }
                    ]}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name='in_stock'
                label='В наличии (шт.)'
                rules={[
                  {
                    required: true,
                    message: 'Заполните количество товара в штуках'
                  },
                  {
                    pattern: numbersPattern,
                    message: 'Заполните количество товара в штуках корректно'
                  }
                ]}
                hasFeedback
              >
                <Input placeholder='Количество товара в штуках' />
              </Form.Item>
            </section>
            <section id='filling' className={styles.section}>
              <Typography.Title
                level={2}
                className={styles.sectionTitle}
                style={{ marginBottom: 17 }}
              >
                Наполнение
              </Typography.Title>
              <div className={styles.subSection}>
                <Typography.Title
                  level={4}
                  className={styles.sectionSubTitle}
                  style={{ marginBottom: 3 }}
                >
                  Фото товара:
                </Typography.Title>
                <Typography.Text
                  className={styles.subTitle}
                  style={{ marginBottom: 17 }}
                >
                  Рекомендованный размер изображений не менее 512px по ширине.{' '}
                  <br />
                  Поддерживаются форматы JPG, JPEG, PNG. Вы можете загрузить{' '}
                </Typography.Text>
                <Uploader
                  multiple
                  maxCount={100}
                  name='photo'
                  rules={[
                    {
                      required: true,
                      message: 'Добавьте хотя бы 1 фотографию товара'
                    }
                  ]}
                  onRemoveClick={(file) => {
                    if (!file.originFileObj) {
                      setDeletedPhotos((prev) => [...prev, file.uid])
                    }
                  }}
                />
              </div>
              <div className={styles.subSection}>
                <Typography.Title
                  level={4}
                  className={styles.sectionSubTitle}
                  style={{ marginBottom: 16 }}
                >
                  Описание:
                </Typography.Title>
                <Form.Item
                  name='desc'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните описание товара'
                    },
                    {
                      whitespace: true,
                      message: 'Заполните описание товара'
                    }
                  ]}
                  hasFeedback
                >
                  <Input.TextArea placeholder='Описание товара' rows={4} />
                </Form.Item>
              </div>
              <Characteristics
                form={form}
                sellerId={seller?._id ?? (sellerId as string)}
                productId={productId}
                firstVariationName={firstVariationName}
                secondVariationName={secondVariationName}
                addToDeletedVariations={onAddToDeletedVariations}
              />
            </section>
            <div className={styles.footer}>
              {role === ERoles.USER && (
                <>
                  <Button
                    htmlType='button'
                    type='primary'
                    size='large'
                    icon={<AppstoreAddOutlined />}
                    onClick={() => onFinish(EProductStatus.PUBLISHED)}
                    loading={isSubmitting}
                  >
                    Опубликовать
                  </Button>
                  <Button
                    htmlType='button'
                    size='large'
                    icon={<SaveOutlined />}
                    onClick={() => onFinish(EProductStatus.DRAFT)}
                    loading={isSubmitting}
                  >
                    Сохранить как черновик
                  </Button>
                </>
              )}
              {mode === 'edit' && (
                <>
                  <Button
                    htmlType='button'
                    type={role === ERoles.ADMIN ? 'primary' : 'default'}
                    size='large'
                    icon={<SaveOutlined />}
                    onClick={() => onFinish(undefined)}
                    loading={isSubmitting}
                  >
                    Сохранить
                  </Button>
                  <Button
                    size='large'
                    type='primary'
                    icon={<DeleteOutlined />}
                    onClick={() => deleteProduct()}
                    danger
                  >
                    Удалить
                  </Button>
                </>
              )}
            </div>
          </Form>
        )}
      </div>
      <ProductPreview
        mode={mode}
        form={form}
        open={preview.open}
        data={preview.data}
        firstVariationName={firstVariationName}
        secondVariationName={secondVariationName}
        close={closePreview}
        submit={saveProduct}
        deleteProduct={deleteProduct}
      />
      <CreateCategory
        isOpen={isCreateCategoryOpen}
        close={closeCreateCategory}
        categories={categories}
        onSuccess={getCategories}
      />
    </AnchorsWrapper>
  )
}
