import { MenuProps } from 'antd'
import {
  ApiOutlined,
  CarOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  NotificationOutlined,
  StockOutlined,
  ToolOutlined
} from '@ant-design/icons'
import { Navigate, createBrowserRouter } from 'react-router-dom'
import { GoodsAdd } from 'pages/admin/Goods/Add/Add'
import { GoodsEdit } from 'pages/admin/Goods/Edit/Edit'
import { ProfileInfo } from 'pages/admin/Profile/Info'
import { ProfileShops } from 'pages/admin/Profile/Shops'
import { ProfileDelivery } from 'pages/admin/Profile/Delivery'
import { ProfileAccounting } from 'pages/admin/Profile/Accounting'
import { ProfileApiMoySklad } from 'pages/admin/Profile/ApiMoySklad'
import { ProfileNotificationsTelegram } from 'pages/admin/Profile/NotificationsTelegram'
import { AppWrapper } from 'components/layout/AppWrapper/AppWrapper'
import { adminRoutes } from 'shared/constants/routes'
import { SellerWrapper } from 'components/sellers/SellerWrapper/SellerWrapper'
import { GoodsList } from 'pages/admin/Goods/List/GoodsList'
import { Orders } from 'pages/admin/Orders/Orders'
import { Order } from 'pages/admin/Orders/Order/Order'

const sellerLinks: MenuProps['items'] = [
  {
    label: 'Основная информация',
    key: adminRoutes.PROFILE_INFO,
    icon: <IdcardOutlined />,
    itemIcon: <span style={{ color: 'red' }}> *</span>
  },
  {
    label: 'Адреса магазинов',
    key: adminRoutes.PROFILE_SHOPS,
    icon: <EnvironmentOutlined />,
    itemIcon: <span style={{ color: 'red' }}> *</span>
  },
  {
    label: 'Способы доставки',
    key: adminRoutes.PROFILE_DELIVERY,
    icon: <CarOutlined />,
    itemIcon: <span style={{ color: 'red' }}> *</span>
  },
  {
    label: 'Бухгалтерия',
    key: adminRoutes.PROFILE_ACCOUNTING,
    icon: <StockOutlined />
  },
  {
    label: 'API Синхронизация',
    key: 'api',
    icon: <ApiOutlined />,
    children: [
      {
        label: 'Мой склад',
        key: adminRoutes.PROFILE_API_MOYSKLAD
      }
    ]
  },
  {
    label: 'Уведомления',
    key: 'notifications',
    icon: <NotificationOutlined />,
    children: [
      {
        label: 'Телеграм',
        key: adminRoutes.PROFILE_NOTIFICATIONS_TELEGRAM
      }
    ]
  },
  {
    label: 'Техническая поддержка',
    key: adminRoutes.PROFILE_SUPPORT,
    icon: <ToolOutlined />
  }
]

export const adminRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppWrapper />,
    children: [
      {
        index: true,
        element: <Navigate to={adminRoutes.GOODS} />
      },
      {
        path: adminRoutes.GOODS,
        element: <GoodsList />
      },
      {
        path: adminRoutes.GOODS_ADD,
        element: <GoodsAdd />
      },
      {
        path: adminRoutes.GOODS_EDIT,
        element: <GoodsEdit />
      },
      {
        path: adminRoutes.ORDERS,
        element: <Orders />
      },
      {
        path: adminRoutes.ORDER,
        element: <Order />
      },
      {
        path: adminRoutes.PROFILE,
        element: <SellerWrapper type='profile' links={sellerLinks} />,
        children: [
          {
            index: true,
            element: <Navigate to={adminRoutes.PROFILE_INFO} />
          },
          {
            path: adminRoutes.PROFILE_INFO,
            element: <ProfileInfo />
          },
          {
            path: adminRoutes.PROFILE_SHOPS,
            element: <ProfileShops />
          },
          {
            path: adminRoutes.PROFILE_DELIVERY,
            element: <ProfileDelivery />
          },
          {
            path: adminRoutes.PROFILE_ACCOUNTING,
            element: <ProfileAccounting />
          },
          {
            path: adminRoutes.PROFILE_API_MOYSKLAD,
            element: <ProfileApiMoySklad />
          },
          {
            path: adminRoutes.PROFILE_NOTIFICATIONS_TELEGRAM,
            element: <ProfileNotificationsTelegram />
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to='/' replace />
      }
    ]
  }
])
