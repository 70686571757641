import React from 'react'
import {
  Alert,
  Button,
  Empty,
  Form,
  FormInstance,
  Input,
  Switch,
  Typography
} from 'antd'
import omit from 'lodash.omit'
import {
  DeleteOutlined,
  ForkOutlined,
  TagsOutlined
} from '@ant-design/icons'
import {
  ICharacteristic,
  IChangeVariationProduct,
  IFrontVariation,
  IFrontVariationProduct,
  ICreateUpdateVariationProduct
} from 'models/product'
import { showMessage } from 'shared/utils/showMessage'
import styles from '../ProductForm.module.scss'
import { ChooseVariationProductModal } from './ChooseVariationProductModal'
import { VariantProductModalForm } from './VariantProductModalForm'
import { SelectProduct } from './SelectProduct'

interface ICharacteristicsProps {
  form: FormInstance
  sellerId: string
  productId?: string
  firstVariationName: string | undefined
  secondVariationName: string | undefined
  addToDeletedVariations: (productId: string) => void
}

export const Characteristics: React.FC<ICharacteristicsProps> = React.memo(
  ({ form, sellerId, productId, firstVariationName, secondVariationName, addToDeletedVariations }) => {
    const [isVariationType, setIsVariationType] = React.useState(false)
    const [isVariationTypeDisabled, setIsVariationTypeDisabled] =
      React.useState(false)

    const [chooseProductData, setChooseProductData] =
      React.useState<IChangeVariationProduct>({
        visible: false,
        variation: null,
        variationIndex: null
      })

    const [createUpdateProductData, setCreateUpdateProductData] =
      React.useState<ICreateUpdateVariationProduct>({
        visible: false,
        type: null,
        variation: null,
        variationIndex: null
      })

    const parentFormData = form.getFieldsValue()

    const characteristics: ICharacteristic[] =
      Form.useWatch('characteristics', {
        form,
        preserve: true
      }) ?? []

    const variations: IFrontVariation[] =
      Form.useWatch('variations', {
        form,
        preserve: true
      }) ?? []

    const onCreateCharacteristic = () => {
      const createCharacteristicName: string =
        form.getFieldValue('createCharacteristicName')?.trim() ?? ''
      const createCharacteristicValues: string[] = form.getFieldValue(
        'createCharacteristicValues'
      )

      if (!createCharacteristicName) {
        return showMessage('error', 'Заполните название характеристики', '', 2)
      }

      if (!createCharacteristicValues[0].trim()) {
        return showMessage('error', 'Заполните значение характеристики', '', 2)
      }

      if (!isVariationType) {
        form.setFieldValue('characteristics', [
          ...(form.getFieldValue('characteristics') ?? []),
          {
            name: createCharacteristicName,
            value: createCharacteristicValues[0].trim()
          }
        ])
      } else {
        if (variations.length) {
          const newVariations: IFrontVariation[] = []

          if (!variations.some((item) => item.firstCharacteristicValue)) {
            return showMessage(
              'error',
              'Для добавления 2-ой характеристики, укажите хотя бы одно значение для 1-ой характеристики',
              ''
            )
          }

          const clearedCreateCharacteristicValues =
            createCharacteristicValues.filter((item) => item.trim())
          const clearedOldVariations = variations.filter((item) =>
            item.firstCharacteristicValue?.trim()
          )

          for (const newCharacteristicValue of clearedCreateCharacteristicValues) {
            for (const oldVariation of clearedOldVariations) {
              newVariations.push({
                ...oldVariation,
                product: undefined,
                secondCharacteristicName: createCharacteristicName,
                secondCharacteristicValue: newCharacteristicValue
              })
            }
          }
          form.setFieldValue('variations', [
            ...newVariations,
            {
              firstCharacteristicName: variations[0].firstCharacteristicName,
              firstCharacteristicValue: '',
              secondCharacteristicName: createCharacteristicName,
              secondCharacteristicValue: ''
            }
          ])
        } else {
          form.setFieldValue('variations', [
            ...(form.getFieldValue('variations') ?? []),
            ...createCharacteristicValues
              .filter((item) => item.trim())
              .map((item) => ({
                firstCharacteristicName: createCharacteristicName,
                firstCharacteristicValue: item
              })),
            {
              firstCharacteristicName: createCharacteristicName,
              firstCharacteristicValue: ''
            }
          ])
        }
      }
      form.setFieldValue('createCharacteristicName', '')
      form.setFieldValue('createCharacteristicValues', [''])
    }

    const onChangeCharacteristicName = (
      position: 'first' | 'second',
      value: string
    ) => {
      if (!value.trim()) return
      form.setFieldValue(
        'variations',
        variations.map((item) => {
          if (position === 'first') {
            return {
              ...item,
              firstCharacteristicName: value
            }
          }
          return {
            ...item,
            secondCharacteristicName: value
          }
        })
      )
    }

    const openChooseProductModal = React.useCallback(
      (variation: IFrontVariation, variationIndex: number) => {
        closeCreateUpdateProductModal()
        setChooseProductData({
          visible: true,
          variation,
          variationIndex
        })
      },
      []
    )

    const closeChooseProductModal = React.useCallback(() => {
      setChooseProductData({
        visible: false,
        variation: null,
        variationIndex: null
      })
    }, [])

    const openUpdateProductModal = React.useCallback((variation: IFrontVariation, variationIndex: number) => {
      closeChooseProductModal()
      setCreateUpdateProductData({
        visible: true,
        type: 'update',
        variation,
        variationIndex
      })
    }, [])

    const openCreateProductModal = React.useCallback((variation: IFrontVariation, variationIndex: number) => {
      closeChooseProductModal()
      setCreateUpdateProductData({
        visible: true,
        type: 'create',
        variation,
        variationIndex
      })
    }, [])

    const openCreateProductModalForChoosing = React.useCallback(() => {
      closeChooseProductModal()
      setCreateUpdateProductData({
        visible: true,
        type: 'create',
        variation: chooseProductData?.variation,
        variationIndex: chooseProductData?.variationIndex
      })
    }, [chooseProductData])

    const closeCreateUpdateProductModal = React.useCallback(() => {
      setCreateUpdateProductData({
        visible: false,
        type: null,
        variation: null,
        variationIndex: null
      })
    }, [])

    const onSelectVariationProduct = React.useCallback(
      (product: IFrontVariationProduct, variationIndex: number) => {
        form.setFieldValue(
          'variations',
          variations.map((item, index) => {
            if (index === variationIndex) {
              return {
                ...item,
                product
              }
            }
            return item
          })
        )
        closeCreateUpdateProductModal()
        closeChooseProductModal()
      },
      [variations]
    )

    const removeProductFromVariation = (variationIndex: number) => {
      form.setFieldValue(
        'variations',
        variations.map((item, index) => {
          if (index === variationIndex) {
            return omit(item, 'product')
          }
          return item
        })
      )
    }

    React.useEffect(() => {
      const firstValue = form
        .getFieldValue('createCharacteristicValues')[0]
        .trim()
      if (!isVariationType) {
        form.setFieldValue('createCharacteristicValues', [firstValue])
      } else {
        if (firstValue) {
          form.setFieldValue('createCharacteristicValues', [firstValue, ''])
        }
      }
    }, [isVariationType])

    React.useEffect(() => {
      if (variations.length && variations[0].secondCharacteristicName) {
        setIsVariationType(false)
        setIsVariationTypeDisabled(true)
      } else {
        setIsVariationTypeDisabled(false)
      }
    }, [variations])

    React.useEffect(() => {
      if (variations.length) {
        const lastVariation = variations[variations.length - 1]
        if (
          lastVariation.firstCharacteristicValue?.length === 1 ||
          lastVariation.secondCharacteristicValue?.length === 1
        ) {
          if (lastVariation.secondCharacteristicName) {
            const newVariation: IFrontVariation = {
              firstCharacteristicName: lastVariation.firstCharacteristicName,
              firstCharacteristicValue: '',
              secondCharacteristicName: lastVariation.secondCharacteristicName,
              secondCharacteristicValue: ''
            }
            form.setFieldValue('variations', [...variations, newVariation])
          } else {
            const newVariation: IFrontVariation = {
              firstCharacteristicName: lastVariation.firstCharacteristicName,
              firstCharacteristicValue: ''
            }
            form.setFieldValue('variations', [...variations, newVariation])
          }
        }
      }
    }, [variations])

    React.useEffect(() => {
      if (
        variations.length === 1 &&
        !variations[0].firstCharacteristicValue &&
        !variations[0].secondCharacteristicValue
      ) {
        form.setFieldValue('variations', [])
        setIsVariationTypeDisabled(false)
      }
    }, [variations])

    return (
      <>
        <div className={styles.subSection}>
          <Typography.Title
            level={4}
            className={styles.sectionSubTitle}
            style={{ marginBottom: 18 }}
          >
            Характеристики:
          </Typography.Title>
          {!characteristics.length && !variations.length ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<></>} />
          ) : (
            <div className={styles.characteristicsWrapper}>
              <Form.List name='characteristics'>
                {(fields, { remove }) => (
                  <>
                    {fields.map((fieldData) => (
                      <div
                        key={fieldData.key}
                        className={styles.characteristicRow}
                      >
                        <TagsOutlined
                          style={{
                            fontSize: 18,
                            color: 'rgba(0, 0, 0, 0.45)',
                            paddingTop: 12
                          }}
                        />
                        <Form.Item
                          name={[fieldData.name, 'name']}
                          className={styles.characteristicRowField}
                          rules={[
                            {
                              required: true,
                              message: 'Заполните название характеристики'
                            },
                            {
                              whitespace: true,
                              message: 'Заполните название характеристики'
                            }
                          ]}
                          hasFeedback
                        >
                          <Input
                            size='middle'
                            placeholder='Название характеристики'
                          />
                        </Form.Item>
                        <Form.Item
                          name={[fieldData.name, 'value']}
                          className={styles.characteristicRowField}
                          rules={[
                            {
                              required: true,
                              message: 'Заполните значение характеристики'
                            },
                            {
                              whitespace: true,
                              message: 'Заполните значение характеристики'
                            }
                          ]}
                          hasFeedback
                        >
                          <Input
                            size='middle'
                            placeholder='Значение характеристики'
                          />
                        </Form.Item>
                        <Button
                          type='primary'
                          htmlType='button'
                          size='middle'
                          style={{ marginTop: 4 }}
                          icon={<DeleteOutlined style={{ fontSize: 16 }} />}
                          onClick={() => remove(fieldData.name)}
                          ghost
                          danger
                        />
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
              <Form.List name='variations'>
                {(fields, { remove }) => (
                  <div className={styles.variants}>
                    <div
                      className={styles.variantsValueColumn}
                      style={{
                        flex: secondVariationName
                          ? undefined
                          : 1
                      }}
                    >
                      {fields.map((field, index) => (
                        <div
                          key={field.key}
                          className={styles.characteristicRow}
                        >
                          <TagsOutlined
                            style={{
                              fontSize: 18,
                              color: 'rgba(0, 0, 0, 0.45)',
                              alignSelf: 'flex-end',
                              paddingBottom: 9
                            }}
                          />
                          <div style={{ flex: 1 }}>
                            {!index && (
                              <Typography.Title
                                level={5}
                                editable={{
                                  onChange(value) {
                                    onChangeCharacteristicName('first', value)
                                  }
                                }}
                                style={{ marginBlock: 10 }}
                              >
                                {firstVariationName}
                              </Typography.Title>
                            )}
                            <Form.Item
                              name={[field.name, 'firstCharacteristicValue']}
                              style={{ marginBottom: 10 }}
                              className={styles.characteristicRowField}
                              hasFeedback
                            >
                              <Input size='middle' placeholder='Значение' />
                            </Form.Item>
                          </div>
                          {!secondVariationName && (
                            <div
                              style={{
                                alignSelf: 'center',
                                paddingTop: index ? 0 : 41.5
                              }}
                            >
                              {index + 1 !== variations.length && (
                                <SelectProduct 
                                  variation={variations[index]}
                                  openUpdateProductModal={() => openUpdateProductModal(variations[index], index)}
                                  openCreateProductModal={() => openCreateProductModal(variations[index], index)}
                                  openChooseProductModal={() => openChooseProductModal(variations[index], index)}
                                  onDelete={() => {
                                    if (variations[index].product) {
                                      addToDeletedVariations(variations[index].product?.id as string)
                                      removeProductFromVariation(index)
                                    } else {
                                      remove(field.name)
                                    }
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {!!secondVariationName && (
                      <div
                        className={styles.variantsValueColumn}
                        style={{ flex: 1 }}
                      >
                        {fields.map((field, index) => (
                          <div
                            key={field.key}
                            className={styles.characteristicRow}
                          >
                            <div style={{ flex: !variations[index].firstCharacteristicValue && !variations[index].secondCharacteristicValue ? undefined : 1 }}>
                              {!index && (
                                <Typography.Title
                                  level={5}
                                  editable={{
                                    onChange(value) {
                                      onChangeCharacteristicName(
                                        'second',
                                        value
                                      )
                                    }
                                  }}
                                  style={{ marginBlock: 10 }}
                                >
                                  {secondVariationName}
                                </Typography.Title>
                              )}
                              <Form.Item
                                name={[field.name, 'secondCharacteristicValue']}
                                className={styles.characteristicRowField}
                                style={{ marginBottom: 10 }}
                                hasFeedback
                              >
                                <Input size='middle' placeholder='Значение' />
                              </Form.Item>
                            </div>
                            <div
                              style={{
                                alignSelf: 'center',
                                paddingTop: index ? 0 : 41.5
                              }}
                            >
                              {index + 1 !== variations.length && (
                                <SelectProduct 
                                  variation={variations[index]}
                                  openUpdateProductModal={() => openUpdateProductModal(variations[index], index)}
                                  openCreateProductModal={() => openCreateProductModal(variations[index], index)}
                                  openChooseProductModal={() => openChooseProductModal(variations[index], index)}
                                  onDelete={() => {
                                    if (variations[index].product) {
                                      addToDeletedVariations(variations[index].product?.id as string)
                                      removeProductFromVariation(index)
                                    } else {
                                      remove(field.name)
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Form.List>
            </div>
          )}
          <div className={styles.addCharacteristicsWrapper}>
            <div className={styles.addCharacteristicsInputs}>
              <div className={styles.addCharacteristicsInput}>
                <Typography.Title
                  level={5}
                  className={styles.addCharacteristicsInputsTitle}
                >
                  Название
                </Typography.Title>
                <Form.Item
                  name='createCharacteristicName'
                  className={styles.addCharacteristicsInputsField}
                >
                  <Input size='middle' placeholder='Название характеристики' />
                </Form.Item>
              </div>
              <div className={styles.addCharacteristicsInput}>
                <Typography.Title
                  level={5}
                  className={styles.addCharacteristicsInputsTitle}
                >
                  Значение
                </Typography.Title>
                <Form.List
                  name='createCharacteristicValues'
                  initialValue={['']}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((fieldData, index) => (
                        <Form.Item
                          name={fieldData.name}
                          key={fieldData.key}
                          className={styles.addCharacteristicsInputsField}
                        >
                          <Input
                            size='middle'
                            placeholder='Значение'
                            onChange={(e) => {
                              if (
                                isVariationType &&
                                e.target.value.length === 1 &&
                                !fields[index + 1]
                              ) {
                                return add('')
                              }
                              if (
                                isVariationType &&
                                e.target.value.length === 0 &&
                                fields[index + 1]
                              ) {
                                if (
                                  !form
                                    .getFieldValue('createCharacteristicValues')
                                    [index + 1].trim()
                                ) {
                                  return remove(index + 1)
                                }
                              }
                              if (
                                isVariationType &&
                                e.target.value.length === 0 &&
                                fields[index + 1]
                              ) {
                                if (
                                  form
                                    .getFieldValue('createCharacteristicValues')
                                    [index + 1].trim()
                                ) {
                                  return remove(index)
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>
            </div>
            <div>
              <div className={styles.addCharacteristicsFooterActions}>
                <Button
                  type='primary'
                  htmlType='button'
                  icon={<ForkOutlined />}
                  size='middle'
                  onClick={onCreateCharacteristic}
                >
                  Добавить
                </Button>
                <div className={styles.addCharacteristicsFooterSwitch}>
                  <Switch
                    checked={isVariationType}
                    disabled={isVariationTypeDisabled}
                    onChange={setIsVariationType}
                  />
                  <Typography.Text
                    style={{
                      color: isVariationTypeDisabled
                        ? 'rgba(0, 0, 0, 0.25)'
                        : 'rgba(0, 0, 0, 0.88)'
                    }}
                  >
                    Несколько значений (вариация)
                  </Typography.Text>
                </div>
              </div>
              {isVariationTypeDisabled && (
                <Alert
                  type='info'
                  message='У товара может быть не более 2х характеристик с несколькими значениями (вариаций)'
                  style={{ marginTop: 15 }}
                  showIcon
                />
              )}
            </div>
          </div>
        </div>
        <VariantProductModalForm
          data={createUpdateProductData}
          parentFormData={parentFormData}
          sellerId={sellerId}
          openChooseProductModal={openChooseProductModal}
          onSelectSuccess={onSelectVariationProduct}
          onClose={closeCreateUpdateProductModal}
        />
        <ChooseVariationProductModal
          data={chooseProductData}
          variations={variations}
          sellerId={sellerId}
          productId={productId}
          openCreateProductModal={openCreateProductModalForChoosing}
          openUpdateProductModal={openUpdateProductModal}
          onClose={closeChooseProductModal}
        />
      </>
    )
  }
)
