interface Props {
  data: any
  isPhoto?: boolean
}

export function convertToFormData({isPhoto, data}: Props) {
  const formData = new FormData()

  Object.keys(data).map((key: string) => {
    const value = data[key]
    if (value instanceof Array) {
      formData.append(key, JSON.stringify(value))
    } else if (typeof value === 'object' && value !== null) {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value)
    }
  })

  if (isPhoto) {
    data.photo.forEach((file: any) => { formData.append('photo', file.originFileObj, file.name) })
  }
  return formData
}