import React from 'react'
import { Button, Form, Input, Select, Typography, message } from 'antd'
import { ESellerTransactionsType } from 'models/transaction'
import { transactionsApi } from 'api/transaction'
import { getTransactionTypeName } from 'shared/utils/transactions/getTransactionTypeName'
import { floatNumbersPattern } from 'shared/utils/patterns'
import { showServerError } from 'shared/utils/showServerError'
import { normalizeNumber } from 'shared/utils/normalizeNumber'
import styles from './ChangeBalance.module.scss'

interface IChangeBalanceProps {
  sellerId: string
  style?: React.CSSProperties
  onSuccess: () => void
}

interface IFormData {
  type: ESellerTransactionsType
  sum: string
}

export const ChangeBalance: React.FC<IChangeBalanceProps> = React.memo(
  ({ sellerId, style, onSuccess }) => {
    const [isLoading, setIsLoading] = React.useState(false)

    const [form] = Form.useForm()

    const typeSelectedValue: ESellerTransactionsType | undefined =
      Form.useWatch('type', {
        form,
        preserve: true
      })

    const onSubmit = async (data: IFormData) => {
      try {
        setIsLoading(true)
        await transactionsApi.createTransaction(sellerId, { type: data.type, sum: data.type === ESellerTransactionsType.SALE ? normalizeNumber(data.sum) : -normalizeNumber(data.sum)})
        onSuccess()
        message.success('Баланс успешно обновлен')
        form.resetFields()
      } catch (e) {
        showServerError(e)
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <div style={style}>
        <Typography.Title level={2} className={styles.title}>
          Изменение баланса
        </Typography.Title>
        <Form
          form={form}
          name='changeBalance'
          size='large'
          className={styles.formWrapper}
          onFinish={onSubmit}
        >
          <Form.Item
            name='type'
            rules={[
              {
                required: true,
                message: 'Выберите тип транзакции'
              }
            ]}
            hasFeedback
            style={{ flex: 1, margin: 0 }}
          >
            <Select
              placeholder='Тип транзакции'
              options={(
                Object.keys(ESellerTransactionsType) as Array<
                  keyof typeof ESellerTransactionsType
                >
              ).map((item) => ({
                value: item,
                label: getTransactionTypeName(item)
              }))}
            />
          </Form.Item>
          <Form.Item
            name='sum'
            rules={[
              {
                required: true,
                message: 'Введите сумму'
              },
              {
                whitespace: true,
                message: 'Введите сумму'
              },
              {
                pattern: floatNumbersPattern,
                message: 'Укажите сумму корректно'
              }
            ]}
            hasFeedback
            style={{ flex: 1, margin: 0 }}
          >
            <Input placeholder='Сумма' prefix={typeSelectedValue && typeSelectedValue !== ESellerTransactionsType.SALE ? '-' : ''} />
          </Form.Item>
          <Button htmlType='submit' type='primary' loading={isLoading}>
            Изменить
          </Button>
        </Form>
      </div>
    )
  }
)
