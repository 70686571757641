import React from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { Connect, VKSilentAuthPayload } from '@vkontakte/superappkit'
import AppleLogin from 'react-apple-login'
import { useLocation, useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import { LargeText } from 'components/ui'
import { authApi } from 'api/auth'
import { appActions } from 'store/app'
import { ELoginTypes, ERoles, ISignUpAppleResponse, ISignUpGoogleResponse, ISignUpVkResponse } from 'models/auth'
import { ESellerStatus } from 'models/seller'
import appleIcon from 'assets/icons/apple.svg'
import vkIcon from 'assets/icons/vk.svg'
import googleIcon from 'assets/icons/google.svg'
import { useAppDispatch } from 'shared/hooks/useReduxHooks'
import { showMessage } from 'shared/utils/showMessage'
import { APPLE_CLIENT_ID, APPLE_REDIRECT_URL, SITE_URL } from 'shared/constants/urls'
import { showServerError } from 'shared/utils/showServerError'
import { getSellerStatusMessage } from 'shared/utils/auth/getSellerStatusMessage'
import styles from './ContinueWithApps.module.scss'

interface IContinueWithAppsProps {
  setActiveScreenPassword: () => void
  setActiveScreenSeller: () => void
  setIsEmptyPassword: (isEmpty: boolean) => void
  setToken: (token: string) => void
}

export const ContinueWithApps: React.FC<IContinueWithAppsProps> = ({
  setActiveScreenPassword,
  setActiveScreenSeller,
  setIsEmptyPassword,
  setToken
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const validateSocialResponse = (data: ISignUpGoogleResponse | ISignUpAppleResponse | ISignUpVkResponse) => {
    if (data.role === ERoles.ADMIN) {
      return dispatch(appActions.setToken(data))
    }
    if (!data.isExistPassword) {
      setIsEmptyPassword(true)
      setToken(data.access_token)
      return setActiveScreenPassword()
    }
    if (!data.isSeller) {
      setToken(data.access_token)
      return setActiveScreenSeller()
    }
    dispatch(appActions.setToken(data))
  }

  const sendGoogleData = async (accessToken: string) => {
    try {
      const response = await authApi.googleSignUp({
        access_token: accessToken,
        auth_type: ELoginTypes.SELLER
      })
      validateSocialResponse(response.data)
    } catch (e) {
      const error = e as AxiosError<{ message: ESellerStatus }>
      if (error?.response?.status === 403) {
        return getSellerStatusMessage(error.response.data.message)
      }
      showServerError(e)
    }
  }

  const sendAppleData = async (code: string) => {
    try {
      const response = await authApi.appleSignUp({
        code,
        auth_type: ELoginTypes.SELLER
      })
      validateSocialResponse(response.data)
    } catch (e) {
      const error = e as AxiosError<{ message: ESellerStatus }>
      if (error?.response?.status === 403) {
        return getSellerStatusMessage(error.response.data.message)
      }
      showServerError(e)
    }
  }

  const sendVkData = async (token: string, uuid: string) => {
    try {
      const response = await authApi.vkSignUp({
        uuid,
        silent_token: token,
        auth_type: ELoginTypes.SELLER
      })
      validateSocialResponse(response.data)
    } catch (e) {
      const error = e as AxiosError<{ message: ESellerStatus }>
      if (error?.response?.status === 403) {
        return getSellerStatusMessage(error.response.data.message)
      }
      showServerError(e)
    }
  }

  const onGoogleClick = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        sendGoogleData(tokenResponse.access_token)
      } catch (e) {
        showMessage(
          'error',
          'Ошибка при входе в гугл аккаунт',
          'Попробуйте отправить запрос еще раз, либо немного позднее'
        )
      }
    },
    onError: () =>
      showMessage(
        'error',
        'Ошибка при входе в гугл аккаунт',
        'Попробуйте отправить запрос еще раз, либо немного позднее'
      )
  })

  const onVkClick = () => {
    Connect.redirectAuth({
      url: `${SITE_URL}${location.pathname.substring(1)}`
    })
  }

  const appleResponse = (response: any) => {
    if (response?.authorization?.id_token) {
      sendAppleData(response.authorization.id_token)
    }
  }

  React.useEffect(() => {
    if (searchParams.has('payload')) {
      const payload = searchParams.get('payload')
      if (payload) {
        const data: VKSilentAuthPayload = JSON.parse(payload)
        if (Object.hasOwn(data, 'token') && Object.hasOwn(data, 'uuid')) {
          sendVkData(data.token, data.uuid)
        }
        searchParams.delete('payload')
        setSearchParams(searchParams)
      }
    }
  }, [])

  return (
    <>
      <LargeText className={styles.title}>Продолжить с помощью:</LargeText>
      <div className={styles.list}>
        <AppleLogin
          clientId={APPLE_CLIENT_ID}
          redirectURI={APPLE_REDIRECT_URL}
          usePopup={true}
          callback={appleResponse}
          scope='email'
          responseMode='form_post'
          render={({ onClick }) => (
            <div
              className={classNames(styles.item, styles.apple)}
              onClick={onClick}
            >
              <img src={appleIcon} alt='Apple' />
            </div>
          )}
        />
        <div className={classNames(styles.item, styles.vk)} onClick={onVkClick}>
          <img src={vkIcon} alt='Vk' />
        </div>
        <div
          className={classNames(styles.item, styles.google)}
          onClick={() => onGoogleClick()}
        >
          <img src={googleIcon} alt='Google' />
        </div>
      </div>
    </>
  )
}
