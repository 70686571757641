import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex, Modal, Table, Tag, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { collectionsApi } from 'api/collections'
import noProductImage from 'assets/images/no-image-product.png'
import { ICollection } from 'models/collections'
import { getColorByStatus, getStatus } from 'pages/superAdmin/Collections/utils/status'
import { Key, useEffect, useState } from 'react'
import { IMAGES_URL } from 'shared/constants/urls'
import { showMessage } from 'shared/utils/showMessage'
import { showServerError } from 'shared/utils/showServerError'


interface Props {
  visible: boolean
  multiple?: boolean
  onClose: () => void
  onSave: (cs: ICollection[])=> void
}

export const GetCollectionModal = ({visible, onClose, onSave, multiple}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCollections, setSelectedCollections] = useState<ICollection[]>([])
  const [collections, setCollections] = useState<ICollection[]>([])


  const getCollections = async () => {
    try {
      setIsLoading(true)
      const response = await collectionsApi.getCollections()
      setCollections(response.data)
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    const getData = async () => {
      try {
        await getCollections()
      } catch (e) {
        showServerError(e)
      }
    }
    getData()
  }, [])

  const onRowSelection = (
    _: Key[],
    selectedRows: ICollection[]
  ) => {
    const selectedIds = new Set(selectedRows.map(r => r._id))
    setSelectedCollections(
      selectedCollections.filter(product => selectedIds.has(product._id))
        .concat(
          selectedRows.filter(newProduct => !selectedCollections.some(existing => existing._id === newProduct._id))
        )
    )
  }

  const chooseCollection = () => {
    if (!selectedCollections.length) {
      return showMessage('info', 'Выберите подборку из таблицы', '')
    }
    onSave(selectedCollections)
    onClose()
  }

  const columns: ColumnsType<ICollection> = [
    {
      title: 'Названик подборки',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Фото',
      dataIndex: 'photo',
      width: 76,
      render: (_, { photo }) => {                
        return photo ? (
          <div style={{ width: 62, height: 56, backgroundImage: `url(${IMAGES_URL}${photo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
        ) : (
          <img
            src={noProductImage}
            alt='Изображение товара'
            width={62}
            height={56}
          />
        )
      }
    },
    {
      title: 'Ссылка',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => {
        return (
          <Tag color={getColorByStatus(status)} onClick={(event) => event.stopPropagation()}>
            {getStatus(status)}
          </Tag>
        )
      }
    }
  ]

  return <Modal
    centered
    open={visible}
    footer={null}
    closable={false}
    onCancel={onClose}
    width={1100}
  >

    <Flex style={{alignItems: 'center', justifyContent: 'space-between', marginBottom: 15}}>
      <Typography.Title level={4}>Подборки товаров</Typography.Title>
      <CloseOutlined onClick={onClose}/>
    </Flex>
        
    <div style={{marginBottom: 32, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Button type='default' size='middle' onClick={onClose}>Отменить</Button>
      <Button type='primary' size='middle' disabled={!selectedCollections.length} onClick={chooseCollection}>Выбрать</Button>
    </div>

    <Table
      scroll={{
        x: 900
      }}
      columns={columns}
      dataSource={collections}
      loading={isLoading}
      rowKey={(el) => el._id}
      rowSelection={{
        type: multiple ? 'checkbox' : 'radio',
        selectedRowKeys: selectedCollections.map(p=> p._id),
        onChange: onRowSelection
      }}
      rowClassName='tableRow'
      footer={undefined}
      pagination={{ pageSize: 7 }}
    />
  </Modal>
}