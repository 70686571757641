import { ESellerTransactionsType } from 'models/transaction'

export const getTransactionTypeName = (type: keyof typeof ESellerTransactionsType): string => {
  switch (type) {
  case ESellerTransactionsType.SALE:
    return 'Приход (продажа)'
  case ESellerTransactionsType.FINE:
    return 'Штраф'
  case ESellerTransactionsType.WITHDRAW:
    return 'Вывод'
  case ESellerTransactionsType.REFUND:
    return 'Возврат'
  default:
    return ''
  }
}
