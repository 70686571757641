import React from 'react'

interface IPageWrapperProps {
  children: React.ReactNode
}

export const PageWrapper: React.FC<IPageWrapperProps> = React.memo(({ children }) => {
  return (
    <div style={{ paddingBottom: 80 }}>
      {children}
    </div>
  )
})