import { MenuProps } from 'antd'
import { AnchorsWrapper } from 'components/layout/AnchorsWrapper/AnchorsWrapper'
import { SellerInfo } from 'components/sellers/SellerInfo/SellerInfo'

const anchors: MenuProps['items'] = [
  { label: 'Основная информация', key: '#info' },
  { label: 'Банковские сведения', key: '#banking-details' },
  { label: 'Данные о магазине', key: '#shop-details' }
]

export const AddSeller: React.FC = () => {
  return (
    <AnchorsWrapper title='Добавить продавца' titleId='info' anchors={anchors}>
      <SellerInfo mode='create' type='seller' />
    </AnchorsWrapper>
  )
}
