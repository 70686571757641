import { ESellerStatus } from 'models/seller'
import { showMessage } from '../showMessage'

export const getSellerStatusMessage = (status: ESellerStatus) => {
  if (status === ESellerStatus.WAITING_APPROVE) {
    return showMessage(
      'error',
      'Ваша заявка еще на рассмотрении',
      'С вами свяжется менеджер для подтверждения данных'
    )
  }

  if (status === ESellerStatus.DECLINED) {
    return showMessage(
      'error',
      'Ваша заявка была отклонена',
      'Вы можете связаться с тех поддержкой для уточнения причины отказа'
    )
  }

}
