import { EUnit, IDimension } from 'models/product'

interface IGetWeightByUnitResponse {
  value: number
  unit: string
}

export const getWeightByUnit = (unit: EUnit, data: IDimension, count: number): IGetWeightByUnitResponse => {
  if (unit === EUnit.KG) {
    return {
      value: data.unit === EUnit.GR ? (data.weight / 1000) * count : data.weight * count,
      unit: 'кг'
    }
  }

  return {
    value: data.unit === EUnit.KG ? (data.weight * 1000) * count : data.weight * count,
    unit: 'гр'
  }
}
