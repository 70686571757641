import { IPreviewData, IProductFormData } from 'models/product'
import { normalizeNumber } from 'shared/utils/normalizeNumber'

export const getPreviewData = (
  data: IProductFormData,
  category: string,
  subCategory: string
): IPreviewData => ({
  photo: data.photo,
  categoryName: category,
  subCategoryName: subCategory,
  name: data.name,
  price: normalizeNumber(data.price),
  desc: data.desc,
  dimension: {
    width: normalizeNumber(data.width),
    length: normalizeNumber(data.length),
    height: normalizeNumber(data.height),
    weight: normalizeNumber(data.weight),
    unit: data.unit,
    in_stock: normalizeNumber(data.in_stock)
  },
  characteristics: data?.characteristics ?? [],
  variations: data?.variations ?? []
})
