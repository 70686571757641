import React from 'react'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import classNames from 'classnames'
import styles from './MobileMenu.module.scss'

interface IMobileMenuProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  Menu: React.ReactElement
}

export const MobileMenu: React.FC<IMobileMenuProps> = React.memo(({ isOpen, setIsOpen, Menu }) => {
  return (
    <>
      <Button
        type='text'
        icon={<MenuOutlined className={styles.button} />}
        onClick={() => setIsOpen(true)}
        className={styles.buttonWrapper}
      />
      <div
        className={classNames(styles.modalWrapper, {
          [styles.modalWrapperActive]: isOpen
        })}
      >
        <div className={styles.modalHeader}>
          <Button
            type='text'
            icon={<CloseOutlined className={styles.button} />}
            onClick={() => setIsOpen(false)}
            className={styles.buttonWrapper}
          />
        </div>
        {Menu}
      </div>
    </>
  )
})
