import {
  Button,
  Input,
  InputRef,
  Modal,
  Space,
  Typography,
  Table,
  message,
  Tag,
  Radio
} from 'antd'
import React from 'react'
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  PlusOutlined,
  SearchOutlined,
  UserAddOutlined
} from '@ant-design/icons'
import qs from 'qs'
import { MenuInfo } from 'rc-menu/lib/interface'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { ColumnsType } from 'antd/es/table'
import { sellerApi } from 'api/seller'
import { getSellerEditPath } from 'routing/utils/getSellerEditPath'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { ESellerStatus, ISeller, ISellersQueryFilters } from 'models/seller'
import { DEBOUNCE_DELAY } from 'shared/constants/app'
import { superAdminRoutes } from 'shared/constants/routes'
import { showServerError } from 'shared/utils/showServerError'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import { getSellerStatusItems, sellerStatuses } from 'shared/constants/sellers'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { cleanObject } from 'shared/utils/cleanObject'
import styles from './List.module.scss'

export const SellersList: React.FC = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const [isFirstRequestFinished, setIsFirstRequestFinished] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [sellers, setSellers] = React.useState<ISeller[]>([])

  const [selectedItemsId, setSelectedItemsId] = React.useState<string[]>([])

  const [globalSearch, setGlobalSearch] = React.useState('')

  const [idSearch, setIdSearch] = React.useState('')
  const idSearchRef = React.useRef<InputRef>(null)

  const [fullNameSearch, setFullNameSearch] = React.useState('')
  const fullNameSearchRef = React.useRef<InputRef>(null)

  const [shopNameSearch, setShopNameSearch] = React.useState('')
  const shopNameSearchRef = React.useRef<InputRef>(null)

  const [phoneSearch, setPhoneSearch] = React.useState('')
  const phoneSearchRef = React.useRef<InputRef>(null)

  const [status, setStatus] = React.useState<ESellerStatus | undefined>(
    undefined
  )

  const [currentPage, setCurrentPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const pageSize = 10

  const showDeleteConfirm = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Вы действительно хотите удалить выбранных продавцов?',
      content: 'Отменить данное действие будет невозможно.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',  
      cancelText: 'Закрыть',
      onOk() {
        return new Promise((resolve) => {
          setProductsAction().then(resolve)
        })
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const getSellers = async (skip: number, status: ESellerStatus | undefined, data?: ISellersQueryFilters) => {
    try {
      setIsLoading(true)
      const response = await sellerApi.getSellers({
        skip: String(skip),
        limit: String(pageSize),
        all_fields: data?.all_fields ?? globalSearch,
        number: data?.number ?? idSearch,
        full_name: data?.full_name ?? fullNameSearch,
        shop_name: data?.shop_name ?? shopNameSearch,
        tel: data?.tel ?? phoneSearch,
        status
      })
      setTotal(response.data.count)
      setSellers(response.data.sellers)
      setSearchParams(cleanObject({
        page: skip / pageSize + 1 === 1 ? '' : String(skip / pageSize + 1),
        all_fields: data?.all_fields ?? globalSearch,
        number: data?.number ?? idSearch,
        full_name: data?.full_name ?? fullNameSearch,
        shop_name: data?.shop_name ?? shopNameSearch,
        tel: data?.tel ?? phoneSearch,
        status: status ?? ''
      }))
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const setProductsAction = async () => {
    try {
      const response = await sellerApi.setActions({
        toDelete: selectedItemsId.join(',')
      })
      message.success(response.data.message)
      if (selectedItemsId.length === sellers.length) {
        getSellers(0, status)
        setCurrentPage(1)
      } else {
        getSellers((currentPage - 1) * pageSize, status)
      }
      setSelectedItemsId([])
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const onChangeSellerStatus = async (e: MenuInfo, sellerId: string) => {
    try {
      setIsLoading(true)
      await sellerApi.changeSellerStatus(sellerId, { status: e.key as ESellerStatus.APPROVED | ESellerStatus.DECLINED })
      setSellers((prev) =>
        prev.map((el) => {
          if (el._id === sellerId) {
            return {
              ...el,
              status: e.key as ESellerStatus
            }
          }
          return el
        })
      )
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const saveFieldValue = (data: ISellersQueryFilters) => {
    getSellers(0, status, data)
    setCurrentPage(1)
  }

  const onChangeGlobalSearch = (searchValue: string) => {
    getSellers(0, status, { all_fields: searchValue })
    setCurrentPage(1)
  }

  const onChangeStatus = (newStatus: ESellerStatus | undefined) => {
    getSellers(0, newStatus)
    setCurrentPage(1)
  }

  const onRowSelection = (selectedItemsId: React.Key[]) => {
    setSelectedItemsId(selectedItemsId as string[])
  }

  const onPageChange = (current: number) => {
    getSellers((current - 1) * pageSize, status)
    setCurrentPage(current)
  }

  const debouncedGetSellersByGlobalSearch = React.useCallback(debounce(onChangeGlobalSearch, DEBOUNCE_DELAY), [idSearch, fullNameSearch, shopNameSearch, phoneSearch, status])

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1))
      if (params.page) {
        setCurrentPage(Number(params.page))
      }
      if (params.all_fields) {
        setGlobalSearch(params.all_fields as string)
      }
      if (params.number) {
        setIdSearch((params.number as string).replace(/[^0-9]/g, ''))
      }
      if (params.full_name) {
        setFullNameSearch(params.full_name as string)
      }
      if (params.shop_name) {
        setShopNameSearch(params.shop_name as string)
      }
      if (params.tel) {
        setPhoneSearch((params.tel as string).replace(/[^0-9]/g, ''))
      }
      if (params.status) {
        setStatus(params.status as ESellerStatus)
      }
    }
    setIsFirstRequestFinished(true)
  }, [])

  React.useEffect(() => {
    if (isFirstRequestFinished) {
      getSellers((currentPage - 1) * pageSize, status)
      setCurrentPage(currentPage)
    }
  }, [isFirstRequestFinished])

  const columns: ColumnsType<ISeller> = React.useMemo(
    () => [
      {
        title: 'ID Продавца',
        dataIndex: 'number',
        width: '15%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getSellerEditPath(_id))
          }
        },
        render: (_, { number }) => {
          return (
            <Typography.Text style={{ wordWrap: 'break-word' }} onClick={(event) => event.stopPropagation()}>
              {number}
            </Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={idSearchRef}
                value={idSearch}
                onChange={(e) => setIdSearch(e.target.value.replace(/[^0-9]/g, ''))}
                onPressEnter={() => {
                  saveFieldValue({ number: idSearch })
                  close()
                }}
                placeholder='ID Продавца'
                className='filterInput'
              />
              <div className='filterFooterButtons'>
                <Button
                  type='primary'
                  onClick={() => {
                    saveFieldValue({ number: idSearch })
                    close()
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  className='filterButton'
                >
                  Найти
                </Button>
                <Button
                  onClick={() => {
                    setIdSearch('')
                    saveFieldValue({ number: '' })
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => idSearchRef.current?.select(), 100)
          }
        },
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: idSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
            }}
          />
        )
      },
      {
        title: 'ФИО продавца',
        dataIndex: 'name',
        width: '22.5%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getSellerEditPath(_id))
          }
        },
        render: (_, { user_id }) => {
          return (
            <Typography.Text onClick={(event) => event.stopPropagation()}>
              {user_id?.full_name ?? ''}
            </Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={fullNameSearchRef}
                value={fullNameSearch}
                onChange={(e) => setFullNameSearch(e.target.value)}
                onPressEnter={() => {
                  saveFieldValue({ full_name: fullNameSearch })
                  close()
                }}
                placeholder='ФИО продавца'
                className='filterInput'
              />
              <div className='filterFooterButtons'>
                <Button
                  type='primary'
                  onClick={() => {
                    saveFieldValue({ full_name: fullNameSearch })
                    close()
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  className='filterButton'
                >
                  Найти
                </Button>
                <Button
                  onClick={() => {
                    setFullNameSearch('')
                    saveFieldValue({ full_name: '' })
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => fullNameSearchRef.current?.select(), 100)
          }
        },
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: fullNameSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
            }}
          />
        )
      },
      {
        title: 'Название магазина',
        dataIndex: 'shopName',
        width: '22.5%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getSellerEditPath(_id))
          }
        },
        render: (_, { shop_details }) => {
          return (
            <Typography.Text onClick={(event) => event.stopPropagation()}>
              {shop_details?.shop_name}
            </Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={shopNameSearchRef}
                value={shopNameSearch}
                onChange={(e) => setShopNameSearch(e.target.value)}
                onPressEnter={() => {
                  saveFieldValue({ shop_name: shopNameSearch })
                  close()
                }}
                placeholder='Название магазина'
                className='filterInput'
              />
              <div className='filterFooterButtons'>
                <Button
                  type='primary'
                  onClick={() => {
                    saveFieldValue({ shop_name: shopNameSearch })
                    close()
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  className='filterButton'
                >
                  Найти
                </Button>
                <Button
                  onClick={() => {
                    setShopNameSearch('')
                    saveFieldValue({ shop_name: '' })
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => shopNameSearchRef.current?.select(), 100)
          }
        },
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: shopNameSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
            }}
          />
        )
      },
      {
        title: 'Номер телефона',
        dataIndex: 'tel',
        width: '20%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getSellerEditPath(_id))
          }
        },
        render: (_, { user_id }) => {
          return (
            <Typography.Text onClick={(event) => event.stopPropagation()}>
              {!!user_id?.tel && phoneMaskStatic(user_id.tel)}
            </Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={phoneSearchRef}
                value={phoneSearch}
                onChange={(e) => setPhoneSearch(e.target.value.replace(/[^0-9]/g, ''))}
                onPressEnter={() => {
                  saveFieldValue({ tel: phoneSearch })
                  close()
                }}
                placeholder='Номер телефона'
                className='filterInput'
              />
              <div className='filterFooterButtons'>
                <Button
                  type='primary'
                  onClick={() => {
                    saveFieldValue({ tel: phoneSearch })
                    close()
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  className='filterButton'
                >
                  Найти
                </Button>
                <Button
                  onClick={() => {
                    setPhoneSearch('')
                    saveFieldValue({ tel: '' })
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => phoneSearchRef.current?.select(), 100)
          }
        },
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: phoneSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
            }}
          />
        )
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        width: '20%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getSellerEditPath(_id))
          }
        },
        render: (_, { status, _id }) => {
          if (status === ESellerStatus.APPROVED) {
            return (
              <Tag color={sellerStatuses[status].color} onClick={(event) => event.stopPropagation()}>
                {sellerStatuses[status].label}
              </Tag>
            )
          }
          return (
            <div onClick={(event) => event.stopPropagation()}>
              <StatusDropdown
                items={getSellerStatusItems(status)}
                label={sellerStatuses[status].label}
                color={sellerStatuses[status].color}
                onClick={(e) => onChangeSellerStatus(e, _id)}
              />
            </div>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Radio.Group
                onChange={(e) => {
                  setStatus(e.target.value)
                  onChangeStatus(e.target.value)
                  close()
                }}
                value={status}
                style={{ display: 'block', marginBottom: 15 }}
              >
                <Space direction='vertical' size={15}>
                  {Object.values(sellerStatuses).map((item) => (
                    <Radio key={item.value as string} value={item.value}>
                      <Tag color={item.color}>{item.label}</Tag>
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
              <Button
                onClick={() => {
                  setStatus(undefined)
                  onChangeStatus(undefined)
                  close()
                }}
                size='small'
                className='filterButton'
              >
                Очистить
              </Button>
              <Button
                type='link'
                size='small'
                onClick={() => {
                  close()
                }}
              >
                Закрыть
              </Button>
            </div>
          )
        },
        filterIcon: () => (
          <FilterFilled
            style={{
              color: status ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
            }}
          />
        )
      }
    ],
    [sellers, idSearch, fullNameSearch, shopNameSearch, phoneSearch, status]
  )

  return (
    <div style={{ paddingBottom: 80 }}>
      <MainTitle text='Продавцы' />
      <div className={styles.header}>
        <Input
          value={globalSearch}
          onChange={(e) => {
            setGlobalSearch(e.target.value)
            debouncedGetSellersByGlobalSearch(e.target.value)
          }}
          placeholder='Поиск (по имени/номеру телефона/ID)'
          size='large'
          suffix={<SearchOutlined />}
          className={classNames('searchInput', styles.searchInput)}
        />
        <Link to={superAdminRoutes.SELLERS_ADD}>
          <Button type='primary' size='large' icon={<UserAddOutlined />}  className={styles.addSellerButton}>
            Добавить продавца
          </Button>
          <Button type='primary' size='large' icon={<PlusOutlined />} className={styles.addSellerButtonMobile} />
        </Link>
      </div>
      <Table
        scroll={{ x: 950 }}
        columns={columns}
        dataSource={sellers}
        loading={isLoading}
        rowKey={(el) => el._id}
        rowSelection={{
          selectedRowKeys: selectedItemsId,
          onChange: onRowSelection
        }}
        rowClassName='tableRow'
        footer={() => (
          <Space size={8}>
            <Typography.Text>Действия:</Typography.Text>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              onClick={showDeleteConfirm}
              disabled={!selectedItemsId.length}
            >
              Удалить
            </Button>
          </Space>
        )}
        pagination={{
          current: currentPage,
          onChange: onPageChange,
          pageSize,
          total,
          showSizeChanger: false
        }}
      />
    </div>
  )
}
