import { Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import arrowBack from 'assets/icons/arrow-back.svg'
import styles from './MainTitle.module.scss'

interface IMainTitleProps {
  text: string
  id?: string
  isGoBack?: boolean
  onGoBack?: () => void
  style?: React.CSSProperties
}

export const MainTitle: React.FC<IMainTitleProps> = ({ text, id, style, isGoBack = false, onGoBack }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.wrapper} style={style}>
      {isGoBack && (
        <button
          type='button'
          className='reset-button'
          onClick={() => {
            if (onGoBack) {
              return onGoBack()
            }
            navigate(-1)
          }}
        >
          <img src={arrowBack} alt='Вернуться назад' />
        </button>
      )}
      <Typography.Title id={id} className={styles.title}>{text}</Typography.Title>
    </div>
  )
}
