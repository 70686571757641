import {
  EProductStatus,
  EProductType,
  ICreateProduct,
  ICreateProductVariation,
  IProductFormData,
  IProductVariationFormData,
  IUpdateProduct,
  IUpdateProductVariation
} from 'models/product'
import { normalizeNumber } from 'shared/utils/normalizeNumber'
import { getNormalizedVariations } from './variations'

export const getCreateSubmitData = (
  data: IProductFormData,
  status:
    | EProductStatus.DRAFT
    | EProductStatus.PUBLISHED
    | EProductStatus.REJECTED
    | undefined,
  firstVariationName: string | undefined,
  secondVariationName: string | undefined
): ICreateProduct => ({
  name: data.name,
  status,
  category_id: data.categories_id[0],
  subCategory_id: data.categories_id[1],
  vendor_code: data.vendor_code,
  price: normalizeNumber(data.price),
  tax: data.tax,
  dimension: {
    width: normalizeNumber(data.width),
    length: normalizeNumber(data.length),
    height: normalizeNumber(data.height),
    weight: normalizeNumber(data.weight),
    unit: data.unit,
    in_stock: normalizeNumber(data.in_stock)
  },
  photo: data.photo.map((el: any) => el.originFileObj),
  desc: data.desc,
  characteristic: data?.characteristics,
  variations: getNormalizedVariations(data?.variations, firstVariationName, secondVariationName)
})

export const getEditSubmitData = (
  data: IProductFormData,
  status:
    | EProductStatus.DRAFT
    | EProductStatus.PUBLISHED
    | EProductStatus.REJECTED
    | undefined,
  productId: string,
  toDeletePhotos: string[],
  toDeleteVariations: string[],
  firstVariationName: string | undefined,
  secondVariationName: string | undefined
): IUpdateProduct => ({
  id: productId,
  toDeletePhotos,
  toDeleteVariations,
  name: data.name,
  status,
  category_id: data.categories_id[0],
  subCategory_id: data.categories_id[1],
  vendor_code: data.vendor_code,
  price: normalizeNumber(data.price),
  tax: data.tax,
  dimension: {
    width: normalizeNumber(data.width),
    length: normalizeNumber(data.length),
    height: normalizeNumber(data.height),
    weight: normalizeNumber(data.weight),
    unit: data.unit,
    in_stock: normalizeNumber(data.in_stock)
  },
  photo: data.photo.map((el: any) => el.originFileObj).filter((el) => el),
  desc: data.desc,
  characteristic: data?.characteristics,
  variations: getNormalizedVariations(data?.variations, firstVariationName, secondVariationName)
})

export const getCreateVariationSubmitData = (
  data: IProductVariationFormData,
  sellerId: string
): ICreateProductVariation => ({
  seller_id: sellerId,
  name: data.name,
  vendor_code: data.vendor_code,
  price: normalizeNumber(data.price),
  tax: data.tax,
  dimension: {
    width: normalizeNumber(data.width),
    length: normalizeNumber(data.length),
    height: normalizeNumber(data.height),
    weight: normalizeNumber(data.weight),
    unit: data.unit,
    in_stock: normalizeNumber(data.in_stock)
  },
  desc: data?.desc,
  photo: data?.photo?.map((el: any) => el.originFileObj)
})

export const getEditVariationSubmitData = (
  data: IProductVariationFormData,
  productId: string,
  toDeletePhotos: string[]
): IUpdateProductVariation => ({
  id: productId,
  toDeletePhotos,
  type: EProductType.VARIATION,
  name: data.name,
  vendor_code: data.vendor_code,
  price: normalizeNumber(data.price),
  tax: data.tax,
  dimension: {
    width: normalizeNumber(data.width),
    length: normalizeNumber(data.length),
    height: normalizeNumber(data.height),
    weight: normalizeNumber(data.weight),
    unit: data.unit,
    in_stock: normalizeNumber(data.in_stock)
  },
  desc: data?.desc,
  photo: data?.photo?.map((el: any) => el.originFileObj).filter((el) => el)
})
