import React from 'react'
import { Button, Cascader, Form, Input, Modal, Spin, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { sellerApi } from 'api/seller'
import { productApi } from 'api/product'
import { InfoBlock } from 'components/ui'
import {
  IIntegrationStatusResponse,
  MoySkladLink,
  MoySkladLinker,
  TVariantSeller
} from 'models/seller'
import { IParsedCategory } from 'models/product'
import { showServerError } from 'shared/utils/showServerError'
import { getDeclination } from 'shared/utils/getDeclination'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { IAlertData } from 'models/app'
import styles from './SellerApiMoySklad.module.scss'
import { InfoAlert } from './ui/InfoAlert'

interface ISellerApiMoySkladProps {
  type: TVariantSeller
  sellerId: string
}

interface IFormData {
  token: string
}

export const SellerApiMoySklad: React.FC<ISellerApiMoySkladProps> = ({
  type,
  sellerId
}) => {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [isSubmittingImport, setIsSubmittingImport] = React.useState(false)
  const [isSubmittingLinker, setIsSubmittingLinker] = React.useState(false)

  const [statusInfo, setStatusInfo] =
    React.useState<IIntegrationStatusResponse | null>(null)
  const [alertData, setAlertData] = React.useState<
    IAlertData<'SUCCESSFUL' | 'FAILED' | 'FINISHED'>
  >({
    visible: false,
    type: null
  })
  const [moySkladCategories, setMoySkladCategories] = React.useState<
    MoySkladLinker[]
  >([])
  const [categories, setCategories] = React.useState<IParsedCategory[]>([])

  const [importForm] = Form.useForm()
  const [categoriesForm] = Form.useForm()

  const closeAlert = React.useCallback(() => {
    setAlertData({ visible: false, type: null })
  }, [])

  const showDeleteConfirm = (data: IFormData) => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Внимание!',
      content: <>
        <Typography.Text style={{ display: 'block', marginBottom: 20 }}>Для синхронизации остатков у вас должна быть платная подписка на МойСклад.</Typography.Text>
        <Typography.Text>Для начала импорта с синхронизацией остатков оплатите подписку и запустите процесс синхронизации.</Typography.Text>
      </>,
      icon: <ExclamationCircleOutlined />,
      okText: 'Запустить',
      cancelText: 'Отменить',
      onOk() {
        bodyScrollLock.disable()
        importProducts(data)
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const getCurrentStatus = async () => {
    try {
      const response = await sellerApi.getIntegrationStatus({
        seller_id: sellerId,
        type: 'moy_sklad_import'
      })
      setStatusInfo(response.data || null)
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoaded(true)
    }
  }

  const importProducts = async (data: IFormData) => {
    try {
      setIsSubmittingImport(true)
      closeAlert()
      window.getCurrentMoySkladStatusInterval = setInterval(() => {
        getCurrentStatus()
      }, 3000)
      await sellerApi.moySkladImportProducts({
        token: data.token.trim(),
        seller_id: sellerId
      })
    } catch (e) {
      showServerError(e)
      setAlertData({ visible: true, type: 'FAILED' })
    } finally {
      setTimeout(() => {
        setIsSubmittingImport(false)
      }, 3000)
    }
  }

  const getMoySkladCategories = async () => {
    try {
      const response = await sellerApi.moySkladGetCategories({
        seller_id: sellerId
      })
      setMoySkladCategories(response.data.data)
    } catch (e) {
      showServerError(e)
    }
  }

  const getCategories = async () => {
    try {
      const response = await productApi.getCategories({
        parsed: 'true'
      })
      setCategories(response.data as IParsedCategory[])
    } catch (e) {
      showServerError(e)
    }
  }

  React.useEffect(() => {
    const getSeller = async () => {
      try {
        const response = await sellerApi.getSeller(sellerId)
        importForm.setFieldValue('token', response.data?.moy_sklad?.api_token)
      } catch (e) {
        showServerError(e)
      }
    }
    getSeller()
    getCurrentStatus()
  }, [])

  React.useEffect(() => {
    if (
      statusInfo &&
      (statusInfo.state === 'SUCCESSFUL' || statusInfo.state === 'FINISHED' || statusInfo.state === 'FAILED')
    ) {
      setAlertData({ visible: true, type: statusInfo.state })
      clearInterval(window?.getCurrentMoySkladStatusInterval)
    }
    if (statusInfo && statusInfo.state === 'SUCCESSFUL') {
      getMoySkladCategories()
      getCategories()
    }
  }, [statusInfo])

  const onSubmitImport = (data: IFormData) => {
    showDeleteConfirm(data)
  }

  const onSubmitLinker = async (data: Record<string, string[]>) => {
    try {
      setIsSubmittingLinker(true)
      const linksForRequest: MoySkladLink[] = Object.keys(data).map(el => ({ linker_id: el, category_id: data[el][0], subCategory_id: data[el][1] }))
      await sellerApi.connectMoySkladCategories({ updateData: linksForRequest, seller_id: sellerId })
      getCurrentStatus()
    } catch (e) {
      showServerError(e)
    } finally {
      setIsSubmittingLinker(false)
    }
  }

  const columns: ColumnsType<MoySkladLinker> = React.useMemo(
    () => [
      {
        title: 'Группы мой склад',
        dataIndex: 'pathName',
        minWidth: '50%',
        width: '50%',
        render: (_, { pathName }) => {
          return <Typography.Text>{pathName}</Typography.Text>
        }
      },
      {
        title: 'Категории Horsesmart',
        dataIndex: 'category',
        minWidth: '50%',
        width: '50%',
        render: (_, { _id, category_id, subCategory_id }) => {
          return (
            <Form.Item
              initialValue={category_id && subCategory_id ? [category_id, subCategory_id] : undefined}
              name={_id}
              rules={[
                {
                  required: true,
                  message: 'Укажите категорию/подкатегорию'
                }
              ]}
              style={{ margin: '8px 0' }}
              hasFeedback
            >
              <Cascader
                showSearch
                placeholder='Выберите категорию/подкатегорию'
                rootClassName='myCascader'
                popupClassName={styles.categorySelect}
                options={categories}
                fieldNames={{ label: 'title' }}
                expandTrigger='click'
              />
            </Form.Item>
          )
        }
      }
    ],
    [moySkladCategories, categories]
  )

  if (!isLoaded) {
    return null
  }

  return (
    <div style={{ paddingBottom: 80 }}>
      <Typography.Title level={2} className={styles.title}>
        Синхронизация мой склад
      </Typography.Title>
      <InfoAlert
        isVisible={alertData.visible}
        type={alertData.type}
        onClose={closeAlert}
      />
      {statusInfo && statusInfo.state === 'WAITING' ? (
        <div className={styles.syncWrapper}>
          <div className={styles.syncLoader}>
            <Spin />
            <Typography.Text className={styles.syncLoaderText}>
              Синхронизация...
            </Typography.Text>
          </div>
          <div>
            <Typography.Text
              className={classNames(
                styles.syncDescription,
                styles.syncDescriptionFirst
              )}
            >
              Запущен активный процесс синхронизации, страницу можно покинуть.{' '}
              <br /> Примерное время ожидание:{' '}
              <span style={{ fontWeight: 700 }}>
                {statusInfo?.waiting_time
                  ? `${statusInfo.waiting_time} ${getDeclination(
                    statusInfo.waiting_time,
                    ['минут', 'минута', 'минуты']
                  )}`
                  : ''}
              </span>
            </Typography.Text>
            <Typography.Text className={styles.syncDescription}>
              <span className={styles.syncDescriptionDanger}>ВНИМАНИЕ!</span>{' '}
              После завершения синхронизации необходимы дополнительные действия!
            </Typography.Text>
          </div>
        </div>
      ) : (
        <>
          <Form
            form={importForm}
            name='moySklad'
            size='large'
            onFinish={onSubmitImport}
            className={styles.form}
          >
            <div style={{ flexGrow: 1 }}>
              <Form.Item
                name='token'
                rules={[
                  {
                    required: true,
                    message: 'Укажите токен'
                  },
                  {
                    whitespace: true,
                    message: 'Укажите токен'
                  }
                ]}
                hasFeedback
                className={styles.input}
              >
                <Input placeholder='40a8cedcc57b2161430935fd558ad51a434a30ec' />
              </Form.Item>
              <InfoBlock text='При смене API токена в системе МойСклад синхронизация перестанет работать!' />
            </div>
            <Button
              htmlType='submit'
              type='default'
              loading={isSubmittingImport}
            >
              Синхронизировать
            </Button>
          </Form>
          <Typography.Text className={styles.info}>
            Токен может быть создан на{' '}
            <Typography.Link
              href='https://online.moysklad.ru/app/#token'
              target='_blank'
              className={styles.linkToMoySklad}
            >
              странице настроек Мой склад
            </Typography.Link>
          </Typography.Text>
        </>
      )}
      {statusInfo &&
        statusInfo.state === 'SUCCESSFUL' &&
        moySkladCategories.length && (
        <>
          <Typography.Title
            level={2}
            className={styles.moySkladCategoriesTitle}
          >
            Установите соответствие категорий
          </Typography.Title>
          <Form
            form={categoriesForm}
            name='moySkladCategories'
            size='small'
            onFinish={onSubmitLinker}
          >
            <Table
              scroll={{
                x: 550
              }}
              columns={columns}
              dataSource={moySkladCategories}
              rowKey={(el) => el._id}
              pagination={false}
            />
            <div className={styles.linkerSubmitButtonWrapper}>
              <Button htmlType='submit' type='primary' size='large' loading={isSubmittingLinker}>Сохранить</Button>
            </div>
          </Form>
        </>
      )}
    </div>
  )
}
