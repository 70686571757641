import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Email } from 'components/auth/RecoveryPassword/Email/Email'
import { Code } from 'components/auth/RecoveryPassword/Code/Code'
import { NewPassword } from 'components/auth/RecoveryPassword/NewPassword/NewPassword'

enum ESteps {
  EMAIL = 'Email',
  CODE = 'Code',
  NEW_PASSWORD = 'NewPassword'
}

export const RecoveryPassword: React.FC = () => {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState('')
  const [step, setStep] = React.useState<ESteps>(ESteps.EMAIL)

  const setStepOne = React.useCallback(() => setStep(ESteps.EMAIL), [])
  const setStepTwo = React.useCallback(() => setStep(ESteps.CODE), [])
  const setStepThree = React.useCallback(() => setStep(ESteps.NEW_PASSWORD), [])
  const setStepFour = React.useCallback(() => navigate('/'), [navigate])

  if (step === ESteps.CODE) {
    return <Code email={email} onSuccess={setStepThree} onGoBack={setStepOne} />
  }

  if (step === ESteps.NEW_PASSWORD) {
    return <NewPassword email={email} onSuccess={setStepFour} />
  }

  return <Email setEmail={setEmail} onSuccess={setStepTwo} />
}