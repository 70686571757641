import React from 'react'
import { Typography } from 'antd'
import { ModerationIcon } from '../ModerationIcon/ModerationIcon'
import styles from './ChangeDataWrapper.module.scss'

interface IChangeDataWrapperProps {
  title: string,
  isOnModeration?: boolean
  children: React.ReactNode
}

export const ChangeDataWrapper: React.FC<IChangeDataWrapperProps> = React.memo(({ title, isOnModeration, children }) => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        {isOnModeration && <ModerationIcon width={18} height={17} />}
        <Typography.Title level={4} style={{ margin: 0 }}>{title}</Typography.Title>
      </header>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
})