import { Typography, theme } from 'antd'

interface LargeTextProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export const LargeText: React.FC<LargeTextProps> = ({
  children,
  className,
  style
}) => {
  const { token } = theme.useToken()
  return (
    <Typography.Paragraph
      className={className}
      style={{
        fontSize: token.fontSizeLG,
        ...style
      }}
    >
      {children}
    </Typography.Paragraph>
  )
}
