export interface IAdminTransactionsRequest extends Components.Schemas.IAdminGetTransactionsRequest {}
export interface IAdminTransactionsResponse extends Components.Schemas.IAdminTransactionsQueryResponse {}
export interface ITransaction extends Components.Schemas.ISellerTransactions {}
export interface IAdminCreateSellerTransactionRequest extends Components.Schemas.IAdminCreateSellerTransactionRequest {}
export interface IAdminAddRefundTransactionRequest extends Components.Schemas.IAdminAddCustomRefundTransactionRequest {}

export enum ESellerTransactionsType {
  SALE = 'SALE',
  FINE = 'FINE',
  WITHDRAW = 'WITHDRAW',
  REFUND = 'REFUND',
}