import React from 'react'
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Typography,
  message
} from 'antd'
import { cdekApi } from 'api/cdek'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import {
  DeleteOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import metroIcom from 'assets/icons/metro.svg'
import timeIcom from 'assets/icons/time.svg'
import addressIcom from 'assets/icons/address.svg'
import { ICDEKOffice } from 'models/cdek'
import { INearestPointOfCdek, IShop, IShopWorkTime, TVariantSeller } from 'models/seller'
import { IAddressSuggestion } from 'models/app'
import { sellerApi } from 'api/seller'
import { appApi } from 'api/app'
import { superAdminRoutes } from 'shared/constants/routes'
import { showServerError } from 'shared/utils/showServerError'
import {
  DEBOUNCE_DELAY,
  LOCAL_STORAGE_SELECTED_CITY_KEY
} from 'shared/constants/app'
import { showMessage } from 'shared/utils/showMessage'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { ColumnsType } from 'antd/es/table'
import styles from './SellerShops.module.scss'

interface IAddressData {
  name: string
  lat: string
  lng: string
}

interface ICreateShopFormData {
  shopName: string
  address: string
  workTime1: string
  workTime2: string
  workTime3: string
}
interface ISellerShopsProps {
  type: TVariantSeller
  sellerId: string
}

export const SellerShops: React.FC<ISellerShopsProps> = ({
  type,
  sellerId
}) => {
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const mapRef = React.useRef<any>(null)
  const objectManagerRef = React.useRef<any>(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [shops, setShops] = React.useState<IShop[]>([])

  const [addresses, setAddresses] = React.useState<IAddressSuggestion[]>([])

  const [selectedDeliveryPoint, setSelectedDeliveryPoint] =
    React.useState<INearestPointOfCdek | null>(null)
  const [isSelectedDeliveryPointLoaded, setIsSelectedDeliveryPointLoaded] =
    React.useState(false)
  const [isSelectedDeliveryPointError, setIsSelectedDeliveryPointError] =
    React.useState(false)
  const [showChangePVZ, setShowChangePVZ] = React.useState(false)

  const [cities, setCities] = React.useState<IAddressSuggestion[]>([])

  const selectedCityFromLocalStorage: string | null = localStorage.getItem(
    LOCAL_STORAGE_SELECTED_CITY_KEY
  )
  const [selectedCity, setSelectedCity] = React.useState<string | null>(
    selectedCityFromLocalStorage
      ? JSON.parse(selectedCityFromLocalStorage)
      : null
  )

  const [cdekPoints, setCdekPoints] = React.useState<ICDEKOffice[]>([])
  const [selectedCdekId, setSelectedCdekId] = React.useState<string | null>(
    null
  )

  const getShops = async () => {
    try {
      setIsLoading(true)
      const response = await sellerApi.getShops(sellerId)
      setShops(response.data)
    } catch (e) {
      const error = e as AxiosError
      if (
        (error.response?.status === 500 ||
          error.response?.status === 400 ||
          error.response?.status === 404) &&
        type === 'seller'
      ) {
        return navigate(superAdminRoutes.SELLERS)
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const getAddressesBySearch = async (searchValue: string) => {
    try {
      const response = await appApi.getAddresses({ address: searchValue })
      setAddresses(response.data.suggestions)
    } catch (e) {
      showServerError(e)
    }
  }

  const debouncedGetAddressesBySearch = debounce(
    getAddressesBySearch,
    DEBOUNCE_DELAY
  )

  const getCdekPoints = async (city: string) => {
    try {
      const response = await cdekApi.getPvzPoints({ city })
      setCdekPoints(response.data)
      return response.data
    } catch (e) {
      showServerError(e)
    }
  }

  const getCitiesBySearch = async (searchValue: string) => {
    try {
      const response = await appApi.getAddresses({
        address: searchValue,
        isCityOnly: 'true'
      })
      setCities(response.data.suggestions)
    } catch (e) {
      showServerError(e)
    }
  }

  const debouncedGetCitiesBySearch = debounce(getCitiesBySearch, DEBOUNCE_DELAY)

  const updateSelectedDeliveryPoint = async (point: ICDEKOffice) => {
    try {
      message.loading('Обновление данных')
      await sellerApi.updateSelectedDeliveryPoint({
        code: point.code,
        name: point.name,
        location: point.location,
        seller_id: sellerId
      })
      message.destroy()
      message.success('Данные успешно обновлены')
      setSelectedDeliveryPoint({
        code: point.code,
        name: point.name,
        location: point.location
      })
      localStorage.setItem(
        LOCAL_STORAGE_SELECTED_CITY_KEY,
        JSON.stringify(selectedCity)
      )
    } catch (e) {
      message.destroy()
      showServerError(e)
    }
  }

  window.closeBalloon = () => {
    document.querySelector('.ymaps-2-1-79-balloon-overlay')?.remove()
    setShowChangePVZ(false)
    const newSelectedPoint = cdekPoints.find((el) => el.uuid === selectedCdekId)
    updateSelectedDeliveryPoint(newSelectedPoint as ICDEKOffice)
  }

  React.useEffect(() => {
    const getSelectedDeliveryPoint = async () => {
      try {
        const response = await sellerApi.getSelectedDeliveryPoint(sellerId)
        setSelectedDeliveryPoint(response.data)
        setShowChangePVZ(!response.data)
      } catch (e) {
        const error = e as AxiosError
        if (
          (error.response?.status === 500 ||
            error.response?.status === 400 ||
            error.response?.status === 404) &&
          type === 'seller'
        ) {
          return navigate(superAdminRoutes.SELLERS)
        }
        setIsSelectedDeliveryPointError(true)
        showMessage(
          'error',
          'Не удалось получить информацию о выбранном пункте выдачи',
          ''
        )
      } finally {
        setIsSelectedDeliveryPointLoaded(true)
      }
    }
    getSelectedDeliveryPoint()
    getShops()
  }, [])

  const getModalInfo = (data: ICDEKOffice) => ({
    balloonContentHeader: `<span style="font-family: SFProText;">Пункт СДЭК ${data.name.replace(
      /["']/g,
      ''
    )}</span>`,
    balloonContentBody: `
    ${
    data?.nearest_metro_station
      ? `<div style="margin-top: 10px; display: flex; gap: 10px; align-items: center; max-width: 200px;">
  <img src=${metroIcom} />
    <span style="font-family: SFProText;">ст. м. ${data.nearest_metro_station}</span>
  </div>`
      : ''
    }
      <div style="margin-top: 10px; display: flex; gap: 10px; align-items: flex-start;">
        <img src=${timeIcom} />
        <div>
    <span style="font-family: SFProText; display: block;">${
    data.work_time.split(',')[0]
    }</span>
    <span style="font-family: SFProText; display: block;">${
    data.work_time.split(',')?.[1]
    }</span>
  ${
    !!data.work_time.split(',')[2]
      ? `<span style="font-family: SFProText; display: block;">${
        data.work_time.split(',')[2]
      }</span>`
      : ''
    }
        </div>
      </div>
      <div style="margin-top: 10px; display: flex; gap: 10px; align-items: center; max-width: 200px;">
        <img src=${addressIcom} />
        <span style="font-family: SFProText;">${
    data.location.address_full
    }</span>
      </div>
    `,
    balloonContentFooter: `
      <button style="margin-top: 15px;" class="ant-btn css-wpbmuc ant-btn-primary ant-btn-md" onclick="window.closeBalloon()">
        <span>Выбрать</span>
      </button>
    `
  })

  React.useEffect(() => {
    const init = async () => {
      const map = new window.ymaps.Map('map', {
        center: [55.755864, 37.617698],
        zoom: 9.5,
        controls: ['geolocationControl', 'zoomControl', 'fullscreenControl']
      })
      const objectManager = new window.ymaps.ObjectManager({
        clusterize: true,
        gridSize: 32
      })
      objectManager.objects.options.set('preset', 'islands#greenDotIcon')
      objectManager.clusters.options.set('preset', 'islands#greenClusterIcons')
      objectManager.objects.events.add(['click'], function (e: any) {
        setSelectedCdekId(e.get('objectId') ?? null)
      })
      map.geoObjects.add(objectManager)

      mapRef.current = map
      objectManagerRef.current = objectManager
    }
    window.ymaps.ready(init)
  }, [])

  const setPointsToMap = async (city: string) => {
    const parseCityData = JSON.parse(city) as IAddressData
    objectManagerRef?.current?.removeAll()
    const points = await getCdekPoints(parseCityData.name)
    message.destroy()
    mapRef.current?.setCenter([parseCityData.lat, parseCityData.lng], 9.5)
    if (points) {
      objectManagerRef?.current?.add(
        points.map((item) => ({
          id: item.uuid,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [item.location.latitude, item.location.longitude]
          },
          properties: getModalInfo(item)
        }))
      )
    }
  }

  React.useEffect(() => {
    if (selectedCity) {
      setPointsToMap(selectedCity)
    }
  }, [selectedCity])

  const deleteShop = async (shopId: string) => {
    try {
      await sellerApi.deleteShop(shopId)
      getShops()
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const showDeleteConfirm = (shopId: string) => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Вы действительно хотите удалить магазин?',
      content: 'Отменить данное действие будет невозможно.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',
      cancelText: 'Закрыть',
      onOk() {
        return new Promise((resolve) => {
          deleteShop(shopId).then(resolve)
        })
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const createShop = async (data: ICreateShopFormData) => {
    try {
      setIsSubmitting(true)
      const addressData = JSON.parse(data.address) as IAddressData
      const workTime: IShopWorkTime[] = []
      if (data.workTime1) {
        workTime.push({ time: data.workTime1 })
      }
      if (data.workTime2) {
        workTime.push({ time: data.workTime2 })
      }
      if (data.workTime3) {
        workTime.push({ time: data.workTime3 })
      }
      if (!workTime.length) {
        return showMessage('error', 'Укажите режим работы вашего магазина', '')
      }
      await sellerApi.createShop({
        seller_id: sellerId,
        name: data.shopName,
        address: {
          name: addressData.name,
          lat: Number(addressData.lat),
          lng: Number(addressData.lng)
        },
        work_time: workTime
      })
      form.resetFields()
      getShops()
    } catch (e) {
      showServerError(e)
    } finally {
      setIsSubmitting(false)
    }
  }
  const columns: ColumnsType<IShop> = React.useMemo(
    () => [
      {
        title: 'Наименование магазина',
        dataIndex: 'name',
        width: '33%'
      },
      {
        title: 'Адрес',
        dataIndex: 'address',
        width: '33%',
        render: (_, { address }) => {
          return <Typography.Text>{address.name}</Typography.Text>
        }
      },
      {
        title: 'Режим работы',
        dataIndex: 'workTime',
        width: '33%',
        render: (_, { work_time }) => {
          return (
            <>
              {work_time?.map((item, index) => (
                <Typography.Text key={index} className={styles.workTimeTableText}>{item.time}</Typography.Text>
              ))}
            </>
          )
        }
      },
      {
        title: '',
        dataIndex: '',
        width: 58,
        render: (_, { _id }) => {
          return (
            <Button
              htmlType='button'
              icon={<DeleteOutlined />}
              onClick={() => showDeleteConfirm(_id)}
            />
          )
        }
      }
    ],
    [shops]
  )

  return (
    <div style={{ paddingBottom: 80 }}>
      <Table
        scroll={{
          x: 600
        }}
        columns={columns}
        dataSource={shops}
        loading={isLoading}
        rowKey={(el) => el._id}
        pagination={false}
      />
      <Form
        name='shop'
        form={form}
        size='large'
        className={styles.form}
        onFinish={createShop}
      >
        <Typography.Title
          level={4}
          style={{ marginBottom: 15, paddingTop: '40px' }}
          className={styles.title}
        >
          Добавить магазин
        </Typography.Title>
        <div className={styles.addInputs}>
          <Form.Item
            name='shopName'
            className={styles.input}
            rules={[
              {
                required: true,
                message: 'Заполните наименование магазина'
              },
              {
                whitespace: true,
                message: 'Заполните наименование магазина'
              }
            ]}
            hasFeedback
          >
            <Input placeholder='Наименование магазина' />
          </Form.Item>
          <Form.Item
            name='address'
            className={classNames(styles.input, styles.inputAddress)}
            rules={[
              {
                required: true,
                message: 'Заполните адрес магазина'
              },
              {
                whitespace: true,
                message: 'Заполните адрес магазина'
              }
            ]}
            hasFeedback
          >
            <Select
              showSearch
              placeholder='Адрес'
              notFoundContent={null}
              filterOption={false}
              onSearch={debouncedGetAddressesBySearch}
              options={addresses.map((item) => ({
                value: JSON.stringify({
                  name: item.value,
                  lat: item.data.geo_lat,
                  lng: item.data.geo_lon
                }),
                label: item.value
              }))}
            />
          </Form.Item>
        </div>
        <Typography.Title level={5} style={{ marginBottom: 10 }}>
          Режим работы:
        </Typography.Title>
        <Typography.Text className={styles.descriptionWorkTime}>
          Укажите режим работы в свободном формате, не более 3x строк:
        </Typography.Text>
        <Form.Item
          name='workTime1'
          className={styles.inputTiming}
          hasFeedback
        >
          <Input
            size='small'
            placeholder='ПН-ПТ: 9:00-20:00'
          />
        </Form.Item>
        <Form.Item
          name='workTime2'
          className={styles.inputTiming}
          hasFeedback
        >
          <Input
            size='small'
            placeholder='СБ: 13:00-20:00'
          />
        </Form.Item>
        <Form.Item
          name='workTime3'
          className={styles.inputTiming}
          hasFeedback
        >
          <Input
            size='small'
            placeholder='ВС: выходной'
          />
        </Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          size='large'
          loading={isSubmitting}
          className={styles.createShopButton}
        >
          Добавить
        </Button>
      </Form>

      {isSelectedDeliveryPointLoaded && !isSelectedDeliveryPointError && (
        <>
          <Typography.Title level={4} className={styles.title}>
            Выбор пункта выдачи{' '}
            {type === 'profile' && <span style={{ color: 'red' }}> *</span>}
          </Typography.Title>
          <Typography.Text>
            Выберите самый удобный пункт выдачи заказа для передачи заказов
            клиентов:
          </Typography.Text>
          {selectedDeliveryPoint && (
            <div className={styles.selectedDeliveryPointWrapper}>
              <div className={styles.selectedDeliveryPoint}>
                <EnvironmentOutlined style={{ color: '#4096FF' }} />
                <Typography.Text strong>
                  {selectedDeliveryPoint?.location?.address_full}
                </Typography.Text>
              </div>
              <Button
                type='primary'
                htmlType='button'
                size='large'
                onClick={() => setShowChangePVZ((prev) => !prev)}
              >
                Изменить
              </Button>
            </div>
          )}
        </>
      )}
      {showChangePVZ && (
        <Select
          showSearch
          value={selectedCity ? JSON.parse(selectedCity).name : undefined}
          placeholder='Введите ваш город'
          notFoundContent={null}
          filterOption={false}
          size='large'
          className={styles.selectCityField}
          onSearch={debouncedGetCitiesBySearch}
          onSelect={(city: any) => {
            setSelectedCity(city)
            message.loading('Загрузка точек сдэк')
          }}
          options={cities.map((item) => ({
            value: JSON.stringify({
              name: item.value,
              lat: item.data.geo_lat,
              lng: item.data.geo_lon
            }),
            label: item.value
          }))}
        />
      )}
      <div
        id='map'
        style={{
          display: showChangePVZ && selectedCity ? 'block' : 'none',
          width: '100%',
          height: 480,
          marginTop: 20
        }}
      ></div>
    </div>
  )
}
