import React from 'react'
import { Button, Typography } from 'antd'
import { AxiosError } from 'axios'
import { Line } from '@ant-design/plots'
import { sellerApi } from 'api/seller'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { ISeller, TVariantSeller } from 'models/seller'
import { TransactionsTable } from 'components/transactions/TransactionsTable/TransactionsTable'
import { MostPurchasedProductsTable } from 'components/goods/MostPurchasedProductsTable/MostPurchasedProductsTable'
import { ChangeBalance } from 'components/sellers/ChangeBalance/ChangeBalance'
import { SellerCommission } from 'components/sellers/SellerCommission/SellerCommission'
import { getMonthName } from 'shared/utils/getMonthName'
import { showServerError } from 'shared/utils/showServerError'
import { superAdminRoutes } from 'shared/constants/routes'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { getRandomString } from 'shared/utils/getRandomString'
import styles from './SellerAccounting.module.scss'
import { WithdrawalRequestModal } from './ui/WithdrawalRequestModal'

interface ISellerAccountingProps {
  type: TVariantSeller
  sellerId: string
}

interface IData {
  month: string
  sum: number
}

const ToolTip: React.FC<{ items: any[] }> = React.memo(({ items }) => {
  return (
    <>
      {items.map((el) => (
        <div key={el.data.month} className={styles.tooltip}>
          <p className={styles.tooltipMonth}>{el.data.month} 2024</p>
          <p className={styles.tooltipSum}>{getNormalizedPrice(el.data.sum)}</p>
        </div>
      ))}
    </>
  )
})

export const SellerAccounting: React.FC<ISellerAccountingProps> = ({
  type,
  sellerId
}) => {
  const navigate = useNavigate()

  const [salesVolume, setSalesVolume] = React.useState<IData[]>([])
  const [seller, setSeller] = React.useState<ISeller | null>(null)

  const [isOpenWithdrawalRequestModal, setIsOpenWithdrawalRequestModal] = React.useState(false)

  const [isNeedToUpdateTransactions, setIsNeedToUpdateTransactions] =
    React.useState('')

  React.useEffect(() => {
    getSeller()
  }, [])

  React.useEffect(() => {
    if (seller) {
      getSalesVolumeData()
    }
  }, [seller])

  const getSeller = async () => {
    try {
      const response = await sellerApi.getSeller(sellerId)
      setSeller(response.data)
    } catch (e) {
      const error = e as AxiosError
      if (
        (error.response?.status === 500 ||
          error.response?.status === 400 ||
          error.response?.status === 404) &&
        type === 'seller'
      ) {
        return navigate(superAdminRoutes.SELLERS)
      }
      showServerError(e)
    }
  }

  const getSalesVolumeData = async () => {
    try {
      const response = await sellerApi.getSalesVolume({
        year: '2024',
        seller_id: sellerId
      })
      setSalesVolume(
        Object.values(response.data).map((el, index) => ({
          month: getMonthName(index),
          sum: el
        }))
      )
    } catch (e) {
      showServerError(e)
    }
  }

  const updatePageData = () => {
    getSeller()
    setIsNeedToUpdateTransactions(getRandomString())
  }

  const onSuccessCreateTransaction = () => {
    updatePageData()
  }

  const openWithdrawalRequestModal = () => {
    setIsOpenWithdrawalRequestModal(true)
    bodyScrollLock.enable()
  }

  const onCloseWithdrawalRequestModal = React.useCallback(() => {
    setIsOpenWithdrawalRequestModal(false)
    bodyScrollLock.disable()
  }, [])

  const onSuccessWithdrawalRequestModal = React.useCallback(() => {
    updatePageData()
  }, [])

  const changeCommission = (commission: number) => {
    setSeller({
      ...(seller as ISeller),
      commission
    })
  }

  if (!seller) {
    return null
  }

  return (
    <>
      <div style={{ paddingBottom: 80 }}>
        <div className={styles.salesVolumeWrapper}>
          <div className={styles.salesVolumeHeader}>
            <Typography.Title level={3} style={{ margin: 0 }}>
              Объем продаж
            </Typography.Title>
            <div className={styles.salesVolumeHeaderDesc}>
              <div className={styles.salesVolumeHeaderDescCircle} />
              <Typography.Text className={styles.salesVolumeHeaderText}>
                Объем продаж в ₽
              </Typography.Text>
            </div>
          </div>
          <Line
            smooth
            data={salesVolume}
            color='#1677FF'
            xField='month'
            yField='sum'
            className={styles.chart}
            tooltip={{ customContent: (_, items) => <ToolTip items={items} /> }}
          />
        </div>
        <div className={styles.moneyInfo}>
          <div className={styles.moneyInfoItem}>
            <Typography.Title level={3} style={{ marginBottom: 3 }}>
              {getNormalizedPrice(seller.balance_info.total_turnover)}
            </Typography.Title>
            <Typography.Text>Общий оборот</Typography.Text>
          </div>
          <div className={styles.moneyInfoItem}>
            <Typography.Title level={3} style={{ marginBottom: 3 }}>
              {getNormalizedPrice(seller.balance_info.withdrawn)}
            </Typography.Title>
            <Typography.Text>Выведено</Typography.Text>
          </div>
          <div style={{ border: 'none' }} className={styles.moneyInfoItem}>
            <Typography.Title level={3} style={{ marginBottom: 3 }}>
              {getNormalizedPrice(seller.balance_info.balance)}
            </Typography.Title>
            <div
              className={classNames({
                [styles.conclusionWrapper]: type === 'profile'
              })}
            >
              <Typography.Text>Доступно для вывода</Typography.Text>
              {type === 'profile' && (
                <Button
                  htmlType='button'
                  type='primary'
                  size='middle'
                  onClick={openWithdrawalRequestModal}
                  className={styles.conclusionButton}
                  style={{ marginTop: 11 }}
                >
                  Заявка на вывод
                </Button>
              )}
            </div>
          </div>
        </div>
        {type === 'seller' && (
          <>
            <ChangeBalance
              sellerId={sellerId}
              style={{ marginBottom: 24 }}
              onSuccess={onSuccessCreateTransaction}
            />
            <SellerCommission
              sellerId={sellerId}
              currentCommission={seller.commission}
              style={{ marginBottom: 33 }}
              onSuccess={changeCommission}
            />
          </>
        )}
        <TransactionsTable
          sellerId={sellerId}
          isNeedToUpdate={isNeedToUpdateTransactions}
          style={{ marginBottom: 17 }}
        />
        <MostPurchasedProductsTable sellerId={sellerId} />
      </div>
      {type === 'profile' && (
        <WithdrawalRequestModal isOpen={isOpenWithdrawalRequestModal} close={onCloseWithdrawalRequestModal} onSuccess={onSuccessWithdrawalRequestModal} />
      )}
    </>
  )
}
