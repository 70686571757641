import React from 'react'
import { ExportOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Space, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { sellerApi } from 'api/seller'
import { ECheckForFillingSellerDataError } from 'models/seller'
import { normalizeNumber } from 'shared/utils/normalizeNumber'
import { showServerError } from 'shared/utils/showServerError'
import { floatNumbersPattern } from 'shared/utils/patterns'
import { adminRoutes } from 'shared/constants/routes'
import { showMessage } from 'shared/utils/showMessage'
import styles from './WithdrawalRequestModal.module.scss'

interface IWithdrawalRequestModalProps {
  isOpen: boolean
  onSuccess: () => void
  close: () => void
}

interface IFormData {
  sum: string
}

export const WithdrawalRequestModal: React.FC<IWithdrawalRequestModalProps> = ({
  isOpen,
  onSuccess,
  close
}) => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = React.useState(false)
  const [form] = Form.useForm<IFormData>()

  const onClose = () => {
    close()
    form.resetFields()
  }

  const onSubmit = async (data: IFormData) => {
    try {
      setIsLoading(true)
      await sellerApi.withdrawalRequest({ sum: normalizeNumber(data.sum) })
      onSuccess()
      close()
      form.resetFields()
    } catch (e) {
      const error = e as AxiosError<{
        message: ECheckForFillingSellerDataError
      }>
      if (
        error.response?.data.message ===
        ECheckForFillingSellerDataError.SELLER_INFO_IS_NOT_FILLED_OUT
      ) {
        showMessage(
          'error',
          'Для вывода средств, заполните все обязательные поля "Основной информации"',
          ''
        )
        return navigate(`${adminRoutes.PROFILE}/${adminRoutes.PROFILE_INFO}`)
      }
      if (
        error.response?.data.message ===
        ECheckForFillingSellerDataError.DELIVERY_WAYS_IS_NOT_EXIST
      ) {
        showMessage(
          'error',
          'Для вывода средств, выберите способы доставки вашего магазина',
          ''
        )
        return navigate(
          `${adminRoutes.PROFILE}/${adminRoutes.PROFILE_DELIVERY}`
        )
      }
      if (
        error.response?.data.message ===
        ECheckForFillingSellerDataError.SHOP_IS_NOT_EXIST
      ) {
        showMessage(
          'error',
          'Для вывода средств, заполните название и адрес вашего магазина',
          ''
        )
        return navigate(`${adminRoutes.PROFILE}/${adminRoutes.PROFILE_SHOPS}`)
      }
      if (
        error.response?.data.message ===
        ECheckForFillingSellerDataError.CDEK_POINT_IS_NOT_EXIST
      ) {
        showMessage(
          'error',
          'Для вывода средств, выберите пункт выдачи заказа для передачи заказов клиентов',
          ''
        )
        return navigate(`${adminRoutes.PROFILE}/${adminRoutes.PROFILE_SHOPS}`)
      }
      if (
        error.response?.data.message ===
        ECheckForFillingSellerDataError.BANKING_DETAILS_IS_NOT_FILLED_OUT
      ) {
        showMessage(
          'error',
          'Для вывода средств, заполните банковские сведения',
          ''
        )
        return navigate(`${adminRoutes.PROFILE}/${adminRoutes.PROFILE_INFO}`)
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal centered open={isOpen} footer={null} onCancel={onClose} width={671}>
      <div className={styles.wrapper}>
        <ExportOutlined className={styles.icon} />
        <div>
          <Typography.Title level={5}>Заявка на вывод средств</Typography.Title>
          <Typography.Text>
            Введите сумму вывода не более <strong>ДоступноДляВывода</strong>
          </Typography.Text>
          <Form
            form={form}
            name='withdrawalRequest'
            size='large'
            onFinish={onSubmit}
            style={{ marginTop: 14 }}
          >
            <Form.Item
              name='sum'
              rules={[
                {
                  required: true,
                  message: 'Заполните сумму вывода'
                },
                {
                  whitespace: true,
                  message: 'Заполните сумму вывода'
                },
                {
                  pattern: floatNumbersPattern,
                  message: 'Укажите сумму корректно'
                }
              ]}
              hasFeedback
            >
              <Input placeholder='50000' />
            </Form.Item>
            <Space direction='horizontal' size={8}>
              <Button htmlType='button' onClick={onClose}>
                Отменить
              </Button>
              <Button htmlType='submit' type='primary' loading={isLoading}>
                Вывести
              </Button>
            </Space>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
