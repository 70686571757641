import React, { useState } from 'react'
import { Button, Checkbox, Flex, Form, Input, Select, Switch, Typography, message } from 'antd'
import { ENotificationRecipients } from 'models/seller'
import { actionsApi } from 'api/actions'
import { showServerError } from 'shared/utils/showServerError'
import { showMessage } from 'shared/utils/showMessage'
import { ENotificationType, InternalPushMessageData } from 'models/notifications'
import { IProductResponse } from 'models/product'
import { GetProductModal } from 'components/GetProductModal'
import { GetCollectionModal } from 'components/GetCollectionModal'
import { ICollection } from 'models/collections'
import styles from './Notifications.module.scss'
import { getNotificationTypeName } from './components/getNotificationTypeName'

interface IFormValues {
  sendToUsers: boolean
  sendToSellers: boolean
  title: string
  message: string
}

export const ActionsNotifications: React.FC = () => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLinkExist, setIsLinkExist] = useState(false)
  const [visibleProductModal, setVisibleProductModal] = useState(false)
  const [visibleCollectionModal, setVisibleCollectionModal] = useState(false)
  const [exteranlLink, setExternalLink] = useState<boolean | string>(false)
  const [notificationData, setNotificationData] = useState<null | InternalPushMessageData>(null)
  const choosedPath = notificationData ? notificationData.typeSlug : false

  const resetNotificationData = () => {
    form.setFieldValue('data', null)
    setNotificationData(null) 
  }

  const handleFormSubmit = async (data: IFormValues) => {
    try {
      if (!data.sendToUsers && !data.sendToSellers) {
        return showMessage('error', 'Выберите получателей уведомления', '')
      }
      setIsLoading(true)
  
      const recipients: ENotificationRecipients =
        data.sendToUsers && data.sendToSellers
          ? ENotificationRecipients.ALL
          : data.sendToUsers
            ? ENotificationRecipients.USERS
            : ENotificationRecipients.SELLERS
                        
      await actionsApi.sendNotifications({
        to: recipients,
        notificationData: {
          title: data.title.trim(),
          body: data.message.trim(),
          data: form.getFieldValue('data')
        }
      })  
      resetNotificationData()
      message.success('Уведомление успешно отправлено!')
    } catch (e) {
      showServerError(e)
    } finally {
      form.resetFields()
      setIsLoading(false)
    }
  }



  const handleLink = (exist: boolean) => {
    resetNotificationData()
    setIsLinkExist(exist)
  }

  const changeLinkVariant = (ch: boolean) => {
    resetNotificationData()
    setExternalLink(ch)
  }

  const handleTypeNotificationChange = (type: ENotificationType) => {
    form.setFieldValue('data', {type})
    setNotificationData({ type, typeId: null, typeSlug: null } as unknown as InternalPushMessageData)  
  }

  
  const onCloseProductModal = () => { setVisibleProductModal(false)}
  
  const onCloseCOllectionModal = () => { setVisibleCollectionModal(false)}
  
  
  const onChoosePath =(data: InternalPushMessageData) => {
    if (data.type === ENotificationType.PRODUCT) {
      setVisibleProductModal(true)
    }
    if (data.type === ENotificationType.COLLECTION) {
      setVisibleCollectionModal(true)
    }
  } 
  
 
  const setProductId = (products: IProductResponse[]) => {
    const data = form.getFieldValue('data')
    const product = products[0]
    const notificationData = {type: data.type, typeId: product._id, typeSlug: product.slug} as InternalPushMessageData
    form.setFieldValue('data', notificationData)
    setNotificationData(notificationData)  
  }
  
 
  const setCollectionId = (collections: ICollection[]) => {
    const data = form.getFieldValue('data')
    const collection = collections[0]
    const notificationData = {type: data.type, typeId: collection._id, typeSlug: collection.slug + '-' + collection._id} as InternalPushMessageData
    form.setFieldValue('data', notificationData)
    setNotificationData(notificationData)  
  }
  
  const isEnableSendButton = !isLinkExist || (notificationData?.type && notificationData) || typeof exteranlLink === 'string'
 
  return (
    <Form
      style={{paddingBottom: 80}}
      form={form}
      onFinish={handleFormSubmit}
      initialValues={{ sendToUsers: true, sendToSellers: true }}
    >
      <Typography.Title className={styles.title} level={2}>
        Отправка уведомлений
      </Typography.Title>
      <Form.Item
        name='sendToUsers'
        className={styles.boxBorder}
        valuePropName='checked'
      >
        <Checkbox>Всем пользователям</Checkbox>
      </Form.Item>
      <Form.Item
        name='sendToSellers'
        className={styles.boxBorder}
        valuePropName='checked'
      >
        <Checkbox>Всем продавцам</Checkbox>
      </Form.Item>
      <Typography.Title level={4} className={styles.descriptionTitle}>
        Описание уведомлений:
      </Typography.Title>
      <Form.Item
        name='title'
        style={{ marginBottom: 10 }}
        rules={[
          {
            required: true,
            message: 'Заполните заголовок уведомления'
          },
          {
            whitespace: true,
            message: 'Заполните заголовок уведомления'
          }
        ]}
      >
        <Input placeholder='Заголовок уведомления' size='large' />
      </Form.Item>
      <Form.Item
        name='message'
        style={{ marginBottom: 15 }}
        rules={[
          {
            required: true,
            message: 'Заполните текст уведомления'
          },
          {
            whitespace: true,
            message: 'Заполните текст уведомления'
          }
        ]}
      >
        <Input.TextArea
          placeholder='Текст уведомления'
          size='large'
        />
      </Form.Item>


      <Flex style={{paddingBottom: 40, display: 'flex', paddingTop: 20, gap: 16, flexDirection: 'column'}}>
        <div>
          <Switch style={{marginRight: 12}} onChange={handleLink} value={isLinkExist} />Ссылка:
        </div>
        { isLinkExist && 
          <>
            <div><Switch style={{marginRight: 12}} onChange={changeLinkVariant}  />Внешняя ссылка</div>
            {
              typeof exteranlLink === 'string' || exteranlLink ? 
                <Form.Item name={['data', 'url']} style={{ marginBottom: 20 }}>
                  <Input placeholder='URL (ссылка)' onChange={e=> {
                    const value = e.target.value
                    if (value.length) {setExternalLink(value)} else {setExternalLink(true)}
                  }} size='large' />
                </Form.Item>
                : 
                <Flex gap={20} style={{ marginBottom: 20, marginTop: 10, alignItems: 'center' }}>

                  <Form.Item name={['data', 'type']} style={{marginBottom: 0 }}>
                    <Select
                      placeholder='На что ссылка'
                      style={{ width: 200 }}
                      onChange={handleTypeNotificationChange}
                      options={[
                        { value: ENotificationType.COLLECTION, label: getNotificationTypeName(ENotificationType.COLLECTION) },
                        { value: ENotificationType.PRODUCT, label: getNotificationTypeName(ENotificationType.PRODUCT) }
                      ]}
                    />
                  </Form.Item>
                  <Button color='primary' disabled={!notificationData} onClick={()=> onChoosePath(form.getFieldValue('data'))} >{choosedPath ? 'Изменить путь': 'Выбрать путь'}</Button>
                  <Form.Item name={['data', 'typeId']} style={{marginBottom: 0, minWidth: 300  }}>
                    <Input 
                      disabled
                      variant='borderless' 
                      placeholder={choosedPath || 'Путь не выбран'} 
                      type={choosedPath? 'success' : 'danger'} 
                      size='large' />
                  </Form.Item>
                </Flex>
            }
          </>
        }
      </Flex>

      <Button type='primary' htmlType='submit' disabled={!isEnableSendButton} size='large' loading={isLoading}>
        Отправить
      </Button>
      <GetProductModal
        multiple={false}
        onSave={setProductId}
        visible={visibleProductModal}
        onClose={onCloseProductModal} />
      <GetCollectionModal
        multiple={false}
        onSave={setCollectionId}
        visible={visibleCollectionModal}
        onClose={onCloseCOllectionModal} />
    </Form>
  )
}
