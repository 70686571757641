import { privateApi } from 'api'
import { AxiosResponse } from 'axios'
import {
  IAdminAddRefundTransactionRequest,
  IAdminCreateSellerTransactionRequest,
  IAdminTransactionsRequest,
  IAdminTransactionsResponse
} from 'models/transaction'

export const transactionsApi = {
  getTransactions({
    skip,
    limit,
    order_id,
    seller_id,
    type,
    sum,
    from,
    to
  }: IAdminTransactionsRequest): Promise<
    AxiosResponse<IAdminTransactionsResponse>
  > {
    return privateApi.get<IAdminTransactionsResponse>(
      `admin/transactions?skip=${skip}&limit=${limit}&order_id=${
        order_id ?? ''
      }&seller_id=${seller_id ?? ''}&type=${type ?? ''}&sum=${sum ?? ''}&from=${
        from ?? ''
      }&to=${to ?? ''}`
    )
  },
  createTransaction(sellerId: string, data: IAdminCreateSellerTransactionRequest): Promise<AxiosResponse> {
    return privateApi.post(`admin/transactions/${sellerId}`, data)
  },
  createRefundOrderTransaction(orderId: string, data: IAdminAddRefundTransactionRequest): Promise<AxiosResponse> {
    return privateApi.post(`admin/order/add/transaction/${orderId}`, data)
  }
}
