// SignUp
export interface ITokensResponse extends Components.Schemas.ITokensResponse {}
export interface ISignUpRequest extends Components.Schemas.ISignUpRequest {}
export interface ISignUpResponse extends Components.Schemas.ISignUpResponse {}
export interface ISignUpAppleRequest extends Components.Schemas.ISignUpAppleRequest {}
export interface ISignUpAppleResponse extends Components.Schemas.ISignUpAppleResponse {}
export interface ISignUpGoogleRequest extends Components.Schemas.ISignUpGoogleRequest {}
export interface ISignUpGoogleResponse extends Components.Schemas.ISignUpGoogleResponse {}
export interface ISignUpVkRequest extends Components.Schemas.ISignUpVkRequest {}
export interface ISignUpVkResponse extends Components.Schemas.ISignUpVkResponse {}
export interface IGenerateTokenRequest extends Components.Schemas.GenerateTokenRequestDto {}
export interface IGenerateTokenResponse extends Components.Schemas.GenerateTokenResponseDto {}
export interface IActivationRequest
  extends Components.Schemas.IActivationRequest {}
export interface ICreateSellerRequest
  extends Components.Schemas.ICreateSellerRequest {}
export interface ICreatePhysicalSellerRequest
  extends Components.Schemas.ICreatePhysicalSellerRequest {}

// SignIn
export interface ISignInRequest extends Components.Schemas.ILoginRequest {}
export interface ISignInResponse extends Components.Schemas.ILoginResponse {}
export interface IRefreshResponse extends Components.Schemas.IRefreshResponse {}
export enum ELoginTypes {
  SELLER = 'SELLER',
  USER = 'USER'
}

// Recovery Password
export interface ISendEmailRequest extends Components.Schemas.ISendEmailRequest {}
export interface IRecoveryRequest extends Components.Schemas.IRecoveryRequest {}

// User
export enum ERoles {
  ADMIN = 'ADMIN',
  USER = 'USER'
}
export interface ITokenParseUser {
  _id: string
  email: string
  role: ERoles
  isActivated: boolean
  seller_id?: string
  exp: number
  iat: number
}

// Checking
export interface ICheckSellerDataForCorrectness extends Components.Schemas.ICheckSellerDataForCorrectness {}
export enum ECheckSellerDataErrors {
  'INVALID_PASSPORT' = 'INVALID_PASSPORT',
  'INCORRECT_INN' = 'INCORRECT_INN',
  'INN_NOT_FOUND' = 'INN_NOT_FOUND',
}
