import { AxiosError } from 'axios'
import { showMessage } from './showMessage'

export const showServerError = (e?: unknown) => {
  if (e) {
    const error = e as AxiosError<{ message: string }>
    if (error.status === 401 || error.status === 403) return
    return showMessage(
      'error',
      error.response?.data?.message ?? 'Ошибка на сервере',
      error.response?.data?.message
        ? ''
        : 'Попробуйте отправить запрос еще раз, либо немного позднее'
    )
  }
  return showMessage(
    'error',
    'Ошибка на сервере',
    'Попробуйте отправить запрос еще раз, либо немного позднее'
  )
}
