export const phoneMaskStatic = (value: string | number) => {
  let formattedValue = ''
  let cleanedValue = String(value).replace(/\D/g, '')

  if (cleanedValue[0] === '9') {
    cleanedValue = '7' + cleanedValue
  }

  const firstSymbols = '+7'
  formattedValue = firstSymbols + ' '

  if (cleanedValue.length > 1) {
    formattedValue += '(' + cleanedValue.substring(1, 4)
  }
  if (cleanedValue.length >= 5) {
    formattedValue += ') ' + cleanedValue.substring(4, 7)
  }
  if (cleanedValue.length >= 8) {
    formattedValue += '-' + cleanedValue.substring(7, 9)
  }
  if (cleanedValue.length >= 10) {
    formattedValue += '-' + cleanedValue.substring(9, 11)
  }

  return formattedValue
}
