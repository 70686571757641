import React from 'react'
import { Menu, MenuProps } from 'antd'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import { sellerApi } from 'api/seller'
import { showMessage } from 'shared/utils/showMessage'
import { AxiosError } from 'axios'
import { adminRoutes } from 'shared/constants/routes'
import { SUPPORT_URL } from 'shared/constants/urls'
import styles from './SellerWrapper.module.scss'

interface IAnchorsWrapperProps {
  type: 'seller' | 'profile'
  links: MenuProps['items']
}

export const SellerWrapper: React.FC<IAnchorsWrapperProps> = ({
  type,
  links
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()
  const [sellerNumberId, setSellerNumberId] = React.useState<null | number>(null)
  const [current, setCurrent] = React.useState('')

  React.useEffect(() => {
    if (type === 'seller') {
      const getSeller = async () => {
        try {
          const { data } = await sellerApi.getSeller(id as string)
          setSellerNumberId(data.number)
        } catch (e) {
          const error = e as AxiosError
          if (error.response?.status === 500 || error.response?.status === 400 || error.response?.status === 404) {
            return
          }
          showMessage('error', 'Не удалось получить ID продавца', '')
        }
      }
      getSeller()
    }
  }, [])

  React.useEffect(() => {
    if (type === 'seller') {
      return setCurrent(location.pathname.split('/')[3] ?? '')
    }
    setCurrent(location.pathname.split('/')[2])
  }, [location.pathname])

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === adminRoutes.PROFILE_SUPPORT) {
      return window?.open(SUPPORT_URL, '_blank')?.focus()
    }
    setCurrent(e.key)
    navigate(e.key)
  }

  return (
    <div>
      {type === 'seller' && (
        <MainTitle
          isGoBack
          text={`Продавец ID ${!!sellerNumberId ? sellerNumberId : ''}`}
          onGoBack={ () => navigate(-1)}
        />
      )}
      <Menu
        mode='inline'
        items={links}
        selectedKeys={[current]}
        onClick={onClick}
        className={classNames('side-menu', styles.menu)}
      />
      <div className={classNames('side-content', styles.content)}>
        <Outlet />
      </div>
    </div>
  )
}
