import { Tag } from 'antd'
import { ERoles } from 'models/auth'
import { EOrderStatus, ERecieveMethod } from 'models/orders'

interface IOrderStatusItemData {
  key: EOrderStatus,
  label: React.ReactNode
}

export const orderTags = {
  [EOrderStatus.ON_CONFIRMATION]: {
    value: EOrderStatus.ON_CONFIRMATION,
    label: 'На подтверждении',
    color: 'purple'
  },
  [EOrderStatus.CONFIRMED_BY_SELLER]: {
    value: EOrderStatus.CONFIRMED_BY_SELLER,
    label: 'Принят',
    color: 'orange'
  },
  [EOrderStatus.CANCELED]: {
    value: EOrderStatus.CANCELED,
    label: 'Отменен',
    color: 'red'
  },
  [EOrderStatus.ARCHIVED]: {
    value: EOrderStatus.ARCHIVED,
    label: 'Удален',
    color: 'red'
  },
  [EOrderStatus.IN_DELIVERY]: {
    value: EOrderStatus.IN_DELIVERY,
    label: 'В доставке',
    color: 'geekblue'
  },
  [EOrderStatus.WAITING_IN_POINT]: {
    value: EOrderStatus.WAITING_IN_POINT,
    label: 'Ожидает',
    color: 'volcano'
  },
  [EOrderStatus.FINISHED]: {
    value: EOrderStatus.FINISHED,
    label: 'Завершен',
    color: 'green'
  },
  [EOrderStatus.NOT_PAID]: {
    value: EOrderStatus.NOT_PAID,
    label: 'Не оплачен',
    color: 'cyan'
  }
}

const canceledStatus: IOrderStatusItemData = {
  key: EOrderStatus.CANCELED,
  label: (
    <Tag color={orderTags[EOrderStatus.CANCELED].color}>
      {orderTags[EOrderStatus.CANCELED].label}
    </Tag>
  )
}

const confirmedStatus: IOrderStatusItemData = {
  key: EOrderStatus.CONFIRMED_BY_SELLER,
  label: (
    <Tag color={orderTags[EOrderStatus.CONFIRMED_BY_SELLER].color}>
      {orderTags[EOrderStatus.CONFIRMED_BY_SELLER].label}
    </Tag>
  )
}

const inDeliveryStatus: IOrderStatusItemData = {
  key: EOrderStatus.IN_DELIVERY,
  label: (
    <Tag color={orderTags[EOrderStatus.IN_DELIVERY].color}>
      {orderTags[EOrderStatus.IN_DELIVERY].label}
    </Tag>
  )
}

const waitingInPointStatus: IOrderStatusItemData = {
  key: EOrderStatus.WAITING_IN_POINT,
  label: (
    <Tag color={orderTags[EOrderStatus.WAITING_IN_POINT].color}>
      {orderTags[EOrderStatus.WAITING_IN_POINT].label}
    </Tag>
  )
}

const finishedStatus: IOrderStatusItemData = {
  key: EOrderStatus.FINISHED,
  label: (
    <Tag color={orderTags[EOrderStatus.FINISHED].color}>
      {orderTags[EOrderStatus.FINISHED].label}
    </Tag>
  )
}

export const getOrderStatusItems = (status: EOrderStatus, role: ERoles, deliveryType: ERecieveMethod): IOrderStatusItemData[] => {

  if (status === EOrderStatus.ON_CONFIRMATION) {
    return [
      confirmedStatus,
      canceledStatus
    ]
  }

  if (status === EOrderStatus.CONFIRMED_BY_SELLER) {
    if (deliveryType === ERecieveMethod.PICKUP_POINT) {
      if (role === ERoles.ADMIN) {
        return [
          waitingInPointStatus,
          canceledStatus
        ]
      }
      return [waitingInPointStatus]
    }
    if (role === ERoles.ADMIN) {
      return [
        inDeliveryStatus,
        canceledStatus
      ]
    }
    return []
  }

  if (status === EOrderStatus.IN_DELIVERY) {
    if (deliveryType === ERecieveMethod.COURIER) {
      if (role === ERoles.ADMIN) {
        return [
          finishedStatus,
          canceledStatus
        ]
      }
      return []
    }
    if (role === ERoles.ADMIN) {
      return [
        waitingInPointStatus,
        canceledStatus
      ]
    }
    return []
  }

  if (status === EOrderStatus.WAITING_IN_POINT) {
    if (role === ERoles.ADMIN) {
      return [
        finishedStatus,
        canceledStatus
      ]
    }
    if (deliveryType === ERecieveMethod.PICKUP_POINT) {
      return [finishedStatus]
    }
    return []
  }

  if (status === EOrderStatus.FINISHED) {
    if (role === ERoles.ADMIN) {
      return [canceledStatus]
    }
    return []
  }

  return []
}