import { InboxOutlined } from '@ant-design/icons'
import { Form, Image, Upload, UploadFile } from 'antd'
import classNames from 'classnames'
import { Rule } from 'antd/es/form'
import removePhoto from 'assets/icons/remove-photo.svg'
import { showMessage } from 'shared/utils/showMessage'
import { normalizeFile } from 'shared/utils/normalizeFile'
import styles from './Uploader.module.scss'

interface IUploaderProps {
  name: string
  rules: Rule[]
  disabled?: boolean
  multiple?: boolean
  maxCount?: number
  type?: 'default' | 'variation'
  onRemoveClick?: (file: UploadFile) => void
}

export const Uploader: React.FC<IUploaderProps> = ({
  name,
  rules,
  disabled = false,
  multiple = false,
  maxCount = 1,
  type = 'default',
  onRemoveClick = () => false
}) => {
  const isTypeDefault = type === 'default'
  return (
    <Form.Item
      name={name}
      valuePropName='fileList'
      getValueFromEvent={normalizeFile}
      rules={rules}
      style={{ marginBottom: isTypeDefault ? 24 : 10 }}
      hasFeedback
    >
      <Upload.Dragger
        multiple={multiple}
        maxCount={maxCount}
        disabled={disabled}
        accept='image/png, image/jpeg, image/jpg'
        listType='picture'
        beforeUpload={(file) => {
          const isValid =
            file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg'
          if (!isValid) {
            showMessage(
              'error',
              'Поддерживаются только форматы JPG, JPEG, PNG',
              ''
            )
          }
          return isValid || Upload.LIST_IGNORE
        }}
        customRequest={() => false}
        itemRender={(_, file, fileList, { remove }) => {
          return (
            <div className={styles.photoWrapper}>
              <Image
                src={file.thumbUrl}
                preview={!file.originFileObj}
                alt='Изображение'
                width={isTypeDefault ? 154 : undefined}
                height={isTypeDefault ? 150 : undefined}
                className={isTypeDefault ? styles.photo : undefined}
                style={{ maxHeight: isTypeDefault ? undefined : 280 }}
              />
              <button
                type='button'
                className={classNames('reset-button', styles.removePhoto)}
                onClick={() => {
                  onRemoveClick(file)
                  remove()
                }}
              >
                <img src={removePhoto} alt='Удалить изображение' />
              </button>
            </div>
          )
        }}
      >
        <div className={styles.descriptionWrapper}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            Нажмите или перетащите файл в эту область, чтобы загрузить
          </p>
          <p className='ant-upload-hint'>
            Поддерживается одиночная {multiple && 'и массовая'} загрузка.
          </p>
        </div>
      </Upload.Dragger>
    </Form.Item>
  )
}
