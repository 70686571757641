import { Button, Typography } from 'antd'
import {  Space } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'

interface Props {
  onArchive: ()=> void
  onPublish: ()=> void
  isSubmitting: boolean
  isCreate: boolean
}

export const HeaderEdit = ({onArchive, onPublish, isCreate, isSubmitting}: Props) => {
  const title = isCreate ? 'Создать подборку': 'Редактировать подборку'
  return (
    <div style={{ paddingBottom: 32, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography.Title style={{ margin: 0 }}>{title}</Typography.Title>
      <Space size='middle'>
        <Button type='primary' danger disabled={isSubmitting} icon={<DeleteOutlined />} onClick={onArchive}>
          Архив
        </Button>
        <Button disabled={isSubmitting} type='primary' icon={<SaveOutlined />} onClick={onPublish}>
          Опубликовать
        </Button>
      </Space>
    </div>
  )
}
