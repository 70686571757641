import React from 'react'
import { Menu, MenuProps, Modal } from 'antd'
import {
  ExclamationCircleOutlined,
  FolderOpenOutlined,
  LogoutOutlined,
  ReadOutlined,
  StockOutlined
} from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'shared/hooks/useReduxHooks'
import { authApi } from 'api/auth'
import { appActions } from 'store/app'
import { showServerError } from 'shared/utils/showServerError'
import { adminRoutes } from 'shared/constants/routes'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { MobileMenu } from '../MobileMenu/MobileMenu'

const items: MenuProps['items'] = [
  {
    label: 'Мои товары',
    key: adminRoutes.GOODS,
    icon: <FolderOpenOutlined />
  },
  {
    label: 'Мои заказы',
    key: adminRoutes.ORDERS,
    icon: <StockOutlined />
  },
  {
    label: 'Профиль',
    key: adminRoutes.PROFILE,
    icon: <ReadOutlined />
  },
  {
    label: 'Выйти',
    key: 'logout',
    icon: <LogoutOutlined />
  }
]

export const AdminMenu: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [current, setCurrent] = React.useState('')
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.outerWidth
  )
  const [isOpenMobileMenu, setIsOpenMobileMenu] = React.useState(false)

  React.useEffect(() => {
    setCurrent(`/${location.pathname.split('/')[1]}`)
  }, [location.pathname])

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.outerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const logout = async () => {
    try {
      await authApi.logout()
      dispatch(appActions.logout())
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const showLogoutModal = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Вы действительно хотите выйти из аккаунта?',
      content: 'Отменить данное действие будет невозможно.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Выйти',
      cancelText: 'Закрыть',
      onOk() {
        return new Promise((resolve) => {
          logout().then(resolve)
        })
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const onClick: MenuProps['onClick'] = (e) => {
    setIsOpenMobileMenu(false)
    if (e.key === 'logout') {
      return showLogoutModal()
    }
    setCurrent(e.key)
    navigate(e.key)
  }

  return (
    <>
      {windowWidth &&
        (windowWidth >= 768 ? (
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode='horizontal'
            items={items}
            disabledOverflow
          />
        ) : (
          <MobileMenu
            isOpen={isOpenMobileMenu}
            setIsOpen={setIsOpenMobileMenu}
            Menu={
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode='vertical'
                items={items}
                style={{ borderInlineEnd: 'none' }}
              />
            }
          />
        ))}
    </>
  )
}
