import React from 'react'
import { Button, Form, Input, Select, message, Collapse } from 'antd'
import {
  ESubCategoryStatus,
  IFrontCategory,
  IFrontSubCategory,
  ISubCategory
} from 'models/product'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { appSelectors } from 'store/app'
import { productApi } from 'api/product'
import { showServerError } from 'shared/utils/showServerError'
import { ChangeDataWrapper } from '../ChangeDataWrapper/ChangeDataWrapper'

import styles from './ChangeSubCategory.module.scss'

interface IChangeSubCategoryProps {
  currentSubCategory: IFrontSubCategory
  categories: IFrontCategory[]
  onSuccess: (updatedSubCategory: ISubCategory) => void
  onDeleteSuccess: (subCategoryId: string) => void
}

interface IFormData {
  name: string
  categoryId: string
  title?: string
  desc?: string
  // h1?: string
  seoText?: string
}

export const ChangeSubCategory: React.FC<IChangeSubCategoryProps> = React.memo(
  ({ currentSubCategory, categories, onSuccess, onDeleteSuccess }) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [isDeleting, setIsDeleting] = React.useState(false)
    const [form] = Form.useForm()

    const role = useAppSelector(appSelectors.selectRole)
    const { Panel } = Collapse

    const isOnModeration =
      currentSubCategory.status === ESubCategoryStatus.MODERATION

    const normalizedCategories: IFrontCategory[] = React.useMemo(() => {
      return categories.filter((item) => !item.isNew)
    }, [categories])

    const onSubmit = async (data: IFormData) => {
      try {
        setIsLoading(true)
        const commonData = {
          name: data.name,
          category_id: data.categoryId,
          title: data?.title,
          desc: data?.desc,
          // h1: data?.h1,
          seoText: data?.seoText
        }
        if (currentSubCategory.isNew) {
          const response = await productApi.createSubCategory(commonData)
          onSuccess(response.data)
          message.success('Подкатегория успешно добавлена')
        } else {
          const response = await productApi.updateSubCategory(
            currentSubCategory._id,
            commonData
          )
          onSuccess(response.data)

          message.success('Подкатегория успешно обновлена')
        }
      } catch (e) {
        showServerError(e)
      } finally {
        setIsLoading(false)
      }
    }

    const onDelete = async () => {
      try {
        setIsDeleting(true)
        if (!currentSubCategory.isNew) {
          await productApi.deleteSubCategory(currentSubCategory._id)
        }
        onDeleteSuccess(currentSubCategory._id)
      } catch (e) {
        showServerError(e)
      } finally {
        setIsDeleting(false)
      }
    }

    React.useEffect(() => {
      form.resetFields()

      const fieldsToSet = [
        { name: 'name', value: currentSubCategory.name },
        { name: 'title', value: currentSubCategory?.seoInfo?.title },
        { name: 'desc', value: currentSubCategory?.seoInfo?.desc },
        { name: 'categoryId', value: currentSubCategory?.category_id?._id },
        // { name: 'h1', value: currentSubCategory?.seoInfo?.h1 },
        { name: 'seoText', value: currentSubCategory?.seoInfo?.seoText }
      ]

      form.setFields(fieldsToSet)
    }, [currentSubCategory])

    return (
      <ChangeDataWrapper
        title={isOnModeration ? 'Подкатегория (подтверждение)' : 'Подкатегория'}
        isOnModeration={isOnModeration}
      >
        <Form
          form={form}
          name='changeSubCategory'
          size='small'
          onFinish={onSubmit}
        >
          <Form.Item
            label='Название подкатегории'
            name='name'
            rules={[
              {
                required: true,
                message: 'Введите название'
              },
              {
                whitespace: true,
                message: 'Введите название'
              }
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='categoryId'
            label='Основная категория'
            rules={[
              {
                required: true,
                message: 'Выберите категорию'
              }
            ]}
            hasFeedback
          >
            <Select
              options={normalizedCategories.map((item) => ({
                value: item._id,
                label: item.name
              }))}
            />
          </Form.Item>
          {role === 'ADMIN' && (
            <Collapse defaultActiveKey={['1']}>
              <Panel header='SEO' key='1' className={styles.panelHeader}>
                <Form.Item
                  label='Title'
                  name='title'
                  hasFeedback
                  style={{ marginBottom: '10px' }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  labelAlign='left'
                  rules={[
                    {
                      whitespace: true,
                      message: 'Введите что-то кроме пробелов'
                    }
                  ]}
                >
                  <Input className={styles.formInput} />
                </Form.Item>

                <Form.Item
                  label='Desc'
                  name='desc'
                  hasFeedback
                  style={{ marginBottom: '10px' }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  labelAlign='left'
                  rules={[
                    {
                      whitespace: true,
                      message: 'Введите что-то кроме пробелов'
                    }
                  ]}
                >
                  <Input.TextArea className={styles.formTextarea} />
                </Form.Item>

                {/* <Form.Item */}
                {/* //  label='H1'
                //   name='h1'
                //   hasFeedback
                //   style={{ marginBottom: '10px' }}
                //   labelCol={{ span: 4 }}
                //   wrapperCol={{ span: 20 }}
                //   labelAlign='left'
                //   rules={[
                //     {
                //       whitespace: true,
                //       message: 'Введите что-то кроме пробелов'
                //     }
                //   ]}
                // >
                  // <Input className={styles.formInput} />
                // </Form.Item> */}

                <Form.Item
                  label='SEO text'
                  name='seoText'
                  hasFeedback
                  style={{ marginBottom: '10px' }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  labelAlign='left'
                  rules={[
                    {
                      whitespace: true,
                      message: 'Введите что-то кроме пробелов'
                    }
                  ]}
                >
                  <Input.TextArea className={styles.formTextarea} />
                </Form.Item>
              </Panel>
            </Collapse>
          )}
          <div className={styles.footer}>
            <Button
              htmlType='submit'
              type='primary'
              size='middle'
              loading={isLoading}
            >
              {isOnModeration
                ? 'Подтвердить и сохранить'
                : 'Сохранить изменения'}
            </Button>
            <Button
              danger
              htmlType='button'
              type='primary'
              size='middle'
              loading={isDeleting}
              onClick={onDelete}
            >
              Удалить
            </Button>
          </div>
        </Form>
      </ChangeDataWrapper>
    )
  }
)
