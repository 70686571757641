import { privateApi } from 'api'
import { AxiosResponse } from 'axios'
import {
  IActionsProductRequest,
  IActionsProductResponse,
  ICategory,
  ICategoryQueryRequest,
  ICreateCategoryRequest,
  ICreateProduct,
  ICreateProductVariation,
  ICreateSubCategoryRequest,
  IParsedCategory,
  IProduct,
  IProductQueryRequest,
  IProductQueryResponse,
  IProductResponse,
  ISubCategory,
  ITopProductSalesQueryRequest,
  ITopProductSalesResponse,
  IUpdateCategoryRequest,
  IUpdateProduct,
  IUpdateSubCategoryRequest
} from 'models/product'
import { getNormalizedFormData } from 'shared/utils/getNormalizedFormData'

export const productApi = {
  getProducts({
    skip,
    limit,
    all_fields,
    name,
    seller_id,
    category_id,
    subCategory_id,
    status,
    type,
    isHaveParent,
    excludeProductId
  }: IProductQueryRequest): Promise<AxiosResponse<IProductQueryResponse>> {
    return privateApi.get<IProductQueryResponse>(
      `admin/products?skip=${skip}&limit=${limit}&all_fields=${
        all_fields ?? ''
      }&name=${name ?? ''}&seller_id=${seller_id ?? ''}&category_id=${
        category_id ?? ''
      }&subCategory_id=${subCategory_id ?? ''}&status=${status ?? ''}&type=${type ?? ''}&isHaveParent=${isHaveParent ?? ''}&excludeProductId=${excludeProductId ?? ''}`
    )
  },
  getProduct(id: string): Promise<AxiosResponse<IProductResponse>> {
    return privateApi.get<IProductResponse>(`admin/products/${id}`)
  },
  setActions({
    toCopy,
    toDelete,
    toDraft,
    toPublish,
    toReject
  }: IActionsProductRequest): Promise<AxiosResponse<IActionsProductResponse>> {
    return privateApi.put(
      `admin/products?toCopy=${toCopy ?? ''}&toDelete=${
        toDelete ?? ''
      }&toDraft=${toDraft ?? ''}&toPublish=${toPublish ?? ''}&toReject=${
        toReject ?? ''
      }`
    )
  },
  getCategories({
    name,
    parsed
  }: ICategoryQueryRequest): Promise<
    AxiosResponse<IParsedCategory[] | ICategory[]>
  > {
    return privateApi.get(`admin/category?parsed=${parsed}&name=${name ?? ''}`)
  },
  createCategory(
    data: ICreateCategoryRequest
  ): Promise<AxiosResponse<ICategory>> {
    const formData = getNormalizedFormData(data)
    return privateApi.post<ICategory>('admin/category', formData)
  },
  updateCategory(
    categoryId: string,
    data: IUpdateCategoryRequest
  ): Promise<AxiosResponse<ICategory>> {
    const formData = getNormalizedFormData(data)
    return privateApi.patch<ICategory>(`admin/category/${categoryId}`, formData)
  },
  getSubCategoriesByCategory(
    categoryId: string
  ): Promise<AxiosResponse<ISubCategory[]>> {
    return privateApi.get<ISubCategory[]>(`/admin/subCategory/${categoryId}`)
  },
  createSubCategory(
    data: ICreateSubCategoryRequest
  ): Promise<AxiosResponse<ISubCategory>> {
    return privateApi.post<ISubCategory>('admin/subCategory', data)
  },
  deleteSubCategory(
    subCategoryId: string
  ): Promise<AxiosResponse> {
    return privateApi.delete(`admin/subCategory/${subCategoryId}`)
  },
  updateSubCategory(
    subCategoryId: string,
    data: IUpdateSubCategoryRequest
  ): Promise<AxiosResponse<ISubCategory>> {
    return privateApi.patch<ISubCategory>(
      `admin/subCategory/${subCategoryId}`,
      data
    )
  },
  addProduct(data: ICreateProduct): Promise<AxiosResponse> {
    const formData = getNormalizedFormData(data)
    return privateApi.post('admin/products', formData)
  },
  updateProduct(data: IUpdateProduct): Promise<AxiosResponse<IProduct>> {
    const formData = getNormalizedFormData(data)
    return privateApi.patch<IProduct>('admin/products', formData)
  },
  createProductVariation(data: ICreateProductVariation): Promise<AxiosResponse<IProduct>> {
    const formData = getNormalizedFormData(data)
    return privateApi.post<IProduct>('admin/products/variation', formData)
  },
  getMostPurchasedProducts({
    skip,
    limit,
    seller_id
  }: ITopProductSalesQueryRequest): Promise<
    AxiosResponse<ITopProductSalesResponse>
  > {
    return privateApi.get<ITopProductSalesResponse>(
      `admin/seller/products/top-sales?skip=${skip}&limit=${limit}&seller_id=${seller_id}`
    )
  }
}
