import dayjs from 'dayjs'
import { ICreatePhysicalSellerRequest, ICreateSellerRequest } from 'models/auth'
import { ESellerTypes, ISellerCreateAuthForm } from 'models/seller'
import { normalizePhone } from 'shared/utils/normalizePhone'

export const getSubmitData = (
  data: ISellerCreateAuthForm
): ICreateSellerRequest | ICreatePhysicalSellerRequest => {
  if (data.type === ESellerTypes.LEGAL) {
    return {
      type: data.type,
      name: data.firstName.trim(),
      surname: data.lastName.trim(),
      patronymic: data.middleName?.trim(),
      opt_otch: data.noMiddleName,
      tel: normalizePhone(data.phone),
      shop_name: data.shopName.trim(),
      INN: data.legalINN as string
    }
  }

  return {
    type: data.type,
    name: data.firstName.trim(),
    surname: data.lastName.trim(),
    patronymic: data.middleName?.trim(),
    opt_otch: data.noMiddleName,
    tel: normalizePhone(data.phone),
    shop_name: data.shopName.trim(),
    passport_details: data.passportData as string,
    passport_photo: data.passportPhoto[0]?.originFileObj,
    birthday: dayjs(data.birthDate).format('YYYY-MM-DDTHH:mm:ss'),
    INN: data.physicalINN as string
  }
}
