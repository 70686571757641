import { Alert, Typography } from 'antd'
import { ECheckSellerDataTypes } from 'models/seller'
import React from 'react'
import { SUPPORT_URL } from 'shared/constants/urls'

interface InfoAlertProps {
  isVisible: boolean
  type: ECheckSellerDataTypes | null
  onClose: () => void
}

const viewData = {
  [ECheckSellerDataTypes.SUCCESS]: {
    title: 'Ваши данные подтверждены',
    description: (
      <Typography.Text>
        Введенные данные прошли проверку, вы можете подтвердить отправку заявки
        на регистрацию продавца, нажав кнопку «Отправить заявку».
      </Typography.Text>
    )
  },
  [ECheckSellerDataTypes.ERROR_LEGAL_INN]: {
    title: 'Организация не действительна',
    description: (
      <Typography.Text>
        Ваша организация ликвидирована или признана банкротом. Укажите другую
        организацию. Если вы уверены, что это ошибка, свяжитесь со{' '}
        <Typography.Link
          href={SUPPORT_URL}
          target='_blank'
          style={{ textDecoration: 'underline' }}
        >
          службой поддержки.
        </Typography.Link>
      </Typography.Text>
    )
  },
  [ECheckSellerDataTypes.INN_NOT_FOUND]: {
    title: 'Данные не верны',
    description: (
      <Typography.Text>
        Не удалось подтвердить ваши паспортные данные, проверьте правильность
        введенных данных и попробуйте еще раз. Если вы уверены, что данные
        введены верно, свяжитесь со{' '}
        <Typography.Link
          href={SUPPORT_URL}
          target='_blank'
          style={{ textDecoration: 'underline' }}
        >
          службой поддержки.
        </Typography.Link>
      </Typography.Text>
    )
  },
  [ECheckSellerDataTypes.INVALID_PASSPORT]: {
    title: 'Паспорт не действительный',
    description: (
      <Typography.Text>
        Паспорт является не действительным. Проверьте правильность введенных
        данных и попробуйте еще раз. Если вы уверены, что данные введены верно,
        свяжитесь со{' '}
        <Typography.Link
          href={SUPPORT_URL}
          target='_blank'
          style={{ textDecoration: 'underline' }}
        >
          службой поддержки.
        </Typography.Link>
      </Typography.Text>
    )
  },
  [ECheckSellerDataTypes.INCORRECT_INN]: {
    title: 'Не верный ИНН',
    description: (
      <Typography.Text>
        Введенный ИНН не совпадает с паспортными данными. Вы можете узнать свой
        ИНН в приложении Госуслуги или{' '}
        <Typography.Link
          href='https://service.nalog.ru/inn.do'
          target='_blank'
          style={{ textDecoration: 'underline' }}
        >
          на сайте ФНС
        </Typography.Link>
      </Typography.Text>
    )
  }
}

export const InfoAlert: React.FC<InfoAlertProps> = React.memo(
  ({ isVisible, type, onClose }) => {
    if (isVisible && type) {
      return (
        <Alert
          message={
            <Typography.Title
              level={5}
              style={{ fontWeight: 700 }}
            >
              {viewData[type].title}
            </Typography.Title>
          }
          description={
            <Typography.Text>{viewData[type].description}</Typography.Text>
          }
          type={type === ECheckSellerDataTypes.SUCCESS ? 'success' : 'error'}
          showIcon
          closable
          style={{ marginBottom: 22 }}
          onClose={onClose}
        />
      )
    }

    return null
  }
)
