export interface ISellersQueryRequest extends Components.Schemas.IAdminSellersQueryRequest { }
export interface ISellersQueryResponse extends Components.Schemas.IAdminSellersQueryResponse { }
export interface ISellersQueryFilters extends Omit<ISellersQueryRequest, 'skip' | 'limit'> { }
export interface IActionsSellersRequest extends Components.Schemas.IActionsSellersRequest { }
export interface IAdminCreateSellerRequest extends Components.Schemas.IAdminCreateSellerRequest { }
export interface IAdminCreatePhysicalSellerRequest extends Components.Schemas.IAdminCreatePhysicalSellerRequest { }
export interface IAdminUpdateSellerRequest extends Components.Schemas.IUpdateSellerRequest { }
export interface ISeller extends Components.Schemas.ISeller { }
export interface IShop extends Components.Schemas.IShop { }
export interface IShopDetails extends Components.Schemas.IShopDetails { }
export interface ICreateSellerShopRequest extends Components.Schemas.ICreateSellerShopRequest { }
export interface IDeliveryWays extends Components.Schemas.IDeliveryWays { }
export interface IAdminUpdateDeliveryWaysRequest extends Components.Schemas.IAdminUpdateDeliveryWaysRequest { }
export interface INearestPointOfCdek extends Components.Schemas.INearestPointOfCdek { }
export interface IUpdateNearestPointOfCdekRequest extends Components.Schemas.IAdminUpdateNearestPointOfCdekRequest { }
export interface IAdminGetChartDataRequest extends Components.Schemas.IAdminGetChartDataQueryRequest { }
export interface IAdminGetChartDataResponse extends Components.Schemas.IAdminGetChartDataResponse { }
export interface IAdminChangeCommissionRequest extends Components.Schemas.IAdminChangeCommissionRequest { }
export interface IAdminChangeCommissionResponse extends Components.Schemas.IAdminChangeCommissionResponse { }
export interface IAdminChangeSellerStatusRequest extends Components.Schemas.IAdminChangeSellerStatusRequest { }
export interface IGetInfoOfLegalRequest extends Components.Schemas.IGetInfoOfLegalRequest { }
export interface IGetInfoOfLegalResponse extends Components.Schemas.ISuggestion { }
export interface IAdminCreateWithdrawalRequest extends Components.Schemas.IAdminCreateWithdrawalRequest { }
export interface IIntegrationStatusRequest extends Paths.RequestsControllerFindOne.QueryParameters { }
export interface IIntegrationStatusResponse extends Components.Schemas.RequestDto { }
export interface IImportMoySkladProductsRequest extends Components.Schemas.MoySkladImportDto { }
export interface MoySkladLinkerRequest extends Paths.MoySkladLinkerControllerFindAll.QueryParameters { }
export interface MoySkladLinkerResponse extends Components.Schemas.MoySkladLinkerResponseDto { }
export interface MoySkladLinker extends Components.Schemas.MoySkladLinkerDto { }
export interface MoySkladLinksRequest extends Components.Schemas.MoySkladLinksDto { }
export interface MoySkladLink extends Components.Schemas.MoySkladLinkDto { }
export interface ISendNotifications extends Components.Schemas.ISendNotifications { }
export interface IShopWorkTime extends Components.Schemas.IShopWorkTime { }

export enum ENotificationRecipients {
  ALL = 'ALL',
  SELLERS = 'SELLERS',
  USERS = 'USERS',
}

export enum ESellerStatus {
  WAITING_APPROVE = 'WAITING_APPROVE',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  ARCHIVED = 'ARCHIVED',
}

export enum ESellerTypes {
  PHYSICAL = 'PHYSICAL',
  LEGAL = 'LEGAL',
}

export enum ESellerLegalTypes {
  LEGAL = 'LEGAL',
  INDIVIDUAL = 'INDIVIDUAL',
}

export interface ISellerCreateAuthForm {
  type: ESellerTypes
  firstName: string
  lastName: string
  middleName?: string
  noMiddleName: boolean
  legalINN?: string
  passportData?: string
  birthDate?: any
  passportPhoto?: any
  physicalINN?: string
  phone: string
  shopName: string
}

export interface ISellerFormData {
  type: ESellerTypes
  firstName: string
  lastName: string
  middleName?: string
  noMiddleName: boolean
  email: string
  password: string
  phone: string
  passportData?: string
  birthDate?: any
  passportPhoto?: any
  physicalINN?: string
  legalINN?: string
  companyName?: string
  OKPO?: string
  legalKPP?: string
  OGRN?: string
  organizationType?: ESellerLegalTypes
  legalAddress?: string
  bankName?: string
  bankBIK?: string
  physicalKPP?: string
  bankPC?: string
  bankKC?: string
  cardNumber?: string
  shopName: string
  desc: string
  logo: any
  banner: any
}

export type TVariantSeller = 'seller' | 'profile'

export enum ECheckSellerDataTypes {
  SUCCESS = 'success',
  ERROR_LEGAL_INN = 'errorLegalINN',
  INN_NOT_FOUND = 'errorPhysicalINNNotFound',
  INVALID_PASSPORT = 'errorPhysicalPassport',
  INCORRECT_INN = 'errorPhysicalIncorrectINN'
}

export enum ECheckForFillingSellerDataError {
  SHOP_IS_NOT_EXIST = 'SHOP_IS_NOT_EXIST',
  CDEK_POINT_IS_NOT_EXIST = 'CDEK_POINT_IS_NOT_EXIST',
  DELIVERY_WAYS_IS_NOT_EXIST = 'DELIVERY_WAYS_IS_NOT_EXIST',
  SELLER_INFO_IS_NOT_FILLED_OUT = 'SELLER_INFO_IS_NOT_FILLED_OUT',
  BANKING_DETAILS_IS_NOT_FILLED_OUT = 'BANKING_DETAILS_IS_NOT_FILLED_OUT',
}
