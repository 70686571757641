import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Tag,
  Tooltip,
  Typography,
  Upload,
  message
} from 'antd'
import { AxiosError } from 'axios'
import React from 'react'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'
import { MenuInfo } from 'rc-menu/lib/interface'
import {
  AppstoreAddOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  LoginOutlined,
  PaperClipOutlined,
  UploadOutlined
} from '@ant-design/icons'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { Uploader } from 'components/ui/Uploader/Uploader'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { passwordPattern, ruNamePattern } from 'shared/utils/patterns'
import {
  ESellerLegalTypes,
  ESellerStatus,
  ESellerTypes,
  ISellerFormData
} from 'models/seller'
import { TVariantSeller } from 'models/seller'
import { sellerApi } from 'api/seller'
import { appSelectors } from 'store/app'
import { superAdminRoutes } from 'shared/constants/routes'
import { showServerError } from 'shared/utils/showServerError'
import { IMAGES_URL } from 'shared/constants/urls'
import { showMessage } from 'shared/utils/showMessage'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { getSellerStatusItems, sellerStatuses } from 'shared/constants/sellers'
import { normalizeFile } from 'shared/utils/normalizeFile'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import styles from './SellerInfo.module.scss'
import {
  getLegalSubmitData,
  getPhysicSubmitData,
  getEditSubmitData
} from './utils/getSubmitData'

interface ISellerInfoEditProps {
  mode: 'edit'
  type: TVariantSeller
  sellerId: string
}

interface ISellerInfoCreateProps {
  mode: 'create'
  type: TVariantSeller
}

interface ICompanyInfo {
  companyName: string
  OKPO?: string
  legalKPP: string
  OGRN: string
  organizationType: string
  legalAddress: string
}

type ISellerInfoProps = ISellerInfoEditProps | ISellerInfoCreateProps

export const SellerInfo: React.FC<ISellerInfoProps> = (props) => {
  const navigate = useNavigate()

  const token = useAppSelector(appSelectors.selectToken)

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = React.useState(
    props.mode === 'create' ? false : true
  )
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const [deletedPhotos, setDeletedPhotos] = React.useState<string[]>([])

  const [sellerStatus, setSellerStatus] = React.useState<ESellerStatus | null>(
    null
  )

  const [companyInfo, setCompanyInfo] = React.useState<ICompanyInfo | null>(
    null
  )

  const [isLegalINNChecking, setIsLegalINNChecking] = React.useState(false)

  const sellerType: ESellerTypes | undefined = Form.useWatch('type', { form })
  const middleName: string | undefined = Form.useWatch('middleName', { form })
  const noMiddleName: boolean | undefined = Form.useWatch('noMiddleName', {
    form
  })
  const isMiddleNameLength: boolean = Boolean(middleName?.length ?? false)
  const isPassportPhoto: boolean =
    Form.useWatch('passportPhoto', { form, preserve: true })?.length || false

  const isLegalType = React.useMemo(
    () => sellerType === ESellerTypes.LEGAL,
    [sellerType]
  )

  const isCreate = React.useMemo(() => props.mode === 'create', [props])

  const isProfile = React.useMemo(() => props.type === 'profile', [props])

  React.useEffect(() => {
    if (sellerType) {
      form.setFields([
        {
          name: 'bankName',
          errors: []
        },
        {
          name: 'bankBIK',
          errors: []
        },
        {
          name: 'bankPC',
          errors: []
        },
        {
          name: 'bankKC',
          errors: []
        }
      ])
    }
  }, [sellerType])

  React.useEffect(() => {
    if (noMiddleName) {
      form.setFields([
        {
          name: 'middleName',
          errors: [],
          value: undefined
        }
      ])
    }
  }, [noMiddleName])

  React.useEffect(() => {
    if (isMiddleNameLength) {
      form.setFieldValue('noMiddleName', false)
      form.setFields([
        {
          name: 'middleName',
          errors: []
        }
      ])
    }
  }, [isMiddleNameLength])

  const checkLegalINN = async () => {
    try {
      setIsLegalINNChecking(true)
      const response = await sellerApi.checkLegalINN({
        INN: form.getFieldValue('legalINN')
      })
      setCompanyInfo({
        companyName: response.data.value,
        OKPO: response.data.data.okpo,
        legalKPP: response.data.data.kpp,
        OGRN: response.data.data.ogrn,
        organizationType:
          response.data.data.type === ESellerLegalTypes.INDIVIDUAL
            ? 'Индивидуальный предприниматель'
            : 'Юридическое лицо',
        legalAddress: response.data.data.address.value
      })
    } catch (e) {
      form.setFields([
        {
          name: 'legalINN',
          errors: ['Организация не действительна']
        }
      ])
      setCompanyInfo(null)
    } finally {
      setIsLegalINNChecking(false)
    }
  }

  React.useEffect(() => {
    if (props.mode === 'edit') {
      const getSeller = async () => {
        try {
          message.loading('Загрузка данных о продавце')
          const { data } = await sellerApi.getSeller(props.sellerId)
          setSellerStatus(data.status)
          form.setFields([
            { name: 'type', value: data.type },
            { name: 'firstName', value: data.user_id.name },
            { name: 'lastName', value: data.user_id.surname },
            { name: 'middleName', value: data.user_id.patronymic },
            { name: 'noMiddleName', value: !data.user_id.patronymic },
            { name: 'email', value: data.user_id.email },
            { name: 'phone', value: phoneMaskStatic(data.user_id.tel) },
            {
              name: 'passportData',
              value: data?.additional_info?.passport_details
            },
            { name: 'companyName', value: data?.company_info?.name },
            { name: 'OKPO', value: data?.company_info?.okpo },
            { name: 'OGRN', value: data?.company_info?.ogrn },
            { name: 'organizationType', value: data?.company_info?.type },
            { name: 'legalAddress', value: data.banking_details.legal_address },
            { name: 'bankName', value: data.banking_details.name },
            { name: 'bankBIK', value: data.banking_details.BIK },
            { name: 'bankPC', value: data.banking_details.PC },
            { name: 'bankKC', value: data.banking_details.KC },
            { name: 'cardNumber', value: data?.additional_info?.card_number },
            { name: 'shopName', value: data.shop_details.shop_name },
            { name: 'desc', value: data.shop_details.desc }
          ])
          if (data.user_id.birthday) {
            form.setFieldValue('birthDate', dayjs(data.user_id.birthday))
          }
          if (data.type === ESellerTypes.LEGAL) {
            form.setFields([
              { name: 'legalINN', value: data.banking_details.INN },
              { name: 'legalKPP', value: data.banking_details.KPP }
            ])
          }
          if (data.type === ESellerTypes.PHYSICAL) {
            form.setFields([
              { name: 'physicalINN', value: data.banking_details.INN },
              { name: 'physicalKPP', value: data.banking_details.KPP }
            ])
          }
          if (data.shop_details.logo) {
            form.setFieldValue('logo', [
              {
                uid: data.shop_details.logo,
                thumbUrl: `${IMAGES_URL}${data.shop_details.logo}`
              }
            ])
          }
          if (data.shop_details.banner) {
            form.setFieldValue('banner', [
              {
                uid: data.shop_details.banner,
                thumbUrl: `${IMAGES_URL}${data.shop_details.banner}`
              }
            ])
          }
          if (data?.additional_info?.passport_photo) {
            form.setFieldValue('passportPhoto', [
              {
                uid: data.additional_info.passport_photo,
                thumbUrl: `${IMAGES_URL}${data.additional_info.passport_photo}`
              }
            ])
          }
        } catch (e) {
          const error = e as AxiosError
          if (
            (error.response?.status === 500 ||
              error.response?.status === 400 ||
              error.response?.status === 404) &&
            props.type === 'seller'
          ) {
            return navigate(superAdminRoutes.SELLERS)
          }
          showServerError(e)
          setIsError(true)
        } finally {
          message.destroy()
          setIsLoading(false)
        }
      }
      getSeller()
    }
  }, [])

  const logo =
    Form.useWatch('logo', {
      form,
      preserve: true
    }) ?? []

  const banner =
    Form.useWatch('banner', {
      form,
      preserve: true
    }) ?? []

  const setProductsAction = async () => {
    try {
      await sellerApi.setActions({
        toDelete: props.mode === 'edit' ? props.sellerId : ''
      })
      message.success('Продавец успешно удален')
      navigate(superAdminRoutes.SELLERS)
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const showDeleteConfirm = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Вы действительно хотите удалить продавца?',
      content: 'Отменить данное действие будет невозможно.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',
      cancelText: 'Закрыть',
      onOk() {
        return new Promise((resolve) => {
          setProductsAction().then(resolve)
        })
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const changeSellerStatus = async (e: MenuInfo) => {
    if (props.mode === 'edit') {
      try {
        await sellerApi.changeSellerStatus(props.sellerId, {
          status: e.key as ESellerStatus.APPROVED | ESellerStatus.DECLINED
        })
        setSellerStatus(e.key as ESellerStatus)
      } catch (e) {
        showServerError(e)
      }
    }
  }

  const onSubmit = async () => {
    try {
      await form.validateFields()
      const data: ISellerFormData = form.getFieldsValue()
      setIsSubmitting(true)
      if (props.mode === 'create') {
        const finalData = isLegalType
          ? getLegalSubmitData(data)
          : getPhysicSubmitData(data)
        await sellerApi.addSeller(finalData)
        message.success('Продавец успешно добавлен')
      } else {
        const finalData = getEditSubmitData(data, props.sellerId, deletedPhotos)
        await sellerApi.updateSellerInfo(finalData)
        message.info(
          props.type === 'seller'
            ? 'Продавец успешно обновлен'
            : 'Данные успешно обновлены'
        )
      }
      if (props.type === 'seller') {
        navigate(superAdminRoutes.SELLERS)
      }
    } catch (e: any) {
      if (e.errorFields) {
        return showMessage('error', 'Заполните все поля корректно', '')
      }
      if (e.response?.data?.message === 'Организация не действительна') {
        form.setFields([
          {
            name: 'legalINN',
            errors: [e.response.data.message]
          }
        ])
      }
      showServerError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onFormChange = (data: Partial<ISellerFormData>) => {
    if (data.hasOwnProperty('legalINN') && companyInfo) {
      setCompanyInfo(null)
    }
  }

  return (
    <>
      {isLoading || isError ? null : (
        <Form form={form} name='sellers' size='large' autoComplete='off' onValuesChange={onFormChange}>
          <section className={styles.section}>
            <div
              className={styles.header}
              style={{ gap: props.type === 'seller' ? 15 : 0 }}
            >
              <Typography.Title
                level={2}
                className={classNames(styles.sectionTitle, styles.title)}
              >
                Информация о продавце
              </Typography.Title>
              <div className={styles.status}>
                {props.type === 'seller' &&
                  sellerStatus &&
                  (sellerStatus !== ESellerStatus.WAITING_APPROVE ? (
                    <Tag color={sellerStatuses[sellerStatus].color}>
                      {sellerStatuses[sellerStatus].label}
                    </Tag>
                  ) : (
                    <StatusDropdown
                      items={getSellerStatusItems(sellerStatus)}
                      label={sellerStatuses[sellerStatus].label}
                      color={sellerStatuses[sellerStatus].color}
                      onClick={(e) => changeSellerStatus(e)}
                    />
                  ))}
              </div>
            </div>
            <Form.Item
              name='type'
              label='Выберите тип лица'
              rules={[
                {
                  required: true,
                  message: 'Выберите тип лица'
                }
              ]}
              rootClassName={styles.sellerType}
              hasFeedback
            >
              <Select
                placeholder='Выберите тип лица'
                options={[
                  { value: ESellerTypes.LEGAL, label: 'Юридическое лицо' },
                  { value: ESellerTypes.PHYSICAL, label: 'Физическое лицо' }
                ]}
                disabled={isProfile}
              />
            </Form.Item>
            {sellerType && (
              <>
                <Form.Item
                  name='firstName'
                  label='Имя'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните имя'
                    },
                    {
                      whitespace: true,
                      message: 'Заполните имя'
                    },
                    ruNamePattern
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder='Введите имя'
                    disabled={isProfile && !isLegalType}
                  />
                </Form.Item>
                <Form.Item
                  name='lastName'
                  label='Фамилия'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните фамилию'
                    },
                    {
                      whitespace: true,
                      message: 'Заполните фамилию'
                    },
                    ruNamePattern
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder='Введите фамилию'
                    disabled={isProfile && !isLegalType}
                  />
                </Form.Item>
                <div className={styles.middleName}>
                  <Form.Item
                    name='middleName'
                    label='Отчество'
                    rules={
                      noMiddleName
                        ? []
                        : [
                          {
                            required: true,
                            message: 'Заполните отчество'
                          },
                          {
                            whitespace: true,
                            message: 'Заполните отчество'
                          },
                          ruNamePattern
                        ]
                    }
                    style={{ flexGrow: 1 }}
                    hasFeedback
                  >
                    <Input
                      placeholder='Введите отчество'
                      disabled={isProfile && !isLegalType}
                    />
                  </Form.Item>
                  <Form.Item
                    name='noMiddleName'
                    label='Нет отчества'
                    valuePropName='checked'
                  >
                    <Switch disabled={isProfile && !isLegalType} />
                  </Form.Item>
                </div>
                <Form.Item
                  name='email'
                  label='Почта'
                  rules={[
                    {
                      type: 'email',
                      message: 'Некорректная почта'
                    },
                    {
                      required: true,
                      message: 'Заполните почту'
                    }
                  ]}
                  hasFeedback
                >
                  <Input placeholder='Введите почтовый адрес' type='email' />
                </Form.Item>
                {props.mode === 'create' && (
                  <Form.Item
                    name='password'
                    label='Пароль'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните пароль'
                      },
                      ...passwordPattern
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder='Введите пароль' />
                  </Form.Item>
                )}
                <Form.Item
                  name='phone'
                  label='Номер телефона'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните номер телефона'
                    },
                    {
                      min: 18,
                      message: 'Заполните номер телефона'
                    }
                  ]}
                  hasFeedback
                >
                  <InputMask mask='+7 (999) 999-99-99' maskChar={null}>
                    {/* @ts-ignore */}
                    {() => <Input placeholder='Введите номер телефона' />}
                  </InputMask>
                </Form.Item>
              </>
            )}
            {sellerType === ESellerTypes.PHYSICAL && (
              <>
                <div className={styles.passportData}>
                  <Form.Item
                    name='passportData'
                    label='Паспортные данные'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните серию и номер паспорта'
                      },
                      {
                        min: 12,
                        message: 'Заполните серию и номер паспорта'
                      }
                    ]}
                    className={styles.passportDataField}
                    hasFeedback
                  >
                    <InputMask
                      mask='99 99 999999'
                      maskChar={null}
                      disabled={isProfile}
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <Input
                          placeholder='__ __ ______'
                          disabled={isProfile}
                        />
                      )}
                    </InputMask>
                  </Form.Item>
                  <Form.Item
                    name='birthDate'
                    label='Дата рождения'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните дату рождения'
                      }
                    ]}
                    rootClassName={styles.dateField}
                  >
                    <DatePicker
                      format='DD/MM/YYYY'
                      placeholder='19/02/2002'
                      disabled={isProfile}
                      inputReadOnly
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name='passportPhoto'
                  valuePropName='fileList'
                  getValueFromEvent={normalizeFile}
                  label='Фото паспорта'
                  rules={[
                    {
                      required: true,
                      message: 'Загрузите фотографию паспорта'
                    }
                  ]}
                  rootClassName={classNames({
                    [styles.passportPhotoWrapper]: isPassportPhoto
                  })}
                >
                  <Upload
                    accept='image/png, image/jpeg, image/jpg'
                    maxCount={1}
                    customRequest={() => false}
                    disabled={isProfile}
                    beforeUpload={(file) => {
                      const isValid =
                        file.type === 'image/png' ||
                        file.type === 'image/jpg' ||
                        file.type === 'image/jpeg'
                      if (!isValid) {
                        showMessage(
                          'error',
                          'Поддерживаются только форматы JPG, JPEG, PNG',
                          ''
                        )
                      } else {
                        message.success('Файл успешно загружен')
                      }
                      return isValid || Upload.LIST_IGNORE
                    }}
                    itemRender={(_, file, fileList, { remove }) => {
                      return (
                        <div className={styles.passportPhoto}>
                          <PaperClipOutlined style={{ opacity: 0.45 }} />
                          {file.uid ? (
                            <Typography.Link
                              href={`${IMAGES_URL}${file.uid}?access_token=${token}`}
                              target='_blank'
                            >
                              passport
                            </Typography.Link>
                          ) : (
                            <Typography.Text
                              style={{ color: '#1677FF' }}
                            >{`passport.${
                                file.type?.split('/')[1]
                              }`}</Typography.Text>
                          )}
                          {!isProfile && (
                            <CloseOutlined
                              className={styles.passportPhotoRemove}
                              onClick={() => {
                                remove()
                                if (!file.originFileObj) {
                                  setDeletedPhotos((prev) => [
                                    ...prev,
                                    file.uid
                                  ])
                                }
                              }}
                            />
                          )}
                        </div>
                      )
                    }}
                  >
                    <Button
                      size='middle'
                      disabled={isProfile}
                      icon={<UploadOutlined />}
                    >
                      Загрузить фото
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  name='physicalINN'
                  label='ИНН'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните ИНН'
                    },
                    {
                      min: 12,
                      message: 'Заполните ИНН корректно'
                    }
                  ]}
                  hasFeedback
                >
                  <InputMask
                    mask='999999999999'
                    maskChar={null}
                    disabled={isProfile}
                  >
                    {/* @ts-ignore */}
                    {() => (
                      <Input placeholder='Введите ИНН' disabled={isProfile} />
                    )}
                  </InputMask>
                </Form.Item>
              </>
            )}
          </section>
          {isLegalType && (
            <section className={styles.section}>
              <Typography.Title level={2} className={styles.sectionTitle}>
                Сведения о компании
              </Typography.Title>
              <div className={classNames({ [styles.legalINNWrapper]: isCreate })}>
                <Form.Item
                  name='legalINN'
                  label='ИНН'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните ИНН'
                    },
                    {
                      min: 10,
                      message: 'Заполните ИНН корректно'
                    },
                    {
                      validator: (_, value) =>
                        value.length === 11
                          ? Promise.reject(new Error('Заполните ИНН корректно'))
                          : Promise.resolve()
                    }
                  ]}
                  style={ isCreate ? { marginBottom: 0, flex: 1 } : {}}
                  hasFeedback
                >
                  <InputMask
                    mask='999999999999'
                    maskChar={null}
                    disabled={isProfile && isLegalType}
                  >
                    {/* @ts-ignore */}
                    {() => (
                      <Input
                        placeholder='Введите ИНН'
                        disabled={isProfile && isLegalType}
                        maxLength={12}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && isCreate) {
                            checkLegalINN()
                          }
                        }}
                      />
                    )}
                  </InputMask>
                </Form.Item>
                {isCreate && (
                  <Tooltip title='Проверить и получить сведения о компании' trigger='hover'>
                    <Button
                      type='primary'
                      size='small'
                      icon={<LoginOutlined />}
                      rootClassName={styles.getCompanyInfo}
                      onClick={checkLegalINN}
                      loading={isLegalINNChecking}
                    />
                  </Tooltip>
                )}
              </div>
              {isCreate ? (
                <>
                  <Form.Item
                    label='Название компании'
                    className={styles.disabledField}
                  >
                    <Input
                      value={companyInfo?.companyName ?? ''}
                      placeholder='Название компании'
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label='ОКПО'
                    className={styles.disabledField}
                  >
                    <Input
                      value={companyInfo?.OKPO ?? ''}
                      placeholder='ОКПО'
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label='КПП'
                    className={styles.disabledField}
                  >
                    <Input
                      value={companyInfo?.legalKPP ?? ''}
                      placeholder='КПП'
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label='ОГРН'
                    className={styles.disabledField}
                  >
                    <Input
                      value={companyInfo?.OGRN ?? ''}
                      placeholder='ОГРН'
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label='Тип организации'
                    className={styles.disabledField}
                  >
                    <Select
                      value={companyInfo?.organizationType}
                      placeholder='Выберите тип организации'
                      suffixIcon={null}
                      disabled
                      options={[
                        {
                          value: ESellerLegalTypes.LEGAL,
                          label: 'Юридическое лицо'
                        },
                        {
                          value: ESellerLegalTypes.INDIVIDUAL,
                          label: 'Индивидуальный предприниматель'
                        }
                      ]}
                      className={classNames({
                        [styles.disableSelectField]: companyInfo?.organizationType
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Адрес'
                    className={styles.disabledField}
                  >
                    <Input
                      value={companyInfo?.legalAddress ?? ''}
                      placeholder='Адрес'
                      disabled
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name='companyName'
                    label='Название компании'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните название компании'
                      },
                      {
                        whitespace: true,
                        message: 'Заполните название компании'
                      }
                    ]}
                    hasFeedback
                  >
                    <Input
                      placeholder='Название компании'
                      disabled={isProfile}
                    />
                  </Form.Item>
                  <Form.Item
                    name='OKPO'
                    label='ОКПО'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните ОКПО'
                      },
                      {
                        min: 8,
                        message: 'Заполните ОКПО корректно'
                      }
                    ]}
                    hasFeedback
                  >
                    <InputMask
                      mask='9999999999'
                      disabled={isProfile}
                      maskChar={null}
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <Input
                          disabled={isProfile}
                          placeholder='ОКПО'
                        />
                      )}
                    </InputMask>
                  </Form.Item>
                  <Form.Item
                    name='legalKPP'
                    label='КПП'
                    rules={[
                      {
                        min: 9,
                        message: 'Заполните КПП корректно'
                      }
                    ]}
                    hasFeedback
                  >
                    <InputMask
                      mask='999999999'
                      disabled={isProfile}
                      maskChar={null}
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <Input
                          placeholder='КПП'
                          disabled={isProfile}
                        />
                      )}
                    </InputMask>
                  </Form.Item>
                  <Form.Item
                    name='OGRN'
                    label='ОГРН'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните ОГРН'
                      },
                      {
                        min: 13,
                        message: 'Заполните ОГРН корректно'
                      }
                    ]}
                    hasFeedback
                  >
                    <InputMask
                      mask='9999999999999'
                      disabled={isProfile}
                      maskChar={null}
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <Input
                          placeholder='ОГРН'
                          disabled={isProfile}
                        />
                      )}
                    </InputMask>
                  </Form.Item>
                  <Form.Item
                    name='organizationType'
                    label='Тип организации'
                    rules={[
                      {
                        required: true,
                        message: 'Выберите тип организации'
                      }
                    ]}
                    hasFeedback
                  >
                    <Select
                      placeholder='Выберите тип организации'
                      disabled={isProfile}
                      suffixIcon={isProfile ? null : <DownOutlined />}
                      options={[
                        {
                          value: ESellerLegalTypes.LEGAL,
                          label: 'Юридическое лицо'
                        },
                        {
                          value: ESellerLegalTypes.INDIVIDUAL,
                          label: 'Индивидуальный предприниматель'
                        }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name='legalAddress'
                    label='Адрес'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните адрес'
                      },
                      {
                        whitespace: true,
                        message: 'Заполните адрес'
                      }
                    ]}
                    hasFeedback
                  >
                    <Input
                      placeholder='Адрес'
                      disabled={isProfile}
                    />
                  </Form.Item>
                </>
              )}
            </section>
          )}
          {sellerType && (
            <>
              <section id='banking-details' className={styles.section}>
                <Typography.Title level={2} className={styles.sectionTitle}>
                  Банковские сведения
                </Typography.Title>
                <Form.Item
                  name='bankName'
                  label='Наименование банка'
                  rules={
                    isLegalType
                      ? [
                        {
                          required: true,
                          message: 'Заполните наименование банка'
                        },
                        {
                          whitespace: true,
                          message: 'Заполните наименование банка'
                        }
                      ]
                      : []
                  }
                  hasFeedback
                >
                  <Input placeholder='Введите название банка' />
                </Form.Item>
                <Form.Item
                  name='bankBIK'
                  label='БИК'
                  rules={
                    isLegalType
                      ? [
                        {
                          required: true,
                          message: 'Заполните БИК'
                        },
                        {
                          min: 9,
                          message: 'Заполните БИК корректно'
                        }
                      ]
                      : [
                        {
                          min: 9,
                          message: 'Заполните БИК корректно'
                        }
                      ]
                  }
                  hasFeedback
                >
                  <InputMask mask='999999999' maskChar={null}>
                    {/* @ts-ignore */}
                    {() => <Input placeholder='Введите БИК' />}
                  </InputMask>
                </Form.Item>
                {sellerType === ESellerTypes.PHYSICAL && (
                  <Form.Item
                    name='physicalKPP'
                    label='КПП'
                    rules={[{ min: 9, message: 'Заполните КПП корректно' }]}
                    hasFeedback
                  >
                    <InputMask mask='999999999' maskChar={null}>
                      {/* @ts-ignore */}
                      {() => <Input placeholder='Введите КПП' />}
                    </InputMask>
                  </Form.Item>
                )}
                <Form.Item
                  name='bankPC'
                  label='РС'
                  rules={
                    isLegalType
                      ? [
                        {
                          required: true,
                          message: 'Заполните расчетный счет'
                        },
                        {
                          min: 20,
                          message: 'Заполните расчетный счет корректно'
                        }
                      ]
                      : [
                        {
                          min: 20,
                          message: 'Заполните расчетный счет корректно'
                        }
                      ]
                  }
                  hasFeedback
                >
                  <InputMask mask='99999999999999999999' maskChar={null}>
                    {/* @ts-ignore */}
                    {() => <Input placeholder='Введите расчетный счет' />}
                  </InputMask>
                </Form.Item>
                <Form.Item
                  name='bankKC'
                  label='КС'
                  rules={
                    isLegalType
                      ? [
                        {
                          required: true,
                          message: 'Заполните корпоративный счет'
                        },
                        {
                          min: 20,
                          message: 'Заполните корпоративный счет корректно'
                        }
                      ]
                      : [
                        {
                          min: 20,
                          message: 'Заполните корпоративный счет корректно'
                        }
                      ]
                  }
                  hasFeedback
                >
                  <InputMask mask='99999999999999999999' maskChar={null}>
                    {/* @ts-ignore */}
                    {() => <Input placeholder='Введите корпоративный счет' />}
                  </InputMask>
                </Form.Item>
                {sellerType === ESellerTypes.PHYSICAL && (
                  <Form.Item
                    name='cardNumber'
                    label='Номер карты'
                    rules={[
                      { min: 19, message: 'Заполните номер карты корректно' }
                    ]}
                    hasFeedback
                  >
                    <InputMask mask='9999 9999 9999 9999' maskChar={null}>
                      {/* @ts-ignore */}
                      {() => <Input placeholder='Введите номер карты' />}
                    </InputMask>
                  </Form.Item>
                )}
              </section>
              <section id='shop-details' className={styles.section}>
                <Typography.Title level={2} className={styles.sectionTitle}>
                  Данные о магазине
                </Typography.Title>
                <Form.Item
                  name='shopName'
                  label='Название магазина'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните название магазина'
                    },
                    {
                      whitespace: true,
                      message: 'Заполните название магазина'
                    }
                  ]}
                  hasFeedback
                >
                  <Input placeholder='Введите название магазина' />
                </Form.Item>
                <Typography.Title level={4} style={{ marginBottom: 3 }}>
                  Логотип:
                </Typography.Title>
                <Typography.Text className={styles.subTitle}>
                  Рекомендованный размер логотипа не менее <br />
                  250px по ширине. Поддерживаются форматы JPG, JPEG, PNG.
                </Typography.Text>
                <Uploader
                  name='logo'
                  disabled={!!logo.length}
                  rules={[
                    {
                      required: true,
                      message: 'Добавьте логотип'
                    }
                  ]}
                  onRemoveClick={(file) => {
                    if (!file.originFileObj) {
                      setDeletedPhotos((prev) => [...prev, file?.uid ?? ''])
                    }
                  }}
                />
                <Typography.Title level={4} style={{ marginBottom: 3 }}>
                  Баннер магазина:
                </Typography.Title>
                <Typography.Text className={styles.subTitle}>
                  Рекомендованный размер баннера не менее <br />
                  1000х300 px. Поддерживаются форматы JPG, JPEG, PNG.
                </Typography.Text>
                <Uploader
                  name='banner'
                  disabled={!!banner.length}
                  rules={[
                    {
                      required: true,
                      message: 'Добавьте баннер магазина'
                    }
                  ]}
                  onRemoveClick={(file) => {
                    if (!file.originFileObj) {
                      setDeletedPhotos((prev) => [...prev, file.uid])
                    }
                  }}
                />
                <Typography.Title level={4} style={{ marginBottom: 22 }}>
                  Описание магазина:
                </Typography.Title>
                <Form.Item
                  name='desc'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните описание магазина'
                    },
                    {
                      whitespace: true,
                      message: 'Заполните описание магазина'
                    }
                  ]}
                  hasFeedback
                >
                  <Input.TextArea placeholder='Описание магазина' rows={2} />
                </Form.Item>
                <div className={styles.footer}>
                  <Button
                    htmlType='button'
                    type='primary'
                    size='large'
                    icon={<AppstoreAddOutlined />}
                    loading={isSubmitting}
                    onClick={onSubmit}
                    disabled={isLegalINNChecking}
                  >
                    Сохранить
                  </Button>
                  {props.mode === 'edit' && props.type === 'seller' && (
                    <Button
                      htmlType='button'
                      danger
                      type='primary'
                      icon={<DeleteOutlined />}
                      onClick={showDeleteConfirm}
                    >
                      Удалить
                    </Button>
                  )}
                </div>
              </section>
            </>
          )}
        </Form>
      )}
    </>
  )
}
