import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Form, Input, Typography } from 'antd'
import { AxiosError } from 'axios'
import { LockOutlined, LoginOutlined, MailOutlined } from '@ant-design/icons'
import { authApi } from 'api/auth'
import { appActions } from 'store/app'
import { ContinueWithApps } from 'components/auth/ContinueWithApps/ContinueWithApps'
import { ELoginTypes, ERoles } from 'models/auth'
import { ESellerStatus } from 'models/seller'
import { routes } from 'shared/constants/routes'
import { passwordPattern } from 'shared/utils/patterns'
import { useAppDispatch } from 'shared/hooks/useReduxHooks'
import { showServerError } from 'shared/utils/showServerError'
import { getSellerStatusMessage } from 'shared/utils/auth/getSellerStatusMessage'
import styles from './SignIn.module.scss'

interface ISignInForm {
  email: string
  password: string
}

interface ISignInProps {
  setActiveScreenPassword: () => void
  setActiveScreenSeller: () => void
  setIsEmptyPassword: (isEmpty: boolean) => void
  setToken: (token: string) => void
}

export const SignIn: React.FC<ISignInProps> = ({
  setActiveScreenPassword,
  setActiveScreenSeller,
  setIsEmptyPassword,
  setToken
}) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async (data: ISignInForm) => {
    try {
      setIsLoading(true)
      const response = await authApi.signIn({
        ...data,
        type: ELoginTypes.SELLER
      })
      if (response.data.role === ERoles.ADMIN) {
        return dispatch(appActions.setToken(response.data))
      }
      if (!response.data.isSeller) {
        setToken(response.data.access_token)
        return setActiveScreenSeller()
      }
      dispatch(appActions.setToken(response.data))
    } catch (e) {
      const error = e as AxiosError<{ message: ESellerStatus }>
      if (error?.response?.status === 403) {
        return getSellerStatusMessage(error.response.data.message)
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      name='signIn'
      size='large'
      initialValues={{
        rememberMe: true
      }}
      onFinish={onSubmit}
    >
      <Typography.Title className={styles.title}>Войти</Typography.Title>
      <Form.Item
        name='email'
        rules={[
          {
            type: 'email',
            message: 'Некорректный электронный адрес'
          },
          {
            required: true,
            message: 'Заполните электронный адрес'
          }
        ]}
        hasFeedback
      >
        <Input
          placeholder='Электронный адрес'
          prefix={<MailOutlined className='icon' />}
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'Заполните пароль'
          },
          ...passwordPattern
        ]}
        hasFeedback
      >
        <Input.Password
          type='password'
          placeholder='Пароль'
          prefix={<LockOutlined className='icon' />}
        />
      </Form.Item>
      <div className={styles.signInActions}>
        <Form.Item
          name='rememberMe'
          valuePropName='checked'
          style={{ marginBottom: 0 }}
        >
          <Checkbox>Запомнить меня</Checkbox>
        </Form.Item>
        <Link to={routes.RECOVERY_PASSWORD}>
          <Button type='link' size='large' style={{ padding: 0 }}>
            Восстановить пароль
          </Button>
        </Link>
      </div>
      <Button
        block
        htmlType='submit'
        type='primary'
        icon={<LoginOutlined />}
        className={styles.signInButton}
        loading={isLoading}
      >
        Войти
      </Button>
      <ContinueWithApps
        setActiveScreenPassword={setActiveScreenPassword}
        setActiveScreenSeller={setActiveScreenSeller}
        setIsEmptyPassword={setIsEmptyPassword}
        setToken={setToken}
      />
    </Form>
  )
}
