import React from 'react'
import { Button, Typography } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { productApi } from 'api/product'
import { ICategory, IFrontCategory } from 'models/product'
import { showServerError } from 'shared/utils/showServerError'
import { getRandomString } from 'shared/utils/getRandomString'
import { SelectedItem } from '../SelectedItem/SelectedItem'
import styles from './CategoriesColumn.module.scss'

interface ICategoriesColumnProps {
  categories: IFrontCategory[]
  selectedCategory: IFrontCategory | null
  setCategories: (categories: IFrontCategory[]) => void
  selectCategory: (selectedCategory: IFrontCategory | null) => void
  createNewCategory: (newCategory: IFrontCategory) => void
}

export const CategoriesColumn: React.FC<ICategoriesColumnProps> = React.memo(
  ({
    categories,
    selectedCategory,
    setCategories,
    selectCategory,
    createNewCategory
  }) => {
    const [isLoaded, setIsLoaded] = React.useState(false)
    const [isError, setIsError] = React.useState(false)

    const [newCategoryNumber, setNewCategoryNumber] = React.useState(0)

    React.useEffect(() => {
      const getCategories = async () => {
        try {
          const response = await productApi.getCategories({ parsed: 'false' })
          setCategories(
            response.data.map((el) => ({
              ...el,
              isNew: false
            })) as IFrontCategory[]
          )
          if (response.data[0]) {
            selectCategory({ ...response.data[0] as ICategory, isNew: false })
          }
        } catch (e) {
          setIsError(true)
          showServerError(e)
        } finally {
          setIsLoaded(true)
        }
      }
      getCategories()
    }, [])

    const onCreateNewCategory = () => {
      createNewCategory({
        _id: getRandomString(),
        name: newCategoryNumber
          ? `Новая категория (${newCategoryNumber})`
          : 'Новая категория',
        photo: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        slug: getRandomString(),
        isNew: true
      })
      setNewCategoryNumber((prev) => ++prev)
    }

    if (!isLoaded || isError) {
      return null
    }

    return (
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Основные
          </Typography.Title>
          <Button
            type='primary'
            size='small'
            icon={<PlusCircleOutlined />}
            onClick={onCreateNewCategory}
          />
        </header>
        {categories.map((item) => (
          <SelectedItem
            key={item._id}
            title={item.name}
            isSelected={selectedCategory?._id === item._id}
            onClick={() => selectCategory(item)}
          />
        ))}
      </div>
    )
  }
)
