import React from 'react'
import { Alert, Typography } from 'antd'

interface InfoAlertProps {
  isVisible: boolean
  type: 'SUCCESSFUL' | 'FAILED' | 'FINISHED' | null
  onClose: () => void
}

export const InfoAlert: React.FC<InfoAlertProps> = React.memo(
  ({ isVisible, type, onClose }) => {
    if (isVisible && type) {
      return (
        <Alert
          message={
            <Typography.Title level={5} style={{ fontWeight: 700 }}>
              {type === 'SUCCESSFUL' || type === 'FINISHED'
                ? 'Ваши товары успешно синхронизированы'
                : 'Во время синхронизации произошла ошибка'}
            </Typography.Title>
          }
          description={
            <Typography.Text>
              {type === 'SUCCESSFUL'
                ? 'Установите соответствие категорий для публикации товаров.'
                : type === 'FINISHED'
                  ? 'Ваши товары синхронизованы, все товары которые не содержали необходимые для интеграции поля попали в черновики, заполните обязательные поля и опубликуйте их.'
                  : 'Ваши товары были не были полностью синхронизированы, вы можете запустить процесс повторно или обратиться в службу технической поддержки.'}
            </Typography.Text>
          }
          type={type === 'SUCCESSFUL' || type === 'FINISHED' ? 'success' : 'error'}
          showIcon
          closable
          style={{ marginBottom: 30 }}
          onClose={onClose}
        />
      )
    }

    return null
  }
)
