import { integrationApi, privateApi, temporaryApi } from 'api'
import { AxiosResponse } from 'axios'
import {
  ISeller,
  ISellersQueryRequest,
  ISellersQueryResponse,
  IAdminCreateSellerRequest,
  IActionsSellersRequest,
  IAdminUpdateSellerRequest,
  IShop,
  ICreateSellerShopRequest,
  IDeliveryWays,
  IAdminUpdateDeliveryWaysRequest,
  INearestPointOfCdek,
  IUpdateNearestPointOfCdekRequest,
  IAdminGetChartDataRequest,
  IAdminGetChartDataResponse,
  IAdminChangeCommissionRequest,
  IAdminChangeCommissionResponse,
  IAdminChangeSellerStatusRequest,
  IGetInfoOfLegalRequest,
  IGetInfoOfLegalResponse,
  IAdminCreatePhysicalSellerRequest,
  IAdminCreateWithdrawalRequest,
  IIntegrationStatusRequest,
  IIntegrationStatusResponse,
  IImportMoySkladProductsRequest,
  MoySkladLinkerRequest,
  MoySkladLinkerResponse,
  MoySkladLinksRequest
} from 'models/seller'
import { getNormalizedFormData } from 'shared/utils/getNormalizedFormData'

export const sellerApi = {
  getSearchSellers(): Promise<AxiosResponse<ISeller[]>> {
    return privateApi.get<ISeller[]>('admin/seller/search')
  },
  getSellers({
    skip,
    limit,
    all_fields,
    number,
    full_name,
    shop_name,
    tel,
    status
  }: ISellersQueryRequest): Promise<AxiosResponse<ISellersQueryResponse>> {
    return privateApi.get<ISellersQueryResponse>(
      `admin/seller?skip=${skip}&limit=${limit}&all_fields=${
        all_fields ?? ''
      }&number=${number ?? ''}&full_name=${full_name ?? ''}&shop_name=${
        shop_name ?? ''
      }&tel=${tel ?? ''}&status=${status ?? ''}`
    )
  },
  getSeller(sellerId: string): Promise<AxiosResponse<ISeller>> {
    return privateApi.get<ISeller>(`admin/seller/${sellerId}`)
  },
  setActions({ toDelete }: IActionsSellersRequest): Promise<AxiosResponse> {
    return privateApi.put(`admin/seller?toDelete=${toDelete}`)
  },
  addSeller(data: IAdminCreateSellerRequest | IAdminCreatePhysicalSellerRequest): Promise<AxiosResponse> {
    const formData = getNormalizedFormData(data)
    return privateApi.post('admin/seller', formData)
  },
  updateSellerInfo(data: IAdminUpdateSellerRequest): Promise<AxiosResponse> {
    const formData = getNormalizedFormData(data)
    return privateApi.patch('admin/seller', formData)
  },
  getShops(sellerId: string): Promise<AxiosResponse<IShop[]>> {
    return privateApi.get<IShop[]>(`admin/seller/shop/${sellerId}`)
  },
  deleteShop(shopId: string): Promise<AxiosResponse> {
    return privateApi.delete(`admin/seller/shop?id=${shopId}`)
  },
  createShop(data: ICreateSellerShopRequest): Promise<AxiosResponse> {
    return privateApi.post('admin/seller/shop', data)
  },
  getDeliveryWays(sellerId: string): Promise<AxiosResponse<IDeliveryWays>> {
    return privateApi.get<IDeliveryWays>(`admin/seller/delivery-ways/${sellerId}`)
  },
  changeDeliveryWays(data: IAdminUpdateDeliveryWaysRequest): Promise<AxiosResponse> {
    return privateApi.patch('admin/seller/delivery-ways', data)
  },
  getSelectedDeliveryPoint(sellerId: string): Promise<AxiosResponse<INearestPointOfCdek>> {
    return privateApi.get<INearestPointOfCdek>(`admin/seller/point-of-cdek/${sellerId}`)
  },
  updateSelectedDeliveryPoint(data: IUpdateNearestPointOfCdekRequest): Promise<AxiosResponse> {
    return privateApi.post('admin/seller/point-of-cdek', data)
  },
  getSalesVolume({ year, seller_id }: IAdminGetChartDataRequest): Promise<AxiosResponse<IAdminGetChartDataResponse>> {
    return privateApi.get<IAdminGetChartDataResponse>(`admin/seller/chart/data?year=${year}&seller_id=${seller_id}`)
  },
  changeCommission(sellerId: string, data: IAdminChangeCommissionRequest): Promise<AxiosResponse<IAdminChangeCommissionResponse>> {
    return privateApi.patch<IAdminChangeCommissionResponse>(`admin/seller/change-commission/${sellerId}`, data)
  },
  changeSellerStatus(sellerId: string, data: IAdminChangeSellerStatusRequest): Promise<AxiosResponse> {
    return privateApi.patch(`admin/seller/change-status/${sellerId}`, data)
  },
  checkLegalINN(data: IGetInfoOfLegalRequest, token?: string): Promise<AxiosResponse<IGetInfoOfLegalResponse>> {
    if (token) {
      return temporaryApi.post<IGetInfoOfLegalResponse>(`dadata/legal?INN=${data.INN}`, undefined, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }) 
    }

    return privateApi.post<IGetInfoOfLegalResponse>(`dadata/legal?INN=${data.INN}`)
  },
  checkSellerDataOnFilling(sellerId: string): Promise<AxiosResponse> {
    return privateApi.get(`admin/seller/check-for-filling/${sellerId}`)
  },
  withdrawalRequest(data: IAdminCreateWithdrawalRequest): Promise<AxiosResponse> {
    return privateApi.post('admin/seller/withdraw', data)
  },
  getIntegrationStatus({ seller_id, type }: IIntegrationStatusRequest): Promise<AxiosResponse<IIntegrationStatusResponse>> {
    return integrationApi.get<IIntegrationStatusResponse>(`requests?seller_id=${seller_id}&type=${type}`)
  },
  moySkladImportProducts(data: IImportMoySkladProductsRequest): Promise<AxiosResponse> {
    return integrationApi.post('moy-sklad/import', data)
  },
  moySkladGetCategories({ seller_id }: MoySkladLinkerRequest): Promise<AxiosResponse<MoySkladLinkerResponse>> {
    return integrationApi.get<MoySkladLinkerResponse>(`moy-sklad-linker?seller_id=${seller_id}`)
  },
  connectMoySkladCategories(data: MoySkladLinksRequest) {
    return integrationApi.post('moy-sklad-linker', data)
  }
}
