import { Router } from 'routing'
import { useAuth } from 'shared/hooks/useAuth'
import { showServerError } from 'shared/utils/showServerError'

export const App: React.FC = () => {
  const { isLoaded, isError } = useAuth()

  if (!isLoaded) {
    return null
  }

  if (isError) {
    showServerError()
    return null
  }

  return <Router />
}

export default App
