import { privateApi } from 'api'
import { AxiosResponse } from 'axios'
import { IAdminChangeOrderStatus, IAdminDenyItem, IAdminGetOrderResponse, IAdminMultiOrderResponse, IAdminOrdersQueryRequest, IAdminOrdersQueryResponse, IAdminRemoveOrderItemRequest, IAdminUpdateOrderRequest } from 'models/orders'
import { getNormalizedFormData } from 'shared/utils/getNormalizedFormData'

export const ordersApi = {
  getOrders({
    skip,
    limit,
    name,
    from,
    to,
    number,
    tel,
    seller_id,
    sum,
    status
  }: IAdminOrdersQueryRequest): Promise<AxiosResponse<IAdminOrdersQueryResponse>> {
    return privateApi.get<IAdminOrdersQueryResponse>(
      `admin/order?skip=${skip}&limit=${limit}&name=${name ?? ''}&from=${from ?? ''}&to=${to ?? ''}&number=${number ?? ''}&tel=${tel ?? ''}&seller_id=${seller_id ?? ''}&sum=${sum ?? ''}&status=${status ?? ''}`
    )
  },
  getOrder(orderId: string): Promise<AxiosResponse<IAdminGetOrderResponse>> {
    return privateApi.get<IAdminGetOrderResponse>(`admin/order/${orderId}`)
  },
  changeStatus(data: IAdminChangeOrderStatus): Promise<AxiosResponse> {
    return privateApi.patch('admin/order/status', data)
  },
  removeProduct(orderId: string, data: IAdminDenyItem): Promise<AxiosResponse> {
    return privateApi.post(`admin/order/items/deny/${orderId}`, data)
  },
  updateOrder(orderId: string, data: IAdminUpdateOrderRequest): Promise<AxiosResponse<IAdminGetOrderResponse>> {
    const formData = getNormalizedFormData(data)
    return privateApi.patch<IAdminGetOrderResponse>(`admin/order/edit/${orderId}`, formData)
  },
  getOrderWithRemovedItem(orderId: string, data: IAdminRemoveOrderItemRequest): Promise<AxiosResponse<IAdminGetOrderResponse>> {
    return privateApi.post<IAdminGetOrderResponse>(`/admin/order/items/remove/${orderId}`, data)
  },
  getSuperOrder(orderId: string): Promise<AxiosResponse<IAdminMultiOrderResponse>> {
    return privateApi.get<IAdminMultiOrderResponse>(`admin/multiOrder/${orderId}`)
  }
}
