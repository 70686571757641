import { Rule } from 'antd/es/form'

export const numbersPattern = /^\d+$/
export const stringPattern = /^[A-Za-zА-Яа-я-]+$/
export const floatNumbersPattern = /^[0-9]+[.,]?[0-9]*$/
export const ruNamePattern: Rule = {
  pattern: /^[А-Яа-я-ё.,IV'-() ]+$/,
  message: 'Допускается вводить только русские буквы, символы I и V, одиночные пробелы, апострофы, дефисы, точки, запятые и круглые скобки'
}

export const passwordPattern: Rule[] = [
  {
    min: 8,
    message: 'Пароль должен состоять минимум из 8 символов'
  },
  {
    pattern: /^[^А-яЁа-яё\s]+$/,
    message: 'Пароль может состоять только из латинских букв, цифр и спец символов без пробелов'
  },
  {
    pattern: /^(?=.*[a-z])/,
    message: 'В пароле должна присутствовать минимум 1 строчная буква'
  },
  {
    pattern: /^(?=.*[A-Z])/,
    message: 'В пароле должна присутствовать минимум 1 заглавная буква'
  },
  {
    pattern: /^(?=.*\d)/,
    message: 'В пароле должна присутствовать минимум 1 цифра'
  }
]
