import React from 'react'
import { Menu, MenuProps } from 'antd'
import classNames from 'classnames'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import styles from './AnchorsWrapper.module.scss'

interface IAnchorsWrapperProps {
  title: string
  titleId?: string
  anchors: MenuProps['items']
  children: React.ReactNode
}

export const AnchorsWrapper: React.FC<IAnchorsWrapperProps> = ({ title, titleId, anchors, children }) => {
  const [current, setCurrent] = React.useState(anchors?.[0]?.key as string ?? '')
  
  React.useEffect(() => {
    const scrollEvent = () => {
      if (anchors) {
        const position = window.scrollY
        for (let i = anchors.length - 1; i >= 0; i--) {
          const anchor = anchors[i]
          const sectionTarget: any = document.querySelector(anchor?.key as string ?? '')
          if (sectionTarget && position + window.innerHeight / 2 > sectionTarget.offsetTop + 70) {
            setCurrent(anchor?.key as string ?? '')
            break
          }
        }
      }
    }
    window.addEventListener('scroll', scrollEvent)
    return () => {
      window.removeEventListener('scroll', scrollEvent)
    }
  }, [])

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
    const sectionTarget: any = document.querySelector(e.key)

    if (sectionTarget !== null) {
      const pos = sectionTarget.offsetTop - 70

      window.scrollTo({
        top: pos,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div>
      <MainTitle text={title} id={titleId} isGoBack />
      <Menu
        mode='vertical'
        items={anchors}
        selectedKeys={[current]}
        onClick={onClick}
        className={classNames('side-menu', styles.menu)}
      />
      <div className={classNames('side-content', styles.content)}>
        {children}
      </div>
    </div>
  )
}
