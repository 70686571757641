export interface IPopulatedHorse extends Components.Schemas.IPopulatedHorseWViewCount { }
export interface IPopulatedHorseWithoutCount extends Components.Schemas.IPopulatedHorse { }
export interface IHorseQueryRequest extends Components.Schemas.IHorseQueryRequest { }
export interface IAdminHorsesQueryResponse extends Components.Schemas.IAdminHorsesQueryResponse { }
export interface IActionsHorseRequest extends Components.Schemas.IActionsHorseRequest { }
export interface IAdminChangeHorseStatusRequest extends Components.Schemas.IAdminChangeHorseStatusRequest { }

export enum EHorseStatus {
  MODERATION = 'MODERATION',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
}