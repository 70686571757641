
export interface ISendNotifications extends Components.Schemas.ISendNotifications {}
export enum ENotificationRecipients {
  ALL = 'ALL',
  SELLERS = 'SELLERS',
  USERS = 'USERS',
}
export interface NotificationData extends Components.Schemas.NotificationData { }
export interface InternalPushMessageData extends Components.Schemas.InternalPushMessageData { }
export interface ExternalPushMessageData extends Components.Schemas.ExternalPushMessageData { }

export enum ENotificationType {
  // ORDER = 'ORDER', // для бека
  PRODUCT = 'PRODUCT',
  COLLECTION = 'COLLECTION',
}