import React from 'react'
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  Typography,
  Upload,
  message
} from 'antd'
import {
  CloseOutlined,
  LoginOutlined,
  PaperClipOutlined,
  UploadOutlined
} from '@ant-design/icons'
import InputMask from 'react-input-mask'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { sellerApi } from 'api/seller'
import { ECheckSellerDataTypes, ESellerTypes, ISellerCreateAuthForm } from 'models/seller'
import { AuthContentWrapper } from 'components/layout/AuthContentWrapper/AuthContentWrapper'
import { ECheckSellerDataErrors } from 'models/auth'
import { IAlertData } from 'models/app'
import { authApi } from 'api/auth'
import { showMessage } from 'shared/utils/showMessage'
import { showServerError } from 'shared/utils/showServerError'
import { normalizeFile } from 'shared/utils/normalizeFile'
import { ruNamePattern } from 'shared/utils/patterns'
import { InfoAlert } from '../InfoAlert/InfoAlert'
import styles from './SellerInfo.module.scss'
import { getSubmitData } from './utils/getSubmitData'

interface ISellerInfoProps {
  token: string
  onSuccess: () => void
  onGoBack?: () => void
}

export const SellerInfo: React.FC<ISellerInfoProps> = ({
  token,
  onSuccess,
  onGoBack
}) => {
  const [form] = Form.useForm<ISellerCreateAuthForm>()

  const [isLoading, setIsLoading] = React.useState(false)

  const [alertData, setAlertData] = React.useState<IAlertData<ECheckSellerDataTypes>>({
    visible: false,
    type: null
  })

  const [isNeedPhysicalChecking, setIsNeedPhysicalChecking] = React.useState(true)
  const [isNeedLegalChecking, setIsNeedLegalChecking] = React.useState(true)

  const sellerType: ESellerTypes | undefined = Form.useWatch('type', { form })
  const firstName: string | undefined = Form.useWatch('firstName', { form })
  const lastName: string | undefined = Form.useWatch('lastName', { form })
  const middleName: string | undefined = Form.useWatch('middleName', { form })
  const noMiddleName: boolean | undefined = Form.useWatch('noMiddleName', {
    form
  })
  const passportData: string | undefined = Form.useWatch('passportData', {
    form,
    preserve: true
  })
  const birthDate: any | undefined = Form.useWatch('birthDate', {
    form,
    preserve: true
  })
  const physicalINN: string | undefined = Form.useWatch('physicalINN', {
    form,
    preserve: true
  })

  const isPassportPhoto: boolean =
    Form.useWatch('passportPhoto', { form, preserve: true })?.length || false

  const isMiddleNameLength: boolean = Boolean(middleName?.length ?? false)

  const legalINN: string =
    Form.useWatch('legalINN', { form, preserve: true }) ?? ''

  const closeAlert = React.useCallback(() => {
    setAlertData({ visible: false, type: null })
  }, [])

  React.useEffect(() => {
    setIsNeedPhysicalChecking(true)
    closeAlert()
  }, [firstName, lastName, middleName, passportData, birthDate, physicalINN])

  React.useEffect(() => {
    setIsNeedLegalChecking(true)
    closeAlert()
  }, [legalINN])

  React.useEffect(() => {
    if (sellerType) {
      closeAlert()
      form.resetFields([
        'legalINN',
        'passportData',
        'birthDate',
        'passportPhoto',
        'physicalINN'
      ])
    }
  }, [sellerType])

  React.useEffect(() => {
    if (noMiddleName) {
      form.setFields([
        {
          name: 'middleName',
          errors: [],
          value: undefined
        }
      ])
    }
  }, [noMiddleName])

  React.useEffect(() => {
    if (isMiddleNameLength) {
      form.setFieldValue('noMiddleName', false)
      form.setFields([
        {
          name: 'middleName',
          errors: []
        }
      ])
    }
  }, [isMiddleNameLength])


  const checkLegalINN = async () => {
    try {
      await form.validateFields()
      const data: ISellerCreateAuthForm = form.getFieldsValue()
      setIsLoading(true)
      await sellerApi.checkLegalINN({ INN: data.legalINN as string }, token)
      setIsNeedLegalChecking(false)
      setAlertData({ visible: true, type: ECheckSellerDataTypes.SUCCESS })
    } catch (e: any) {
      if (!e.errorFields) {
        setAlertData({
          visible: true,
          type: ECheckSellerDataTypes.ERROR_LEGAL_INN
        })
        form.setFields([
          {
            name: 'legalINN',
            errors: ['']
          }
        ])
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const checkPhysicData = async () => {
    try {
      await form.validateFields()
      const data: ISellerCreateAuthForm = form.getFieldsValue()
      setIsLoading(true)
      await authApi.checkPhysicData(token, {
        name: data.firstName,
        surname: data.lastName,
        opt_otch: data.noMiddleName,
        patronymic: data.middleName,
        passport_details: data.passportData as string,
        birthday: new Date(dayjs(data.birthDate).format('YYYY-MM-DD')),
        INN: data.physicalINN as string
      })
      setIsNeedPhysicalChecking(false)
      setAlertData({ visible: true, type: ECheckSellerDataTypes.SUCCESS })
    } catch (e: any) {
      if (!e.errorFields) {
        const error = e as AxiosError<{ message: string, errors: any }>

        if (
          error.response?.data?.message !==
            ECheckSellerDataErrors.INVALID_PASSPORT &&
          error.response?.data?.message !==
            ECheckSellerDataErrors.INN_NOT_FOUND &&
          error.response?.data?.message !== ECheckSellerDataErrors.INCORRECT_INN
        ) {
          return showServerError()
        }
        
        if (error.response?.data?.errors?.nam) {
          form.setFields([
            {
              name: 'firstName',
              errors: [error.response.data.errors.nam]
            }
          ])
        }
        if (error.response?.data?.errors?.fam) {
          form.setFields([
            {
              name: 'lastName',
              errors: [error.response.data.errors.fam]
            }
          ])
        }
        if (error.response?.data?.errors?.otch) {
          form.setFields([
            {
              name: 'middleName',
              errors: [error.response.data.errors.otch]
            }
          ])
        }

        if (error.response?.data?.message === ECheckSellerDataErrors.INVALID_PASSPORT || error.response?.data?.message === ECheckSellerDataErrors.INN_NOT_FOUND || error.response?.data?.message === ECheckSellerDataErrors.INCORRECT_INN) {
          setAlertData({ visible: true, type: ECheckSellerDataTypes[error.response.data.message] })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }

        if (error.response?.data?.message === ECheckSellerDataErrors.INVALID_PASSPORT) {
          form.setFields([
            {
              name: 'passportData',
              errors: ['']
            }
          ])
        }

        if (error.response?.data?.message === ECheckSellerDataErrors.INN_NOT_FOUND) {
          form.setFields([
            {
              name: 'firstName',
              errors: ['']
            },
            {
              name: 'lastName',
              errors: ['']
            },
            {
              name: 'passportData',
              errors: ['']
            },
            {
              name: 'birthDate',
              errors: ['']
            }
          ])
          if (!noMiddleName) {
            form.setFields([
              {
                name: 'middleName',
                errors: ['']
              }
            ])
          }
        }

        if (error.response?.data?.message === ECheckSellerDataErrors.INCORRECT_INN) {
          form.setFields([
            {
              name: 'physicalINN',
              errors: ['']
            }
          ])
        }
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async (data: ISellerCreateAuthForm) => {
    try {
      setIsLoading(true)
      const normalizedData = getSubmitData(data)
      await authApi.createSeller(token, normalizedData)
      onSuccess()
      showMessage(
        'success',
        'Ваша заявка принята',
        'С вами свяжется менеджер для подтверждения данных'
      )
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthContentWrapper
      width={764}
      title='Информация о продавце'
      titleLevel={2}
      titleOffset={30}
      titleClass={styles.title}
      padding={sellerType ? '40px 56px 57px' : '34px 56px 16px'}
      onGoBack={onGoBack}
    >
      <Form form={form} name='sellerInfo' size='large' onFinish={onSubmit}>
        <Form.Item
          name='type'
          label='Выберите тип лица'
          rules={[
            {
              required: true,
              message: 'Выберите тип лица'
            }
          ]}
          rootClassName={styles.sellerType}
          hasFeedback
        >
          <Select
            placeholder='Выберите тип лица'
            options={[
              { value: ESellerTypes.LEGAL, label: 'Юридическое лицо' },
              { value: ESellerTypes.PHYSICAL, label: 'Физическое лицо' }
            ]}
          />
        </Form.Item>
        <InfoAlert
          isVisible={alertData.visible}
          type={alertData.type}
          onClose={closeAlert}
        />
        {sellerType && (
          <>
            <Form.Item
              name='firstName'
              label='Имя'
              rules={[
                {
                  required: true,
                  message: 'Заполните имя'
                },
                {
                  whitespace: true,
                  message: 'Заполните имя'
                },
                ruNamePattern
              ]}
              hasFeedback
            >
              <Input placeholder='Иван' />
            </Form.Item>
            <Form.Item
              name='lastName'
              label='Фамилия'
              rules={[
                {
                  required: true,
                  message: 'Заполните фамилию'
                },
                {
                  whitespace: true,
                  message: 'Заполните фамилию'
                },
                ruNamePattern
              ]}
              hasFeedback
            >
              <Input placeholder='Иванов' />
            </Form.Item>
            <div className={styles.middleName}>
              <Form.Item
                name='middleName'
                label='Отчество'
                rules={
                  noMiddleName
                    ? []
                    : [
                      {
                        required: true,
                        message: 'Заполните отчество'
                      },
                      {
                        whitespace: true,
                        message: 'Заполните отчество'
                      },
                      ruNamePattern
                    ]
                }
                style={{ flexGrow: 1 }}
                hasFeedback
              >
                <Input placeholder='Иванович' />
              </Form.Item>
              <Form.Item
                name='noMiddleName'
                label='Нет отчества'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
            </div>
          </>
        )}
        {sellerType === ESellerTypes.PHYSICAL && (
          <>
            <div className={styles.passportData}>
              <Form.Item
                name='passportData'
                label='Паспортные данные'
                rules={[
                  {
                    required: true,
                    message: 'Заполните серию и номер паспорта'
                  },
                  {
                    min: 12,
                    message: 'Заполните серию и номер паспорта'
                  }
                ]}
                className={styles.passportDataField}
                hasFeedback
              >
                <InputMask mask='99 99 999999' maskChar={null}>
                  {/* @ts-ignore */}
                  {() => <Input placeholder='__ __ ______' />}
                </InputMask>
              </Form.Item>
              <Form.Item
                name='birthDate'
                label='Дата рождения'
                rules={[
                  {
                    required: true,
                    message: 'Заполните дату рождения'
                  }
                ]}
                style={{ maxWidth: 274 }}
              >
                <DatePicker
                  format='DD/MM/YYYY'
                  placeholder='19/02/2002'
                  inputReadOnly
                />
              </Form.Item>
            </div>
            <Form.Item
              name='passportPhoto'
              valuePropName='fileList'
              getValueFromEvent={normalizeFile}
              label='Фото паспорта'
              rules={[
                {
                  required: true,
                  message: 'Загрузите фотографию паспорта'
                }
              ]}
              rootClassName={classNames({
                [styles.passportPhotoWrapper]: isPassportPhoto
              })}
            >
              <Upload
                accept='image/png, image/jpeg, image/jpg'
                maxCount={1}
                customRequest={() => false}
                beforeUpload={(file) => {
                  const isValid =
                    file.type === 'image/png' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/jpeg'
                  if (!isValid) {
                    showMessage(
                      'error',
                      'Поддерживаются только форматы JPG, JPEG, PNG',
                      ''
                    )
                  } else {
                    message.success('Файл успешно загружен')
                  }
                  return isValid || Upload.LIST_IGNORE
                }}
                itemRender={(_, file, fileList, { remove }) => {
                  return (
                    <div className={styles.passportPhoto}>
                      <PaperClipOutlined style={{ opacity: 0.45 }} />
                      <Typography.Text style={{ color: '#1677FF' }}>
                        {`passport.${file.type?.split('/')[1]}`}
                      </Typography.Text>
                      <CloseOutlined
                        className={styles.passportPhotoRemove}
                        onClick={remove}
                      />
                    </div>
                  )
                }}
              >
                <Button size='middle' icon={<UploadOutlined />}>
                  Загрузить фото
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name='physicalINN'
              label='ИНН'
              rules={[
                {
                  required: true,
                  message: 'Заполните ИНН'
                },
                {
                  min: 12,
                  message: 'Заполните ИНН корректно'
                }
              ]}
              hasFeedback
            >
              <InputMask mask='999999999999' maskChar={null}>
                {/* @ts-ignore */}
                {() => <Input placeholder='Введите ИНН' />}
              </InputMask>
            </Form.Item>
          </>
        )}
        {sellerType === ESellerTypes.LEGAL && (
          <>
            <Form.Item
              name='legalINN'
              label='ИНН'
              rules={[
                {
                  required: true,
                  message: 'Заполните ИНН'
                },
                {
                  min: 10,
                  message: 'Заполните ИНН корректно'
                },
                {
                  validator: (_, value) =>
                    value.length === 11 ? Promise.reject(new Error('Заполните ИНН корректно')) : Promise.resolve()
                }
              ]}
              hasFeedback
            >
              <InputMask mask='999999999999' maskChar={null}>
                {/* @ts-ignore */}
                {() => (
                  <Input
                    placeholder='Введите ИНН'
                    maxLength={12}
                  />
                )}
              </InputMask>
            </Form.Item>
          </>
        )}
        {sellerType && (
          <>
            <Form.Item
              name='phone'
              label='Номер телефона'
              rules={[
                {
                  required: true,
                  message: 'Заполните номер телефона'
                },
                {
                  min: 18,
                  message: 'Заполните номер телефона'
                }
              ]}
              hasFeedback
            >
              <InputMask mask='+7 (999) 999-99-99' maskChar={null}>
                {/* @ts-ignore */}
                {() => <Input placeholder='Введите номер телефона' />}
              </InputMask>
            </Form.Item>
            <Form.Item
              name='shopName'
              label='Название магазина'
              rules={[
                {
                  required: true,
                  message: 'Заполните название магазина'
                },
                {
                  whitespace: true,
                  message: 'Заполните название магазина'
                }
              ]}
              hasFeedback
            >
              <Input placeholder='Введите название' />
            </Form.Item>
            {(sellerType === ESellerTypes.LEGAL && isNeedLegalChecking) || (sellerType === ESellerTypes.PHYSICAL && isNeedPhysicalChecking) ? (
              <Button
                block
                htmlType='button'
                type='primary'
                size='large'
                icon={<LoginOutlined />}
                loading={isLoading}
                onClick={sellerType === ESellerTypes.LEGAL ? checkLegalINN : checkPhysicData}
              >
                Проверить
              </Button>
            ) : (
              <Button
                block
                htmlType='submit'
                type='primary'
                size='large'
                icon={<LoginOutlined />}
                loading={isLoading}
              >
                Отправить заявку
              </Button>
            )}
          </>
        )}
      </Form>
    </AuthContentWrapper>
  )
}
