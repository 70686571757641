import React from 'react'
import { Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { ModerationIcon } from '../ModerationIcon/ModerationIcon'
import styles from './SelectedItem.module.scss'

interface ISelectedItemProps {
  title: string
  isSelected: boolean
  isCloseIcon?: boolean
  isOnModeration?: boolean
  onCloseIconClick?: () => void
  onClick: () => void
}

export const SelectedItem: React.FC<ISelectedItemProps> = React.memo(({ title, isSelected, isCloseIcon, isOnModeration, onClick, onCloseIconClick }) => {
  return (
    <div className={styles.wrapper} style={{ backgroundColor: isSelected ? '#1677FF' : 'transparent' }} onClick={onClick}>
      <Typography.Text style={{ color: isSelected ? '#FFFFFF' : '#1D1D1D' }}>{title}</Typography.Text>
      {!isSelected && isOnModeration && <ModerationIcon width={18} height={15} />}
      {isSelected && isCloseIcon && <CloseOutlined style={{ color: '#FFFFFF', fontSize: 15 }} onClick={e => {
        e.stopPropagation()
        if (onCloseIconClick) {
          onCloseIconClick()
        }
      }} />}
    </div>
  )
})