import { MenuProps } from 'antd'
import {
  ApiOutlined,
  AppstoreAddOutlined,
  CarOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  IdcardOutlined,
  NotificationOutlined,
  ShoppingOutlined,
  StockOutlined
} from '@ant-design/icons'
import { Navigate, createBrowserRouter } from 'react-router-dom'
import { UsersList } from 'pages/superAdmin/Users/List/List'
import { SellersList } from 'pages/superAdmin/Sellers/List/List'
// import { Reviews } from 'pages/superAdmin/Reviews/Reviews'
import { AddUser } from 'pages/superAdmin/Users/Add/Add'
import { EditUser } from 'pages/superAdmin/Users/Edit/Edit'
import { GoodsEdit } from 'pages/superAdmin/Goods/Edit/Edit'
import { AppWrapper } from 'components/layout/AppWrapper/AppWrapper'
import { superAdminRoutes } from 'shared/constants/routes'
import { AddSeller } from 'pages/superAdmin/Sellers/Add/Add'
import { SellerWrapper } from 'components/sellers/SellerWrapper/SellerWrapper'
import { SellerInfo } from 'pages/superAdmin/Sellers/Edit/Info/Info'
import { SellerGoods } from 'pages/superAdmin/Sellers/Edit/Goods/Goods'
import { SellerOrders } from 'pages/superAdmin/Sellers/Edit/Orders/Orders'
import { SellerShops } from 'pages/superAdmin/Sellers/Edit/Shops/Shops'
import { SellerDelivery } from 'pages/superAdmin/Sellers/Edit/Delivery/Delivery'
import { SellerAccounting } from 'pages/superAdmin/Sellers/Edit/Accounting/Accounting'
import { SellerApiMoySklad } from 'pages/superAdmin/Sellers/Edit/ApiMoySklad/ApiMoySklad'
import { SellerNotificationsTelegram } from 'pages/superAdmin/Sellers/Edit/NotificationsTelegram/NotificationsTelegram'
import { SuperOrder } from 'pages/superAdmin/SuperOrder/SuperOrder'
import { Categories } from 'pages/superAdmin/Categories/Categories'
import { GoodsList } from 'pages/superAdmin/Goods/List/GoodsList'
import { OrdersList } from 'pages/superAdmin/Orders/List/OrdersList'
import { OrderEdit } from 'pages/superAdmin/Orders/Edit/OrderEdit'
import { ActionsNotifications } from 'pages/superAdmin/Actions/Notifications/Notifications'
import { ActionsWrapper } from 'components/actions/ActionsWrapper/ActionsWrapper'
import { Horses } from 'pages/superAdmin/Horses/Horses'
import { CollectionList } from 'pages/superAdmin/Collections/CollectionList'
import { CollectionEdit } from 'pages/superAdmin/Collections/CollectionEdit'

const sellerLinks: MenuProps['items'] = [
  {
    label: 'Основная информация',
    key: superAdminRoutes.SELLER_INFO,
    icon: <IdcardOutlined />
  },
  {
    label: 'Товары',
    key: superAdminRoutes.SELLER_GOODS,
    icon: <AppstoreAddOutlined />
  },
  {
    label: 'Заказы',
    key: superAdminRoutes.SELLER_ORDERS,
    icon: <ShoppingOutlined />
  },
  {
    label: 'Адреса магазинов',
    key: superAdminRoutes.SELLER_SHOPS,
    icon: <EnvironmentOutlined />
  },
  {
    label: 'Способы доставки',
    key: superAdminRoutes.SELLER_DELIVERY,
    icon: <CarOutlined />
  },
  {
    label: 'Бухгалтерия',
    key: superAdminRoutes.SELLER_ACCOUNTING,
    icon: <StockOutlined />
  },
  {
    label: 'API Синхронизация',
    key: 'api',
    icon: <ApiOutlined />,
    children: [
      {
        label: 'Мой склад',
        key: superAdminRoutes.SELLER_API_MOYSKLAD
      }
    ]
  },
  {
    label: 'Уведомления',
    key: 'notifications',
    icon: <NotificationOutlined />,
    children: [
      {
        label: 'Телеграм',
        key: superAdminRoutes.SELLER_NOTIFICATIONS_TELEGRAM
      }
    ]
  }
]

const actionsLinks: MenuProps['items'] = [
  {
    label: 'Глобальное уведомление',
    key: superAdminRoutes.ACTIONS_NOTIFICATIONS,
    icon: <GlobalOutlined />
  }
]

export const superAdminRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppWrapper />,
    children: [
      {
        index: true,
        element: <Navigate to={superAdminRoutes.GOODS} />
      },
      {
        path: superAdminRoutes.USERS,
        element: <UsersList />
      },
      {
        path: superAdminRoutes.USERS_ADD,
        element: <AddUser />
      },
      {
        path: superAdminRoutes.USERS_EDIT,
        element: <EditUser />
      },
      {
        path: superAdminRoutes.SELLERS,
        element: <SellersList />
      },
      {
        path: superAdminRoutes.SELLERS_ADD,
        element: <AddSeller />
      },
      {
        path: superAdminRoutes.SELLER,
        element: <SellerWrapper type='seller' links={sellerLinks} />,
        children: [
          {
            index: true,
            element: <SellerInfo />
          },
          {
            path: superAdminRoutes.SELLER_GOODS,
            element: <SellerGoods />
          },
          {
            path: superAdminRoutes.SELLER_ORDERS,
            element: <SellerOrders />
          },
          {
            path: superAdminRoutes.SELLER_SHOPS,
            element: <SellerShops />
          },
          {
            path: superAdminRoutes.SELLER_DELIVERY,
            element: <SellerDelivery />
          },
          {
            path: superAdminRoutes.SELLER_ACCOUNTING,
            element: <SellerAccounting />
          },
          {
            path: superAdminRoutes.SELLER_API_MOYSKLAD,
            element: <SellerApiMoySklad />
          },
          {
            path: superAdminRoutes.SELLER_NOTIFICATIONS_TELEGRAM,
            element: <SellerNotificationsTelegram />
          }
        ]
      },
      {
        path: superAdminRoutes.ORDERS,
        element: <OrdersList />
      },
      {
        path: superAdminRoutes.ORDER,
        element: <OrderEdit />
      },
      {
        path: superAdminRoutes.SUPER_ORDER,
        element: <SuperOrder />
      },
      {
        path: superAdminRoutes.GOODS,
        element: <GoodsList />
      },
      {
        path: superAdminRoutes.GOODS_EDIT,
        element: <GoodsEdit />
      },
      {
        path: superAdminRoutes.CATEGORIES,
        element: <Categories />
      },
      {
        path: superAdminRoutes.HORSES,
        element: <Horses />
      },
      {
        path: superAdminRoutes.ACTIONS,
        element: <ActionsWrapper links={actionsLinks} />,
        children: [
          {
            index: true,
            element: <Navigate to={superAdminRoutes.ACTIONS_NOTIFICATIONS} />
          },
          {
            path: superAdminRoutes.ACTIONS_NOTIFICATIONS,
            element: <ActionsNotifications />
          }
        ]
      },
      // {
      //   path: superAdminRoutes.REVIEWS,
      //   element: <Reviews />
      // },
      {
        path: superAdminRoutes.COLLECTIONS,
        element:<CollectionList />
      },
      {
        path: superAdminRoutes.COLLECTIONS_EDIT,
        element: <CollectionEdit />
      },
      {
        path: '*',
        element: <Navigate to='/' />
      }
    ]
  }
])
