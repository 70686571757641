import React from 'react'
import { Menu, MenuProps } from 'antd'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import styles from './ActionsWrapper.module.scss'

interface IAnchorsWrapperProps {
  links: MenuProps['items']
}

export const ActionsWrapper: React.FC<IAnchorsWrapperProps> = ({
  links
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [current, setCurrent] = React.useState('')

  React.useEffect(() => {
    setCurrent(location.pathname.split('/')[2])
  }, [location.pathname])

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
    navigate(e.key)
  }

  return (
    <div>
      <Menu
        mode='inline'
        items={links}
        selectedKeys={[current]}
        onClick={onClick}
        className={classNames('side-menu', styles.menu)}
      />
      <div className={classNames('side-content', styles.content)}>
        <Outlet />
      </div>
    </div>
  )
}
