import React from 'react'
import { Button, Form, Input, Modal, Table, Typography } from 'antd'
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { transactionsApi } from 'api/transaction'
import { ESellerTransactionsType, ITransaction } from 'models/transaction'
import { EOrderStatus } from 'models/orders'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { showServerError } from 'shared/utils/showServerError'
import { getTransactionTypeName } from 'shared/utils/transactions/getTransactionTypeName'
import { floatNumbersPattern } from 'shared/utils/patterns'
import { normalizeNumber } from 'shared/utils/normalizeNumber'
import styles from './TransactionsModal.module.scss'

interface ITransactionsModalProps {
  isOpen: boolean
  orderId: string
  orderStatus: EOrderStatus
  onClose: () => void
}

interface IRefundFormData {
  comment: string
  sum: string
}

export const TransactionsModal: React.FC<ITransactionsModalProps> = React.memo(
  ({ isOpen, orderId, orderStatus, onClose }) => {
    const [transactions, setTransactions] = React.useState<ITransaction[]>([])
    const [isLoading, setIsLoading] = React.useState(false)

    const [form] = Form.useForm()

    const getTransactions = async () => {
      try {
        setIsLoading(true)
        const response = await transactionsApi.getTransactions({
          skip: '0',
          limit: '500',
          order_id: orderId
        })
        setTransactions(response.data.transactions)
      } catch (e) {
        showServerError(e)
      } finally {
        setIsLoading(false)
      }
    }

    React.useEffect(() => {
      if (isOpen) {
        getTransactions()
      } else {
        form.resetFields()
      }
    }, [isOpen])

    const refundRequest = async (data: IRefundFormData) => {
      try {
        await transactionsApi.createRefundOrderTransaction(orderId, {
          comment: data.comment,
          custom_sum: normalizeNumber(data.sum)
        })
        getTransactions()
        form.resetFields()
      } catch (e) {
        showServerError(e)
      }
    }

    const onSubmitRefundForm = async (data: IRefundFormData) => {
      Modal.confirm({
        centered: true,
        title: 'Внимание!',
        content: 'Возврат средств будет невозможно отменить, после подтверждения.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Подтвердить',
        cancelText: 'Отменить',
        onOk() {
          return new Promise((resolve) => {
            refundRequest(data).then(resolve)
          })
        },
        onCancel() {}
      })
    }

    const columns: ColumnsType<ITransaction> = React.useMemo(
      () => [
        {
          title: 'Тип транзакции',
          dataIndex: 'type',
          width: '25%',
          render: (_, { type }) => {
            return (
              <Typography.Text>{getTransactionTypeName(type)}</Typography.Text>
            )
          }
        },
        {
          title: 'Сумма',
          dataIndex: 'sum',
          width: '25%',
          render: (_, { sum, type }) => {
            return (
              <Typography.Text>{`${
                type === ESellerTransactionsType.SALE ? '+' : ''
              }${getNormalizedPrice(sum)}`}</Typography.Text>
            )
          }
        },
        {
          title: 'Дата',
          dataIndex: 'date',
          width: '25%',
          render: (_, { createdAt }) => {
            return (
              <Typography.Text>
                {moment(createdAt).locale('ru').format('LL')}
              </Typography.Text>
            )
          }
        },
        {
          title: 'Юмани ID',
          dataIndex: 'yooMoneyId',
          width: '25%',
          render: (_, { payment_id }) => {
            return (
              <Typography.Link
                href={`https://yookassa.ru/my/payments?search=${payment_id}`}
                target='_blank'
              >
                {payment_id}
              </Typography.Link>
            )
          }
        }
      ],
      [transactions]
    )

    return (
      <Modal
        centered
        open={isOpen}
        onCancel={onClose}
        footer={null}
        wrapClassName={styles.wrapper}
        className={styles.modal}
      >
        <Typography.Title level={3} style={{ marginBottom: 24 }}>
          Транзакции
        </Typography.Title>
        <Table
          scroll={{
            x: 700
          }}
          columns={columns}
          dataSource={transactions}
          loading={isLoading}
          rowKey={(el) => el._id}
          pagination={false}
          style={{ marginBottom: 34 }}
        />
        {orderStatus !== EOrderStatus.ON_CONFIRMATION && orderStatus !== EOrderStatus.CANCELED && orderStatus !== EOrderStatus.NOT_PAID && (
          <>
            <Typography.Title level={3} style={{ marginBottom: 24 }}>
              Возврат
            </Typography.Title>
            <Form
              form={form}
              name='refund'
              className={styles.refundForm}
              onFinish={onSubmitRefundForm}
            >
              <Form.Item
                name='comment'
                label='Причина'
                rules={[
                  {
                    required: true,
                    message: 'Заполните причину возврата'
                  },
                  {
                    whitespace: true,
                    message: 'Заполните причину возврата'
                  }
                ]}
                style={{ flex: 1.3 }}
                hasFeedback
              >
                <Input.TextArea
                  placeholder='Опишите причину возврата'
                  rows={4}
                />
              </Form.Item>
              <div>
                <Form.Item
                  name='sum'
                  label='Сумма'
                  rules={[
                    {
                      required: true,
                      message: 'Введите сумму'
                    },
                    {
                      whitespace: true,
                      message: 'Введите сумму'
                    },
                    {
                      pattern: floatNumbersPattern,
                      message: 'Укажите сумму корректно'
                    }
                  ]}
                  style={{ flex: 1, marginBottom: 17 }}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
                <div className={styles.refundButton}>
                  <Button
                    danger
                    type='primary'
                    htmlType='submit'
                  >
                    Вернуть
                  </Button>
                </div>
              </div>
              <div className={styles.refundTextInfoWrapper}>
                <InfoCircleOutlined className={styles.refundTextInfoIcon} />
                <Typography.Text className={styles.refundTextInfo}>
                  При уже начатом процессе доставки товара (продавец передал
                  товар в службу СДЕК) от статуса “в доставке”, мы не можем
                  вернуть пользователю стоимость доставки.
                </Typography.Text>
              </div>
            </Form>
          </>
        )}
      </Modal>
    )
  }
)
