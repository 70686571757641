import { Link, Outlet } from 'react-router-dom'
import { ERoles } from 'models/auth'
import { appSelectors } from 'store/app'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import logo from 'assets/icons/logo.svg'
import { SuperAdminMenu } from '../SuperAdminMenu/SuperAdminMenu'
import { AdminMenu } from '../AdminMenu/AdminMenu'
import styles from './AppWrapper.module.scss'

export const AppWrapper: React.FC = () => {
  const role = useAppSelector(appSelectors.selectRole)
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <Link to='/'>
            <img src={logo} alt='Логотип компании' className={styles.logo} />
          </Link>
          {role === ERoles.ADMIN ? <SuperAdminMenu /> : <AdminMenu />}
        </header>
        <Outlet />
      </div>
    </div>
  )
}