import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { decodeToken } from 'react-jwt'
import { ITokenParseUser, ITokensResponse } from 'models/auth'
import { TOKEN } from 'shared/constants/app'
import { AppState } from './types'

const initialState: AppState = {
  token: null,
  sellerId: null,
  role: null
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<ITokensResponse>) {
      const user = decodeToken(action.payload.access_token) as ITokenParseUser
      state.sellerId = user?.seller_id ?? null
      state.role = user.role
      state.token = action.payload.access_token
      localStorage.setItem(TOKEN, action.payload.access_token)
    },
    logout(state) {
      state.token = null
      state.sellerId = null
      state.role = null
      localStorage.removeItem(TOKEN)
    }
  }
})

export default appSlice.reducer
export const actions = appSlice.actions
