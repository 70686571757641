import { ESellerTypes, IAdminCreatePhysicalSellerRequest, IAdminCreateSellerRequest, IAdminUpdateSellerRequest, ISellerFormData } from 'models/seller'
import { normalizePhone } from 'shared/utils/normalizePhone'

export const getLegalSubmitData = (data: ISellerFormData): IAdminCreateSellerRequest => {
  return {
    seller_info: {
      type: data.type,
      name: data.firstName.trim(),
      surname: data.lastName.trim(),
      patronymic: data?.middleName?.trim(),
      opt_otch: data.noMiddleName,
      email: data.email.trim(),
      tel: normalizePhone(data.phone),
      password: data.password.trim()
    },
    banking_details: {
      name: data?.bankName?.trim() as string,
      INN: data.legalINN as string,
      BIK: data.bankBIK as string,
      PC: data.bankPC as string,
      KC: data.bankKC as string
    },
    shop_details: {
      shop_name: data.shopName.trim(),
      desc: data.desc.trim()
    },
    logo: data.logo[0].originFileObj,
    banner: data.banner[0].originFileObj
  }
}

export const getPhysicSubmitData = (data: ISellerFormData): IAdminCreatePhysicalSellerRequest => {
  return {
    seller_info: {
      type: data.type,
      name: data.firstName.trim(),
      surname: data.lastName.trim(),
      patronymic: data?.middleName?.trim(),
      opt_otch: data.noMiddleName,
      email: data.email.trim(),
      tel: normalizePhone(data.phone),
      password: data.password.trim(),
      birthday: new Date(data.birthDate).toString()
    },
    banking_details: {
      name: data?.bankName?.trim(),
      INN: data.physicalINN as string,
      BIK: data?.bankBIK,
      KPP: data?.physicalKPP,
      PC: data?.bankPC,
      KC: data?.bankKC
    },
    shop_details: {
      shop_name: data.shopName.trim(),
      desc: data.desc.trim()
    },
    logo: data.logo[0].originFileObj,
    banner: data.banner[0].originFileObj,
    passport_photo: data.passportPhoto[0].originFileObj,
    card_number: data?.cardNumber?.replaceAll(' ', ''),
    passport_details: data.passportData as string
  }
}

export const getEditSubmitData = (data: ISellerFormData, sellerId: string, toDeletePhotos: string[]): IAdminUpdateSellerRequest => ({
  seller_info: {
    type: data.type,
    name: data.firstName.trim(),
    surname: data.lastName.trim(),
    patronymic: data?.middleName?.trim(),
    opt_otch: data.noMiddleName,
    email: data.email.trim(),
    tel: normalizePhone(data.phone),
    birthday: data.birthDate ? new Date(data.birthDate).toString() : undefined
  },
  banking_details: {
    name: data?.bankName?.trim(),
    BIK: data?.bankBIK,
    KPP: data.type === ESellerTypes.LEGAL ? data.legalKPP : data.physicalKPP,
    PC: data?.bankPC,
    KC: data?.bankKC,
    legal_address: data?.legalAddress,
    INN: data.type === ESellerTypes.LEGAL ? data.legalINN : data.physicalINN
  },
  shop_details: {
    shop_name: data.shopName.trim(),
    desc: data.desc.trim()
  },
  company_info: {
    name: data?.companyName?.trim(),
    okpo: data?.OKPO,
    ogrn: data?.OGRN,
    type: data?.organizationType
  },
  logo: data.logo[0]?.originFileObj ?? data.logo[0].uid,
  banner: data.banner[0]?.originFileObj ?? data.banner[0].uid,
  passport_photo: data?.passportPhoto?.[0]?.originFileObj ?? data?.passportPhoto?.[0]?.uid,
  seller_id: sellerId,
  toDeletePhotos,
  passport_details: data?.passportData,
  card_number: data?.cardNumber
})
