import { Dropdown, MenuProps, Tag } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface'
import styles from './StatusDropdown.module.scss'

interface IStatusDropdownProps {
  items: MenuProps['items']
  color: string
  label: string
  className?: string
  onClick: (e: MenuInfo) => void
}

export const StatusDropdown: React.FC<IStatusDropdownProps> = ({ items, color, label, className, onClick }) => {
  return (
    <Dropdown
      menu={{ items, onClick }}
      trigger={['click']}
      className={className}
    >
      <Tag
        color={color}
        icon={<DownOutlined className={styles.icon} />}
        className={styles.tag}
      >
        {label}
      </Tag>
    </Dropdown>
  )
}
