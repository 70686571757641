import { Typography } from 'antd'
import styles from './ListInfoItem.module.scss'

interface IListInfoItemProps {
  name: string
  value: string | number
  style?: React.CSSProperties
  valueStyle?: React.CSSProperties
}

export const ListInfoItem: React.FC<IListInfoItemProps> = ({ name, value, style, valueStyle }) => {
  return (
    <div className={styles.wrapper} style={{ ...style }}>
      <Typography.Text className={styles.name}>{name}:</Typography.Text>
      <Typography.Text className={styles.value} style={valueStyle}>{value}</Typography.Text>
    </div>
  )
}
