import { Tag } from 'antd'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { appSelectors } from 'store/app'
import { EProductStatus } from 'models/product'
import { getProductStatusItems, productTags } from 'shared/constants/product'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { ERoles } from 'models/auth'
import styles from '../ProductForm.module.scss'

interface IProductStatusProps {
  activeStatus: EProductStatus | undefined
  onStatusClick: (
    status:
      | EProductStatus.PUBLISHED
      | EProductStatus.DRAFT
      | EProductStatus.REJECTED
  ) => void
}

export const ProductStatus: React.FC<IProductStatusProps> = ({
  activeStatus,
  onStatusClick
}) => {
  const role = useAppSelector(appSelectors.selectRole)

  if (!activeStatus) {
    return null
  }

  if (role === ERoles.USER && activeStatus === EProductStatus.MODERATION) {
    return (
      <Tag color={productTags[activeStatus].color} className={styles.status}>
        {productTags[activeStatus].label}
      </Tag>
    )
  }

  return (
    <StatusDropdown
      items={getProductStatusItems(activeStatus, role as ERoles)}
      label={productTags[activeStatus].label}
      color={productTags[activeStatus].color}
      onClick={(e) =>
        onStatusClick(
          e.key as
            | EProductStatus.PUBLISHED
            | EProductStatus.DRAFT
            | EProductStatus.REJECTED
        )
      }
      className={styles.status}
    />
  )
}
