import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { ConfigProvider } from 'antd'
import { Config } from '@vkontakte/superappkit'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { store } from 'store'
import { mainTheme } from 'theme/mainTheme'
import ru_RU from 'antd/es/locale/ru_RU'
import { injectStore } from 'api'
import { GOOGLE_CLIENT_ID, VK_APP_ID } from 'shared/constants/app'
import { App } from './App'
import 'moment/locale/ru'
import '@mivis/horsesmart-api'
import '@mivis/petmart-api'
import 'antd/dist/reset.css'
import 'styles/index.scss'

Sentry.init({
  dsn: 'https://c216325ae9a5dde90666fe30b37c8220@o4506977240350720.ingest.us.sentry.io/4506977415331840'
})

Config.init({
  appId: Number(VK_APP_ID)
})

injectStore(store)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ConfigProvider theme={mainTheme} locale={ru_RU}>
    <Provider store={store}>
      <GoogleOAuthProvider
        clientId={GOOGLE_CLIENT_ID}
      >
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </ConfigProvider>
)
