import React from 'react'
import { Button, Form, Input, Modal, Select } from 'antd'
import { AxiosError } from 'axios'
import { IParsedCategory } from 'models/product'
import { productApi } from 'api/product'
import { showMessage } from 'shared/utils/showMessage'
import { showServerError } from 'shared/utils/showServerError'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'

interface ICreateCategoryProps {
  isOpen: boolean
  categories: IParsedCategory[]
  onSuccess: () => void
  close: () => void
}

interface IFormData {
  category_id: string
  name: string
}

export const CreateCategory: React.FC<ICreateCategoryProps> = ({
  isOpen,
  categories,
  onSuccess,
  close
}) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async (data: IFormData) => {
    try {
      setIsLoading(true)
      await productApi.createSubCategory(data)
      showMessage('success', 'Подкатегория успешно добавлена', '')
      onSuccess()
      form.resetFields()
      close()
      bodyScrollLock.disable()
    } catch (e) {
      const error = e as AxiosError
      if (error.response?.status === 400) {
        return form.setFields([
          {
            name: 'name',
            errors: ['Данная подкатегория уже существует']
          }
        ])
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    form.resetFields()
    close()
  }

  return (
    <Modal
      centered
      title='Добавить категорию'
      open={isOpen}
      footer={null}
      closable={false}
      onCancel={onClose}
    >
      <Form form={form} name='productCategory' onFinish={onSubmit}>
        <Form.Item
          name='category_id'
          label='Категория'
          tooltip='Выберите категорию'
          rules={[
            {
              required: true,
              message: 'Выберите категорию'
            }
          ]}
          hasFeedback
          style={{ marginTop: 20 }}
        >
          <Select
            placeholder='Категория'
            fieldNames={{ label: 'title' }}
            options={categories}
            showSearch
            allowClear
          />
        </Form.Item>
        <Form.Item
          name='name'
          label='Подкатегория'
          tooltip='Введите название подкатегории'
          rules={[
            {
              required: true,
              message: 'Заполните название подкатегории'
            }
          ]}
          hasFeedback
        >
          <Input placeholder='Название подкатегории' />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button htmlType='button' onClick={onClose}>
            Закрыть
          </Button>
          <Button htmlType='submit' type='primary' loading={isLoading}>
            Добавить
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
