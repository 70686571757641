import { ECollectionStatus } from 'models/collections'

export const getColorByStatus = (status: ECollectionStatus) => {
  switch (status) {
  case ECollectionStatus.ARCHIVED: return 'default'
  case ECollectionStatus.PUBLISHED: return 'success'  
  default: return 'initial'
  }
}

export const getStatus = (status: ECollectionStatus): string => {
  switch (status) {
  case ECollectionStatus.ARCHIVED: return 'Архив'
  case ECollectionStatus.PUBLISHED: return 'Опубликован'  
  default: return '-'
  }
}