import { notification } from 'antd'

export const showMessage = (
  type: 'error' | 'success' | 'warning' | 'info',
  message: React.ReactNode,
  description: string,
  duration?: number
) => {
  return notification[type]({
    style: { fontFamily: 'SFProText', width: 400 },
    message,
    description,
    duration
  })
}
