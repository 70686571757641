import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IProduct } from 'models/product'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getProductEditPath } from 'routing/utils/getProductEditPath'
import noProductImage from 'assets/images/no-image-product.png'
import { IMAGES_URL } from 'shared/constants/urls'
import { showServerError } from 'shared/utils/showServerError'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { productApi } from 'api/product'
import styles from './MostPurchasedProductsTable.module.scss'

interface ITransactionsTableProps {
  sellerId: string
}

export const MostPurchasedProductsTable: React.FC<ITransactionsTableProps> =
  React.memo(({ sellerId }) => {
    const navigate = useNavigate()
    const [products, setProducts] = React.useState<IProduct[]>([])
    const [isLoading, setIsLoading] = React.useState(false)

    const [currentPage, setCurrentPage] = React.useState(1)
    const [total, setTotal] = React.useState(0)
    const pageSize = 6

    const getProducts = async (skip: number) => {
      try {
        setIsLoading(true)
        const response = await productApi.getMostPurchasedProducts({
          skip: String(skip),
          limit: String(pageSize),
          seller_id: sellerId
        })
        setProducts(response.data.products)
        setTotal(response.data.count)
      } catch (e) {
        showServerError(e)
      } finally {
        setIsLoading(false)
      }
    }

    const onPageChange = (current: number) => {
      getProducts((current - 1) * pageSize)
      setCurrentPage(current)
    }

    React.useEffect(() => {
      getProducts(0)
      setCurrentPage(1)
    }, [])

    const columns: ColumnsType<IProduct> = React.useMemo(
      () => [
        {
          title: 'Артикул',
          dataIndex: 'vendor_code',
          width: '15%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { vendor_code }) => {
            return (
              <Typography.Text style={{ wordWrap: 'break-word' }} onClick={(event) => event.stopPropagation()}>{vendor_code}</Typography.Text>
            )
          }
        },
        {
          title: 'Фото',
          dataIndex: 'photo',
          width: 76,
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { _id, photo }) => {
            return !!photo.length ? (
              <div style={{ width: 62, height: 56, backgroundImage: `url(${IMAGES_URL}${photo[0]})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
            ) : (
              <img
                src={noProductImage}
                alt='Изображение товара'
                width={62}
                height={56}
              />
            )
          }
        },
        {
          title: 'Наименование товара',
          dataIndex: 'name',
          width: '27%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { name }) => {
            return (
              <Typography.Text onClick={(event) => event.stopPropagation()}>{name}</Typography.Text>
            )
          }
        },
        {
          title: 'Количество покупок',
          dataIndex: 'purchases_count',
          width: '27%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { purchases_count }) => {
            return (
              <Typography.Text onClick={(event) => event.stopPropagation()}>{purchases_count}</Typography.Text>
            )
          }
        },
        {
          title: 'Цена',
          dataIndex: 'price',
          width: '18%',
          onCell: ({ _id }) => {
            return {
              onClick: () => navigate(getProductEditPath(_id))
            }
          },
          render: (_, { price }) => {
            return (
              <Typography.Text onClick={(event) => event.stopPropagation()}>{getNormalizedPrice(price)}</Typography.Text>
            )
          }
        }
      ],
      [products]
    )

    return (
      <>
        <Typography.Title level={2} className={styles.title}>
          Самые покупаемые товары
        </Typography.Title>
        <Table
          scroll={{
            x: 750
          }}
          columns={columns}
          dataSource={products}
          loading={isLoading}
          rowKey={(el) => el._id}
          rowClassName='tableRow'
          pagination={{
            current: currentPage,
            onChange: onPageChange,
            pageSize,
            total,
            showSizeChanger: false
          }}
        />
      </>
    )
  })
