export const getNormalizedFormData = (data: object) => {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    const value: any = data[key as keyof typeof data]
    if (Array.isArray(value)) {
      value.forEach(el => typeof el === 'object' ? el instanceof File ? formData.append(key, el) : formData.append(key, JSON.stringify(el)) : formData.append(key, el))
    }
    else if (value instanceof File) {
      formData.append(key, value)
    }
    else if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value))
    } else if (typeof value === 'undefined') {}
    else {
      formData.append(key, value)
    }
  })
  return formData
}