import { UploadFile } from 'antd'
import { privateApi } from 'api'
import { AxiosResponse } from 'axios'
import { ICollection, ICreateCollection, IPopulatedCollection } from 'models/collections'
import { convertToFormData } from 'shared/utils/convertToFormData'

type CreateCollectionBody = Omit<ICreateCollection, 'photo'> & {photo: UploadFile[]}

export const collectionsApi = {
  getCollections:():Promise<AxiosResponse<ICollection[]>> => {
    return privateApi.get('/collections', {params: { limit: 100, skip: 0 }})
  },
  createCollection:(data: CreateCollectionBody) => {
    const formData = convertToFormData({data, isPhoto: true})    
    return privateApi.post('/collections', formData)
  }, 
  getCollection:(id: string): Promise<AxiosResponse<IPopulatedCollection>> =>  {
    return privateApi.get(`/collections/${id}`)
  },
  updateCollection:(id: string, data: CreateCollectionBody) => {
    const isPhoto = Boolean(data.photo[0].originFileObj)
    const formData = convertToFormData({data, isPhoto})    
    return privateApi.patch(`/collections/${id}`, formData)
  },
  deleteCollection:(id: string) => {
    return privateApi.delete(`/collections/${id}`)
  }
}
