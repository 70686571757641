import { Tag } from 'antd'
import { ERoles } from 'models/auth'
import { EProductStatus } from 'models/product'

interface IProductStatusItemData {
  key: EProductStatus,
  label: React.ReactNode
}

export const productTags = {
  [EProductStatus.MODERATION]: {
    value: EProductStatus.MODERATION,
    label: 'На модерации',
    color: 'geekblue'
  },
  [EProductStatus.PUBLISHED]: {
    value: EProductStatus.PUBLISHED,
    label: 'Опубликован',
    color: 'green'
  },
  [EProductStatus.DRAFT]: {
    value: EProductStatus.DRAFT,
    label: 'Черновик',
    color: 'orange'
  },
  [EProductStatus.REJECTED]: {
    value: EProductStatus.REJECTED,
    label: 'Отклонен',
    color: 'red'
  },
  [EProductStatus.ARCHIVED]: {
    value: EProductStatus.ARCHIVED,
    label: 'Удален',
    color: 'red'
  }
}

const publishedStatus: IProductStatusItemData = {
  key: EProductStatus.PUBLISHED,
  label: (
    <Tag color={productTags[EProductStatus.PUBLISHED].color}>
      {productTags[EProductStatus.PUBLISHED].label}
    </Tag>
  )
}

const draftedStatus: IProductStatusItemData = {
  key: EProductStatus.DRAFT,
  label: (
    <Tag color={productTags[EProductStatus.DRAFT].color}>
      {productTags[EProductStatus.DRAFT].label}
    </Tag>
  )
}

const rejectedStatus: IProductStatusItemData = {
  key: EProductStatus.REJECTED,
  label: (
    <Tag color={productTags[EProductStatus.REJECTED].color}>
      {productTags[EProductStatus.REJECTED].label}
    </Tag>
  )
}

export const getProductStatusItems = (status: EProductStatus, role: ERoles): IProductStatusItemData[] => {
  if (status === EProductStatus.MODERATION) {
    if (role === ERoles.ADMIN) {
      return [
        publishedStatus,
        draftedStatus,
        rejectedStatus
      ]
    }
    return []
  }
  if (status === EProductStatus.PUBLISHED) {
    return [
      draftedStatus,
      rejectedStatus
    ]
  }
  if (status === EProductStatus.DRAFT) {
    return [
      publishedStatus,
      rejectedStatus
    ]
  }
  if (status === EProductStatus.REJECTED) {
    return [
      publishedStatus,
      draftedStatus
    ]
  }
  return []
}
