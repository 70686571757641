import React from 'react'
import { Button, Form, Input } from 'antd'
import { AxiosError } from 'axios'
import { AuthContentWrapper } from 'components/layout/AuthContentWrapper/AuthContentWrapper'
import { LargeText } from 'components/ui'
import { authApi } from 'api/auth'
import { showServerError } from 'shared/utils/showServerError'
import { numbersPattern } from 'shared/utils/patterns'
import styles from './Code.module.scss'

interface ICodeForm {
  activation_code: string
}

interface ICodeProps {
  email: string
  onSuccess: () => void
  onGoBack: () => void
}

export const Code: React.FC<ICodeProps> = ({ email, onSuccess, onGoBack }) => {
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async (data: ICodeForm) => {
    try {
      setIsLoading(true)
      await authApi.activateEmail({
        email,
        activation_code: Number(data.activation_code)
      })
      onSuccess()
    } catch (e) {
      const error = e as AxiosError
      if (error.response?.status === 400) {
        return form.setFields([
          {
            name: 'activation_code',
            errors: ['Неверный код подтверждения']
          }
        ])
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthContentWrapper
      title='Восстановление пароля'
      titleOffset={15}
      titleLevel={2}
      titleClass={styles.title}
      padding='59px 66px 67px'
      onGoBack={onGoBack}
    >
      <LargeText className={styles.description}>
        Введите код подтверждения, направленный вам на почту
      </LargeText>
      <Form form={form} name='recoveryCode' size='large' onFinish={onSubmit}>
        <Form.Item
          name='activation_code'
          rules={[
            {
              required: true,
              message: 'Введите код подтверждения'
            },
            {
              pattern: numbersPattern,
              message: 'Введите корректный код подтверждения'
            }
          ]}
          hasFeedback
        >
          <Input placeholder='Код подтверждения' />
        </Form.Item>
        <Button block htmlType='submit' type='primary' loading={isLoading}>
          Восстановить пароль
        </Button>
      </Form>
    </AuthContentWrapper>
  )
}
