export interface IProductResponse extends Components.Schemas.IProductDraftResponse { }
export interface IProductQueryRequest extends Components.Schemas.IProductQueryRequest { }
export interface IProductQueryFilters extends Omit<Components.Schemas.IProductQueryRequest, 'skip' | 'limit' | 'status'> { }
export interface IProductQueryResponse extends Components.Schemas.IProductQueryResponse { }
export interface ICreateProduct extends Components.Schemas.ICreateProduct { }
export interface ICreateProductVariation extends Components.Schemas.ICreateVariation { }
export interface IUpdateProduct extends Components.Schemas.IUpdateProduct { }
export interface IUpdateProductVariation extends Partial<Components.Schemas.IUpdateProduct> { }
export interface IUpdateProductStatus extends Pick<Components.Schemas.IUpdateProduct, 'status' | 'id'> { }
export enum EProductStatus {
  DRAFT = 'DRAFT',
  MODERATION = 'MODERATION',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}
export interface IActionsProductRequest extends Partial<Components.Schemas.IActionsProductRequest> { }
export interface IActionsProductResponse extends Components.Schemas.IActionsProductResponse { }
export enum EActionsProductRequest {
  DELETE = 'toDelete',
  PUBLISH = 'toPublish',
  COPY = 'toCopy',
  DRAFT = 'toDraft',
  REJECT = 'toReject',
}

export interface ICategoryQueryRequest extends Components.Schemas.IAdminCategoryQueryRequest { }
export interface ICreateCategoryRequest extends Components.Schemas.ICreateCategoryRequest { }
export interface IUpdateCategoryRequest extends Components.Schemas.IUpdateCategoryRequest { }

export interface ICreateSubCategoryRequest extends Components.Schemas.ICreateSubCategoryRequest { }
export interface IUpdateSubCategoryRequest extends Components.Schemas.IUpdateSubCategoryRequest { }

export interface ICategory extends Components.Schemas.ICategory { }
export interface ISubCategory extends Components.Schemas.ISubCategory { }
export interface IParsedCategory extends Components.Schemas.IParsedCategory { }
export interface IParsedSubCategory extends Components.Schemas.IParsedSubCategory { }
export enum ESubCategoryStatus {
  MODERATION = 'MODERATION',
  ACTIVE = 'ACTIVE',
}

export interface ITopProductSalesQueryRequest extends Components.Schemas.IAdminTopSalesQueryRequest { }
export interface ITopProductSalesResponse extends Components.Schemas.IAdminTopSalesResponse { }
export interface IProduct extends Components.Schemas.IProduct { }

export interface ICharacteristic extends Components.Schemas.ICharacteristic { }
export interface IAddVariation extends Components.Schemas.IAddVariation { }
export interface IDimension extends Components.Schemas.IDimension { }
export enum EUnit {
  KG = 'KG',
  GR = 'GR',
}

export enum EProductType {
  PRODUCT = 'PRODUCT',
  VARIATION = 'VARIATION',
}

export interface IPreviewData {
  photo: any
  categoryName: string
  subCategoryName: string
  name: string
  price: number
  desc: string
  dimension: IDimension
  characteristics: ICharacteristic[]
  variations: IFrontVariation[]
}

export interface IProductFormData {
  id?: string
  name: string
  categories_id: string[]
  vendor_code: string
  price: string
  tax: number
  width: string
  length: string
  height: string
  weight: string
  unit: EUnit
  in_stock: string
  desc: string
  photo: any[]
  characteristicName?: string
  characteristicValues: string[]
  characteristics?: ICharacteristic[]
  variations?: IFrontVariation[]
}

export interface IFrontCategory extends ICategory {
  isNew: boolean
}

export interface IFrontSubCategory extends ISubCategory {
  isNew: boolean
}

export interface IFrontVariationProduct {
  id: string
  name: string
}

export interface IFrontVariation {
  firstCharacteristicName?: string,
  firstCharacteristicValue?: string,
  secondCharacteristicName?: string,
  secondCharacteristicValue?: string,
  product?: IFrontVariationProduct
}

export interface IChangeVariationProduct {
  visible: boolean
  variation: IFrontVariation | null
  variationIndex: number | null
}

export interface ICreateUpdateVariationProduct extends IChangeVariationProduct {
  type: 'create' | 'update' | null
}

export interface IProductVariationFormData {
  name: string
  vendor_code: string
  price: string
  tax: number
  width: string
  length: string
  height: string
  weight: string
  unit: EUnit
  in_stock: string
  desc?: string
  photo?: any[]
}
