import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Data } from 'components/auth/SignUp/Data/Data'
import { Confirm } from 'components/auth/SignUp/Confirm/Confirm'
import { CreatePassword } from 'components/auth/SignUp/CreatePassword/CreatePassword'
import { SellerInfo } from 'components/auth/SignUp/SellerInfo/SellerInfo'

enum ESteps {
  DATA = 'Data',
  CONFIRM = 'Confirm',
  CREATE_PASSWORD = 'CreatePassword',
  SELLER_INFO = 'SellerInfo'
}

export const SignUp: React.FC = () => {
  const navigate = useNavigate()
  const [token, setToken] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [isEmptyPassword, setIsEmptyPassword] = React.useState(false)
  const [step, setStep] = React.useState<ESteps>(ESteps.DATA)

  const setActiveData = React.useCallback(() => setStep(ESteps.DATA), [])
  const setActiveConfirm = React.useCallback(() => {
    setStep(ESteps.CONFIRM)
  }, [])
  const setActiveCreatePassword = React.useCallback(
    () => setStep(ESteps.CREATE_PASSWORD),
    []
  )
  const setActiveSellerInfo = React.useCallback(
    () => setStep(ESteps.SELLER_INFO),
    []
  )
  const setFinish = React.useCallback(() => navigate('/'), [navigate])

  if (step === ESteps.CONFIRM) {
    return (
      <Confirm
        email={email}
        onSuccess={setActiveSellerInfo}
        onGoBack={setActiveData}
      />
    )
  }

  if (step === ESteps.CREATE_PASSWORD) {
    return (
      <CreatePassword
        token={token}
        onGoBack={setActiveData}
        onSuccess={setActiveSellerInfo}
      />
    )
  }

  if (step === ESteps.SELLER_INFO) {
    return (
      <SellerInfo
        token={token}
        onSuccess={setFinish}
        onGoBack={isEmptyPassword || email ? undefined : setActiveData}
      />
    )
  }

  return (
    <Data
      setActiveScreenPassword={setActiveCreatePassword}
      setActiveScreenSeller={setActiveSellerInfo}
      setIsEmptyPassword={setIsEmptyPassword}
      setToken={setToken}
      setEmail={setEmail}
      onSuccess={setActiveConfirm}
    />
  )
}
