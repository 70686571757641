import { AxiosResponse } from 'axios'
import { IActivationRequest, ICheckSellerDataForCorrectness, ICreatePhysicalSellerRequest, ICreateSellerRequest, IRecoveryRequest, IRefreshResponse, ISendEmailRequest, ISignInRequest, ISignInResponse, ISignUpAppleRequest, ISignUpAppleResponse, ISignUpGoogleRequest, ISignUpGoogleResponse, ISignUpRequest, ISignUpResponse, ISignUpVkRequest, ISignUpVkResponse } from 'models/auth'
import { getNormalizedFormData } from 'shared/utils/getNormalizedFormData'
import { api, temporaryApi } from '.'

export const authApi = {
  signUp(data: ISignUpRequest): Promise<AxiosResponse<ISignUpResponse>> {
    return api.post<ISignUpResponse>('signUp', data)
  },
  appleSignUp(data: ISignUpAppleRequest): Promise<AxiosResponse<ISignUpAppleResponse>> {
    return api.post<ISignUpAppleResponse>('signUp/social-apple', data)
  },
  googleSignUp(data: ISignUpGoogleRequest): Promise<AxiosResponse<ISignUpGoogleResponse>> {
    return api.post<ISignUpGoogleResponse>('signUp/social-google', data)
  },
  vkSignUp(data: ISignUpVkRequest): Promise<AxiosResponse<ISignUpVkResponse>> {
    return api.post<ISignUpVkResponse>('signUp/social-vk', data)
  },
  activateEmail(data: IActivationRequest): Promise<AxiosResponse> {
    return api.post('activate', data)
  },
  createSeller(token: string, data: ICreateSellerRequest | ICreatePhysicalSellerRequest): Promise<AxiosResponse> {
    const formData = getNormalizedFormData(data)
    return temporaryApi.post('seller', formData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  },
  signIn(data: ISignInRequest): Promise<AxiosResponse<ISignInResponse>> {
    return api.post<ISignInResponse>('login', data)
  },
  checkAuth(): Promise<AxiosResponse<IRefreshResponse>> {
    return api.get<IRefreshResponse>('refresh')
  },
  recoverPasswordSendCode(data: ISendEmailRequest): Promise<AxiosResponse> {
    return api.post('send-email', data)
  },
  recoveryPassword(data: IRecoveryRequest): Promise<AxiosResponse> {
    return api.post('recovery-password', data)
  },
  logout(): Promise<AxiosResponse> {
    return api.get('logout')
  },
  checkPhysicData(token: string, data: ICheckSellerDataForCorrectness): Promise<AxiosResponse> {
    return temporaryApi.post('admin/seller/check-for-correctness', data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }) 
  }
}
