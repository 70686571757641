import { AxiosResponse } from 'axios'
import {
  IActionsUsersRequest,
  IAdminUpdateUser,
  ICreateUser,
  IGetShortUsersQueryRequest,
  IShortUser,
  IShortUsersQueryResponse,
  IUpdateUser
} from 'models/user'
import { privateApi, temporaryApi } from '.'

export const userApi = {
  getUsers({
    skip,
    limit,
    all_fields,
    number,
    full_name,
    email,
    tel
  }: IGetShortUsersQueryRequest): Promise<AxiosResponse<IShortUsersQueryResponse>> {
    return privateApi.get<IShortUsersQueryResponse>(
      `admin/users?skip=${skip}&limit=${limit}&all_fields=${
        all_fields ?? ''
      }&number=${number ?? ''}&full_name=${full_name ?? ''}&email=${email ?? ''}&tel=${
        tel ?? ''
      }`
    )
  },
  setActions({ toDelete }: IActionsUsersRequest): Promise<AxiosResponse> {
    return privateApi.put(`admin/users?toDelete=${toDelete}`)
  },
  getUser(userId: string): Promise<AxiosResponse<IShortUser>> {
    return privateApi.get(`admin/users/${userId}`)
  },
  addUser(data: ICreateUser): Promise<AxiosResponse> {
    return privateApi.post('admin/users', data)
  },
  updateUser(data: IAdminUpdateUser): Promise<AxiosResponse> {
    return privateApi.patch('admin/users', data)
  },
  updateCurrentUser(data: IUpdateUser, token?: string): Promise<AxiosResponse> {
    if (token) {
      return temporaryApi.patch<AxiosResponse>('users', data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    }
    return privateApi.patch<AxiosResponse>('users', data)
  }
}
