import React from 'react'
import { LockOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { AuthContentWrapper } from 'components/layout/AuthContentWrapper/AuthContentWrapper'
import { passwordPattern } from 'shared/utils/patterns'
import { showMessage } from 'shared/utils/showMessage'
import { authApi } from 'api/auth'
import { showServerError } from 'shared/utils/showServerError'
import styles from './NewPassword.module.scss'

interface INewPasswordForm {
  password: string
  confirm: string
}

interface INewPasswordProps {
  email: string
  onSuccess: () => void
}

export const NewPassword: React.FC<INewPasswordProps> = ({
  email,
  onSuccess
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async (data: INewPasswordForm) => {
    try {
      setIsLoading(true)
      await authApi.recoveryPassword({
        email,
        password: data.password
      })
      showMessage('success', 'Новый пароль успешно сохранен!', '')
      onSuccess()
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthContentWrapper
      title='Придумайте новый пароль'
      titleOffset={24}
      titleLevel={2}
      titleClass={styles.title}
      padding='78px 66px 77px'
    >
      <Form name='recoveryPassword' size='large' onFinish={onSubmit}>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Заполните пароль'
            },
            ...passwordPattern
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder='Ваш пароль'
            prefix={<LockOutlined className='icon' />}
          />
        </Form.Item>
        <Form.Item
          name='confirm'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Заполните повтор пароля'
            },
            ...passwordPattern,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Пароли не совпадают'))
              }
            })
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder='Повтор пароля'
            prefix={<LockOutlined className='icon' />}
          />
        </Form.Item>
        <Button block htmlType='submit' type='primary' loading={isLoading}>
          Сохранить
        </Button>
      </Form>
    </AuthContentWrapper>
  )
}
