import { useState, memo, useCallback, FC, useRef, useEffect , Key, useMemo } from 'react'
import {
  Button,
  Flex,
  Input,
  InputRef,
  Modal,
  Radio,
  Space,
  Table,
  Tag,
  TreeSelect,
  Typography
} from 'antd'
import { CloseOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons'
import debounce from 'lodash.debounce'
import { ColumnsType } from 'antd/es/table'
import { productApi } from 'api/product'
import noProductImage from 'assets/images/no-image-product.png'
import {
  EProductStatus,
  IParsedCategory,
  IProductQueryFilters,
  IProductResponse
} from 'models/product'
import { DEBOUNCE_DELAY } from 'shared/constants/app'
import { IMAGES_URL } from 'shared/constants/urls'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { productTags } from 'shared/constants/product'
import { showServerError } from 'shared/utils/showServerError'
import { showMessage } from 'shared/utils/showMessage'
import styles from './goods/ProductForm/ProductForm.module.scss'

interface IChooseVariationProductModalProps {
  multiple?: boolean
  visible: boolean
  onSave: (products: IProductResponse[]) => void
  onClose: () => void
}

enum ECategoryType {
  CATEGORY = 'category',
  SUB_CATEGORY = 'subCategory'
}

interface ISelectedCategory {
  id: string
  type: ECategoryType
}

export const GetProductModal: FC<IChooseVariationProductModalProps> =
  memo(({ onSave, visible, onClose, multiple=true }) => {
    const [globalSearch, setGlobalSearch] = useState('')
    const [selectedProducts, setSelectedProducts] = useState<IProductResponse[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const pageSize = 7
    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState<IProductResponse[]>([])
    const [nameSearch, setNameSearch] = useState('')
    const nameSearchRef = useRef<InputRef>(null)
    const [categories, setCategories] = useState<IParsedCategory[]>([])
    const [categorySearch, setCategorySearch] = useState<
      string | undefined
    >(undefined)
    const [selectedCategory, setSelectedCategory] =
      useState<ISelectedCategory | null>(null)

    const [status, setStatus] = useState<EProductStatus | undefined>(
      undefined
    )

    useEffect(() => {
      if (visible) {
        const getCategories = async () => {
          try {
            const response = await productApi.getCategories({
              parsed: 'true'
            })
            setCategories(response.data as IParsedCategory[])
          } catch (e) {
            showServerError(e)
          }
        }
        getCategories()
        getProducts((currentPage - 1) * pageSize, status)
      }
    }, [visible])

    const closeModal = () => {
      setSelectedProducts([])
      onClose()
    }

    const chooseProduct = () => {
      if (!selectedProducts.length) {
        return showMessage('info', 'Выберите продукт из таблицы', '')
      }      
      onSave(selectedProducts)
      closeModal()
    }

    const getProducts = async (
      skip: number,
      status: EProductStatus | undefined,
      data?: IProductQueryFilters
    ) => {
      try {
        setIsLoading(true)
        const response = await productApi.getProducts({
          skip: String(skip),
          limit: String(pageSize),
          isHaveParent: false,
          all_fields: data?.all_fields ?? globalSearch,
          status,
          name: data?.name ?? nameSearch,
          category_id:
            data?.category_id ??
            selectedCategory?.type === ECategoryType.CATEGORY
              ? selectedCategory?.id
              : '',
          subCategory_id:
            data?.subCategory_id ??
            selectedCategory?.type === ECategoryType.SUB_CATEGORY
              ? selectedCategory?.id
              : ''
        })
        setTotal(response.data.count)
        setProducts(response.data.products)
      } catch (e) {
        showServerError(e)
      } finally {
        setIsLoading(false)
      }
    }

    const onChangeGlobalSearch = (searchValue: string) => {
      getProducts(0, status, { all_fields: searchValue })
      setCurrentPage(1)
    }

    const debouncedGetProductsByGlobalSearch = useCallback(
      debounce(onChangeGlobalSearch, DEBOUNCE_DELAY),
      [nameSearch, selectedCategory, status]
    )

    const onRowSelection = (
      _: Key[],
      selectedRows: IProductResponse[]
    ) => {
      const selectedIds = new Set(selectedRows.map(r => r._id))
      setSelectedProducts(
        selectedProducts.filter(product => selectedIds.has(product._id))
          .concat(
            selectedRows.filter(newProduct => !selectedProducts.some(existing => existing._id === newProduct._id))
          )
      )
    }

    const onPageChange = (current: number) => {
      getProducts((current - 1) * pageSize, status)
      setCurrentPage(current)
    }

    const onSaveSearchName = (nameSearch: string) => {
      getProducts(0, status, { name: nameSearch })
      setCurrentPage(1)
    }

    const onSaveCategory = (selectedCategory: ISelectedCategory | null) => {
      if (!selectedCategory) {
        getProducts(0, status, { category_id: '', subCategory_id: '' })
        setSelectedCategory(null)
        return setCurrentPage(1)
      }
      if (selectedCategory.type === ECategoryType.CATEGORY) {
        getProducts(0, status, { category_id: selectedCategory.id })
        return setCurrentPage(1)
      }
      getProducts(0, status, { subCategory_id: selectedCategory.id })
      setCurrentPage(1)
    }

    const onChangeStatus = (newStatus: EProductStatus | undefined) => {
      getProducts(0, newStatus)
      setCurrentPage(1)
    }

    const columns: ColumnsType<IProductResponse> = useMemo(
      () => [
        {
          title: 'Артикул',
          dataIndex: 'vendor_code',
          width: 101
        },
        {
          title: 'Фото',
          dataIndex: 'photo',
          width: 70,
          render: (_, { photo }) => {
            return photo && !!photo.length ? (
              <div
                style={{
                  width: 62,
                  height: 56,
                  backgroundImage: `url(${IMAGES_URL}${photo[0]})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              />
            ) : (
              <img
                src={noProductImage}
                alt='Изображение товара'
                width={62}
                height={56}
              />
            )
          }
        },
        {
          title: 'Наименование товара',
          dataIndex: 'name',
          width: 310,
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Input
                  ref={nameSearchRef}
                  value={nameSearch}
                  size='middle'
                  onChange={(e) => setNameSearch(e.target.value)}
                  onPressEnter={() => {
                    onSaveSearchName(nameSearch)
                    close()
                  }}
                  placeholder='Наименование товара'
                  className='filterInput'
                />
                <div className='filterFooterButtons'>
                  <Button
                    type='primary'
                    onClick={() => {
                      onSaveSearchName(nameSearch)
                      close()
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    className='filterButton'
                  >
                    Найти
                  </Button>
                  <Button
                    onClick={() => {
                      setNameSearch('')
                      onSaveSearchName('')
                      close()
                    }}
                    size='small'
                    className='filterButton'
                  >
                    Очистить
                  </Button>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => {
                      close()
                    }}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
            )
          },
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setTimeout(() => nameSearchRef.current?.focus(), 0)
            }
          },
          filterIcon: () => (
            <SearchOutlined
              style={{
                color: nameSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
              }}
            />
          )
        },
        {
          title: 'Категория',
          dataIndex: 'category_id',
          width: 310,
          render: (_, { category_id, subCategory_id }) => {
            if (!category_id || !subCategory_id) {
              return (
                <Typography.Text style={{ opacity: 0.5 }}>
                  Не задана
                </Typography.Text>
              )
            }
            return (
              <>
                <Typography.Text>{category_id.name + ' >'}</Typography.Text>
                <br />
                <Typography.Text>{subCategory_id.name}</Typography.Text>
              </>
            )
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <TreeSelect
                  value={categorySearch}
                  size='middle'
                  onChange={(value) => {
                    setCategorySearch(value)
                    setSelectedCategory(null)
                  }}
                  className='filterInput filterTreeSelect'
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder='Выберите категорию или подкатегорию'
                  onSelect={(_, item) => {
                    setSelectedCategory({
                      id: item.value,
                      type: item.type as ECategoryType
                    })
                  }}
                  treeData={categories}
                  showSearch
                  treeNodeFilterProp='title'
                  allowClear
                />
                <div className='filterFooterButtons'>
                  <Button
                    type='primary'
                    onClick={() => {
                      onSaveCategory(selectedCategory)
                      close()
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    className='filterButton'
                  >
                    Найти
                  </Button>
                  <Button
                    onClick={() => {
                      setCategorySearch(undefined)
                      onSaveCategory(null)
                      close()
                    }}
                    size='small'
                    className='filterButton'
                  >
                    Очистить
                  </Button>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => {
                      close()
                    }}
                  >
                    Закрыть
                  </Button>
                </div>
              </div>
            )
          },
          filterIcon: () => (
            <FilterFilled
              style={{
                color: selectedCategory ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
              }}
            />
          )
        },
        {
          title: 'Цена',
          dataIndex: 'price',
          width: 101,
          render: (_, { price }) => {
            return (
              <Typography.Text style={{ opacity: price ? 1 : 0.5 }}>
                {price ? getNormalizedPrice(price) : 'Не задана'}
              </Typography.Text>
            )
          }
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          width: 136,
          render: (_, { status }) => {
            return (
              <Tag color={productTags[status].color}>
                {productTags[status].label}
              </Tag>
            )
          
          },
          filterDropdown: ({ close }) => {
            return (
              <div
                className='filterWrapper'
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Radio.Group
                  onChange={(e) => {
                    setStatus(e.target.value)
                    onChangeStatus(e.target.value)
                    close()
                  }}
                  value={status}
                  style={{ display: 'block', marginBottom: 15 }}
                >
                  <Space direction='vertical' size={15}>
                    {Object.values(productTags).map((item) => (
                      <Radio key={item.value as string} value={item.value}>
                        <Tag color={item.color}>{item.label}</Tag>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
                <Button
                  onClick={() => {
                    setStatus(undefined)
                    onChangeStatus(undefined)
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            )
          },
          filterIcon: () => (
            <FilterFilled
              style={{
                color: status ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
              }}
            />
          )
        }
      ],
      [
        products,
        categories,
        nameSearch,
        categorySearch,
        selectedCategory,
        status
      ]
    )

    return (
      <Modal
        centered
        open={visible}
        footer={null}
        closable={false}
        onCancel={closeModal}
        width={1100}
      >
        <Flex style={{alignItems: 'center', justifyContent: 'space-between', marginBottom: 15}}>
          <Typography.Title level={4}>Товары</Typography.Title>
          <CloseOutlined onClick={closeModal}/>
        </Flex>
        <div style={{marginBottom: 32, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Input
            value={globalSearch}
            onChange={(e) => {
              setGlobalSearch(e.target.value)
              debouncedGetProductsByGlobalSearch(e.target.value)
            }}
            placeholder='Поиск'
            size='large'
            suffix={<SearchOutlined />}
            className='searchInput'
          />
          <div className={styles.chooseVariationProductFooter}>
            <Button type='default' size='middle' onClick={closeModal}>
            Отменить
            </Button>
            <Button type='primary' size='middle' disabled={!selectedProducts.length} onClick={chooseProduct}>
            Выбрать
            </Button>
          </div>
        </div>
        <Table
          scroll={{
            x: 900
          }}
          columns={columns}
          dataSource={products}
          loading={isLoading}
          rowKey={(el) => el._id}
          rowSelection={{
            type: multiple ? 'checkbox' : 'radio',
            selectedRowKeys: selectedProducts.map(p=> p._id),
            onChange: onRowSelection
          }}
          rowClassName='tableRow'
          footer={undefined}
          pagination={{
            current: currentPage,
            onChange: onPageChange,
            pageSize,
            total,
            showSizeChanger: false
          }}
        />

      </Modal>
    )
  })
