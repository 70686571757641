import {
  Button,
  Input,
  InputRef,
  Modal,
  Space,
  Typography,
  Table,
  message
} from 'antd'
import React from 'react'
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UserAddOutlined
} from '@ant-design/icons'
import qs from 'qs'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import {
  IGetShortUsersQueryFilters,
  IShortUser
} from 'models/user'
import { userApi } from 'api/user'
import { DEBOUNCE_DELAY } from 'shared/constants/app'
import { superAdminRoutes } from 'shared/constants/routes'
import { showServerError } from 'shared/utils/showServerError'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { getUserEditPath } from 'routing/utils/getUserEditPath'
import { cleanObject } from 'shared/utils/cleanObject'
import styles from './List.module.scss'

export const UsersList: React.FC = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const [isFirstRequestFinished, setIsFirstRequestFinished] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [users, setUsers] = React.useState<IShortUser[]>([])

  const [selectedItemsId, setSelectedItemsId] = React.useState<string[]>([])

  const [globalSearch, setGlobalSearch] = React.useState('')

  const [idSearch, setIdSearch] = React.useState('')
  const idSearchRef = React.useRef<InputRef>(null)

  const [fullNameSearch, setFullNameSearch] = React.useState('')
  const fullNameSearchRef = React.useRef<InputRef>(null)

  const [emailSearch, setEmailSearch] = React.useState('')
  const emailSearchRef = React.useRef<InputRef>(null)

  const [phoneSearch, setPhoneSearch] = React.useState('')
  const phoneSearchRef = React.useRef<InputRef>(null)

  const [currentPage, setCurrentPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const pageSize = 10

  const showDeleteConfirm = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Вы действительно хотите удалить выбранных пользователей?',
      content: 'Отменить данное действие будет невозможно.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',
      cancelText: 'Закрыть',
      onOk() {
        return new Promise((resolve) => {
          setProductsAction().then(resolve)
        })
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const getUsers = async (skip: number, data?: IGetShortUsersQueryFilters) => {
    try {
      setIsLoading(true)
      const response = await userApi.getUsers({
        skip: String(skip),
        limit: String(pageSize),
        all_fields: data?.all_fields ?? globalSearch,
        number: data?.number ?? idSearch,
        full_name: data?.full_name ?? fullNameSearch,
        email: data?.email ?? emailSearch,
        tel: data?.tel ?? phoneSearch
      })
      setTotal(response.data.count)
      setUsers(response.data.users)
      setSearchParams(cleanObject({
        page: skip / pageSize + 1 === 1 ? '' : String(skip / pageSize + 1),
        all_fields: data?.all_fields ?? globalSearch,
        number: data?.number ?? idSearch,
        full_name: data?.full_name ?? fullNameSearch,
        email: data?.email ?? emailSearch,
        tel: data?.tel ?? phoneSearch
      }))
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const setProductsAction = async () => {
    try {
      const response = await userApi.setActions({
        toDelete: selectedItemsId.join(',')
      })
      message.success(response.data.message)
      if (selectedItemsId.length === users.length) {
        getUsers(0)
        setCurrentPage(1)
      } else {
        getUsers((currentPage - 1) * pageSize)
      }
      setSelectedItemsId([])
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const saveFieldValue = (data: IGetShortUsersQueryFilters) => {
    getUsers(0, data)
    setCurrentPage(1)
  }

  const onChangeGlobalSearch = (searchValue: string) => {
    getUsers(0,{ all_fields: searchValue })
    setCurrentPage(1)
  }

  const onRowSelection = (selectedItemsId: React.Key[]) => {
    setSelectedItemsId(selectedItemsId as string[])
  }

  const onPageChange = (current: number) => {
    getUsers((current - 1) * pageSize)
    setCurrentPage(current)
  }

  const debouncedGetUsersByGlobalSearch = React.useCallback(debounce(onChangeGlobalSearch, DEBOUNCE_DELAY), [idSearch, fullNameSearch, emailSearch, phoneSearch])

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1))
      if (params.page) {
        setCurrentPage(Number(params.page))
      }
      if (params.all_fields) {
        setGlobalSearch(params.all_fields as string)
      }
      if (params.number) {
        setIdSearch((params.number as string).replace(/[^0-9]/g, ''))
      }
      if (params.full_name) {
        setFullNameSearch(params.full_name as string)
      }
      if (params.email) {
        setEmailSearch(params.email as string)
      }
      if (params.tel) {
        setPhoneSearch((params.tel as string).replace(/[^0-9]/g, ''))
      }
    }
    setIsFirstRequestFinished(true)
  }, [])

  React.useEffect(() => {
    if (isFirstRequestFinished) {
      getUsers((currentPage - 1) * pageSize)
      setCurrentPage(currentPage)
    }
  }, [isFirstRequestFinished])

  const columns: ColumnsType<IShortUser> = React.useMemo(
    () => [
      {
        title: 'ID Пользователя',
        dataIndex: 'number',
        width: '25%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getUserEditPath(_id))
          }
        },
        render: (_, { number }) => {
          return (
            <Typography.Text style={{ wordWrap: 'break-word' }} onClick={(event) => event.stopPropagation()}>
              {number}
            </Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={idSearchRef}
                value={idSearch}
                onChange={(e) => setIdSearch(e.target.value.replace(/[^0-9]/g, ''))}
                onPressEnter={() => {
                  saveFieldValue({ number: idSearch })
                  close()
                }}
                placeholder='ID Пользователя'
                className='filterInput'
              />
              <div className='filterFooterButtons'>
                <Button
                  type='primary'
                  onClick={() => {
                    saveFieldValue({ number: idSearch })
                    close()
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  className='filterButton'
                >
                  Найти
                </Button>
                <Button
                  onClick={() => {
                    setIdSearch('')
                    saveFieldValue({ number: '' })
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => idSearchRef.current?.select(), 100)
          }
        },
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: idSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
            }}
          />
        )
      },
      {
        title: 'ФИО пользователя',
        dataIndex: 'full_name',
        width: '25%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getUserEditPath(_id))
          }
        },
        render: (_, { full_name }) => {
          return (
            <Typography.Text onClick={(event) => event.stopPropagation()}>
              {full_name}
            </Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={fullNameSearchRef}
                value={fullNameSearch}
                onChange={(e) => setFullNameSearch(e.target.value)}
                onPressEnter={() => {
                  saveFieldValue({ full_name: fullNameSearch })
                  close()
                }}
                placeholder='ФИО пользователя'
                className='filterInput'
              />
              <div className='filterFooterButtons'>
                <Button
                  type='primary'
                  onClick={() => {
                    saveFieldValue({ full_name: fullNameSearch })
                    close()
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  className='filterButton'
                >
                  Найти
                </Button>
                <Button
                  onClick={() => {
                    setFullNameSearch('')
                    saveFieldValue({ full_name: '' })
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => fullNameSearchRef.current?.select(), 100)
          }
        },
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: fullNameSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
            }}
          />
        )
      },
      {
        title: 'Почта',
        dataIndex: 'email',
        width: '25%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getUserEditPath(_id))
          }
        },
        render: (_, { email }) => {
          return (
            <Typography.Text onClick={(event) => event.stopPropagation()}>
              {email}
            </Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={emailSearchRef}
                value={emailSearch}
                onChange={(e) => setEmailSearch(e.target.value)}
                onPressEnter={() => {
                  saveFieldValue({ email: emailSearch })
                  close()
                }}
                placeholder='Почта'
                className='filterInput'
              />
              <div className='filterFooterButtons'>
                <Button
                  type='primary'
                  onClick={() => {
                    saveFieldValue({ email: emailSearch })
                    close()
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  className='filterButton'
                >
                  Найти
                </Button>
                <Button
                  onClick={() => {
                    setEmailSearch('')
                    saveFieldValue({ email: '' })
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => emailSearchRef.current?.select(), 100)
          }
        },
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: emailSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
            }}
          />
        )
      },
      {
        title: 'Номер телефона',
        dataIndex: 'tel',
        width: '25%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getUserEditPath(_id))
          }
        },
        render: (_, { tel }) => {
          return (
            <Typography.Text onClick={(event) => event.stopPropagation()}>
              {!!tel && phoneMaskStatic(tel)}
            </Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={phoneSearchRef}
                value={phoneSearch}
                onChange={(e) => setPhoneSearch(e.target.value.replace(/[^0-9]/g, ''))}
                onPressEnter={() => {
                  saveFieldValue({ tel: phoneSearch })
                  close()
                }}
                placeholder='Номер телефона'
                className='filterInput'
              />
              <div className='filterFooterButtons'>
                <Button
                  type='primary'
                  onClick={() => {
                    saveFieldValue({ tel: phoneSearch })
                    close()
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  className='filterButton'
                >
                  Найти
                </Button>
                <Button
                  onClick={() => {
                    setPhoneSearch('')
                    saveFieldValue({ tel: '' })
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => phoneSearchRef.current?.select(), 100)
          }
        },
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: phoneSearch ? '#1677ff' : 'rgba(0, 0, 0, 0.29)'
            }}
          />
        )
      }
    ],
    [users, idSearch, fullNameSearch, emailSearch, phoneSearch]
  )

  return (
    <div style={{ paddingBottom: 80 }}>
      <MainTitle text='Пользователи' />
      <div className={styles.header}>
        <Input
          value={globalSearch}
          onChange={(e) => {
            setGlobalSearch(e.target.value)
            debouncedGetUsersByGlobalSearch(e.target.value)
          }}
          placeholder='Поиск (по почте/номеру телефона/ID)'
          size='large'
          suffix={<SearchOutlined />}
          className={classNames('searchInput', styles.searchInput)}
        />
        <Link to={superAdminRoutes.USERS_ADD}>
          <Button type='primary' size='large' icon={<UserAddOutlined />} className={styles.addUserButton}>
            Добавить пользователя
          </Button>
          <Button type='primary' size='large' icon={<PlusOutlined />} className={styles.addUserButtonMobile} />
        </Link>
      </div>
      <Table
        scroll={{ x: 800 }}
        columns={columns}
        dataSource={users}
        loading={isLoading}
        rowKey={(el) => el._id}
        rowSelection={{
          selectedRowKeys: selectedItemsId,
          onChange: onRowSelection
        }}
        rowClassName='tableRow'
        footer={() => (
          <Space size={8}>
            <Typography.Text>Действия:</Typography.Text>
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
              onClick={showDeleteConfirm}
              disabled={!selectedItemsId.length}
            >
              Удалить
            </Button>
          </Space>
        )}
        pagination={{
          current: currentPage,
          onChange: onPageChange,
          pageSize,
          total,
          showSizeChanger: false
        }}
      />
    </div>
  )
}
