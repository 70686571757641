import { PlusOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'


interface Props {
  onClick: (id: string)=> void
}

export const HeaderList =({onClick}: Props)=> {
  return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 32}}>
    <Typography.Title style={{margin: 0}}>Подборки товаров</Typography.Title>
    <Button type='primary' size='large' icon={<PlusOutlined />} onClick={()=>onClick('create')}>Создать</Button>
  </div>
}