import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { collectionsApi } from 'api/collections'
import { showServerError } from 'shared/utils/showServerError'
import noProductImage from 'assets/images/no-image-product.png'
import { Uploader } from 'components/ui/Uploader/Uploader'
import { Button, Flex, Form, Input, message, Popconfirm, Skeleton, Table, Typography } from 'antd'
import { TextOnePhoto } from 'components/ui/Uploader/TextOnePhoto'
import { IMAGES_URL } from 'shared/constants/urls'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { IProductResponse } from 'models/product'
import { ECollectionStatus } from 'models/collections'
import { getRoute } from 'shared/constants/routes'
import { GetProductModal } from 'components/GetProductModal'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { CREATE_MODE } from './utils/constants'
import { HeaderEdit } from './components/HeaderEdit'
const { TextArea } = Input

export const CollectionEdit = () => {
  const [form] = Form.useForm()
  const { id } = useParams<{ id: string }>()
  const isCreate = id === CREATE_MODE
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [visibleProductModal, setVisibleProductModal] = useState(false)
  const [products, setProducts] = useState<IProductResponse[]>([])
  const navigate = useNavigate()

  const getCollection = async () => {
    if (!id || isCreate) return 
    try {
      const response = await collectionsApi.getCollection(id)
      const {data} = response
      form.setFields([
        { name: 'title', value: data.title },
        { name: 'desc', value: data.desc }
      ])      
      if (data.photo) {
        form.setFieldValue('photo', [
          {
            uid: data.photo,
            thumbUrl: `${IMAGES_URL}${data.photo}`
          }
        ])
      }
      setProducts(data.products)
    } catch (e) {
      showServerError(e)
    } 
  }

  const handleDeleteProduct = (id: string) => {
    const updatedCollection = products.filter((p) => p._id !== id)
    setProducts(updatedCollection)
  }

  const columns: ColumnsType<IProductResponse> = [
    {
      title: '',
      dataIndex: 'number',
      key: ''
    },
    {
      title: 'Фото',
      dataIndex: 'photo',
      width: 76,
      render: (_, { _id, photo }) => {        
        return photo && photo.length ? (
          <div style={{ width: 62, height: 56, backgroundImage: `url(${IMAGES_URL}${photo[0]})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
        ) : (
          <img
            src={noProductImage}
            alt='Изображение товара'
            width={62}
            height={56}
          />
        )
      }
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Категория',
      dataIndex: '',
      key: 'category_id'
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      render: (_, {price }) => <span>{price ? getNormalizedPrice(price): '-'}</span>
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) =>
        products.length >= 1 ? (
          <Popconfirm title='Удалить?' onConfirm={() => handleDeleteProduct(record._id)}>
            <Button type='primary' danger icon={<DeleteFilled />} size={'small'} />
          </Popconfirm>
        ) : null
    }
  ]

  
  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true)
        await getCollection()
      } catch (e) {
        showServerError(e)
      } finally {
        setIsLoading(false)
      }
    }
    getData()
  }, [])

  const onSubmit = async (status: ECollectionStatus) => {
    if (!id) return null
    try {
      const formValues = form.getFieldsValue()
      const {_id, ...values} = formValues       
      if (!formValues.photo) {
        message.error('Добавьте изображение')
        return null
      }
      await form.validateFields()
      if (!products.length) {
        message.error('Добавьте товар')
        return null
      }
      setIsSubmitting(true)
      const body = {...values, status, products: products.map(p=> p._id)}
      if (isCreate) {
        await collectionsApi.createCollection(body)
        message.success('Подборка успешно добавлена')
      } else {
        await collectionsApi.updateCollection(id, body)
        message.success('Подборка успешно обновлена')
      }
      navigate(`${getRoute.collection_list}`)
    } catch (e: any) {
      showServerError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onCloseProductModal = () => {
    setVisibleProductModal(false)
  }

  const saveProducts = (newProducts: IProductResponse[]) => {
    const existingIds = new Set(products.map((p) => p._id))
    const filteredNewElements = newProducts.filter((newItem) => !existingIds.has(newItem._id))
    const items = [...products, ...filteredNewElements]
    setProducts(items)
  }

  if (isLoading) {
    return <Flex gap='middle' vertical>
      <Skeleton.Input active size={'large'} block />
      <Flex gap='middle'>
        <Skeleton.Image active />
        <Skeleton.Input active size={'large'} block />
      </Flex>
      <Skeleton active paragraph={{ rows: 4 }} />
    </Flex>
  }

  return  <div style={{ paddingBottom: 80 }}>
    <Form form={form} name='sellers' size='large' autoComplete='off'>

      <HeaderEdit 
        isSubmitting={isSubmitting}
        onPublish={()=> onSubmit(ECollectionStatus.PUBLISHED)} 
        onArchive={()=> onSubmit(ECollectionStatus.ARCHIVED)} 
        isCreate={isCreate} 
      />

      <Flex gap={32}>
        <div style={{  width: '50%'}}>
          <Typography.Title level={5} style={{marginBottom: 24}}>Обложка для подборки</Typography.Title>
          <Uploader
            name='photo'
            type='variation'
            rules={[
              {required: true, message: 'Добавьте изображение'}
            ]}
          />
          <TextOnePhoto />
        </div>

        <div style={{width: '100%'}}>
          <Form.Item name='title' label='Название подборки'         rules={[
            {required: true, message: 'Добавьте название подборки'}
          ]} style={{width: '100%'}}><Input placeholder='' /></Form.Item>
          <Typography.Title level={5}>Описание</Typography.Title>
          <Form.Item name='desc'><TextArea placeholder='' rows={6} /></Form.Item>
        </div>
      </Flex>


      <Table
        showHeader={false}
        style={{marginTop: 32}}
        pagination={false}
        columns={columns}
        dataSource={products}
        rowKey={(el) => el._id}
        rowClassName='tableRow'
      />     

      <Flex style={{marginTop: 32}} justify='end'>
        <Button type='primary' icon={<PlusOutlined />} onClick={()=>setVisibleProductModal(true)}>Добавить товар</Button>
      </Flex>

      <GetProductModal 
        onSave={saveProducts}
        visible={visibleProductModal}
        onClose={onCloseProductModal} />
    </Form>
  </div>
}