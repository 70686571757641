import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'
import { IRefreshResponse } from 'models/auth'
import { TOKEN } from 'shared/constants/app'
import { API_URL, INTEGRATION_API_URL } from 'shared/constants/urls'
import { appActions } from 'store/app'
import { showMessage } from 'shared/utils/showMessage'

let store: ToolkitStore

export const injectStore = (_store: ToolkitStore) => {
  store = _store
}

export const temporaryApi = axios.create({
  withCredentials: true,
  baseURL: API_URL
})

export const api = axios.create({
  withCredentials: true,
  baseURL: API_URL
})

export const privateApi = axios.create({
  withCredentials: true,
  baseURL: API_URL
})

export const integrationApi = axios.create({
  baseURL: INTEGRATION_API_URL
})

const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN)
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
}

temporaryApi.interceptors.response.use(
  (config) => {
    return config
  },
  async (error: any) => {
    const originalRequest = error.config
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true
      try {
        const response = await api.get<IRefreshResponse>('refresh')
        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`
        return temporaryApi.request(originalRequest)
      } catch (e) {
        showMessage(
          'error',
          'Ошибка',
          'Время для заполнения данных закончилось, обновите страничку'
        )
      }
    }
    throw error
  }
)

privateApi.interceptors.request.use(requestInterceptor)
integrationApi.interceptors.request.use(requestInterceptor)

let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error: any, token: any = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const interceptorResponse = (apiInstance: AxiosInstance) => (error: any) => {
  if (error.response.status === 403) {
    return store.dispatch(appActions.logout())
  }

  const originalRequest = error.config

  if (
    error.response.status === 401 &&
    error.config &&
    !error.config._isRetry
  ) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          return apiInstance.request(originalRequest)
        })
        .catch((err) => {
          return err
        })
    }

    originalRequest._isRetry = true
    isRefreshing = true

    return new Promise(function (resolve, reject) {
      api
        .get<IRefreshResponse>('refresh')
        .then(({ data }) => {
          store.dispatch(appActions.setToken(data))
          originalRequest.headers.Authorization = `Bearer ${data.access_token}`
          processQueue(null, data.access_token)
          resolve(axios(originalRequest))
        })
        .catch((err) => {
          processQueue(err, null)
          reject(err)
          store.dispatch(appActions.logout())
        })
        .then(() => {
          isRefreshing = false
        })
    })
  }

  throw error
}

privateApi.interceptors.response.use(
  (config) => {
    return config
  },
  interceptorResponse(privateApi)
)

integrationApi.interceptors.response.use(
  (config) => {
    return config
  },
  interceptorResponse(integrationApi)
)
