import { Typography } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import styles from './InfoBlock.module.scss'

interface IInfoBlockProps {
  text: React.ReactNode
}

export const InfoBlock: React.FC<IInfoBlockProps> = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      <InfoCircleFilled className={styles.icon} />
      <Typography.Text className={styles.text}>
        {text}
      </Typography.Text>
    </div>
  )
}
