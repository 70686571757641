import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Table,
  Tag,
  Typography,
  Upload,
  message
} from 'antd'
import {
  PaperClipOutlined,
  UnorderedListOutlined,
  UploadOutlined
} from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { ordersApi } from 'api/orders'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import {
  EOrderItemsStatus,
  EOrderStatus,
  ERecieveMethod,
  IAdminGetOrderResponse,
  IAdminUpdateOrderRequest,
  IOrderItem,
  IUpdateAdminOrderFormData
} from 'models/orders'
import React from 'react'
import dayjs from 'dayjs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MenuInfo } from 'rc-menu/lib/interface'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import noProductImage from 'assets/images/no-image-product.png'
import { sellerApi } from 'api/seller'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { EUnit } from 'models/product'
import { getSellerEditPath } from 'routing/utils/getSellerEditPath'
import { CancelOrder } from 'components/orders/CancelOrder/CancelOrder'
import { getSuperOrderPath } from 'routing/utils/getSuperOrderPath'
import { showServerError } from 'shared/utils/showServerError'
import { getOrderStatusItems, orderTags } from 'shared/constants/orders'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { IMAGES_URL } from 'shared/constants/urls'
import { IShop } from 'models/seller'
import { ERoles } from 'models/auth'
import { ListInfoItem } from 'components/orders/ListInfoItem/ListInfoItem'
import { TransactionsModal } from 'components/orders/TransactionsModal/TransactionsModal'
import { normalizeFile } from 'shared/utils/normalizeFile'
import { showMessage } from 'shared/utils/showMessage'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import { getWeightByUnit } from 'shared/utils/getWeightByUnit'
import { superAdminRoutes } from 'shared/constants/routes'
import { acceptOrderModal } from 'shared/utils/orders/acceptOrderModal'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { delay } from 'shared/utils/delay'
import styles from './OrderEdit.module.scss'

export const OrderEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [orderData, setOrderData] =
    React.useState<IAdminGetOrderResponse | null>(null)
  const [isLoaded, setIsLoaded] = React.useState(false)

  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false)

  const [sellerShops, setSellerShops] = React.useState<IShop[]>([])

  const [openCancelOrderModal, setOpenCancelOrderModal] = React.useState(false)
  const [isRemoveProductId, setIsRemoveProductId] = React.useState<
    string | null
  >(null)

  const [openTransactionsModal, setOpenTransactionsModal] =
    React.useState(false)

  const isHasInvoice: boolean =
    Form.useWatch('invoice', {
      form,
      preserve: true
    }) ?? false

  const deliveryType: ERecieveMethod | undefined = Form.useWatch(
    'deliveryType',
    {
      form,
      preserve: true
    }
  )

  const deliveryPrice: string | undefined = Form.useWatch('deliveryPrice', {
    form,
    preserve: true
  })

  const isPickUpPoint = deliveryType === ERecieveMethod.PICKUP_POINT

  React.useEffect(() => {
    if (deliveryPrice) {
      const valueReplacedСommaToDot = deliveryPrice.replace(',', '.')
      const validDeliveryPrice = valueReplacedСommaToDot.replace(/[^0-9.]/g, '')
      form.setFieldValue('deliveryPrice', validDeliveryPrice)
    }
  }, [deliveryPrice])

  React.useEffect(() => {
    getOrder()
  }, [])

  React.useEffect(() => {
    if (orderData) {
      getShops()
    }
  }, [orderData])

  const closeOrderCanceledModal = React.useCallback(() => {
    setOpenCancelOrderModal(false)
    bodyScrollLock.disable()
  }, [])

  const openOrderCanceledModal = React.useCallback(() => {
    setOpenCancelOrderModal(true)
    bodyScrollLock.enable()
  }, [])

  const closeTransactionsModal = React.useCallback(() => {
    setOpenTransactionsModal(false)
    bodyScrollLock.disable()
  }, [])

  const onOpenTransactionsModal = React.useCallback(() => {
    setOpenTransactionsModal(true)
    bodyScrollLock.enable()
  }, [])

  const getShops = async () => {
    try {
      const response = await sellerApi.getShops(
        orderData?.order.seller_id?._id as string
      )
      setSellerShops(response.data)
      form.setFieldValue('shopId', orderData?.order?.shop_id?._id)
    } catch (e) {
      showMessage('error', 'Не удалось получить магазины продавца', '')
    }
  }

  const getOrder = async () => {
    try {
      message.loading('Загрузка данных о заказе')
      const response = await ordersApi.getOrder(id as string)
      setOrderData(response.data)
      setFormData(response.data)
    } catch (e) {
      const error = e as AxiosError
      if (
        error.response?.status === 500 ||
        error.response?.status === 400 ||
        error.response?.status === 404
      ) {
        return navigate(superAdminRoutes.ORDERS)
      }
      showServerError(e)
    } finally {
      message.destroy()
      setIsLoaded(true)
    }
  }

  const setFormData = (data: IAdminGetOrderResponse) => {
    form.setFields([
      {
        name: 'deliveryPrice',
        value:
          data.order?.delivery_info?.delivery_sum?.toString() ??
          data.order?.pvz?.delivery_sum?.toString()
      },
      { name: 'trackNumber', value: data.order?.delivery_info?.tracking },
      { name: 'deliveryType', value: data.order.receive_method },
      {
        name: 'deliveryAddress',
        value:
          data.order?.delivery_info?.address?.address ??
          data.order?.pvz?.location?.address_full
      },
      { name: 'note', value: data.order?.note }
    ])
    if (data.order?.preparation_date) {
      form.setFieldValue('prepareDate', dayjs(data.order.preparation_date))
    }
    if (data.order?.delivery_info?.invoice) {
      form.setFieldValue('invoice', [
        {
          uid: data.order.delivery_info.invoice,
          thumbUrl: data.order.delivery_info.invoice,
          name: 'накладная.pdf'
        }
      ])
    } else {
      form.setFieldValue('invoice', undefined)
    }
  }

  const changeStatus = async (status: EOrderStatus, cancelReason?: string) => {
    try {
      message.loading('Обновление статуса')
      await ordersApi.changeStatus({
        status,
        orderId: orderData?.order._id as string,
        reason_of_cancel: cancelReason
      })
      if (status === EOrderStatus.CONFIRMED_BY_SELLER) {
        await delay(3000)
        getOrder()
      } else {
        setOrderData({
          ...(orderData as IAdminGetOrderResponse),
          order: {
            ...(orderData?.order as IAdminGetOrderResponse['order']),
            status
          }
        })
      }
    } catch (e) {
      showServerError(e)
    } finally {
      message.destroy()
    }
  }

  const removeProductFromOrder = async (productId: string) => {
    if (
      orderData?.order?.items &&
      orderData.order.items.filter(
        (item) => item.items_status === EOrderItemsStatus.AGREED
      ).length <= 1
    ) {
      return openOrderCanceledModal()
    }
    try {
      setIsRemoveProductId(productId)
      const newItems = orderData?.order.items?.map((el) => {
        if (el._id === productId) {
          return {
            ...el,
            items_status: EOrderItemsStatus.DENIED
          }
        }
        return el
      })
      const response = await ordersApi.getOrderWithRemovedItem(
        orderData?.order?._id as string,
        {
          current_items: newItems ?? []
        }
      )
      setOrderData(response.data)
    } catch (e) {
      showServerError(e)
    } finally {
      setIsRemoveProductId(null)
    }
  }

  const changeToCanceledStatus = (cancelReason: string) =>
    changeStatus(EOrderStatus.CANCELED, cancelReason)

  const onTagClick = (e: MenuInfo, itemId: string) => {
    if (e.key === EOrderStatus.CONFIRMED_BY_SELLER) {
      return acceptOrderModal(async () => {
        await changeStatus(EOrderStatus.CONFIRMED_BY_SELLER)
        bodyScrollLock.disable()
      })
    }

    if (e.key === EOrderStatus.CANCELED) {
      return openOrderCanceledModal()
    }

    changeStatus(e.key as EOrderStatus, itemId)
  }

  const onSubmit = async () => {
    try {
      await form.validateFields()
      const data: IUpdateAdminOrderFormData = form.getFieldsValue()
      setIsSubmitLoading(true)
      const baseFormData: IAdminUpdateOrderRequest = {
        items: orderData?.order.items ?? [],
        delivery_info: {
          receive_method: data.deliveryType,
          address: data.deliveryAddress,
          preparation_date: data.prepareDate
            ? new Date(
              data.prepareDate
                .hour(23)
                .minute(59)
                .second(59)
                .format('YYYY-MM-DDTHH:mm:ss')
            )
            : undefined,
          shop_id: data.shopId
        },
        note: data.note
      }
      if (data.deliveryType !== ERecieveMethod.PICKUP_POINT) {
        baseFormData['cdek_info'] = {
          tracking: data.trackNumber,
          delivery_sum: Number(data.deliveryPrice)
        }
      }
      if (data.invoice) {
        baseFormData['invoice'] = data.invoice[0].originFileObj
      }
      const response = await ordersApi.updateOrder(
        orderData?.order?._id as string,
        baseFormData
      )
      setOrderData(response.data)
      setFormData(response.data)
      message.success('Данные успешно обновлены')
    } catch (e: any) {
      if (e.errorFields) {
        return showMessage('error', 'Заполните все поля корректно', '')
      }
      showServerError(e)
    } finally {
      setIsSubmitLoading(false)
    }
  }

  const columns: ColumnsType<IOrderItem> = React.useMemo(() => {
    const baseColumns: ColumnsType<IOrderItem> = [
      {
        title: 'Артикул',
        dataIndex: 'vendor_code',
        width: 101,
        render: (_, { product_id }) => {
          return (
            <Typography.Text style={{ wordWrap: 'break-word', opacity: product_id?.vendor_code ? 1 : 0.5 }}>
              {product_id?.vendor_code ?? 'Удален'}
            </Typography.Text>
          )
        }
      },
      {
        title: 'Фото',
        dataIndex: 'photo',
        width: 76,
        render: (_, { product_id }) => {
          return !!product_id?.photo?.length ? (
            <div
              style={{
                width: 62,
                height: 36,
                backgroundImage: `url(${IMAGES_URL}${product_id.photo[0]})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            />
          ) : (
            <img
              src={noProductImage}
              alt='Изображение товара'
              style={{
                width: 62,
                height: 36,
                objectFit: 'contain'
              }}
            />
          )
        }
      },
      {
        title: 'Наименование товара',
        dataIndex: 'name',
        width: 315,
        render: (_, { product_id }) => {
          return <Typography.Text style={{ opacity: product_id?.name ? 1 : 0.5 }}>{product_id?.name ?? 'Удаленный товар'}</Typography.Text>
        }
      },
      {
        title: 'Кол-во',
        dataIndex: 'count',
        width: 120,
        render: (_, { count }) => {
          return <Typography.Text>{count} шт.</Typography.Text>
        }
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        width: 145,
        render: (_, { price }) => {
          return <Typography.Text>{getNormalizedPrice(price)}</Typography.Text>
        }
      },
      {
        title: 'Вес (кг)',
        dataIndex: 'weight',
        width: 120,
        render: (_, { dimension, count }) => {
          const value = getWeightByUnit(EUnit.KG, dimension, count).value
          const toFixedValue = value.toString().split('.')?.[1]?.length ?? 0
          return (
            <Typography.Text>
              {value.toFixed(toFixedValue)}
            </Typography.Text>
          )
        }
      }
    ]
    if (orderData?.order.status === EOrderStatus.ON_CONFIRMATION) {
      baseColumns.push({
        title: 'Убрать товар',
        dataIndex: '',
        width: 170,
        render: (_, { _id }) => {
          return (
            <Button
              htmlType='button'
              size='middle'
              loading={isRemoveProductId === _id}
              onClick={() => removeProductFromOrder(_id)}
            >
              Убрать
            </Button>
          )
        }
      })
    }
    return baseColumns
  }, [orderData, isRemoveProductId])

  if (!isLoaded || !orderData) {
    return null
  }

  return (
    <>
      <div style={{ paddingBottom: 60 }}>
        <MainTitle
          isGoBack
          text={`Заказ № ${orderData.order.number}`}
          style={{ marginBottom: 25 }}
        />
        <div className={styles.subHeader}>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Статус заказа:
          </Typography.Title>
          <div style={{ position: 'relative', top: 1 }}>
            {orderData.order.status === EOrderStatus.CANCELED ||
            orderData.order.status === EOrderStatus.NOT_PAID ? (
                <Tag color={orderTags[orderData.order.status].color}>
                  {orderTags[orderData.order.status].label}
                </Tag>
              ) : (
                <StatusDropdown
                  items={getOrderStatusItems(
                    orderData.order.status,
                    ERoles.ADMIN,
                    orderData.order.receive_method
                  )}
                  label={orderTags[orderData.order.status].label}
                  color={orderTags[orderData.order.status].color}
                  onClick={(e) => onTagClick(e, orderData.order._id)}
                />
              )}
          </div>
          <div className={styles.openSuperOrder}>
            <Button
              htmlType='button'
              icon={<UnorderedListOutlined />}
              onClick={() => navigate(getSuperOrderPath(orderData.order._id))}
            >
              Открыть супер-заказ
            </Button>
          </div>
        </div>
        <Table
          scroll={{
            x: 800
          }}
          columns={columns}
          dataSource={orderData.order.items.filter(
            (item) => item.items_status === EOrderItemsStatus.AGREED
          )}
          rowKey={(el) => el._id}
          pagination={false}
          style={{ marginBottom: 25 }}
        />
        <Form name='infoForm' form={form} size='small'>
          <div className={styles.detailsContainer}>
            <div className={styles.detailsWrapper} style={{ width: isPickUpPoint ? '66.6666%' : '100%' }}>
              {deliveryType !== ERecieveMethod.PICKUP_POINT && (
                <div
                  className={classNames(
                    styles.detailsItem,
                    styles.columnWithLine,
                    styles.cdekDelivery
                  )}
                >
                  <Typography.Title className={styles.detailsItemTitle} level={3}>
                    {orderData.order.receive_method === ERecieveMethod.EXPRESS ? 'Яндекс экспресс доставка' : 'Доставка СДЭК'}
                  </Typography.Title>
                  <ListInfoItem
                    name='Вес заказа'
                    value={`${(orderData.order.weight / 1000).toFixed(1)} кг`}
                    style={{ marginBottom: 15, gap: 15 }}
                    valueStyle={{ flexGrow: 0 }}
                  />
                  <Form.Item
                    required={false}
                    name='deliveryPrice'
                    label='Стоимость доставки'
                    rules={[
                      {
                        required: true,
                        message: ''
                      },
                      {
                        whitespace: true,
                        message: ''
                      }
                    ]}
                    style={{ marginBottom: 15 }}
                  >
                    <Input
                      placeholder='Стоимость доставки'
                      className={styles.deliveryPrice}
                      maxLength={7}
                    />
                  </Form.Item>
                  <Form.Item
                    required={false}
                    name='trackNumber'
                    label='Отслеживание'
                    style={{ marginBottom: 15 }}
                  >
                    <Input
                      placeholder='Номер отслеживания'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name='invoice'
                    valuePropName='fileList'
                    getValueFromEvent={normalizeFile}
                    label='Накладная'
                  >
                    <Upload
                      accept='.pdf'
                      maxCount={1}
                      customRequest={() => false}
                      beforeUpload={(file) => {
                        const isValid = file.type === 'application/pdf'
                        if (!isValid) {
                          showMessage(
                            'error',
                            'Поддерживаются только формат PDF',
                            ''
                          )
                        } else {
                          message.success('Накладная успешно загружена')
                        }
                        return isValid || Upload.LIST_IGNORE
                      }}
                      itemRender={(_, file) => {
                        return (
                          <div className={styles.invoice}>
                            <PaperClipOutlined
                              style={{ opacity: 0.45, fontSize: 22 }}
                            />
                            <Typography.Link
                              disabled={!file.thumbUrl}
                              href={`${IMAGES_URL}${file.thumbUrl}`}
                              target='_blank'
                            >
                              накладная.pdf
                            </Typography.Link>
                          </div>
                        )
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Загрузить файл</Button>
                    </Upload>
                  </Form.Item>
                  <Link to={orderData.order?.delivery_info?.tracking ? `https://lk.cdek.ru/order-history/${orderData.order.delivery_info.tracking}/view` : 'https://lk.cdek.ru/order-history'} target='_blank'>
                    <Button
                      size='middle'
                      type='primary'
                      htmlType='button'
                      style={{ marginTop: isHasInvoice ? 40 : 0 }}
                    >
                      Редактировать доставку
                    </Button>
                  </Link>
                </div>
              )}
              <div className={classNames(styles.detailsItem, { [styles.shippingInfoWithCdek]: !isPickUpPoint })} style={{ width: isPickUpPoint ? 'calc(100% / 2)' : 'calc(100% / 3)' }}>
                <Typography.Title className={styles.detailsItemTitle} level={3}>
                  Информация о доставке
                </Typography.Title>
                <Form.Item
                  required={false}
                  name='deliveryType'
                  label='Способ доставки'
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                  style={{ marginBottom: 15 }}
                >
                  <Select 
                    placeholder='Способ доставки'
                    disabled={deliveryType === ERecieveMethod.EXPRESS}
                  >
                    <Select.Option value={ERecieveMethod.COURIER}>
                      Курьером до двери
                    </Select.Option>
                    <Select.Option value={ERecieveMethod.PVZ}>
                      В пункт выдачи
                    </Select.Option>
                    <Select.Option value={ERecieveMethod.EXPRESS}>
                      Яндекс экспресс доставка
                    </Select.Option>
                    <Select.Option value={ERecieveMethod.PICKUP_POINT}>
                      Самовывоз из магазина
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  required={false}
                  name='prepareDate'
                  label='Подготовка до:'
                  style={{ marginBottom: 15 }}
                >
                  <DatePicker
                    format='DD/MM/YYYY'
                    placeholder='Подготовка до'
                    style={{ width: '100%', borderRadius: 4 }}
                    inputReadOnly
                  />
                </Form.Item>
                {deliveryType === ERecieveMethod.PICKUP_POINT ? (
                  <Form.Item
                    required={false}
                    name='shopId'
                    label='Самовывоз из:'
                    rules={[
                      {
                        required: true,
                        message: ''
                      }
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder='Выберите магазин'
                    >
                      {sellerShops.map((el) => (
                        <Select.Option key={el._id} value={el._id}>
                          {el.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    required={false}
                    name='deliveryAddress'
                    label={deliveryType === ERecieveMethod.PVZ ? 'Доставка до' : 'Адрес доставки'}
                    rules={[
                      {
                        required: true,
                        message: ''
                      },
                      {
                        whitespace: true,
                        message: ''
                      }
                    ]}
                    style={{ marginBottom: 15 }}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder={deliveryType === ERecieveMethod.PVZ ? 'Доставка до' : 'Адрес доставки'}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
                {deliveryType === ERecieveMethod.PVZ && orderData.order.seller_id?.nearest_point_of_cdek && (
                  <ListInfoItem
                    name='Отправка из'
                    value={orderData.order.seller_id?.nearest_point_of_cdek.location.address_full}
                  />
                )}
              </div>
              <div
                className={classNames(
                  styles.detailsItem,
                  styles.priceInfoContainer,
                  {
                    [styles.priceInfoContainerWithCDEK]: !isPickUpPoint
                  }
                )}
                style={{ width: isPickUpPoint ? 'calc(100% / 2)' : 'calc(100% / 3)' }}
              >
                <div className={styles.priceInfoWrapper}>
                  <Typography.Title className={styles.detailsItemTitle} level={3}>
                    Стоимость
                  </Typography.Title>
                  <ListInfoItem
                    name='Стоимость товаров'
                    value={getNormalizedPrice(orderData.order.sum)}
                  />
                  <ListInfoItem
                    name='Комиссия сервиса'
                    value={`${
                      !!orderData.commission ? '-' : ''
                    }${getNormalizedPrice(orderData.commission)}`}
                  />
                </div>
                <div className={styles.finalPrice}>
                  <Typography.Title
                    level={4}
                    style={{ marginBottom: 0, marginTop: 0 }}
                  >
                    Итог:
                  </Typography.Title>
                  <Typography.Title
                    level={4}
                    style={{
                      marginBottom: 0,
                      marginTop: 0,
                      maxWidth: 185,
                      textAlign: 'right'
                    }}
                  >
                    {getNormalizedPrice(orderData.order.full_sum)}
                  </Typography.Title>
                </div>
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <ListInfoItem
                    name='Стоимость доставки'
                    value={
                      deliveryType === ERecieveMethod.PICKUP_POINT ||
                      Number(deliveryPrice) === 0 ||
                      !deliveryPrice
                        ? getNormalizedPrice(0)
                        : Number(deliveryPrice)
                          ? getNormalizedPrice(deliveryPrice as any)
                          : getNormalizedPrice(
                            orderData.order?.delivery_info?.delivery_sum ??
                              orderData.order?.pvz?.delivery_sum ??
                              0
                          )
                    }
                  />
                  <Button
                    block
                    size='middle'
                    htmlType='button'
                    icon={<UnorderedListOutlined />}
                    style={{ marginTop: 35 }}
                    onClick={onOpenTransactionsModal}
                  >
                    Транзакции заказа
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.detailsContainer}>
            <div className={styles.detailsWrapper}>
              <div
                className={classNames(styles.detailsItem, styles.columnWithLine, styles.buyerColumn)}
              >
                <Typography.Title className={styles.detailsItemTitle} level={3}>
                  Покупатель
                </Typography.Title>
                {!orderData.order?.user_id && (
                  <Typography.Title level={5} style={{ opacity: 0.5 }}>
                    Аккаунт удален
                  </Typography.Title>
                )}
                {(orderData.order?.delivery_info?.full_name ||
                  orderData.order.user_id?.name) && (
                  <ListInfoItem
                    name='ФИО'
                    value={
                      orderData.order?.delivery_info?.full_name ??
                      (orderData.order.user_id?.full_name ?? '')
                    }
                  />
                )}
                {(orderData.order?.delivery_info?.tel ||
                  orderData.order.user_id?.tel) && (
                  <ListInfoItem
                    name='Телефон'
                    value={phoneMaskStatic(
                      orderData.order?.delivery_info?.tel ??
                        (orderData.order.user_id?.tel as number)
                    )}
                  />
                )}
                {orderData.order.user_id?.email && (
                  <ListInfoItem
                    name='Почта'
                    value={orderData.order.user_id.email}
                  />
                )}
              </div>
              <div
                className={classNames(styles.detailsItem, styles.columnWithLine, styles.sellerColumn)}
              >
                <Typography.Title className={styles.detailsItemTitle} level={3}>
                  Продавец
                </Typography.Title>
                <div className={styles.sellerShopNameWrapper}>
                  <Typography.Text className={styles.sellerShopNameTitle}>Магазин:</Typography.Text>
                  <Link
                    to={getSellerEditPath(
                      orderData.order.seller_id?._id as string
                    )}
                    className={styles.sellerShopNameLink}
                  >
                    <Typography.Text className={styles.sellerShopName}>
                      {orderData.order.seller_id?.shop_details.shop_name}
                    </Typography.Text>
                  </Link>
                </div>
                <ListInfoItem
                  name='Контактное лицо'
                  value={orderData.order?.seller_id?.user_id?.name as string}
                />
                <ListInfoItem
                  name='Телефон'
                  value={phoneMaskStatic(
                    orderData.order?.seller_id?.user_id?.tel as number
                  )}
                />
                {orderData.order?.seller_id?.user_id?.email && (
                  <ListInfoItem
                    name='Почта'
                    value={orderData.order.seller_id.user_id.email}
                  />
                )}
              </div>
              <div className={styles.detailsItem}>
                <Typography.Title className={styles.detailsItemTitle} level={3}>
                  Заметка менеджера
                </Typography.Title>
                <Form.Item name='note' style={{ marginBottom: 0 }}>
                  <Input.TextArea rows={4} style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            {orderData.order.status !== EOrderStatus.CANCELED &&
              orderData.order.status !== EOrderStatus.NOT_PAID && (
              <Button
                size='large'
                htmlType='button'
                type='primary'
                danger
                onClick={openOrderCanceledModal}
              >
                Отменить заказ
              </Button>
            )}
            <Button
              size='large'
              htmlType='button'
              type='primary'
              loading={isSubmitLoading}
              onClick={onSubmit}
            >
              Сохранить изменения
            </Button>
          </div>
        </Form>
      </div>
      <CancelOrder
        isOpen={openCancelOrderModal}
        onSuccess={changeToCanceledStatus}
        close={closeOrderCanceledModal}
      />
      <TransactionsModal
        isOpen={openTransactionsModal}
        orderId={orderData.order._id}
        orderStatus={orderData.order.status}
        onClose={closeTransactionsModal}
      />
    </>
  )
}
