import { Button, Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import styles from './AuthContentWrapper.module.scss'

interface AuthContentWrapperProps {
  width?: number
  padding: React.CSSProperties['padding']
  title: string,
  titleLevel?: 1 | 2 | 3 | 4 | 5,
  titleClass?: string
  titleOffset?: number
  onGoBack?: () => void
  children: React.ReactNode
}

export const AuthContentWrapper: React.FC<AuthContentWrapperProps> = ({
  width = 592,
  padding,
  title,
  titleLevel = 1,
  titleOffset = 24,
  titleClass,
  onGoBack,
  children
}) => {
  return (
    <div className={styles.content} style={{ width, padding }}>
      <header className={styles.header} style={{ marginBottom: onGoBack ? titleOffset : 0 }}>
        {onGoBack && <Button size='large' className={styles.goBackButton} onClick={onGoBack}><LeftOutlined /></Button>}
        <Typography.Title level={titleLevel} className={titleClass} style={{ marginBottom: onGoBack ? 0 : titleOffset }}>{title}</Typography.Title>
      </header>
      {children}
    </div>
  )
}
