

export interface ICollection extends Components.Schemas.ICollection { 
  _id: string
  title: string
}
export interface IUpdateCollection extends Components.Schemas.IUpdateCollection { }
export interface ICreateCollection extends Components.Schemas.ICreateCollection { }
export interface ICollectionQueryRequest extends Components.Schemas.ICollectionQueryRequest { }
export interface IPopulatedCollection extends Components.Schemas.IPopulatedCollection { }

export enum ECollectionStatus {
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}
