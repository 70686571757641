import { Button, Form, Input, Typography, message } from 'antd'
import React from 'react'
import { sellerApi } from 'api/seller'
import { showServerError } from 'shared/utils/showServerError'
import { floatNumbersPattern } from 'shared/utils/patterns'
import { normalizeNumber } from 'shared/utils/normalizeNumber'
import styles from './SellerCommission.module.scss'

interface ISellerCommissionProps {
  sellerId: string
  currentCommission: number
  style?: React.CSSProperties
  onSuccess: (commission: number) => void
}

interface IFormData {
  commission: string
}

export const SellerCommission: React.FC<ISellerCommissionProps> = ({
  sellerId,
  currentCommission,
  style,
  onSuccess
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const [form] = Form.useForm()

  const onSubmit = async (data: IFormData) => {
    try {
      setIsLoading(true)
      const response = await sellerApi.changeCommission(sellerId, { commission: normalizeNumber(data.commission) })
      onSuccess(response.data.commission)
      message.success('Комиссия успешно изменена')
      form.resetFields()
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={style}>
      <Typography.Title level={2} className={styles.title}>
        Комиссия для продавца
      </Typography.Title>
      <Form
        form={form}
        name='changeCommission'
        size='large'
        onFinish={onSubmit}
        className={styles.formWrapper}
      >
        <Typography.Title level={3} className={styles.subTitle}>
          Текущая: {currentCommission}%
        </Typography.Title>
        <Form.Item
          name='commission'
          rules={[
            {
              required: true,
              message: 'Введите размер комиссии'
            },
            {
              whitespace: true,
              message: 'Введите размер комиссии'
            },
            {
              pattern: floatNumbersPattern,
              message: 'Укажите размер комиссии корректно'
            }
          ]}
          hasFeedback
          className={styles.formField}
        >
          <Input
            placeholder='Новый размер комиссии %'
          />
        </Form.Item>
        <Button htmlType='submit' type='primary' loading={isLoading}>
          Изменить
        </Button>
      </Form>
    </div>
  )
}
