import React from 'react'
import { Input, Typography } from 'antd'
import { CloseOutlined, PartitionOutlined } from '@ant-design/icons'
import { IFrontVariation } from 'models/product'
import styles from '../ProductForm.module.scss'

interface ISelectedVariationProps {
  title?: string
  variation: IFrontVariation
  onClose: () => void
}

export const SelectedVariation: React.FC<ISelectedVariationProps> = React.memo(({ title = 'Товар для', variation, onClose }) => {
  return (
    <div className={styles.selectedVariationHeader}>
      <PartitionOutlined style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.45)' }} />
      <Typography.Title level={5} className={styles.selectedVariationTitle}>{title}:</Typography.Title>
      <Input size='middle' value={variation.firstCharacteristicValue} className={styles.selectedVariationInput} disabled />
      {variation.secondCharacteristicValue && <Input size='middle' value={variation.secondCharacteristicValue} className={styles.selectedVariationInput} disabled />}
      <CloseOutlined style={{ fontSize: 14, marginLeft: 'auto', color: 'rgba(0, 0, 0, 0.45)', cursor: 'pointer' }} onClick={onClose} />
    </div>
  )
})