import { LockOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { userApi } from 'api/user'
import { AuthContentWrapper } from 'components/layout/AuthContentWrapper/AuthContentWrapper'
import { passwordPattern } from 'shared/utils/patterns'
import { showServerError } from 'shared/utils/showServerError'
import styles from './CreatePassword.module.scss'

interface ICreatePasswordForm {
  password: string
  confirm: string
}

interface ICreatePasswordProps {
  token: string
  onGoBack?: () => void
  onSuccess: () => void
}

export const CreatePassword: React.FC<ICreatePasswordProps> = ({
  token,
  onGoBack,
  onSuccess
}) => {
  const onSubmit = async (data: ICreatePasswordForm) => {
    try {
      await userApi.updateCurrentUser({ password: data.password }, token )
      onSuccess()
    } catch (e) {
      showServerError(e)
    }
  }

  return (
    <AuthContentWrapper
      title='Создайте пароль'
      titleOffset={24}
      titleClass={styles.title}
      padding='96px 66px 119px'
      onGoBack={onGoBack}
    >
      <Form name='createPassword' size='large' onFinish={onSubmit}>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Заполните пароль'
            },
            ...passwordPattern
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder='Ваш пароль'
            prefix={<LockOutlined className='icon' />}
          />
        </Form.Item>
        <Form.Item
          name='confirm'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Заполните повтор пароля'
            },
            ...passwordPattern,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Пароли не совпадают'))
              }
            })
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder='Повтор пароля'
            prefix={<LockOutlined className='icon' />}
          />
        </Form.Item>
        <Button block htmlType='submit' type='primary'>
          Подтвердить
        </Button>
      </Form>
    </AuthContentWrapper>
  )
}
