import { RouterProvider } from 'react-router-dom'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { appSelectors } from 'store/app'
import { ERoles } from 'models/auth'
import { authRouter } from './authRouter'
import { adminRouter } from './adminRouter'
import { superAdminRouter } from './superAdminRouter'

export const Router: React.FC = () => {
  const isAuth = useAppSelector(appSelectors.selectIsAuth)
  const role = useAppSelector(appSelectors.selectRole)
  let router = authRouter

  if (isAuth && role === ERoles.ADMIN) {
    router = superAdminRouter
  }

  if (isAuth && role === ERoles.USER) {
    router = adminRouter
  }

  return (
    <RouterProvider router={router} />
  )
}