import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Button, Modal, Table, Tag, Typography, message } from 'antd'
import moment from 'moment'
import classNames from 'classnames'
import { ColumnsType } from 'antd/es/table'
import { CheckCircleFilled, PaperClipOutlined } from '@ant-design/icons'
import { ordersApi } from 'api/orders'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import {
  EOrderItemsStatus,
  EOrderStatus,
  ERecieveMethod,
  IAdminGetOrderResponse,
  IOrderItem
} from 'models/orders'
import noProductImage from 'assets/images/no-image-product.png'
import { IMAGES_URL } from 'shared/constants/urls'
import { showServerError } from 'shared/utils/showServerError'
import { EUnit } from 'models/product'
import { orderTags } from 'shared/constants/orders'
import { ListInfoItem } from 'components/orders/ListInfoItem/ListInfoItem'
import { CancelOrder } from 'components/orders/CancelOrder/CancelOrder'
import { getDeliveryTypeName } from 'shared/utils/orders/getDeliveryTypeName'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { adminRoutes } from 'shared/constants/routes'
import { getWeightByUnit } from 'shared/utils/getWeightByUnit'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { delay } from 'shared/utils/delay'
import styles from './Order.module.scss'

function formatDate(isoString: string) {
  const momentDate = moment(isoString)
  const formattedDate = momentDate.format('HH:mm')
  return formattedDate
}

export const Order: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const [orderData, setOrderData] =
    React.useState<IAdminGetOrderResponse | null>(null)
  const [isLoaded, setIsLoaded] = React.useState(false)

  const [isChangeStatusLoading, setIsChangeStatusLoading] =
    React.useState<EOrderStatus | null>()
  const [isRemoveProductId, setIsRemoveProductId] = React.useState<
    string | null
  >(null)

  const [openCancelOrderModal, setOpenCancelOrderModal] = React.useState(false)

  React.useEffect(() => {
    getOrder()
  }, [])

  const showSuccessModal = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Заказ принят в работу',
      content:
        'Статус в заказе покупателя также сменится на статус “Принят в работу”',
      icon: <CheckCircleFilled style={{ color: '#52C41A' }} />,
      okText: 'Ок',
      cancelText: 'Закрыть',
      onOk() {
        bodyScrollLock.disable()
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const closeOrderCanceledModal = React.useCallback(() => {
    setOpenCancelOrderModal(false)
    bodyScrollLock.disable()
  }, [])

  const openOrderCanceledModal = React.useCallback(() => {
    setOpenCancelOrderModal(true)
    bodyScrollLock.enable()
  }, []) 

  const getOrder = async () => {
    try {
      message.loading('Загрузка данных о заказе')
      const response = await ordersApi.getOrder(id as string)
      setOrderData(response.data)
    } catch (e) {
      const error = e as AxiosError
      if (
        error.response?.status === 500 ||
        error.response?.status === 400 ||
        error.response?.status === 404
      ) {
        return navigate(adminRoutes.ORDERS)
      }
      showServerError(e)
    } finally {
      message.destroy()
      setIsLoaded(true)
    }
  }

  const removeProductFromOrder = async (productId: string) => {
    if (
      orderData?.order?.items &&
      orderData.order.items.filter(
        (item) => item.items_status === EOrderItemsStatus.AGREED
      ).length <= 1
    ) {
      return openOrderCanceledModal()
    }
    try {
      setIsRemoveProductId(productId)
      await ordersApi.removeProduct(orderData?.order._id as string, {
        item_id: productId
      })
      getOrder()
    } catch (e) {
      showServerError(e)
    } finally {
      setIsRemoveProductId(null)
    }
  }

  const changeStatus = async (status: EOrderStatus, cancelReason?: string) => {
    try {
      setIsChangeStatusLoading(status)
      await ordersApi.changeStatus({
        status,
        orderId: orderData?.order._id as string,
        reason_of_cancel: cancelReason
      })
      await delay(3000)
      getOrder()
      if (status === EOrderStatus.CONFIRMED_BY_SELLER) {
        showSuccessModal()
      }
    } catch (e) {
      showServerError(e)
    } finally {
      setIsChangeStatusLoading(null)
    }
  }

  const changeCancelStatus = (cancelReason: string) => {
    changeStatus(EOrderStatus.CANCELED, cancelReason)
  }

  const columns: ColumnsType<IOrderItem> = React.useMemo(() => {
    const baseColumns: ColumnsType<IOrderItem> = [
      {
        title: 'Артикул',
        dataIndex: 'vendor_code',
        width: 101,
        render: (_, { product_id }) => {
          return (
            <Typography.Text style={{ wordWrap: 'break-word', opacity: product_id?.vendor_code ? 1 : 0.5 }}>
              {product_id?.vendor_code ?? 'Удален'}
            </Typography.Text>
          )
        }
      },
      {
        title: 'Фото',
        dataIndex: 'photo',
        width: 76,
        render: (_, { product_id }) => {
          return !!product_id?.photo?.length ? (
            <div
              style={{
                width: 62,
                height: 36,
                backgroundImage: `url(${IMAGES_URL}${product_id.photo[0]})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            />
          ) : (
            <img
              src={noProductImage}
              alt='Изображение товара'
              style={{
                width: 62,
                height: 36,
                objectFit: 'contain'
              }}
            />
          )
        }
      },
      {
        title: 'Наименование товара',
        dataIndex: 'name',
        width: 315,
        render: (_, { product_id }) => {
          return <Typography.Text style={{ opacity: product_id?.name ? 1 : 0.5 }}>{product_id?.name ?? 'Удаленный товар'}</Typography.Text>
        }
      },
      {
        title: 'Кол-во',
        dataIndex: 'count',
        width: 100,
        render: (_, { count }) => {
          return <Typography.Text>{count} шт.</Typography.Text>
        }
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        width: 145,
        render: (_, { price }) => {
          return <Typography.Text>{getNormalizedPrice(price)}</Typography.Text>
        }
      },
      {
        title: 'Вес (кг)',
        dataIndex: 'weight',
        width: 120,
        render: (_, { dimension, count }) => {
          const value = getWeightByUnit(EUnit.KG, dimension, count).value
          const toFixedValue = value.toString().split('.')?.[1]?.length ?? 0
          return (
            <Typography.Text>
              {value.toFixed(toFixedValue)}
            </Typography.Text>
          )
        }
      }
    ]
    if (orderData?.order.status === EOrderStatus.ON_CONFIRMATION && orderData.order.receive_method !== ERecieveMethod.EXPRESS) {
      baseColumns.push({
        title: 'Убрать товар',
        dataIndex: '',
        width: 190,
        render: (_, { _id }) => {
          return (
            <Button
              htmlType='button'
              size='middle'
              loading={isRemoveProductId === _id}
              onClick={() => removeProductFromOrder(_id)}
            >
              Отказаться
            </Button>
          )
        }
      })
    }
    return baseColumns
  }, [orderData, isRemoveProductId])

  if (!isLoaded || !orderData) {
    return null
  }  

  return (
    <>
      <div style={{ paddingBottom: 60 }}>
        <div className={styles.header}>
          <MainTitle
            isGoBack
            text={`Заказ № ${orderData.order.number}`}
            style={{ marginBottom: 0 }}
          />
          <Tag
            color={orderTags[orderData.order.status].color}
            className={styles.status}
          >
            {orderTags[orderData.order.status].label}
          </Tag>
        </div>
        <Table
          scroll={{
            x: 800
          }}
          columns={columns}
          dataSource={orderData.order.items.filter(
            (item) => item.items_status === EOrderItemsStatus.AGREED
          )}
          rowKey={(el) => el._id}
          pagination={false}
          style={{ marginBottom: 30 }}
        />
        <div className={styles.detailsContainer}>
          <div className={styles.detailsWrapper} style={{ width: orderData.order.delivery_info ? '100%' : '66.6666%' }}>
            {orderData.order.delivery_info && (
              <div
                className={classNames(
                  styles.detailsItem,
                  styles.columnWithLine
                )}
              >
                <Typography.Title className={styles.detailsItemTitle} level={3}>
                  {orderData.order.receive_method === ERecieveMethod.EXPRESS ? 'Яндекс экспресс доставка' : 'Доставка СДЭК'}
                </Typography.Title>
                <ListInfoItem
                  name='Вес заказа'
                  value={`${(orderData.order.weight / 1000).toFixed(1)} кг`}
                />
                {orderData.order.delivery_info?.yandex_offer?.price &&           <ListInfoItem
                  name='Стоимость доставки'
                  value={`${orderData.order.delivery_info?.yandex_offer?.price.total_price} ₽`}
                />}
                {orderData.order.delivery_info?.yandex_offer?.delivery_interval &&           <ListInfoItem
                  name='Время доставки'
                  value={`${formatDate(orderData.order.delivery_info?.yandex_offer?.delivery_interval.from)} - ${formatDate(orderData.order.delivery_info?.yandex_offer?.delivery_interval.to)}`}
                />}
                {orderData.order.delivery_info?.tracking && (
                  <ListInfoItem
                    name='Отслеживание'
                    value={orderData.order.delivery_info.tracking}
                  />
                )}
                {orderData.order.delivery_info?.invoice && (
                  <div className={styles.invoiceWrapper}>
                    <Typography.Text style={{ flexShrink: 0 }}>Накладная:</Typography.Text>
                    <div className={styles.invoice}>
                      <PaperClipOutlined
                        style={{ opacity: 0.45, fontSize: 22 }}
                      />
                      <Typography.Link
                        href={`${IMAGES_URL}${orderData.order.delivery_info.invoice}`}
                        target='_blank'
                      >
                        накладная.pdf
                      </Typography.Link>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={classNames(styles.detailsItem, { [styles.shippingInfoWithoutCdek]: !orderData.order.delivery_info })} style={{ width: orderData.order.delivery_info ? 'calc(100% / 3)' : 'calc(100% / 2)' }}>
              <Typography.Title className={styles.detailsItemTitle} level={3}>
                Информация о доставке
              </Typography.Title>
              <ListInfoItem
                name='Способ доставки'
                value={getDeliveryTypeName(orderData.order.receive_method)}
                style={{ gap: 15 }}
                valueStyle={{ textAlign: 'left' }}
              />
              {!!orderData.order.preparation_date && (
                <ListInfoItem
                  name='Подготовка до'
                  value={moment(orderData.order.preparation_date).format(
                    'DD.MM.YYYY'
                  )}
                  style={{ gap: 35 }}
                  valueStyle={{ textAlign: 'left' }}
                />
              )}
              <ListInfoItem
                name={(orderData.order.receive_method === ERecieveMethod.COURIER || orderData.order.receive_method === ERecieveMethod.EXPRESS) ? 'Адрес доставки' : orderData.order.receive_method === ERecieveMethod.PVZ ? 'Доставка до' : 'Самовывоз из'}
                value={
                  orderData.order?.delivery_info?.address?.address ??
                  orderData.order?.pvz?.location?.address_full ??
                  orderData.order?.shop_id?.address?.name
                }
                style={{ gap: orderData.order.receive_method === ERecieveMethod.COURIER ? 25 : orderData.order.receive_method === ERecieveMethod.PVZ ? 49 : 37 }}
                valueStyle={{ textAlign: 'left' }}
              />
              {orderData.order.receive_method === ERecieveMethod.PVZ && orderData.order.seller_id?.nearest_point_of_cdek && (
                <ListInfoItem
                  name='Отправка из'
                  value={orderData.order.seller_id?.nearest_point_of_cdek.location.address_full}
                  style={{ gap: 49 }}
                  valueStyle={{ textAlign: 'left' }}
                />
              )}
            </div>
            <div 
              className={classNames(
                styles.detailsItem,
                styles.priceInfoContainer
              )}
              style={{ width: orderData.order.delivery_info ? 'calc(100% / 3)' : 'calc(100% / 2)' }}
            >
              <div className={styles.priceInfoWrapper}>
                <Typography.Title className={styles.detailsItemTitle} level={3}>
                  Стоимость
                </Typography.Title>
                <ListInfoItem
                  name='Стоимость товаров'
                  value={getNormalizedPrice(orderData.order.sum)}
                />
                <ListInfoItem
                  name='Комиссия сервиса'
                  value={`${
                    !!orderData.commission ? '-' : ''
                  }${getNormalizedPrice(orderData.commission)}`}
                />
              </div>
              <div className={styles.finalPrice}>
                <Typography.Title
                  level={4}
                  style={{ marginBottom: 0, marginTop: 0 }}
                >
                  Итог:
                </Typography.Title>
                <Typography.Title
                  level={4}
                  style={{ marginBottom: 0, marginTop: 0 }}
                >
                  {getNormalizedPrice(orderData.order.full_sum)}
                </Typography.Title>
              </div>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <ListInfoItem
                  name='Стоимость доставки'
                  value={getNormalizedPrice(
                    orderData.order?.delivery_info?.delivery_sum ??
                      orderData.order?.pvz?.delivery_sum ??
                      0
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          {orderData.order.status === EOrderStatus.CONFIRMED_BY_SELLER &&
            orderData.order.receive_method === ERecieveMethod.PICKUP_POINT && (
            <Button
              size='large'
              htmlType='button'
              type='primary'
              loading={
                isChangeStatusLoading === EOrderStatus.WAITING_IN_POINT
              }
              onClick={() => changeStatus(EOrderStatus.WAITING_IN_POINT)}
            >
              Ожидает получения
            </Button>
          )}
          {orderData.order.status === EOrderStatus.WAITING_IN_POINT && orderData.order.receive_method === ERecieveMethod.PICKUP_POINT && (
            <Button
              size='large'
              htmlType='button'
              type='primary'
              loading={
                isChangeStatusLoading === EOrderStatus.FINISHED
              }
              onClick={() => changeStatus(EOrderStatus.FINISHED)}
            >
              Заказ передан клиенту
            </Button>
          )}
          {orderData.order.status === EOrderStatus.ON_CONFIRMATION && orderData.order.receive_method !== ERecieveMethod.EXPRESS &&  (
            <>
              <Button
                size='large'
                htmlType='button'
                type='primary'
                loading={
                  isChangeStatusLoading === EOrderStatus.CONFIRMED_BY_SELLER
                }
                onClick={() => changeStatus(EOrderStatus.CONFIRMED_BY_SELLER)}
              >
                Принять в работу
              </Button>
              <Button
                danger
                size='large'
                htmlType='button'
                type='primary'
                loading={isChangeStatusLoading === EOrderStatus.CANCELED}
                onClick={openOrderCanceledModal}
              >
                Отказаться от заказа
              </Button>
            </>
          )}
        </div>
      </div>
      <CancelOrder
        isOpen={openCancelOrderModal}
        isLoading={!!isChangeStatusLoading}
        onSuccess={changeCancelStatus}
        close={closeOrderCanceledModal}
      />
    </>
  )
}
