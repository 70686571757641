import React from 'react'
import { Button, DatePicker, Radio, Space, Table, Typography } from 'antd'
import { ESellerTransactionsType, ITransaction } from 'models/transaction'
import moment from 'moment'
import { FilterFilled } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { transactionsApi } from 'api/transaction'
import { IDateFilter } from 'models/app'
import { getTransactionTypeName } from 'shared/utils/transactions/getTransactionTypeName'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { showServerError } from 'shared/utils/showServerError'
import localeRu from 'antd/es/date-picker/locale/ru_RU'
import styles from './TransactionsTable.module.scss'
import 'dayjs/locale/ru'

interface ITransactionsTableProps {
  sellerId: string
  style?: React.CSSProperties
  isNeedToUpdate?: string
}

export const TransactionsTable: React.FC<ITransactionsTableProps> = React.memo(({ sellerId, style, isNeedToUpdate }) => {
  const [transactions, setTransactions] = React.useState<ITransaction[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const [currentPage, setCurrentPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const pageSize = 6

  const [type, setType] = React.useState<ESellerTransactionsType | undefined>(undefined)
  const [sum, setSum] = React.useState<'asc' | 'desc' | undefined>(undefined)
  const [sortDate, setSortData] = React.useState<IDateFilter | null>(null)

  const [datePickerValue, setDatePickerValue] = React.useState<any>(null)

  const getTransactions = async (skip: number) => {
    try {
      setIsLoading(true)
      const response = await transactionsApi.getTransactions({
        skip: String(skip),
        limit: String(pageSize),
        seller_id: sellerId,
        type,
        sum,
        from: sortDate?.from ?? '',
        to: sortDate?.to ?? ''
      })
      setTotal(response.data.count)
      setTransactions(response.data.transactions)
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onPageChange = (current: number) => {
    getTransactions((current - 1) * pageSize)
    setCurrentPage(current)
  }

  React.useEffect(() => {
    getTransactions(0)
    setCurrentPage(1)
  }, [type, sum, sortDate, isNeedToUpdate])

  const columns: ColumnsType<ITransaction> = React.useMemo(
    () => [
      {
        title: 'Тип транзакции',
        dataIndex: 'type',
        width: '33.3%',
        render: (_, { type }) => {
          return <Typography.Text>{getTransactionTypeName(type)}</Typography.Text>
        },
        filterDropdown: ({ close }) => {
          return (
            <div
              className='filterWrapper'
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Radio.Group
                onChange={(e) => {
                  setType(e.target.value)
                  close()
                }}
                value={type}
                style={{ display: 'block', marginBottom: 15 }}
              >
                <Space direction='vertical' size={15}>
                  {(Object.keys(ESellerTransactionsType) as Array<keyof typeof ESellerTransactionsType>).map((item) => (
                    <Radio key={item} value={item}>
                      <Typography.Text>{getTransactionTypeName(item)}</Typography.Text>
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
              <Button
                onClick={() => {
                  setType(undefined)
                  close()
                }}
                size='small'
                className='filterButton'
              >
                Очистить
              </Button>
              <Button
                type='link'
                size='small'
                onClick={() => {
                  close()
                }}
              >
                Закрыть
              </Button>
            </div>
          )
        },
        filterIcon: () => (
          <FilterFilled
            style={{
              color: type ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
            }}
          />
        )
      },
      {
        title: 'Сумма',
        dataIndex: 'sum',
        width: '33.3%',
        sorter: true,
        render: (_, { sum, type }) => {
          return <Typography.Text>{`${type === ESellerTransactionsType.SALE ? '+' : ''}${getNormalizedPrice(sum)}`}</Typography.Text>
        }
      },
      {
        title: 'Дата',
        dataIndex: 'date',
        width: '33.3%',
        render: (_, { createdAt }) => {
          return (
            <Typography.Text>{moment(createdAt).locale('ru').format('LL')}</Typography.Text>
          )
        },
        filterDropdown: ({ close }) => {
          return (
            <div className='filterWrapper'>
              <DatePicker.RangePicker
                rootClassName='myRangePicker'
                value={datePickerValue}
                format='DD/MM/YYYY'
                locale={{
                  ...localeRu,
                  lang: {
                    ...localeRu.lang,
                    rangePlaceholder: ['От', 'До']
                  }
                }}
                onCalendarChange={(value) => {
                  setDatePickerValue(value)
                  if (!value?.[0] && !value?.[1]) {
                    setSortData(null)
                  } else {
                    setSortData({
                      from: value?.[0]?.hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss') ?? '',
                      to: value?.[1]?.hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss') ?? ''
                    })
                  }
                }}
                style={{ width: 200, borderRadius: 4 }}
                inputReadOnly
              />
              <div className={styles.dateButtons}>
                <Button
                  onClick={() => {
                    setSortData(null)
                    setDatePickerValue(null)
                    close()
                  }}
                  size='small'
                  className='filterButton'
                >
                  Очистить
                </Button>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    close()
                  }}
                >
                  Закрыть
                </Button>
              </div>
            </div>
          )
        },
        filterIcon: () => (
          <FilterFilled
            style={{
              color: sortDate ? '#1677ff' : 'rgba(0, 0, 0, 0.25)'
            }}
          />
        )
      }
    ],
    [transactions, type, sum, sortDate]
  )

  return (
    <div style={style}>
      <Typography.Title level={2} className={styles.title}>
        Транзакции
      </Typography.Title>
      <Table
        scroll={{
          x: 550
        }}
        columns={columns}
        dataSource={transactions}
        loading={isLoading}
        rowKey={(el) => el._id}
        onChange={(_, filters, sort: any) => {
          setSum(
            sort.order === 'ascend'
              ? 'asc'
              : sort.order === 'descend'
                ? 'desc'
                : undefined
          )
        }}
        pagination={{
          current: currentPage,
          onChange: onPageChange,
          pageSize,
          total,
          showSizeChanger: false
        }}
      />
    </div>
  )
})
