import React from 'react'
import { Table, Tag, Typography, message } from 'antd'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { EOrderStatus, IAdminMultiOrderResponse, ITableOrder } from 'models/orders'
import moment from 'moment'
import { MenuInfo } from 'rc-menu/lib/interface'
import { ordersApi } from 'api/orders'
import { showServerError } from 'shared/utils/showServerError'
import { superAdminRoutes } from 'shared/constants/routes'
import { getOrderPath } from 'routing/utils/getOrderPath'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { getOrderStatusItems, orderTags } from 'shared/constants/orders'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { acceptOrderModal } from 'shared/utils/orders/acceptOrderModal'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import { CancelOrder } from 'components/orders/CancelOrder/CancelOrder'
import { ERoles } from 'models/auth'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'

export const SuperOrder: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const [superOrderData, setSuperOrderData] = React.useState<IAdminMultiOrderResponse | null>(null)
  const [isLoaded, setIsLoaded] = React.useState(false)

  const [isStatusUpdating, setIsStatusUpdating] = React.useState(false)

  const [openCancelOrderModal, setOpenCancelOrderModal] = React.useState(false)

  const [selectedOrderId, setSelectedOrderId] = React.useState<string | null>(null)

  React.useEffect(() => {
    const getOrders = async () => {
      try {
        message.loading('Загрузка данных')
        const response = await ordersApi.getSuperOrder(id as string)
        setSuperOrderData(response.data)
      } catch (e) {
        const error = e as AxiosError
        if (
          error.response?.status === 500 ||
          error.response?.status === 400 ||
          error.response?.status === 404
        ) {
          return navigate(superAdminRoutes.ORDERS)
        }
        showServerError(e)
      } finally {
        message.destroy()
        setIsLoaded(true)
      }
    }
    getOrders()
  }, [])

  const changeStatus = async (status: EOrderStatus, orderId?: string, cancelReason?: string) => {
    try {
      setIsStatusUpdating(true)
      const normalizedOrderId = orderId ?? selectedOrderId ?? ''
      await ordersApi.changeStatus({
        status,
        orderId: normalizedOrderId,
        reason_of_cancel: cancelReason
      })
      setSuperOrderData(() => ({
        ...superOrderData as IAdminMultiOrderResponse,
        orders: (superOrderData as IAdminMultiOrderResponse).orders.map(el => {
          if (el._id === normalizedOrderId) {
            return {
              ...el,
              status
            }
          }
          return el
        })
      }))
      setSelectedOrderId(null)
    } catch (e) {
      showServerError(e)
    } finally {
      setIsStatusUpdating(false)
    }
  }

  const closeOrderCanceledModal = React.useCallback(() => {
    setOpenCancelOrderModal(false)
    bodyScrollLock.disable()
  }, [])

  const openOrderCanceledModal = React.useCallback(() => {
    setOpenCancelOrderModal(true)
    bodyScrollLock.enable()
  }, [])

  const onTagClick = (e: MenuInfo, orderId: string) => {
    if (e.key === EOrderStatus.CONFIRMED_BY_SELLER) {
      return acceptOrderModal(async () => {
        await changeStatus(EOrderStatus.CONFIRMED_BY_SELLER, orderId)
        bodyScrollLock.disable()
      })
    }

    if (e.key === EOrderStatus.CANCELED) {
      setSelectedOrderId(orderId)
      return openOrderCanceledModal()
    }

    changeStatus(e.key as EOrderStatus, orderId)
  }
  
  const changeToCanceledStatus = (cancelReason: string) =>
    changeStatus(EOrderStatus.CANCELED, undefined, cancelReason)

  const columns: ColumnsType<ITableOrder> = React.useMemo(
    () => [
      {
        title: 'Заказ №',
        dataIndex: 'number',
        width: '12%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getOrderPath(_id))
          }
        },
        render: (_, { number }) => {
          return (
            <Typography.Text style={{ wordWrap: 'break-word' }} onClick={(event) => event.stopPropagation()}>
              {number}
            </Typography.Text>
          )
        }
      },
      {
        title: 'Продавец',
        dataIndex: 'seller',
        width: '21%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getOrderPath(_id))
          }
        },
        render: (_, { seller_id }) => {
          return (
            <Typography.Text
              style={{
                opacity: seller_id?.shop_details?.shop_name ? 1 : 0.5
              }}
              onClick={(event) => event.stopPropagation()}
            >
              {seller_id?.shop_details?.shop_name ?? 'Удаленный аккаунт'}
            </Typography.Text>
          )
        }
      },
      {
        title: 'Покупатель',
        dataIndex: 'buyer',
        width: '21%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getOrderPath(_id))
          }
        },
        render: (_, { user_id }) => {
          return (
            <Typography.Text style={{ opacity: user_id?.tel ? 1 : 0.5 }} onClick={(event) => event.stopPropagation()}>
              {user_id?.tel ? phoneMaskStatic(user_id.tel) : 'Удаленный аккаунт'}
            </Typography.Text>
          )
        }
      },
      {
        title: 'Дата',
        dataIndex: 'createdAt',
        width: '15%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getOrderPath(_id))
          }
        },
        render: (_, { createdAt }) => {
          return (
            <Typography.Text onClick={(event) => event.stopPropagation()}>
              {moment(createdAt).format('DD.MM.YYYY')}
            </Typography.Text>
          )
        }
      },
      {
        title: 'Сумма',
        dataIndex: 'full_sum',
        width: '16%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getOrderPath(_id))
          }
        },
        render: (_, { full_sum }) => {
          return (
            <Typography.Text onClick={(event) => event.stopPropagation()}>{getNormalizedPrice(full_sum)}</Typography.Text>
          )
        }
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        width: '15%',
        onCell: ({ _id }) => {
          return {
            onClick: () => navigate(getOrderPath(_id))
          }
        },
        render: (_, { status, _id, receive_method }) => {
          if (
            status === EOrderStatus.CANCELED ||
            status === EOrderStatus.NOT_PAID
          ) {
            return (
              <Tag color={orderTags[status].color} onClick={(event) => event.stopPropagation()}>
                {orderTags[status].label}
              </Tag>
            )
          }
          return (
            <div onClick={(event) => event.stopPropagation()}>
              <StatusDropdown
                items={getOrderStatusItems(status, ERoles.ADMIN, receive_method)}
                label={orderTags[status as EOrderStatus].label}
                color={orderTags[status as EOrderStatus].color}
                onClick={(e) => onTagClick(e, _id)}
              />
            </div>
          )
        }
      }
    ],
    [superOrderData]
  )

  if (!isLoaded) {
    return null
  }

  return (
    <>
      <div style={{ paddingBottom: 60 }}>
        <MainTitle
          isGoBack
          text={`Супер-заказ № ${superOrderData?.number}`}
          style={{ marginBottom: 30 }}
        />
        <Table
          scroll={{ x: 850 }}
          columns={columns}
          dataSource={superOrderData?.orders}
          loading={isStatusUpdating}
          rowKey={(el) => el._id}
          rowClassName='tableRow'
          pagination={false}
        />
      </div>
      <CancelOrder
        isOpen={openCancelOrderModal}
        onSuccess={changeToCanceledStatus}
        close={closeOrderCanceledModal}
      />
    </>
  )
}
