import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { bodyScrollLock } from '../bodyScrollLock'

export const acceptOrderModal = (onSuccessCallback: () => Promise<void>) => {
  bodyScrollLock.enable()
  Modal.confirm({
    centered: true,
    title: 'Внимание!',
    content:
      'При изменении статуса заказа на “Принят” оформляется доставка, а за все удаленные товары будут возвращены деньги.',
    icon: <ExclamationCircleOutlined />,
    okText: 'Подтвердить',
    cancelText: 'Отменить',
    wrapClassName: 'modalFooterButtonsAdaptive',
    onOk() {
      return new Promise((resolve) => {
        onSuccessCallback().then(resolve)
      })
    },
    onCancel() {
      bodyScrollLock.disable()
    }
  })
}
