import React from 'react'
import Table, { ColumnsType } from 'antd/es/table'
import { horsesApi } from 'api/horses'
import { PageWrapper, MainTitle } from 'components/ui'
import { EHorseStatus, IPopulatedHorseWithoutCount } from 'models/horse'
import { showServerError } from 'shared/utils/showServerError'
import { Button, message, Modal, Space, Typography } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import moment from 'moment'
import { phoneMaskStatic } from 'shared/utils/phoneMaskStatic'
import { StatusDropdown } from 'components/ui/StatusDropdown/StatusDropdown'
import { getHorseStatusItems, horseStatuses } from 'shared/constants/horses'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import { useSearchParams } from 'react-router-dom'
import { cleanObject } from 'shared/utils/cleanObject'
import qs from 'qs'
import { RejectHorseModal } from 'components/horses/RejectHorseModal/RejectHorseModal'

export const Horses: React.FC = () => {
  const [, setSearchParams] = useSearchParams()

  const [horses, setHorses] = React.useState<IPopulatedHorseWithoutCount[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const [selectedItemsId, setSelectedItemsId] = React.useState<string[]>([])
  const [selectedHorseId, setSelectedHorseId] = React.useState<string | null>(null)
  const [openRejectedHorseModal, setOpenRejectedHorseModal] = React.useState(false)

  const [currentPage, setCurrentPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const pageSize = 10

  const getHorses = async (skip: number) => {
    try {
      setIsLoading(true)
      const response = await horsesApi.getHorses({
        skip: String(skip),
        limit: String(pageSize)
      })
      setTotal(response.data.count)
      setHorses(response.data.horses)
      setSearchParams(cleanObject({
        page: skip / pageSize + 1 === 1 ? '' : String(skip / pageSize + 1)
      }))
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1))
      const page = Number(params.page)
      if (!isNaN(page)) {
        setCurrentPage(page)
        getHorses((page - 1) * pageSize)
      }
    } else {
      getHorses(0)
    }
  }, [])

  const onCloseRejectedHorseModal = React.useCallback(() => {
    setOpenRejectedHorseModal(false)
    bodyScrollLock.disable()
  }, [])

  const onOpenRejectedHorseModal = React.useCallback(() => {
    setOpenRejectedHorseModal(true)
    bodyScrollLock.enable()
  }, [])


  const onChangeHorseStatus = async (status: EHorseStatus, horseId?: string, rejectReason?: string) => {
    try {
      setIsLoading(true)
      const normalizedHorseId = horseId ?? selectedHorseId ?? ''
      await horsesApi.changeHorseStatus(normalizedHorseId, { status, reason_of_cancel: rejectReason })
      setHorses((prev) =>
        prev.map((el) => {
          if (el._id === normalizedHorseId) {
            return {
              ...el,
              status
            }
          }
          return el
        })
      )
      setSelectedHorseId(null)
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const changeToRejectedStatus = (rejectReason: string) => onChangeHorseStatus(EHorseStatus.REJECTED, undefined, rejectReason)

  const onStatusTagClick = (horseId: string, e: MenuInfo) => {
    if (e.key === EHorseStatus.REJECTED) {
      setSelectedHorseId(horseId)
      return onOpenRejectedHorseModal()
    }
    onChangeHorseStatus(e.key as EHorseStatus, horseId)
  }

  const setProductsAction = async () => {
    try {
      await horsesApi.setActions({
        toDelete: selectedItemsId.join(',')
      })
      message.success(selectedItemsId.length === 1 ? 'Лошадь успешно удалена' : 'Выбранные лошади успешно удалены')
      if (selectedItemsId.length === horses.length) {
        getHorses(0)
        setCurrentPage(1)
      } else {
        getHorses((currentPage - 1) * pageSize)
      }
      setSelectedItemsId([])
    } catch (e) {
      showServerError(e)
    } finally {
      bodyScrollLock.disable()
    }
  }

  const showDeleteConfirm = () => {
    bodyScrollLock.enable()
    Modal.confirm({
      centered: true,
      title: 'Вы действительно хотите удалить выбранные лошади?',
      content: 'Отменить данное действие будет невозможно.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',
      cancelText: 'Закрыть',
      onOk() {
        return new Promise((resolve) => {
          setProductsAction().then(resolve)
        })
      },
      onCancel() {
        bodyScrollLock.disable()
      }
    })
  }

  const onPageChange = (current: number) => {
    getHorses((current - 1) * pageSize)
    setCurrentPage(current)
  }

  const onRowSelection = (selectedItemsId: React.Key[]) => {
    setSelectedItemsId(selectedItemsId as string[])
  }

  const columns: ColumnsType<IPopulatedHorseWithoutCount> = React.useMemo(
    () => [
      {
        title: 'Номер',
        dataIndex: 'number',
        width: 101
      },
      {
        title: 'Дата',
        dataIndex: 'date',
        width: 192,
        render: (_, { createdAt }) => {
          return <Typography.Text>{moment(createdAt).format('DD.MM.YYYY')}</Typography.Text>
        }
      },
      {
        title: 'Продавец',
        dataIndex: 'seller',
        width: 192,
        render: (_, { user_id }) => {
          return (
            <Typography.Text style={{ opacity: user_id?.full_name ? 1 : 0.5 }}>{user_id?.full_name ?? 'Удаленный аккаунт'}</Typography.Text>
          )
        }
      },
      {
        title: 'Телефон',
        dataIndex: 'sellerPhone',
        width: 192,
        render: (_, { user_id }) => {
          return (
            <Typography.Text style={{ opacity: user_id?.tel ? 1 : 0.5 }}>{user_id?.tel ? phoneMaskStatic(user_id.tel) : 'Удаленный аккаунт'}</Typography.Text>
          )
        }
      },
      {
        title: 'Лошадь',
        dataIndex: 'name',
        width: 192
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        width: 154,
        render: (_, { _id, status }) => {
          return (
            <StatusDropdown
              items={getHorseStatusItems(status)}
              label={horseStatuses[status].label}
              color={horseStatuses[status].color}
              onClick={(e) => onStatusTagClick(_id, e)}
            />
          )
        }
      }
    ],
    [horses]
  )

  return (
    <>
      <PageWrapper>
        <MainTitle text='Лошади' />
        <Table
          scroll={{ x: 950 }}
          columns={columns}
          dataSource={horses}
          loading={isLoading}
          rowKey={(el) => el._id}
          rowSelection={{
            selectedRowKeys: selectedItemsId,
            onChange: onRowSelection
          }}
          rowClassName='tableRow'
          footer={() => (
            <Space size={8}>
              <Typography.Text>Действия:</Typography.Text>
              <Button
                danger
                type='primary'
                icon={<DeleteOutlined />}
                onClick={showDeleteConfirm}
                disabled={!selectedItemsId.length}
              >
                Удалить
              </Button>
            </Space>
          )}
          pagination={{
            current: currentPage,
            onChange: onPageChange,
            pageSize,
            total,
            showSizeChanger: false
          }}
        />
      </PageWrapper>
      <RejectHorseModal
        isOpen={openRejectedHorseModal}
        onSuccess={changeToRejectedStatus}
        close={onCloseRejectedHorseModal}
      />
    </>
  )
}