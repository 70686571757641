import React from 'react'
import { Button, Form, Input, Space } from 'antd'
import { authApi } from 'api/auth'
import { AxiosError } from 'axios'
import { AuthContentWrapper } from 'components/layout/AuthContentWrapper/AuthContentWrapper'
import { LargeText } from 'components/ui'
import { numbersPattern } from 'shared/utils/patterns'
import { showServerError } from 'shared/utils/showServerError'
import styles from './Confirm.module.scss'

interface IConfirmForm {
  activation_code: string
}

interface IConfirmProps {
  email: string
  onSuccess: () => void
  onGoBack: () => void
}

export const Confirm: React.FC<IConfirmProps> = ({
  email,
  onSuccess,
  onGoBack
}) => {
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async (data: IConfirmForm) => {
    try {
      setIsLoading(true)
      await authApi.activateEmail({
        email,
        activation_code: Number(data.activation_code)
      })
      onSuccess()
    } catch (e) {
      const error = e as AxiosError
      if (error.response?.status === 400) {
        return form.setFields([
          {
            name: 'activation_code',
            errors: ['Неверный код проверки']
          }
        ])
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthContentWrapper
      title='Подтверждение почты'
      titleOffset={19}
      titleClass={styles.title}
      padding='60px 66px 83px'
    >
      <LargeText style={{ marginBottom: 24 }}>
        На ваш электронный адрес <b>{email}</b> было отправлено письмо с кодом
        для подтверждения:
      </LargeText>
      <Form form={form} name='confirmEmail' size='large' onFinish={onSubmit}>
        <Form.Item
          name='activation_code'
          rules={[
            {
              required: true,
              message: 'Введите код проверки'
            },
            {
              pattern: numbersPattern,
              message: 'Введите корректный код проверки'
            }
          ]}
          hasFeedback
        >
          <Input placeholder='Код проверки' />
        </Form.Item>
        <Space size={24} direction='vertical' style={{ width: '100%' }}>
          <Button block htmlType='submit' type='primary' loading={isLoading}>
            Подтвердить
          </Button>
          <Button block type='default' onClick={onGoBack}>
            Изменить e-mail
          </Button>
        </Space>
      </Form>
    </AuthContentWrapper>
  )
}
