import { privateApi } from 'api'
import { AxiosResponse } from 'axios'
import {
  IAddressSuggestionRequest,
  IAddressSuggestionResponse
} from 'models/app'

export const appApi = {
  getAddresses(
    data: IAddressSuggestionRequest
  ): Promise<AxiosResponse<IAddressSuggestionResponse>> {
    return privateApi.post<IAddressSuggestionResponse>(
      `dadata/address?address=${data.address}&isCityOnly=${
        data?.isCityOnly ?? 'false'
      }`
    )
  }
}
