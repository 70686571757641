import React from 'react'
import { Button, Carousel, Modal, Space, Typography } from 'antd'
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons'
import uniq from 'lodash.uniq'
import classNames from 'classnames'
import { EProductStatus, IPreviewData } from 'models/product'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import { LargeText } from 'components/ui'
import { TVariantMode } from 'models/app'
import { bodyScrollLock } from 'shared/utils/bodyScrollLock'
import styles from './ProductPreview.module.scss'
import { getWeightUnit } from './utils/getWeightUnit'

interface IProductPreviewProps {
  mode: TVariantMode
  open: boolean
  data: IPreviewData | null
  firstVariationName: string | undefined
  secondVariationName: string | undefined
  form: any
  close: () => void
  deleteProduct: (onSuccess?: () => void) => void
  submit: (status: EProductStatus.DRAFT | EProductStatus.PUBLISHED) => void
}

export const ProductPreview: React.FC<IProductPreviewProps> = React.memo(
  ({ mode, open, data, form, deleteProduct, close, submit }) => {
    const onDelete = () => {
      if (mode === 'create') {
        return deleteProduct(() => {
          close()
          form.resetFields()
          window.scrollTo({ top: 0, behavior: 'smooth' })
          bodyScrollLock.disable()
        })
      }
      return deleteProduct()
    }

    const firstVariationName = React.useMemo(() => {
      if (data?.variations) {
        return data.variations.find(el => el.firstCharacteristicName)?.firstCharacteristicName
      }
    }, [data?.variations])

    const firstVariationValue = React.useMemo(() => {
      if (data?.variations) {
        return data.variations.find(el => el.firstCharacteristicValue)?.firstCharacteristicValue
      }
    }, [data?.variations])

    const secondVariationName = React.useMemo(() => {
      if (data?.variations) {
        return data.variations.find(el => el.secondCharacteristicName)?.secondCharacteristicName
      }
    }, [data?.variations])

    const secondVariationValue = React.useMemo(() => {
      if (data?.variations) {
        return data.variations.find(el => el.secondCharacteristicValue)?.secondCharacteristicValue
      }
    }, [data?.variations])

    const firstValuesArray = React.useMemo(() => {
      if (data?.variations) {
        return uniq(data.variations.filter(item => item.firstCharacteristicValue).map(item => item.firstCharacteristicValue))
      }
      return []
    }, [data?.variations])

    const secondValuesArray = React.useMemo(() => {
      if (data?.variations) {
        return uniq(data.variations.filter(item => item.secondCharacteristicValue).map(item => item.secondCharacteristicValue))
      }
      return []
    }, [data?.variations]) 

    return (
      <Modal
        centered
        closable={false}
        footer={null}
        open={open}
        onCancel={close}
        wrapClassName={styles.wrapper}
      >
        <Carousel autoplay className={styles.carousel}>
          {data?.photo.map((el: any) => (
            <img
              key={el?.uid}
              src={el.thumbUrl}
              alt='Фотография товара'
              className={styles.image}
            />
          ))}
        </Carousel>
        <Typography.Text
          className={styles.category}
        >{`${data?.categoryName} > ${data?.subCategoryName}`}</Typography.Text>
        <Typography.Title level={3} className={styles.name}>
          {data?.name}
        </Typography.Title>
        <Space align='center'>
          <Typography.Title level={4} className={styles.price}>
            Цена:
          </Typography.Title>
          <Typography.Title level={4} className={styles.price}>
            {data?.price && getNormalizedPrice(data.price)}
          </Typography.Title>
          <Typography.Text className={styles.price}>за шт.</Typography.Text>
        </Space>
        <LargeText className={styles.description}>{data?.desc}</LargeText>
        <Typography.Title level={4} className={styles.dimensionsTitle}>
          Габариты
        </Typography.Title>
        <div className={styles.dimensionsWrapper}>
          <div>
            <div className={styles.list}>
              <LargeText className={styles.listText}>Ширина:</LargeText>
              <LargeText
                className={classNames(styles.listText, styles.listValue)}
              >
                {data?.dimension.width} см
              </LargeText>
            </div>
            <div className={styles.list}>
              <LargeText className={styles.listText}>Высота:</LargeText>
              <LargeText
                className={classNames(styles.listText, styles.listValue)}
              >
                {data?.dimension.height} см
              </LargeText>
            </div>
            <div className={styles.list}>
              <LargeText className={styles.listText}>В наличии:</LargeText>
              <LargeText
                className={classNames(styles.listText, styles.listValue)}
              >
                {data?.dimension.in_stock} шт.
              </LargeText>
            </div>
          </div>
          <div>
            <div className={styles.list}>
              <LargeText className={styles.listText}>Длина:</LargeText>
              <LargeText
                className={classNames(styles.listText, styles.listValue)}
              >
                {data?.dimension.length} см
              </LargeText>
            </div>
            <div className={styles.list}>
              <LargeText className={styles.listText}>Вес:</LargeText>
              <LargeText
                className={classNames(styles.listText, styles.listValue)}
              >
                {data?.dimension.weight} {getWeightUnit(data?.dimension.unit)}
              </LargeText>
            </div>
          </div>
        </div>
        {!!data?.characteristics?.length && (
          <Typography.Title level={4} className={styles.characteristicsTitle}>
            Характеристики
          </Typography.Title>
        )}
        {data?.characteristics?.map((item, index) => (
          <div key={index} className={styles.list}>
            <LargeText className={styles.listText} style={{ wordBreak: 'keep-all' }}>{item.name}:</LargeText>
            <LargeText
              className={classNames(styles.listText, styles.listValue)}
            >
              {item.value}
            </LargeText>
          </div>
        ))}
        {data?.variations && (
          <>
            {firstVariationValue && (
              <div className={styles.list}>
                <LargeText className={styles.listText} style={{ wordBreak: 'keep-all' }}>{firstVariationName}:</LargeText>
                <div style={{ display: 'flex', gap: 2 }}>
                  {firstValuesArray.map((item, index) => (
                    <LargeText
                      className={classNames(styles.listText, styles.listValue)}
                    >
                      {item}{!!item && index + 1 !== firstValuesArray.length ? ',' : ''}
                    </LargeText>
                  ))}
                </div>
              </div>
            )}
            {secondVariationValue && (
              <div className={styles.list}>
                <LargeText className={styles.listText} style={{ wordBreak: 'keep-all' }}>{secondVariationName}:</LargeText>
                <div style={{ display: 'flex', gap: 2 }}>
                  {secondValuesArray.map((item, index) => (
                    <LargeText
                      className={classNames(styles.listText, styles.listValue)}
                    >
                      {item}{!!item && index + 1 !== secondValuesArray.length ? ',' : ''}
                    </LargeText>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        <div className={styles.buttons}>
          <Space size={14} style={{ marginBottom: 13 }} wrap>
            <Button
              size='large'
              type='primary'
              icon={<AppstoreAddOutlined />}
              onClick={() => submit(EProductStatus.PUBLISHED)}
            >
              Опубликовать
            </Button>
            <Button
              size='large'
              type='primary'
              icon={<DeleteOutlined />}
              onClick={onDelete}
              danger
            >
              Удалить
            </Button>
          </Space>
          <Space size={14} wrap>
            <Button
              size='large'
              icon={<SaveOutlined />}
              onClick={() => submit(EProductStatus.DRAFT)}
            >
              Сохранить как черновик
            </Button>
            <Button size='large' icon={<EditOutlined />} onClick={close}>
              Редактировать
            </Button>
          </Space>
        </div>
      </Modal>
    )
  }
)
