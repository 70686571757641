import { EUnit } from 'models/product'

export const getWeightUnit = (unit: EUnit | undefined): string => {
  if (!unit) return ''
  switch (unit) {
  case EUnit.KG:
    return 'кг'
  case EUnit.GR:
    return 'гр'
  }
}
