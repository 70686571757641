import { Button, Popconfirm, Table, Tag } from 'antd'
import React from 'react'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import noProductImage from 'assets/images/no-image-product.png'
import { showServerError } from 'shared/utils/showServerError'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { getRoute, superAdminRoutes } from 'shared/constants/routes'
import { appSelectors } from 'store/app'
import { ERoles } from 'models/auth'
import { ColumnsType } from 'antd/es/table'
import { ICollection } from 'models/collections'
import { collectionsApi } from 'api/collections'
import { DeleteFilled } from '@ant-design/icons'
import { IMAGES_URL } from 'shared/constants/urls'
import { HeaderList } from './components/HeaderList'
import { CREATE_MODE, getLink } from './utils/constants'
import { getColorByStatus, getStatus } from './utils/status'

export const CollectionList: React.FC = () => {
  const role = useAppSelector(appSelectors.selectRole)
  const isSuperAdmin = role === ERoles.ADMIN

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)
  const [collections, setCollections] = React.useState<ICollection[]>([])

  const getCollections = async () => {
    try {
      setIsLoading(true)
      const response = await collectionsApi.getCollections()
      setCollections(response.data)
    } catch (e) {
      const error = e as AxiosError
      if (
        (error.response?.status === 500 ||
          error.response?.status === 400 ||
          error.response?.status === 404) && isSuperAdmin
      ) {
        return navigate(superAdminRoutes.SELLERS)
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    const getData = async () => {
      try {
        await getCollections()
      } catch (e) {
        showServerError(e)
      }
    }
    getData()
  }, [])

  const onNavigate = (_id: string) => navigate(getRoute.collection_edit(_id))
  const onCell = ({ _id }: { _id: string }) => ({ onClick: () => onNavigate(_id) })


  const handleDeleteCollection = async (id: string) => {
    try {
      await collectionsApi.deleteCollection(id)
      await getCollections()
    } catch (e) {
      showServerError(e)
    }
  }


  const columns: ColumnsType<ICollection> = [
    {
      title: 'Названик подборки',
      dataIndex: 'title',
      key: 'title',
      onCell
    },
    {
      title: 'Фото',
      dataIndex: 'photo',
      width: 76,
      render: (_, { photo }) => {
        return photo ? (
          <div style={{ width: 62, height: 56, backgroundImage: `url(${IMAGES_URL}${photo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
        ) : (
          <img
            src={noProductImage}
            alt='Изображение товара'
            width={62}
            height={56}
          />
        )
      }
    },
    {
      title: 'Ссылка',
      dataIndex: 'slug',
      key: 'slug',
      onCell,
      render: (_, { slug }) => {
        return (
          <a href={getLink(slug)} onClick={(event) => event.stopPropagation()}>
            {getLink(slug)}
          </a>
        )
      }
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      onCell,
      render: (_, { status }) => {
        return (
          <Tag color={getColorByStatus(status)} onClick={(event) => event.stopPropagation()}>
            {getStatus(status)}
          </Tag>
        )
      }
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 56,
      render: (_, collection) =>
        collections.length >= 1 ? (
          <Popconfirm title='Отправить в архив?' onConfirm={() => handleDeleteCollection(collection._id)}>
            <Button type='primary' danger icon={<DeleteFilled />} size={'small'} />
          </Popconfirm>
        ) : null
    }
  ]

  return (
    <>
      <div style={{ paddingBottom: 80 }}>
        <HeaderList onClick={() => onNavigate(CREATE_MODE)} />
        <Table
          scroll={{
            x: 850
          }}
          pagination={false}
          columns={columns}
          dataSource={collections}
          loading={isLoading}
          rowKey={(el) => el._id}
          rowClassName='tableRow'
        />
      </div>
    </>
  )
}
