import React from 'react'
import { Button, Input, Typography } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import {
  ESubCategoryStatus,
  IFrontCategory,
  IFrontSubCategory
} from 'models/product'
import { productApi } from 'api/product'
import { showServerError } from 'shared/utils/showServerError'
import { getRandomString } from 'shared/utils/getRandomString'
import { showMessage } from 'shared/utils/showMessage'
import { SelectedItem } from '../SelectedItem/SelectedItem'
import styles from './SubCategoriesColumn.module.scss'

interface ISubCategoriesColumnProps {
  subCategories: IFrontSubCategory[]
  selectedCategory: IFrontCategory
  selectedSubCategory: IFrontSubCategory | null
  setSubCategories: (subCategories: IFrontSubCategory[]) => void
  setSelectedSubCategory: (
    selectedSubCategory: IFrontSubCategory | null
  ) => void
  createNewSubCategory: (newSubCategory: IFrontSubCategory) => void
}

export const SubCategoriesColumn: React.FC<ISubCategoriesColumnProps> =
  React.memo(
    ({
      subCategories,
      selectedCategory,
      selectedSubCategory,
      setSubCategories,
      setSelectedSubCategory,
      createNewSubCategory
    }) => {
      const [newSubCategoryNumber, setNewSubCategoryNumber] = React.useState(0)
      const [isOnModerationFilter, setIsOnModerationFilter] =
        React.useState(false)
      const [search, setSearch] = React.useState('')

      React.useEffect(() => {
        const getSubCategories = async () => {
          try {
            const response = await productApi.getSubCategoriesByCategory(
              selectedCategory._id
            )
            setSubCategories(
              response.data.map((el) => ({
                ...el,
                isNew: false
              })) as IFrontSubCategory[]
            )
          } catch (e) {
            showServerError(e)
          }
        }

        setIsOnModerationFilter(false)

        if (selectedCategory.isNew) {
          setSubCategories([])
        } else {
          getSubCategories()
        }
      }, [selectedCategory])

      const onCreateNewSubCategory = () => {
        if (selectedCategory.isNew) {
          return showMessage(
            'error',
            'Перед добавлением подкатегории, необходимо сохранить новую категорию',
            ''
          )
        }
        createNewSubCategory({
          _id: getRandomString(),
          name: newSubCategoryNumber
            ? `Новая подкатегория (${newSubCategoryNumber})`
            : 'Новая подкатегория',
          category_id: selectedCategory,
          createdAt: new Date(),
          updatedAt: new Date(),
          status: ESubCategoryStatus.ACTIVE,
          slug: getRandomString(),
          isNew: true
        })
        setNewSubCategoryNumber((prev) => ++prev)
      }

      const subCategoriesBySearchAndFilter: IFrontSubCategory[] = React.useMemo(() => {
        const searchedSubCategories = subCategories.filter(item => item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        if (isOnModerationFilter) {
          return searchedSubCategories.filter(item => item.status === ESubCategoryStatus.MODERATION)
        }
        return searchedSubCategories
      }, [isOnModerationFilter, search, subCategories])

      return (
        <div className={styles.wrapper}>
          <header className={styles.header}>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Подкатегории
            </Typography.Title>
            <div className={styles.actions}>
              <Button
                type={isOnModerationFilter ? 'primary' : 'default'}
                danger={isOnModerationFilter}
                size='small'
                onClick={() => setIsOnModerationFilter((prev) => !prev)}
              >
                M
              </Button>
              <Button
                type='primary'
                size='small'
                icon={<PlusCircleOutlined />}
                onClick={onCreateNewSubCategory}
              />
            </div>
          </header>
          <Input.Search
            allowClear
            value={search}
            placeholder='Поиск'
            size='small'
            onChange={(e) => setSearch(e.target.value)}
            className={styles.search}
          />
          {subCategoriesBySearchAndFilter.map((item) => (
            <SelectedItem
              isCloseIcon
              key={item._id}
              title={item.name}
              isSelected={selectedSubCategory?._id === item._id}
              isOnModeration={item.status === ESubCategoryStatus.MODERATION}
              onClick={() => setSelectedSubCategory(item)}
              onCloseIconClick={() => setSelectedSubCategory(null)}
            />
          ))}
        </div>
      )
    }
  )
