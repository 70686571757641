import React from 'react'
import {
  Button,
  Form,
  Input,
  Typography,
  Upload,
  message,
  Collapse
} from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { productApi } from 'api/product'
import { ICategory, IFrontCategory } from 'models/product'
import removePhoto from 'assets/icons/remove-photo.svg'
import { IMAGES_URL } from 'shared/constants/urls'
import { showServerError } from 'shared/utils/showServerError'
import { normalizeFile } from 'shared/utils/normalizeFile'
import { showMessage } from 'shared/utils/showMessage'
import { useAppSelector } from 'shared/hooks/useReduxHooks'
import { appSelectors } from 'store/app'
import { ChangeDataWrapper } from '../ChangeDataWrapper/ChangeDataWrapper'
import styles from './ChangeCategory.module.scss'

interface IChangeCategoryProps {
  currentCategory: IFrontCategory
  onSuccess: (updatedCategory: ICategory) => void
}

interface IFormData {
  name: string
  photo: any
  title?: string
  desc?: string
  // h1?: string
  seoText?: string
}

const { Panel } = Collapse

export const ChangeCategory: React.FC<IChangeCategoryProps> = React.memo(
  ({ currentCategory, onSuccess }) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [deletedPhoto, setDeletedPhoto] = React.useState<string>('')

    const [form] = Form.useForm()

    const role = useAppSelector(appSelectors.selectRole)

    const photo =
      Form.useWatch('photo', {
        form,
        preserve: true
      }) ?? []

    React.useEffect(() => {
      form.resetFields()
      form.setFields([
        { name: 'name', value: currentCategory.name },
        { name: 'title', value: currentCategory?.seoInfo?.title },
        { name: 'desc', value: currentCategory?.seoInfo?.desc },
        // { name: 'h1', value: currentCategory?.seoInfo?.h1 },
        { name: 'seoText', value: currentCategory?.seoInfo?.seoText }
      ])

      if (currentCategory.photo) {
        form.setFieldValue('photo', [
          {
            uid: currentCategory.photo,
            thumbUrl: `${IMAGES_URL}${currentCategory.photo}`
          }
        ])
      }
    }, [currentCategory])

    const onRemovePhoto = (file: any) => {
      if (!file.originFileObj) {
        setDeletedPhoto(file.uid)
      }
    }

    const onSubmit = async (data: IFormData) => {
      try {
        setIsLoading(true)

        const commonData = {
          name: data.name,
          photo: data.photo[0].originFileObj ?? data.photo[0].uid,
          title: data?.title,
          desc: data?.desc,
          // h1: data?.h1,
          seoText: data?.seoText
        }

        if (currentCategory.isNew) {
          const response = await productApi.createCategory(commonData)
          onSuccess(response.data)
          message.success('Категория успешно добавлена')
        } else {
          const response = await productApi.updateCategory(
            currentCategory._id,
            {
              ...commonData,
              toDeletePhoto: deletedPhoto
            }
          )
          onSuccess(response.data)
          message.success('Категория успешно обновлена')
        }
      } catch (e) {
        showServerError(e)
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <ChangeDataWrapper title='Основная категория'>
        <Form
          form={form}
          name='changeCategory'
          size='small'
          onFinish={onSubmit}
        >
          <div style={{ display: 'flex' }}>
            <Form.Item
              name='photo'
              valuePropName='fileList'
              getValueFromEvent={normalizeFile}
              rules={[
                {
                  required: true,
                  message: 'Добавьте изображение категории'
                }
              ]}
              style={{ flex: 1 }}
              hasFeedback
            >
              <Upload.Dragger
                multiple={false}
                maxCount={1}
                accept='image/png, image/jpeg, image/jpg'
                listType='picture'
                beforeUpload={(file) => {
                  const isValid =
                    file.type === 'image/png' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/jpeg'
                  if (!isValid) {
                    showMessage(
                      'error',
                      'Поддерживаются только форматы JPG, JPEG, PNG',
                      ''
                    )
                  }
                  return isValid || Upload.LIST_IGNORE
                }}
                customRequest={() => false}
                itemRender={(_, file, fileList, { remove }) => {
                  return (
                    <div className={styles.photoWrapper}>
                      <img
                        src={file.thumbUrl}
                        alt='Изображение'
                        width={84}
                        height={82}
                        style={{ objectFit: 'contain' }}
                      />
                      <button
                        type='button'
                        className={classNames(
                          'reset-button',
                          styles.removePhoto
                        )}
                        onClick={() => {
                          onRemovePhoto(file)
                          remove()
                        }}
                      >
                        <img src={removePhoto} alt='Удалить изображение' />
                      </button>
                    </div>
                  )
                }}
                className={classNames({
                  [styles.uploaderWrapperWithPhoto]: !!photo.length
                })}
              >
                <div className={styles.uploader}>
                  <InboxOutlined style={{ color: '#1677FF', fontSize: 48 }} />
                  <Typography.Text style={{ textAlign: 'left' }}>
                    Нажмите или перетащите файл в эту <br /> область, чтобы
                    загрузить
                  </Typography.Text>
                </div>
              </Upload.Dragger>
            </Form.Item>
          </div>
          <Form.Item
            label='Название категории'
            name='name'
            rules={[
              {
                required: true,
                message: 'Введите название'
              },
              {
                whitespace: true,
                message: 'Введите название'
              }
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          {role === 'ADMIN' && (
            <Collapse defaultActiveKey={['1']}>
              <Panel header='SEO' key='1' className={styles.panelHeader}>
                <Form.Item
                  label='Title'
                  name='title'
                  hasFeedback
                  style={{ marginBottom: '10px' }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  labelAlign='left'
                  className={styles.formLabel}
                  rules={[
                    {
                      whitespace: true,
                      message: 'Введите что то кроме пробелов'
                    }
                  ]}
                >
                  <Input className={styles.formInput} />
                </Form.Item>

                <Form.Item
                  label='Desc'
                  name='desc'
                  hasFeedback
                  style={{ marginBottom: '10px' }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  labelAlign='left'
                  className={styles.formLabel}
                  rules={[
                    {
                      whitespace: true,
                      message: 'Введите что то кроме пробелов'
                    }
                  ]}
                >
                  <Input.TextArea className={styles.formTextarea} />
                </Form.Item>

                {/* <Form.Item */}
                {/* label='H1'
                  name='h1'
                  hasFeedback
                  style={{ marginBottom: '10px' }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  labelAlign='left'
                  className={styles.formLabel}
                  rules={[
                    {
                      whitespace: true,
                      message: 'Введите что то кроме пробелов'
                    }
                  ]}
                >
                  <Input className={styles.formInput} />
                </Form.Item> */}

                <Form.Item
                  label='SEO text'
                  name='seoText'
                  hasFeedback
                  style={{ marginBottom: '10px' }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  labelAlign='left'
                  className={styles.formLabel}
                  rules={[
                    {
                      whitespace: true,
                      message: 'Введите что то кроме пробелов'
                    }
                  ]}
                >
                  <Input.TextArea className={styles.formTextarea} />
                </Form.Item>
              </Panel>
            </Collapse>
          )}
          <div className={styles.footer}>
            <Button
              htmlType='submit'
              type='primary'
              size='middle'
              loading={isLoading}
            >
              Сохранить изменения
            </Button>
            <Button
              danger
              disabled
              htmlType='submit'
              type='primary'
              size='middle'
            >
              Удалить
            </Button>
          </div>
        </Form>
      </ChangeDataWrapper>
    )
  }
)
