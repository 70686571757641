import { RollbackOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Space, Typography } from 'antd'
import styles from './RejectHorseModal.module.scss'

interface IRejectHorseModalProps {
  isOpen: boolean
  isLoading?: boolean
  onSuccess: (cancelReason: string) => void
  close: () => void
}

interface IFormData {
  reason: string
}

export const RejectHorseModal: React.FC<IRejectHorseModalProps> = ({
  isOpen,
  isLoading,
  onSuccess,
  close
}) => {
  const [form] = Form.useForm()

  const onClose = () => {
    close()
    form.resetFields()
  }

  const onSubmit = (data: IFormData) => {
    onSuccess(data.reason)
    close()
    form.resetFields()
  }

  return (
    <Modal centered open={isOpen} footer={null} onCancel={onClose} width={671}>
      <div className={styles.wrapper}>
        <RollbackOutlined className={styles.icon} />
        <div style={{ flex: 1 }}>
          <Typography.Title level={5}>Отклонить заявку</Typography.Title>
          <Typography.Text>
            Напишите причину отклонения
          </Typography.Text>
          <Form form={form} name='rejectHorse' onFinish={onSubmit}>
            <Form.Item
              name='reason'
              rules={[
                {
                  required: true,
                  message: 'Заполните причину отказа'
                },
                {
                  whitespace: true,
                  message: 'Заполните причину отказа'
                }
              ]}
              style={{ marginTop: 15 }}
              hasFeedback
            >
              <Input.TextArea
                placeholder='Опишите причину отказа'
                rows={3}
                styles={{ textarea: { fontFamily: 'SFProText' } }}
              />
            </Form.Item>
            <Space direction='horizontal' size={8}>
              <Button htmlType='button' onClick={onClose}>
                Закрыть
              </Button>
              <Button htmlType='submit' type='primary' danger loading={isLoading}>
                Отклонить
              </Button>
            </Space>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
