import { Tag } from 'antd'
import { ESellerStatus } from 'models/seller'

interface IOrderStatusItemData {
  key: ESellerStatus,
  label: React.ReactNode
}

export const sellerStatuses = {
  [ESellerStatus.WAITING_APPROVE]: {
    value: ESellerStatus.WAITING_APPROVE,
    label: 'Ожидает подтверждения',
    color: 'purple'
  },
  [ESellerStatus.APPROVED]: {
    value: ESellerStatus.APPROVED,
    label: 'Подтвержден',
    color: 'green'
  },
  [ESellerStatus.DECLINED]: {
    value: ESellerStatus.DECLINED,
    label: 'Отклонен',
    color: 'red'
  },
  [ESellerStatus.ARCHIVED]: {
    value: ESellerStatus.ARCHIVED,
    label: 'Удален',
    color: 'red'
  }
}

const approvedStatus: IOrderStatusItemData = {
  key: ESellerStatus.APPROVED,
  label: (
    <Tag color={sellerStatuses[ESellerStatus.APPROVED].color}>
      {sellerStatuses[ESellerStatus.APPROVED].label}
    </Tag>
  )
}

const declinedStatus: IOrderStatusItemData = {
  key: ESellerStatus.DECLINED,
  label: (
    <Tag color={sellerStatuses[ESellerStatus.DECLINED].color}>
      {sellerStatuses[ESellerStatus.DECLINED].label}
    </Tag>
  )
}

export const getSellerStatusItems = (status: ESellerStatus) => {
  if (status === ESellerStatus.WAITING_APPROVE) {
    return [approvedStatus, declinedStatus]
  }
  if (status === ESellerStatus.DECLINED) {
    return [approvedStatus]
  }
  return []
}
