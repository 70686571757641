import React from 'react'
import { AxiosError } from 'axios'
import { appActions } from 'store/app'
import { authApi } from 'api/auth'
import { TOKEN } from 'shared/constants/app'
import { useAppDispatch } from './useReduxHooks'

export const useAuth = (): { isLoaded: boolean; isError: boolean } => {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem(TOKEN)
        if (!token) return
        const response = await authApi.checkAuth()
        dispatch(appActions.setToken(response.data))
      } catch (e) {
        const error = e as AxiosError
        if(error?.response?.status === 401 || error?.response?.status === 400 || error?.response?.status === 403) {
          return dispatch(appActions.logout())
        }
        setIsError(true)
      } finally {
        setIsLoaded(true)
      }
    }
    checkAuth()
  }, [])

  return { isLoaded, isError }
}
