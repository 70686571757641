import { Tag } from 'antd'
import { EHorseStatus } from 'models/horse'

interface IHorseStatusItemData {
  key: EHorseStatus,
  label: React.ReactNode
}

export const horseStatuses = {
  [EHorseStatus.MODERATION]: {
    value: EHorseStatus.MODERATION,
    label: 'На модерации',
    color: 'geekblue'
  },
  [EHorseStatus.PUBLISHED]: {
    value: EHorseStatus.PUBLISHED,
    label: 'Опубликован',
    color: 'green'
  },
  [EHorseStatus.REJECTED]: {
    value: EHorseStatus.REJECTED,
    label: 'Отклонен',
    color: 'red'
  }
}

const moderationStatus: IHorseStatusItemData = {
  key: EHorseStatus.MODERATION,
  label: (
    <Tag color={horseStatuses[EHorseStatus.MODERATION].color}>
      {horseStatuses[EHorseStatus.MODERATION].label}
    </Tag>
  )
}

const approvedStatus: IHorseStatusItemData = {
  key: EHorseStatus.PUBLISHED,
  label: (
    <Tag color={horseStatuses[EHorseStatus.PUBLISHED].color}>
      {horseStatuses[EHorseStatus.PUBLISHED].label}
    </Tag>
  )
}

const rejectedStatus: IHorseStatusItemData = {
  key: EHorseStatus.REJECTED,
  label: (
    <Tag color={horseStatuses[EHorseStatus.REJECTED].color}>
      {horseStatuses[EHorseStatus.REJECTED].label}
    </Tag>
  )
}

export const getHorseStatusItems = (status: EHorseStatus) => {
  if (status === EHorseStatus.MODERATION) {
    return [approvedStatus, rejectedStatus]
  }
  if (status === EHorseStatus.PUBLISHED) {
    return [moderationStatus, rejectedStatus]
  }
  if (status === EHorseStatus.REJECTED) {
    return [moderationStatus, approvedStatus]
  }
  return []
}
