import React from 'react'
import { Button, Form, Input } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { AxiosError } from 'axios'
import { AuthContentWrapper } from 'components/layout/AuthContentWrapper/AuthContentWrapper'
import { LargeText } from 'components/ui'
import { authApi } from 'api/auth'
import { showMessage } from 'shared/utils/showMessage'
import { showServerError } from 'shared/utils/showServerError'
import { useNavigate } from 'react-router-dom'
import styles from './Email.module.scss'

interface IEmailForm {
  email: string
}

interface IEmailProps {
  setEmail: (email: string) => void
  onSuccess: () => void
}

export const Email: React.FC<IEmailProps> = ({ setEmail, onSuccess }) => {
  const navigate = useNavigate()
  
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async (data: IEmailForm) => {
    try {
      setIsLoading(true)
      await authApi.recoverPasswordSendCode(data)
      showMessage(
        'success',
        'Код для восстановления направлен вам на почту',
        ''
      )
      setEmail(data.email)
      onSuccess()
    } catch (e) {
      const error = e as AxiosError
      if (error.response?.status === 400) {
        return form.setFields([
          {
            name: 'email',
            errors: ['Пользователя с таким электронным адресом не существует']
          }
        ])
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthContentWrapper
      title='Восстановление пароля'
      titleLevel={2}
      titleOffset={9}
      titleClass={styles.title}
      padding='44px 66px 59px'
      onGoBack={() => navigate(-1)}
    >
      <LargeText className={styles.description}>
        На вашу почту будет направлен код подтверждения для восстановления
        пароля
      </LargeText>
      <Form form={form} name='recoveryEmail' size='large' onFinish={onSubmit}>
        <Form.Item
          name='email'
          rules={[
            {
              type: 'email',
              message: 'Некорректный электронный адрес'
            },
            {
              required: true,
              message: 'Заполните электронный адрес'
            }
          ]}
          hasFeedback
        >
          <Input
            placeholder='Электронный адрес'
            prefix={<MailOutlined className='icon' />}
          />
        </Form.Item>
        <Button block htmlType='submit' type='primary' loading={isLoading}>
          Отправить код
        </Button>
      </Form>
    </AuthContentWrapper>
  )
}
