import React from 'react'
import { Button, Typography } from 'antd'
import { IFrontVariation } from 'models/product'
import { DeleteOutlined, DownOutlined, EditFilled, PlusOutlined } from '@ant-design/icons'
import styles from '../ProductForm.module.scss'

interface ISelectProductProps {
  variation?: IFrontVariation
  openUpdateProductModal: () => void
  openChooseProductModal: () => void
  openCreateProductModal: () => void
  onDelete: () => void
}

export const SelectProduct: React.FC<ISelectProductProps> = React.memo(({ variation, openUpdateProductModal, openChooseProductModal, openCreateProductModal, onDelete }) => {
  return (
    <div className={styles.variantsActionsRow}>
      {variation?.product ? (
        <>
          <Typography.Text className={styles.selectedVariationProductName}>
            {variation.product.name}
          </Typography.Text>
          <Button
            type='primary'
            htmlType='button'
            size='small'
            style={{ marginLeft: 'auto' }}
            icon={<EditFilled style={{ fontSize: 14 }} />}
            onClick={openUpdateProductModal}
          />
        </>
      ) : (
        <>
          <Typography.Link
            style={{
              marginRight: 10
            }}
            onClick={openChooseProductModal}
          >
            <span>Выбрать товар</span>
            <DownOutlined style={{ paddingLeft: 4 }} />
          </Typography.Link>
          <Button
            type='primary'
            htmlType='button'
            size='small'
            style={{ marginLeft: 'auto' }}
            icon={<PlusOutlined style={{ fontSize: 14 }} />}
            onClick={openCreateProductModal}
          >
            Создать
          </Button>
        </>
      )}
      <Button
        type='primary'
        htmlType='button'
        size='small'
        icon={<DeleteOutlined style={{ fontSize: 14 }} />}
        onClick={onDelete}
        ghost
        danger
      />
    </div>
  )
})
