import React from 'react'
import { Button, Typography } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import { sellerApi } from 'api/seller'
import { showServerError } from 'shared/utils/showServerError'
import { NOTIFICATIONS_BOT_URL } from 'shared/constants/urls'
import styles from './SellerNotificationsTelegram.module.scss'

interface ISellerNotificationsTelegramProps {
  sellerId: string
}

export const SellerNotificationsTelegram: React.FC<
  ISellerNotificationsTelegramProps
> = ({ sellerId }) => {
  const [telegramToken, setTelegramToken] = React.useState<string | null>(null)

  React.useEffect(() => {
    const getSeller = async () => {
      try {
        const response = await sellerApi.getSeller(sellerId)
        setTelegramToken(response.data.telegram_api_token)
      } catch (e) {
        showServerError(e)
      }
    }
    getSeller()
  }, [])

  return (
    <div style={{ paddingBottom: 80 }}>
      {telegramToken && (
        <>
          <Typography.Title level={2} className={styles.title}>
            Подключения телеграм уведомлений
          </Typography.Title>
          <Typography.Text className={styles.description}>
            Вы можете получать уведомления в подключенные месенджеры. <br />{' '}
            Выберите нужный мессенджер и нажмите “Подключить”.
          </Typography.Text>
          <a
            href={`${NOTIFICATIONS_BOT_URL}?start=${telegramToken}`}
            target='_blank'
            rel='noreferrer'
          >
            <Button type='primary' size='large' icon={<SendOutlined />}>
              Открыть телеграм бота
            </Button>
          </a>
        </>
      )}
    </div>
  )
}
