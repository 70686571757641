export const TOKEN = 'token'
export const DEBOUNCE_DELAY = 500
export const LOCAL_STORAGE_SELECTED_CITY_KEY = 'selectedCity'

export const VK_APP_ID = process.env.REACT_APP_VK_APP_ID as string
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string

export const APK_APP_URL = process.env.REACT_APP_APK_APP_URL as string
export const DOWNLOAD_ANDROID_APP_LINK = process.env.REACT_APP_DOWNLOAD_ANDROID_APP_LINK as string
export const DOWNLOAD_IOS_APP_LINK = process.env.REACT_APP_DOWNLOAD_IOS_APP_LINK as string
