import React from 'react'
import { MainTitle } from 'components/ui/MainTitle/MainTitle'
import { CategoriesColumn } from 'components/categories/CategoriesColumn/CategoriesColumn'
import { ChangeCategory } from 'components/categories/ChangeCategory/ChangeCategory'
import { SubCategoriesColumn } from 'components/categories/SubCategoriesColumn/SubCategoriesColumn'
import { ChangeSubCategory } from 'components/categories/ChangeSubCategory/ChangeSubCategory'
import {
  ICategory,
  IFrontCategory,
  IFrontSubCategory,
  ISubCategory
} from 'models/product'
import styles from './Categories.module.scss'

export const Categories: React.FC = () => {
  const [categories, setCategories] = React.useState<IFrontCategory[]>([])
  const [selectedCategory, setSelectedCategory] =
    React.useState<IFrontCategory | null>(null)

  const [subCategories, setSubCategories] = React.useState<IFrontSubCategory[]>(
    []
  )
  const [selectedSubCategory, setSelectedSubCategory] =
    React.useState<IFrontSubCategory | null>(null)

  const onSelectCategory = React.useCallback(
    (category: IFrontCategory | null) => {
      setSelectedCategory(category)
      setSelectedSubCategory(null)
    },
    []
  )
  const createNewCategory = React.useCallback(
    (newCategory: IFrontCategory) =>
      setCategories((prev) => [...prev, newCategory]),
    [categories]
  )
  const updateCategory = React.useCallback(
    (updatedCategory: ICategory) =>
      setCategories((prev) =>
        prev.map((item) => {
          if (item._id === selectedCategory?._id) {
            const newData: IFrontCategory = {
              ...updatedCategory,
              isNew: false
            }
            setSelectedCategory(newData)
            return newData
          }
          return item
        })
      ),
    [categories, selectedCategory]
  )

  const createNewSubCategory = React.useCallback(
    (newSubCategory: IFrontSubCategory) =>
      setSubCategories((prev) => [...prev, newSubCategory]),
    [subCategories]
  )
  const updateSubCategory = React.useCallback(
    (updatedSubCategory: ISubCategory) => {
      const updatedSelectedSubCategory: IFrontSubCategory = {
        ...updatedSubCategory,
        isNew: false
      }
      if (
        selectedSubCategory?.category_id._id !==
        updatedSelectedSubCategory.category_id._id
      ) {
        setSelectedCategory({
          ...updatedSelectedSubCategory.category_id,
          isNew: false
        })
        setSelectedSubCategory(updatedSelectedSubCategory)
      } else {
        setSubCategories((prev) =>
          prev.map((item) => {
            if (item._id === selectedSubCategory._id) {
              return updatedSelectedSubCategory
            }
            return item
          })
        )
        setSelectedSubCategory(updatedSelectedSubCategory)
      }
    },
    [subCategories, selectedSubCategory]
  )

  const deleteSubCategory = React.useCallback(
    (subCategoryId: string) => {
      setSubCategories((prev) =>
        prev.filter((item) => item._id !== subCategoryId)
      )
      setSelectedSubCategory(null)
    },
    [subCategories]
  )

  return (
    <div style={{ paddingBottom: 80 }}>
      <MainTitle text='Категории' />
      <div className={styles.content}>
        <CategoriesColumn
          categories={categories}
          selectedCategory={selectedCategory}
          setCategories={setCategories}
          selectCategory={onSelectCategory}
          createNewCategory={createNewCategory}
        />
        {selectedCategory && (
          <SubCategoriesColumn
            subCategories={subCategories}
            selectedSubCategory={selectedSubCategory}
            selectedCategory={selectedCategory}
            setSubCategories={setSubCategories}
            setSelectedSubCategory={setSelectedSubCategory}
            createNewSubCategory={createNewSubCategory}
          />
        )}
        {selectedCategory && !selectedSubCategory && (
          <ChangeCategory
            currentCategory={selectedCategory}
            onSuccess={updateCategory}
          />
        )}
        {selectedSubCategory && (
          <ChangeSubCategory
            categories={categories}
            currentSubCategory={selectedSubCategory}
            onSuccess={updateSubCategory}
            onDeleteSuccess={deleteSubCategory}
          />
        )}
      </div>
    </div>
  )
}
