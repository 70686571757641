import React from 'react'
import { Checkbox, Typography, message } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { sellerApi } from 'api/seller'
import { IDeliveryWays, TVariantSeller } from 'models/seller'
import { superAdminRoutes } from 'shared/constants/routes'
import { showServerError } from 'shared/utils/showServerError'
import styles from './SellerDelivery.module.scss'

interface IDeliveryItemProps {
  label: string
  value: string
  checked: boolean
  onChange: (e: CheckboxChangeEvent) => void
}

interface ISellerDeliveryProps {
  type: TVariantSeller
  sellerId: string
}

const getDeliveryWaysLabel = (key: keyof IDeliveryWays) => {
  switch (key) {
  case 'pickup':
    return 'Самовывоз'
  case 'delivery_to_point':
    return 'Доставка до пункта выдачи'
  case 'yandex_express':
    return 'Яндекс экспресс'
  case 'courier':
    return 'Доставка курьером'
  default:
    return 'Неизвестно'
  }
}

const DeliveryItem: React.FC<IDeliveryItemProps> = ({
  label,
  value,
  checked,
  onChange
}) => {
  return (
    <div className={styles.deliveryWrapper}>
      <Checkbox checked={checked} value={value} onChange={onChange}>
        <Typography.Text>{label}</Typography.Text>
      </Checkbox>
    </div>
  )
}

export const SellerDelivery: React.FC<ISellerDeliveryProps> = ({
  type,
  sellerId
}) => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = React.useState(true)
  const [isError, setIsError] = React.useState(false)
  const [deliveryWays, setDeliveryWays] = React.useState<IDeliveryWays | null>(
    null
  )

  React.useEffect(() => {
    const getDeliveryWays = async () => {
      try {
        message.loading('Загрузка способов доставки')
        const response = await sellerApi.getDeliveryWays(sellerId)
        const ways = {
          ...response.data,
          yandex_express: response.data.yandex_express || false
        }
        setDeliveryWays(ways)
      } catch (e) {
        const error = e as AxiosError
        if (
          (error.response?.status === 500 ||
            error.response?.status === 400 ||
            error.response?.status === 404) &&
          type === 'seller'
        ) {
          return navigate(superAdminRoutes.SELLERS)
        }
        showServerError(e)
        setIsError(true)
      } finally {
        message.destroy()
        setIsLoading(false)
      }
    }
    getDeliveryWays()
  }, [])

  const changeDeliveryWays = async (
    key: keyof IDeliveryWays,
    value: boolean
  ) => {
    try {
      message.loading('Обновление данных')
      const newDeliveryWays = {
        ...(deliveryWays as IDeliveryWays),
        [key]: value
      }
      await sellerApi.changeDeliveryWays({
        ...newDeliveryWays,
        seller_id: sellerId
      })
      setDeliveryWays(newDeliveryWays)
      message.destroy()
      message.success('Данные успешно обновлены')
    } catch (e) {
      message.destroy()
      showServerError()
    }
  }

  const onChange = async (e: CheckboxChangeEvent) => {
    changeDeliveryWays(e.target.value as keyof IDeliveryWays, e.target.checked)
  }

  return (
    <>
      {isLoading || isError ? null : (
        <div style={{ paddingBottom: 80 }}>
          <Typography.Title level={2} className={styles.title}>
            Способы доставки
          </Typography.Title>
          {deliveryWays &&
            (Object.keys(deliveryWays) as Array<keyof IDeliveryWays>).map(
              (key) => (
                <DeliveryItem
                  key={key}
                  label={getDeliveryWaysLabel(key)}
                  value={key}
                  checked={deliveryWays[key]}
                  onChange={onChange}
                />
              )
            )}
        </div>
      )}
    </>
  )
}
