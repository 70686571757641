import React from 'react'
import { Button, Checkbox, Form, Input } from 'antd'
import { FormOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
import { AxiosError } from 'axios'
import { AuthContentWrapper } from 'components/layout/AuthContentWrapper/AuthContentWrapper'
import { ContinueWithApps } from 'components/auth/ContinueWithApps/ContinueWithApps'
import { authApi } from 'api/auth'
import { passwordPattern } from 'shared/utils/patterns'
import { showServerError } from 'shared/utils/showServerError'
import { useNavigate } from 'react-router-dom'
import styles from './Data.module.scss'

interface IDataForm {
  email: string
  password: string
}

interface IDataProps {
  setActiveScreenPassword: () => void
  setActiveScreenSeller: () => void
  setIsEmptyPassword: (isEmpty: boolean) => void
  setToken: (token: string) => void
  setEmail: (email: string) => void
  onSuccess: () => void
}

export const Data: React.FC<IDataProps> = ({
  setActiveScreenPassword,
  setActiveScreenSeller,
  setIsEmptyPassword,
  setEmail,
  setToken,
  onSuccess
}) => {
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async (data: IDataForm) => {
    try {
      setIsLoading(true)
      const response = await authApi.signUp(data)
      setToken(response.data.access_token)
      setEmail(data.email)
      onSuccess()
    } catch (e) {
      const error = e as AxiosError
      if (error.response?.status === 400) {
        return form.setFields([
          {
            name: 'email',
            errors: ['Пользователь с таким электронным адресом уже существует']
          }
        ])
      }
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthContentWrapper
      title='Регистрация'
      titleOffset={35}
      titleClass={styles.title}
      padding='60px 66px'
      onGoBack={() => navigate(-1)}
    >
      <Form
        form={form}
        name='signUp'
        size='large'
        initialValues={{
          rememberMe: true
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name='email'
          rules={[
            {
              type: 'email',
              message: 'Некорректный электронный адрес'
            },
            {
              required: true,
              message: 'Заполните электронный адрес'
            }
          ]}
          hasFeedback
        >
          <Input
            placeholder='Электронный адрес'
            prefix={<MailOutlined className='icon' />}
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Заполните пароль'
            },
            ...passwordPattern
          ]}
          style={{ marginBottom: 17 }}
          hasFeedback
        >
          <Input.Password
            type='password'
            placeholder='Пароль'
            prefix={<LockOutlined className='icon' />}
          />
        </Form.Item>
        <Form.Item
          name='rememberMe'
          valuePropName='checked'
          style={{ marginBottom: 17 }}
        >
          <Checkbox>Запомнить меня</Checkbox>
        </Form.Item>
        <Button
          block
          htmlType='submit'
          type='primary'
          icon={<FormOutlined />}
          style={{ marginBottom: 24 }}
          loading={isLoading}
        >
          Зарегистрироваться
        </Button>
        <ContinueWithApps
          setActiveScreenPassword={setActiveScreenPassword}
          setActiveScreenSeller={setActiveScreenSeller}
          setIsEmptyPassword={setIsEmptyPassword}
          setToken={setToken}
        />
      </Form>
    </AuthContentWrapper>
  )
}
