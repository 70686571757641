import { Typography } from 'antd'
import { IAddVariation, IFrontVariation } from 'models/product'
import { showMessage } from 'shared/utils/showMessage'

interface IErrorProductProps {
  variation: IFrontVariation
}

const ErrorProduct: React.FC<IErrorProductProps> = ({ variation }) => {
  return (
    <>
      <Typography.Text style={{ fontSize: 16, fontWeight: 600, display: 'block', marginBottom: 7 }}>Выберите товар для вариации</Typography.Text>
      {variation.firstCharacteristicValue && (
        <Typography.Text  style={{ fontSize: 16, fontWeight: 500, display: 'block' }}>{variation.firstCharacteristicName}: {variation.firstCharacteristicValue}</Typography.Text>
      )}
      {variation.secondCharacteristicValue && (
        <Typography.Text  style={{ fontSize: 16, fontWeight: 500, display: 'block' }}>{variation.secondCharacteristicName}: {variation.secondCharacteristicValue}</Typography.Text>
      )}
    </>
  )
}

export const validateVariations = (variations: IFrontVariation[]): { isError: boolean } => {
  const clearedVariations = variations.filter((_, index) => index + 1 !== variations.length)
  for (let i = 0; i < clearedVariations.length; i++) {
    const item = clearedVariations[i]
    if (!item.firstCharacteristicValue && !item.secondCharacteristicValue && item.product) {
      showMessage('error', `Заполните одно из значений для ${i + 1} вариации, либо удалите товар`, '')
      return { isError: true }
    }
    if ((item.firstCharacteristicValue || item.secondCharacteristicValue) && !item.product) {
      showMessage('error', <ErrorProduct variation={item} />, '')
      return { isError: true }
    }
  }
  return { isError: false }
}

export const getNormalizedVariations = (variations: IFrontVariation[] | undefined, firstVariationName: string | undefined, secondVariationName: string | undefined): IAddVariation[] | undefined => {
  if (!variations) {
    return undefined
  }
  const clearedVariations = variations.filter((_, index) => index + 1 !== variations.length)
  return clearedVariations.filter(item => (item.firstCharacteristicValue || item.secondCharacteristicValue) && item.product).map(item => {
    if (item.firstCharacteristicValue && item.secondCharacteristicValue) {
      return {
        _id: item.product?.id,
        characteristic: [
          {
            name: firstVariationName,
            value: item.firstCharacteristicValue
          },
          {
            name: secondVariationName,
            value: item.secondCharacteristicValue
          }
        ]
      } as IAddVariation
    }
    if (item.firstCharacteristicValue) {
      return {
        _id: item.product?.id,
        characteristic: [
          {
            name: firstVariationName,
            value: item.firstCharacteristicValue
          }
        ]
      } as IAddVariation
    }
    return {
      _id: item.product?.id,
      characteristic: [
        {
          name: firstVariationName,
          value: ''
        },
        {
          name: secondVariationName,
          value: item.secondCharacteristicValue
        }
      ]
    } as IAddVariation
  }) as IAddVariation[]
}
