import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Space, Typography } from 'antd'
import styles from './CancelOrder.module.scss'

interface ICancelOrderProps {
  isOpen: boolean
  isLoading?: boolean
  onSuccess: (cancelReason: string) => void
  close: () => void
}

interface IFormData {
  reason: string
}

export const CancelOrder: React.FC<ICancelOrderProps> = ({
  isOpen,
  isLoading,
  onSuccess,
  close
}) => {
  const [form] = Form.useForm()

  const onClose = () => {
    close()
    form.resetFields()
  }

  const onSubmit = (data: IFormData) => {
    onSuccess(data.reason)
    close()
    form.resetFields()
  }

  return (
    <Modal centered open={isOpen} footer={null} onCancel={onClose} width={671}>
      <div className={styles.wrapper}>
        <ExclamationCircleOutlined className={styles.icon} />
        <div>
          <Typography.Title level={5}>Отказаться от заказа</Typography.Title>
          <Typography.Text>
            Чтобы отказаться от заказа, укажите причину отказа или опишите
            ситуацию.
          </Typography.Text>
          <Form form={form} name='cancelOrder' onFinish={onSubmit}>
            <Form.Item
              name='reason'
              rules={[
                {
                  required: true,
                  message: 'Заполните причину отказа'
                },
                {
                  whitespace: true,
                  message: 'Заполните причину отказа'
                }
              ]}
              style={{ marginTop: 15 }}
              hasFeedback
            >
              <Input.TextArea
                placeholder='Опишите причину отказа'
                rows={3}
                styles={{ textarea: { fontFamily: 'SFProText' } }}
              />
            </Form.Item>
            <Space direction='horizontal' size={8}>
              <Button htmlType='button' onClick={onClose}>
                Закрыть
              </Button>
              <Button htmlType='submit' type='primary' loading={isLoading}>
                Отправить
              </Button>
            </Space>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
